<div class="modal-header bg-danger" >
    <h5 class="modal-title pull-left">{{title}}</h5>
</div>
<div class="modal-body" >
    <div [innerHTML]="text">

    </div>
    <div style="overflow-x: scroll; max-height: 300px; overflow-y: scroll;" *ngIf="tableContent?.length > 0">
        <table class="table">
          <thead>
            <tr>
              <ng-container *ngFor="let key of getKeys(tableContent[0])">
                <th style="text-align:left;">{{ key }}</th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of tableContent">
              <ng-container *ngFor="let key of getKeys(item)">
                <td>{{ item[key] }}</td>
              </ng-container>
            </tr>
          </tbody>
        </table>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="buttonClicked(event)">{{buttonText}}</button>
</div>