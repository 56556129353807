<div class="modal-header">
  <h5 class="modal-title pull-left">Add Tools</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form class="p-5" [formGroup]="projectSystemToolsAddForm">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-8">
        <div class="form-group row">
          <label for="inputEmail3" class="col-sm-4 col-form-label col-form-label-sm">Tool Category</label>
          <div class="col-sm-8">
            <ngx-select optionValueField="toolCategoryId" optionTextField="toolCategoryDesignation" [items]="toolCategory" [placeholder]="'Select Category'" formControlName="categoryId" appMandatoryField appInfoPromptBox [page]="'specialTools'" [component]="'add'" [field]="'categoryId'"></ngx-select>
            <div *ngIf="projectSystemToolsAddForm.controls['categoryId'].invalid && (projectSystemToolsAddForm.controls['categoryId'].dirty || projectSystemToolsAddForm.controls['categoryId'].touched)" class="ng-validator">
              <div *ngIf="projectSystemToolsAddForm.controls['categoryId'].errors.required">
                This field is required.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">Supplier</label>
          <div class="col-sm-8">
            <ngx-select optionValueField="stakeholderId" optionTextField="nameUsed" [items]="suppliers" [placeholder]="'Select Supplier'" formControlName="supplierId" appInfoPromptBox [page]="'specialTools'" [component]="'add'" [field]="'supplierId'"></ngx-select>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">Stadler Article ID </label>
          <div class="col-sm-6">
            <input type="text" (keyup)="checkExistArticleId()" class="form-control " formControlName="toolArticleId" placeholder="Stadler Article ID" [appDecimal]="0" [max]="29999999" appMandatoryField [disabled]="isDummyArticle" [readonly]="isDummyArticle" appInfoPromptBox [page]="'specialTools'" [component]="'add'" [field]="'toolArticleId'">
            <span *ngIf="existArticleId" class="text-danger"><small>This Stadler article id already exist</small></span>
            <div *ngIf="projectSystemToolsAddForm.controls['toolArticleId'].invalid && (projectSystemToolsAddForm.controls['toolArticleId'].dirty || projectSystemToolsAddForm.controls['toolArticleId'].touched)" class="ng-validator">
              <div *ngIf="projectSystemToolsAddForm.controls['toolArticleId'].errors.required">
                This field is required.
              </div>
              <div *ngIf="projectSystemToolsAddForm.controls['toolArticleId'].value != '' && projectSystemToolsAddForm.controls['toolArticleId'].errors.valueZero">
                Should be Greater Than 0
              </div>
              <div *ngIf="projectSystemToolsAddForm.controls['toolArticleId'].errors.maxlength  || projectSystemToolsAddForm.controls['toolArticleId'].errors.minlength">
                Article ID length should be 8 characters
              </div>
              <div *ngIf="projectSystemToolsAddForm.controls['toolArticleId'].errors.max">
                Stadler Article IDs should be less than 30000000
              </div>
              <div *ngIf="projectSystemToolsAddForm.controls['toolArticleId'].errors.min">
                Dummy Stadler Article IDs should be greater than 30000000
              </div>
            </div>

          </div>
          <div class="col-sm-2 form-switch">
            <div class="form-check" style="padding-top: 0.3rem">
              <input class="form-check-input" type="checkbox" value="" [checked] = "isDummyArticle" id="dummyArticleId" (change)="dummyArticleCheckBoxClicked($event)" >
              <label class="form-check-label" for="dummyArticleId">
                dummy
              </label>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 col-form-label col-form-label-sm">Supplier Article ID</label>
          <div class="col-sm-8">
            <input type="text" formControlName="toolSupplierArticleId" class="form-control " placeholder="Supplier Article ID" appInfoPromptBox [page]="'specialTools'" [component]="'add'" [field]="'toolSupplierArticleId'">
            <div *ngIf="projectSystemToolsAddForm.controls['toolSupplierArticleId'].invalid && (projectSystemToolsAddForm.controls['toolSupplierArticleId'].dirty || projectSystemToolsAddForm.controls['toolSupplierArticleId'].touched)" class="ng-validator">
              <div *ngIf="projectSystemToolsAddForm.controls['toolSupplierArticleId'].errors.maxlength">
                Supplier Article ID maximum length should be 100 characters
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="designationDe" class="col-sm-4 col-form-label col-form-label-sm">Tool Designation DE</label>
          <div class="col-sm-8">
            <input type="text" class="form-control " formControlName="designationDe" placeholder="Tool Designation DE" appMandatoryField appInfoPromptBox [page]="'specialTools'" [component]="'add'" [field]="'designationDe'">
            <div *ngIf="projectSystemToolsAddForm.controls['designationDe'].invalid && (projectSystemToolsAddForm.controls['designationDe'].dirty || projectSystemToolsAddForm.controls['designationDe'].touched)" class="ng-validator">
              <div *ngIf="projectSystemToolsAddForm.controls['designationDe'].errors.required">
                This field is required.
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="designationEn" class="col-sm-4 col-form-label col-form-label-sm">Tool Designation EN</label>
          <div class="col-sm-8">
            <input type="text" class="form-control " formControlName="designationEn" placeholder="Tool Designation EN" appInfoPromptBox [page]="'specialTools'" [component]="'add'" [field]="'designationEn'" >
            <div *ngIf="projectSystemToolsAddForm.controls['designationEn'].invalid && (projectSystemToolsAddForm.controls['designationEn'].dirty || projectSystemToolsAddForm.controls['designationEn'].touched)" class="ng-validator">
              <div *ngIf="projectSystemToolsAddForm.controls['designationEn'].errors.required">
                This field is required.
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">Tool Cost</label>
          <div class="col-sm-4">
            <input [required]="projectSystemToolsAddForm.controls['currency'].value" type="text" class="form-control" formControlName="cost" [appThousandSeparator]="projectSystemToolsAddForm.controls['cost'].value" placeholder="Tool Cost" [appDecimal]="2" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'specialTools'" [component]="'add'" [field]="'cost'">
            <div *ngIf="projectSystemToolsAddForm.controls['cost'].invalid && (projectSystemToolsAddForm.controls['cost'].dirty || projectSystemToolsAddForm.controls['cost'].touched)" class="ng-validator">
              <div *ngIf="projectSystemToolsAddForm.controls['cost'].errors.required">
                This field is required.
              </div>
              <div *ngIf="projectSystemToolsAddForm.controls['cost'].value != '' && projectSystemToolsAddForm.controls['cost'].errors.valueZero">
                Should be Greater Than 0
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <ngx-select [required]="projectSystemToolsAddForm.controls['cost'].value > 0" [allowClear]="true" optionValueField="code" optionTextField="code" [items]="currencyData" [placeholder]="'Select Currency'" formControlName="currency" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'specialTools'" [component]="'add'" [field]="'currency'"></ngx-select>
            <div *ngIf="projectSystemToolsAddForm.controls['currency'].invalid && (projectSystemToolsAddForm.controls['currency'].dirty || projectSystemToolsAddForm.controls['currency'].touched)" class="ng-validator">
              <div *ngIf="projectSystemToolsAddForm.controls['currency'].errors.required">
                This field is required.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">Tool Weight</label>
          <div class="col-sm-4">
            <input [required]="projectSystemToolsAddForm.controls['weightUnit'].value" type="text" class="form-control " formControlName="weight" [appThousandSeparator]="projectSystemToolsAddForm.controls['weight'].value" placeholder="Tool Weight" [appDecimal]="2" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'specialTools'" [component]="'add'" [field]="'weight'">
            <div *ngIf="projectSystemToolsAddForm.controls['weight'].invalid && (projectSystemToolsAddForm.controls['weight'].dirty || projectSystemToolsAddForm.controls['weight'].touched)" class="ng-validator">
              <div *ngIf="projectSystemToolsAddForm.controls['weight'].errors.required">
                This field is required.
              </div>
              <div *ngIf="projectSystemToolsAddForm.controls['weight'].value != '' && projectSystemToolsAddForm.controls['weight'].errors.valueZero">
                Should be Greater Than 0
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <ngx-select [required]="projectSystemToolsAddForm.controls['weight'].value > 0" [allowClear]="true" optionValueField="id" optionTextField="value" [items]="weightUnit" [placeholder]="'Select Weight Unit'" formControlName="weightUnit" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'specialTools'" [component]="'add'" [field]="'weightUnit'"></ngx-select>
            <div *ngIf="projectSystemToolsAddForm.controls['weightUnit'].invalid && (projectSystemToolsAddForm.controls['weightUnit'].dirty || projectSystemToolsAddForm.controls['weightUnit'].touched)" class="ng-validator">
              <div *ngIf="projectSystemToolsAddForm.controls['weightUnit'].errors.required">
                This field is required.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">Tool Dimensions</label>
          <div class="col-sm-4">
            <input type="text" class="form-control " formControlName="dimensions" placeholder="Tool Dimensions" appInfoPromptBox [page]="'specialTools'" [component]="'add'" [field]="'dimensions'"/>
            <div *ngIf="projectSystemToolsAddForm.controls['dimensions'].invalid && (projectSystemToolsAddForm.controls['dimensions'].dirty || projectSystemToolsAddForm.controls['dimensions'].touched)" class="ng-validator">
              <div *ngIf="projectSystemToolsAddForm.controls['dimensions'].errors.required">
                This field is required.
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <ngx-select optionValueField="id" optionTextField="value" [items]="dimentionUnitArray" [placeholder]="'Select Dimensions Unit'" formControlName="dimentionsUnit" appInfoPromptBox [page]="'specialTools'" [component]="'add'" [field]="'dimentionsUnit'"></ngx-select>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-sm-4 col-form-label col-form-label-sm">Comment</label>
          <div class="col-sm-8">
            <textarea formControlName="comment" class="form-control" appInfoPromptBox [page]="'specialTools'" [component]="'add'" [field]="'comment'"></textarea>
            <div *ngIf="projectSystemToolsAddForm.controls['comment'].invalid && (projectSystemToolsAddForm.controls['comment'].dirty || projectSystemToolsAddForm.controls['comment'].touched)" class="ng-validator">
              <div *ngIf="projectSystemToolsAddForm.controls['comment'].errors.maxlength">
                Maximum length is 10'000
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <img class="img img-thumbnail" [src]="url" height="200"> <br/>
        <div class="input-group">
          <div class="input-group-prepend">
          </div>
          <div class="custom-file">
            <input type="file" (change)="onSelectFile($event)" class="custom-file-input" id="inputGroupFile01"
                   aria-describedby="inputGroupFileAddon01" appInfoPromptBox [page]="'specialTools'" [component]="'add'" [field]="'image'">
            <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <div class="mandatory-description d-flex flex-row">
      <span class="mandatory-description-asterisk mandatory-asterisk">*</span>
      <span class="mandatory-field-text">mandatory fields</span>
      <span class="mandatory-description-conditional-asterisk conditional-mandatory-asterisk">*</span>
      <span class="mandatory-field-text">conditional mandatory fields</span>
    </div> -->
    <app-action-comment [btnLoading]="laddaLoading" [okBtnName]="'Save'" [closeBtnName]="'Close'" [saveDisable]="projectSystemToolsAddForm.invalid || submitDisabled" [mandatoryDescriptionVisible]="true" [commentVisible]="false" [commentRequired]="false" [justificationCategoryVisible]="false" [justificationCategoryRequired]="false" (okClick)="createSpecialtools($event)" (cancelClick)="bsModalRef.hide()"></app-action-comment>
  </div>
</form>
