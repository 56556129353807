import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TaskStageService {

    constructor(private http: HttpClient) { }

    /**
     * Get All Task Stage
     */
    getAllTaskStage()
    {
        return this.http.get(environment.apiUrl + "/taskstage/");
    }

    getTaskStageByNature(projectNumber, systemNumber, nature, isNonRecurring)
    {
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/tasks/stage/" + nature + "/" + isNonRecurring + "/");
    }

    /**
     * Calculate task stage
     * @param data
     */
    calculcateTaskStage(data, projectNumber, systemNumber)
    {

        return this.http.post(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/tasks/stage/", data);
    }
}
