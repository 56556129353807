import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SparePartListComponent } from './spare-part-list/spare-part-list.component';


const routes: Routes = [
  { path: 'spare-parts', component: SparePartListComponent, data: { animation: 'projects' }},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SparePartsRoutingModule { }
