import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MaintenanceLocationService {

    constructor(private http: HttpClient) { }

    /**
     * Get All Infrastructure
     */
    getAllILocations()
    {
        return this.http.get(environment.apiUrl + "/infrastructure/");
    }


    /**
     * Create task infrastructure
     */
    createTaskMaintenanceLocation(data, taskDbId)
    {
        return this.http.post(environment.apiUrl + "/taskmaintenancelocation/" + taskDbId + "/", data);
    }

    /**
     * Get all Task infrastructures
     * @param taskDbId
     */
    getTaskMaintenanceLocation(taskDbId)
    {
        return this.http.get(environment.apiUrl + "/taskmaintenancelocation/" + taskDbId + "/");
    }
}
