import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ToolsCategoryService {

    constructor(private http: HttpClient) { }


    /**
     * Get Tool Category List
     */
    getToolCategoryList()
    {
        return this.http.get(environment.apiUrl + "/toolcategory/");
    }
}
