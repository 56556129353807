<div class="modal-header">
    <h5 class="modal-title pull-left">Session Timed-out</h5>
  
  </div>
  <div class="modal-body">
    <p>
      It seems like your session is timed-out due to inactivity. <br /><br />
      Please Login again by providing your credentials<br />
      <a href="/login">Click here </a>to go to the <a href="/login">login screen.</a>
    </p>
  
  </div>