import {Component, EventEmitter, OnInit} from '@angular/core';
import {BsModalService} from "ngx-bootstrap/modal";
import {MasterListUsageComponent} from "../master-list-usage/master-list-usage.component";

@Component({
  selector: 'app-master-list-usage-url',
  templateUrl: './master-list-usage-url.component.html',
  styleUrls: ['./master-list-usage-url.component.scss']
})
export class MasterListUsageUrlComponent implements OnInit {

  rowData:any;
  itemType:string;
  itemId:any;
  eventEmitter:EventEmitter<string> = new EventEmitter();
  constructor(
      private modalService:BsModalService
  ) { }

  ngOnInit(): void {
    if(this.itemType == "tools"){
      this.itemId = this.rowData.toolNumber;
    }
    else if(this.itemType == "operatingmaterials"){
      this.itemId = this.rowData.operatingMaterialsId;
    }
    else if(this.itemType == "parts"){
      this.itemId = this.rowData.spareStadlerArticleId
    }
  }


  loadListModal(){
    const initialState = {
      itemType : this.itemType,
      itemId: this.itemId
    };
    this.modalService.show(MasterListUsageComponent, {class:'modal-lg',initialState,keyboard:false,ignoreBackdropClick: true});
  }
}
