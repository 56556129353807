
<div class="modal-header">
    <h5 class="modal-title pull-left">Log of Official Task Exports</h5>
    <button type="button" class="close " aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="data-table">
        <app-tree-table [settings]="settings" [data]="taskExportLogs" [currentPage]="currentPage" [pageCount]="pageCount" (pageChangeEvent)="changePage($event)" (pageSizeChangeEvent)="changePageSize($event);"></app-tree-table>
    </div>
</div>
<div class="modal-footer">
    <button type="button" (click)="bsModalRef.hide()" class="btn btn-primary btn-sm"> Close</button>
</div>

