import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-view-system-url',
  templateUrl: './view-system-url.component.html',
  styleUrls: ['./view-system-url.component.scss']
})
export class ViewSystemUrlComponent implements OnInit {

  rowData:any;
  constructor(private router: Router,
              public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  goToSystem(){
    if(this.rowData['projectNumber'] && this.rowData['projectSystemId']){
      let params = {
        systemVariationNumberAndCode : this.rowData['systemVariationNumberAndCode']
      }
      this.router.navigate(["/projects/" + this.rowData['projectNumber'] + '/systems'], {queryParams:params} );
    }
    this.bsModalRef.hide();
  }
}
