import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CopyProjectSystemService } from 'src/app/services/copy-project-system.service';
import { ProjectService } from 'src/app/services/project.service';
import { StandardSystemsService } from 'src/app/services/standard-systems.service';
import {LanguageServiceService} from "../../../../../services/language-service.service";

@Component({
  selector: 'app-copy-modal',
  templateUrl: './copy-modal.component.html',
  styleUrls: ['./copy-modal.component.scss']
})
export class CopyModalComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();
  projectsData:any;
  param:any;
  standardSystems:any;
  btnLadda:Boolean=false;
  defaultSystem:any;
  projectFreezeFunction = "";
  projectsFreezeFunctionMap: Map<string, string> = new Map<string, string>();
  btnLoading:Boolean=false;

  copyForm = new UntypedFormGroup({
    projectNumber: new UntypedFormControl(),
    newSystemNumber: new UntypedFormControl()
  });
  constructor(
      public bsModalRef: BsModalRef,
      private projectService : ProjectService,
      private standardSystemService : StandardSystemsService,
      private copyProjectSystemService : CopyProjectSystemService,
      private notification : ToastrService,
      private languageService:LanguageServiceService
  ) { }

  ngOnInit(): void {

    this.getAllProjects();
    this.getStandardSystems();
  }

  /**
   * Get all projects
   */
  getAllProjects(){
    this.projectService.getProjectList().subscribe(res=>{
      if(res && res['page']){
        res = res['page']['content'];
        Object.keys(res).forEach(item => {
          res[item].projectDispStr = res[item].projectNumber + ' ' + res[item].projectDesignation;
          this.projectsFreezeFunctionMap[res[item].projectNumber] = res[item].freezeFunction;
        });
        this.projectsData = res;
        this.copyForm.patchValue({
          projectNumber: this.param.projectNumber
        })
      }
    });
  }

  selectProject(projectNumber){
    this.projectFreezeFunction = this.projectsFreezeFunctionMap[projectNumber];
  }

  onSubmitForm(event){
    this.btnLoading=true;
    this.btnLadda=true;
    let data = this.copyForm.getRawValue();

    let formData = {
      projectNumber : this.param.projectNumber,
      newProjectNumber : data['projectNumber'],
      projectSystemId : this.param.projectSystemId,
      newSystemNumber : data['newSystemNumber'],
      systemNumber : this.param.systemNumber

    };
    this.copyProjectSystemService.copyProjectSystem(event, formData).subscribe(res=>{
      if(res){
        this.notification.success('Project system has been copied successfully');
        this.bsModalRef.hide();
        this.event.emit();
      }
      else{
        this.btnLoading=false;
        this.notification.error('Project system has not been copied');
      }
      this.btnLadda=false;
    }, (error => {
      this.btnLoading=false;
      this.notification.error('Project system has not been copied');
      this.btnLadda=false;
    }));
  }

  getStandardSystems(){
    this.standardSystemService.getLowSystems().subscribe(res=>{
      if(!res || !res['page'] || !res['page']['content']) return;

      let result = res['page']['content'];

      Object.keys(result).forEach(item => {
          result[item].systemDesignation = result[item].systemCode+'-';
          result[item].systemDesignation += this.languageService.getCurrentLanguage().id == 0 ? result[item].systemDesignationEn : result[item].systemDesignationDe;
      });

      if(result.length > 0){
        var value = result.shift(); //Remove First Elemnt which is "AA00" [Spec ID 18 of ClickUp]
      }

      this.standardSystems = result;
      this.copyForm.patchValue({
        newSystemNumber:this.param.systemNumber
      })
      this.defaultSystem = this.param.systemNumber;
    });
  }


}
