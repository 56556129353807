<div class="container-fluid">

  <div class="row">
    <div class="pageTitle col-sm-3">
      Parts &nbsp;&nbsp;
      <button (click)="loadAddModal()" id="btnAddProject" type="button" class="btn btn-primary btn-sm"><fa-icon [icon]="faPlus"></fa-icon> &nbsp;&nbsp;Add</button>&nbsp;&nbsp;
      <button type="button" class="btn btn-primary btn-sm" (click)="showLogs()"><fa-icon [icon]="faFile"></fa-icon>&nbsp;&nbsp;Logs</button>&nbsp;&nbsp;
      <button appButtonLoader [isLoading]="btnExportLoading" loaderBtnName="Export" type="button" class="btn btn-primary btn-sm" (click)="exportSpareParts()"><fa-icon [icon]="faFileExport"></fa-icon>&nbsp;&nbsp;Export</button>&nbsp;&nbsp;
      <input type="file" #fileInput style="display: none" (change)="onChangeFileInput()"/>
      <button type="button" class="btn btn-primary btn-sm" (click)="openFileSelectDialog()" appInfoPromptBox [page]="'masterParts'" [component]="'grid'" [field]="'importButton'"><fa-icon [icon]="faFileImport"></fa-icon>&nbsp;&nbsp;Import</button>
      <input type="file" #fileInputArticleChange style="display: none" (change)="onChangeFileInputArticleChange()"/>
      <button style="margin-left: 40px;" type="button" class="btn btn-primary btn-sm" (click)="openFileSelectDialogArticleChange()" appInfoPromptBox [page]="'masterParts'" [component]="'grid'" [field]="'importButtonArticleChange'"><fa-icon [icon]="faFileImport"></fa-icon>&nbsp;&nbsp;Stadler Article ID Update</button>

    </div>
  </div>

  <div class="data-table">
    <app-tree-table [spinnerVisible]="spinnerVisible" [spinnerText]="spinnerText" [settings]="settings" [data]="sparePartsData" [currentPage]="currentPage" [pageCount]="pageCount" (pageChangeEvent)="changePage($event)" (pageSizeChangeEvent)="changePageSize($event);"  (filterChangeEvent)="filterOrSortChange($event)"></app-tree-table>
  </div>



</div> <!-- wrapper -->
