import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RouterService {

    private previousUrl: string;
    private currentUrl: string;

    constructor(private router: Router)
    {


    }

    getPreviousUrl()
    {
        this.currentUrl = this.router.url;
        this.router.events.subscribe(event =>
        {
            if (event instanceof NavigationEnd)
            {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
            }
            ;
        });

        return this.previousUrl;
    }
}
