import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TaskTypeService {

    constructor(private http: HttpClient) { }

    /**
     * Get all task types
     */
    getAll()
    {
        return this.http.get(environment.apiUrl + "/tasktypes/")
    }

    /**
     * Get Task type by nature
     * @param nature
     */
    getByNature(nature)
    {
        return this.http.get(environment.apiUrl + "/tasktypes/nature/" + nature + '/');
    }

    /**
     * Get Relted Tasks by nature
     * @param nature
     */
    getRelatedTasksByNature(projectNumber, taskNature)
    {
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/nature/" + taskNature + "/tasks/");
    }
}
