import {Component, OnInit} from '@angular/core';
import {faSalesforce} from '@fortawesome/free-brands-svg-icons';
import {faAngleRight, faPlus} from '@fortawesome/free-solid-svg-icons';
import {BsModalService} from 'ngx-bootstrap/modal';
import {UserService} from 'src/app/services/user.service';
import {AddUserComponent} from '../components/add-user/add-user.component';
import {EditUserComponent} from '../components/edit-user/edit-user.component';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  faAngleRight = faAngleRight;
  faPlus = faPlus;
  loading:Boolean = true;
  users:any = [];
  rowData:any;

  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";
  filterData = [];

  settings = {
    type:'table',
    id:'userId',
    columnManagerEnable:true,
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    enableZoom:true,
    filterOptionFunction:(params, filterData)=>{return this.userService.getAllUsers("distinct-values", filterData, params)},
    columns:[
      {
        title:'ID',
        attribute:'userId',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:100,
      },
      {
        title:'First Name',
        attribute: 'firstName',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:250
      },
      {
        title:'Last Name',
        attribute: 'lastName',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:250
      },
      {
        title:'Email',
        attribute:'userEmail',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:350,
      },
      {
        title:'Status',
        attribute:'status',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:100,
      },
      {
        renderComponent: '<a class="btn btn-info btn-url btn-sm text-light btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/edit.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.editUser(row)},
        width:50
      },
      // {
      //   renderComponent:'<a class="btn btn-danger btn-sm text-light btn-url"><img title="Delete" class="delete-icon" src="/assets/icon/delete.png"/></a>',
      //   type:'html',
      //   actionFunction: (row)=>{},
      //   width:60,
      // }
    ]
  };

  constructor(
      private userService : UserService,
      private activatedRoute : ActivatedRoute,
      private modalService : BsModalService,

  ) {
    activatedRoute.queryParams.subscribe(params => {
      if(params['page']) this.currentPage = +params['page'];
      if(params['column']) this.sortColumn = params['column'];
      if(params['sortOrder']) this.sortOrder = params['sortOrder'];
    });
  }

  ngOnInit(): void {
    this.getAllUsers();
  }

  /**
   * Load student add modal
   */
  loadAddModal(){
    const initialState = {};
    let modalRef = this.modalService.show(AddUserComponent, {class:'modal-md', initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      this.getAllUsers();
    });
  }

  /**
   * Get all Users
   */
  getAllUsers(){
    this.loading = true;
    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;
    this.userService.getAllUsers('filtered-data', this.filterData, params).subscribe(res=>{
      if(res && res['page']){
        this.pageCount = res['page']['totalPages'];
        this.users  = res['page']['content'];
        this.loading = false;
      }
      if(res && res['tablePropertyMap']){
        let columns = this.settings.columns;
        let needFillColumnAndTable = false;
        for(let column of columns){
          if(column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
        }
        if(!needFillColumnAndTable) return;

        let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];
        for(let i=0; i<columns.length; i++){
          let data = tablePropertyMap[columns[i].attribute];
          if(!data) continue;
          columns[i].filter['column'] = res['isNative'] ? data['table'] + "." + data['column'] : data['column'];
          columns[i].filter['table'] = data['table'];
        }
      }
      this.settings['isNative'] = false;
    });
  }

  filterOrSortChange(event){
    if(!event){
      this.currentPage = 1;
      this.users = [];
      return;
    }
    if(!event['sortChanged']) this.currentPage = 1;
    this.sortColumn = event['sortColumn'];
    this.sortOrder = event['sortOrder'];
    this.filterData = event['filterData'];

    this.getAllUsers();
  }

  /**
   * Load edit user modal
   */
  editUser(data){
    const initialState = {
      param:data
    };
    let modalRef = this.modalService.show(EditUserComponent, {class:'modal-xl', initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      this.getAllUsers();
    });
  }

  changePage(currentPage){
    this.currentPage = currentPage;
    this.getAllUsers();
  }

  changePageSize(pageSize){
    this.pageSize = pageSize;
    this.getAllUsers();
  }
}



