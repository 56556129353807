<div class="modal-header">
  <h5 class="modal-title pull-left">Add Parts</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="addSparePartsForm" class="pr-4 pl-4">
  <div class="modal-body">
    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-5 col-form-label col-form-label-sm">Standard System</label>
      <div class="col-sm-7">
        <ngx-select optionValueField="systemNumber" [items]="standardSystemData" formControlName="systemNumber" optionTextField="systemDesignation" [placeholder]="'Standard system'" appInfoPromptBox [page]="'masterParts'" [component]="'add'" [field]="'systemNumber'"></ngx-select>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputEmail3" class="col-sm-5 col-form-label col-form-label-sm">Stadler Article ID</label>
      <div class="col-sm-5">
        <input type="text" formControlName="spareStadlerArticleId" (keyup)="checkExistArticleId()" class="form-control form-control-sm" id="inputEmail3" placeholder="Stadler Article ID"  [max]="30000000" [appDecimal]="0" appMandatoryField [disabled]="isDummyArticle" [readonly]="isDummyArticle" appInfoPromptBox [page]="'masterParts'" [component]="'add'" [field]="'spareStadlerArticleId'">
        <span *ngIf="existArticleId" class="text-danger"><small>This Stadler article id already exist</small></span>
        <div *ngIf="addSparePartsForm.controls['spareStadlerArticleId'].invalid && (addSparePartsForm.controls['spareStadlerArticleId'].dirty || addSparePartsForm.controls['spareStadlerArticleId'].touched)" class="ng-validator">
          <div *ngIf="addSparePartsForm.controls['spareStadlerArticleId'].errors.required">
            This field is required.
          </div>
          <div *ngIf="addSparePartsForm.controls['spareStadlerArticleId'].value != '' && addSparePartsForm.controls['spareStadlerArticleId'].errors.valueZero">
            Should be Greater Than 0
          </div>
          <div *ngIf="addSparePartsForm.controls['spareStadlerArticleId'].errors.maxlength  || addSparePartsForm.controls['spareStadlerArticleId'].errors.minlength">
            Article ID length should be 8 characters
          </div>
          <div *ngIf="addSparePartsForm.controls['spareStadlerArticleId'].errors.max">
            Stadler Article IDs should be less than 30000000
          </div>
          <div *ngIf="addSparePartsForm.controls['spareStadlerArticleId'].errors.min">
            Dummy Stadler Article IDs should be greater than 30000000
          </div>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="form-check form-switch" style="padding-top: 0.25rem">
          <input class="form-check-input" type="checkbox" value="" id="dummyArticleId" (change)="dummyArticleCheckBoxClicked($event)" [value]="isDummyArticle" >
          <label class="form-check-label" for="dummyArticleId">
            dummy
          </label>
        </div>
      </div>
    </div>
    
    <div class="form-group row">
      <label class="col-sm-5 col-form-label col-form-label-sm">SAP Article ID</label>
      <div class="col-sm-7">
        <input type="text" formControlName="sapArticleId" class="form-control form-control-sm" placeholder="SAP Article ID" appInfoPromptBox [page]="'masterParts'" [component]="'add'" [field]="'sapArticleId'">
        <div *ngIf="addSparePartsForm.controls['sapArticleId'].invalid && (addSparePartsForm.controls['sapArticleId'].dirty || addSparePartsForm.controls['sapArticleId'].touched)" class="ng-validator">
          <div *ngIf="addSparePartsForm.controls['sapArticleId'].errors.required">
            This field is required.
          </div>
          <div *ngIf="addSparePartsForm.controls['sapArticleId'].errors.maxlength">
            SAP Article ID maximum length should be 100 characters
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label col-form-label-sm">3dx Article ID</label>
      <div class="col-sm-7">
        <input type="text" formControlName="threedxArticleId" class="form-control form-control-sm" placeholder="3dx Article ID" appInfoPromptBox [page]="'masterParts'" [component]="'add'" [field]="'threedxArticleId'">
        <div *ngIf="addSparePartsForm.controls['threedxArticleId'].invalid && (addSparePartsForm.controls['threedxArticleId'].dirty || addSparePartsForm.controls['threedxArticleId'].touched)" class="ng-validator">
          <div *ngIf="addSparePartsForm.controls['threedxArticleId'].errors.required">
            This field is required.
          </div>
          <div *ngIf="addSparePartsForm.controls['threedxArticleId'].errors.maxlength">
            3dx Article ID maximum length should be 100 characters
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label col-form-label-sm">Supplier Article ID</label>
      <div class="col-sm-7">
        <input type="text" formControlName="supplierArticleId" class="form-control form-control-sm" placeholder="Supplier Article ID" appMandatoryField appInfoPromptBox [page]="'masterParts'" [component]="'add'" [field]="'supplierArticleId'">
        <div *ngIf="addSparePartsForm.controls['supplierArticleId'].invalid && (addSparePartsForm.controls['supplierArticleId'].dirty || addSparePartsForm.controls['supplierArticleId'].touched)" class="ng-validator">
          <div *ngIf="addSparePartsForm.controls['supplierArticleId'].errors.required">
            This field is required.
          </div>
          <div *ngIf="addSparePartsForm.controls['supplierArticleId'].errors.maxlength">
            Supplier Article ID maximum length should be 100 characters
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-5 col-form-label col-form-label-sm">Designation DE</label>
      <div class="col-sm-7">
        <input type="text" formControlName="spareDesignationDe" class="form-control form-control-sm" placeholder="Designation DE" appMandatoryField appInfoPromptBox [page]="'masterParts'" [component]="'add'" [field]="'spareDesignationDe'">
        <div *ngIf="addSparePartsForm.controls['spareDesignationDe'].invalid && (addSparePartsForm.controls['spareDesignationDe'].dirty || addSparePartsForm.controls['spareDesignationDe'].touched)" class="ng-validator">
          <div *ngIf="addSparePartsForm.controls['spareDesignationDe'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-5 col-form-label col-form-label-sm">Designation EN</label>
      <div class="col-sm-7">
        <input type="text" formControlName="spareDesignationEn" class="form-control form-control-sm" placeholder="Designation EN" appInfoPromptBox [page]="'masterParts'" [component]="'add'" [field]="'spareDesignationEn'">
      </div>
    </div>
    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-5 col-form-label col-form-label-sm">Supplier</label>
      <div class="col-sm-7">
        <ngx-select optionValueField="stakeholderId" [items]="supplierData" formControlName="supplierId" optionTextField="nameUsed" [placeholder]="'Select Supplier'" appMandatoryField appInfoPromptBox [page]="'masterParts'" [component]="'add'" [field]="'supplierId'"></ngx-select>
        <div *ngIf="addSparePartsForm.controls['supplierId'].invalid && (addSparePartsForm.controls['supplierId'].dirty || addSparePartsForm.controls['supplierId'].touched)" class="ng-validator">
          <div *ngIf="addSparePartsForm.controls['supplierId'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label col-form-label-sm">Weight [kg]</label>
      <div class="col-sm-7">
        <input type="text" class="form-control form-control-sm" formControlName="spareWeight" placeholder="Weight" [appThousandSeparator]="addSparePartsForm.controls['spareWeight'].value" [appDecimal]="4" appInfoPromptBox [page]="'masterParts'" [component]="'add'" [field]="'spareWeight'">
        <div *ngIf="addSparePartsForm.controls['spareWeight'].invalid && (addSparePartsForm.controls['spareWeight'].dirty || addSparePartsForm.controls['spareWeight'].touched)" class="ng-validator">
          <div *ngIf="addSparePartsForm.controls['spareWeight'].value != '' && addSparePartsForm.controls['spareWeight'].errors.valueZero">
            Should be Greater Than 0
          </div>
          <div *ngIf="addSparePartsForm.controls['spareWeight'].errors.max">
            Should be Less Than or Equal to 1000000
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-5 col-form-label col-form-label-sm">Parts Units</label>
      <div class="col-sm-7">
        <ngx-select optionValueField="quantityUnitId" [items]="quantityUnitData" formControlName="spareUnitId" optionTextField="designationDe" [placeholder]="'Part units'" appInfoPromptBox [page]="'masterParts'" [component]="'add'" [field]="'spareUnitId'"></ngx-select>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-5 col-form-label col-form-label-sm">Comment</label>
      <div class="col-sm-7">
        <textarea formControlName="comment" class="form-control" appInfoPromptBox [page]="'masterParts'" [component]="'add'" [field]="'comment'"></textarea>
        <div *ngIf="addSparePartsForm.controls['comment'].invalid && (addSparePartsForm.controls['comment'].dirty || addSparePartsForm.controls['comment'].touched)" class="ng-validator">
          <div *ngIf="addSparePartsForm.controls['comment'].errors.maxlength">
            Maximum length is 10'000
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <div class="mandatory-description d-flex flex-row">
      <span class="mandatory-description-asterisk mandatory-asterisk">*</span>
      <span class="mandatory-field-text">mandatory fields</span>
      <span class="mandatory-description-conditional-asterisk conditional-mandatory-asterisk">*</span>
      <span class="mandatory-field-text">conditional mandatory fields</span>
    </div> -->
    <app-action-comment [btnLoading]="btnLoading" [okBtnName]="'Save'" [closeBtnName]="'Close'" [saveDisable]="addSparePartsForm.invalid || submitDisabled" [mandatoryDescriptionVisible]="true" [commentVisible]="false" [commentRequired]="false" [justificationCategoryVisible]="false" [justificationCategoryRequired]="false" (okClick)="createSpareParts($event)" (cancelClick)="bsModalRef.hide()"></app-action-comment>
  </div>
</form>
