<div class="modal-header">
    <h5 class="modal-title pull-left">Import / Export {{this.projectNumber}}</h5>
    <ngx-select style="padding-left: 25px; width: 200px; display: inline-block; float: right;" optionValueField="id"
                optionTextField="str" [items]="languages" [(ngModel)]="selectedLanguage"
                (select)="languageChanged()"></ngx-select>
    <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<form [formGroup]="taskExportForm" (ngSubmit)="submitExportForm()">
    <div #modalBody class="modal-body">
        <div class="form-check form-group row">
            <input class="form-check-input" type="checkbox" formControlName="taskExportEnabled"
                   (change)="changeEnableTaskExport()" id="taskExportChk">
            <label class="form-check-label col-form-label-sm" for="taskExportChk">
                <b>Task Export</b>
            </label>
        </div>
        <div class="option-group">
            <div class="form-group row pr-4 pl-4">
                <label class="col-sm-4 col-form-label col-form-label-sm">Task Type</label>
                <div class="col-sm-8">
                    <ngx-select formControlName="taskExportTaskType" [items]="taskTypeData" optionValueField="id"
                                optionTextField="value" appInfoPromptBox [page]="'project'" [component]="'export'"
                                [field]="'taskExportTaskType'"></ngx-select>
                </div>
            </div>
            <div class="form-group row pr-4 pl-4">
                <label class="col-sm-4 col-form-label col-form-label-sm">RAM Engineer</label>
                <div class="col-sm-8">
                    <ngx-select formControlName="taskExportRamEngineer" [items]="ramEngineersData"
                                (select)="changeRamEngineerExport()" optionValueField="stakeholderId"
                                optionTextField="fullName" appInfoPromptBox [page]="'project'" [component]="'export'"
                                [field]="'taskExportRamEngineer'"></ngx-select>
                </div>
            </div>
            <div class="form-group row pr-4 pl-4">
                <label class="col-sm-4 col-form-label col-form-label-sm">Tool Categories</label>
                <div class="col-sm-8">
                    <ngx-select formControlName="taskExportToolCategories" [items]="toolCategoriesData"
                                optionValueField="id" optionTextField="value" appInfoPromptBox [page]="'project'"
                                [component]="'export'" [field]="'taskExportToolCategories'"></ngx-select>
                </div>
            </div>
            <div class="form-group row pr-4 pl-4">
                <label class="col-sm-4 col-form-label col-form-label-sm">Operating Materials</label>
                <div class="col-sm-8">
                    <ngx-select formControlName="taskExportOperatingMaterials" [items]="operatingMaterialsData"
                                optionValueField="id" optionTextField="value" appInfoPromptBox [page]="'project'"
                                [component]="'export'" [field]="'taskExportOperatingMaterials'"></ngx-select>
                </div>
            </div>
            <div class="form-group row pr-4 pl-4">
                <label class="col-sm-4 col-form-label col-form-label-sm">Parts</label>
                <div class="col-sm-8">
                    <ngx-select formControlName="taskExportParts" [items]="partsData" optionValueField="id"
                                optionTextField="value" appInfoPromptBox [page]="'project'" [component]="'export'"
                                [field]="'taskExportParts'"></ngx-select>
                </div>
            </div>
            <div class="form-group row pr-4 pl-4">
                <div class="form-group col-sm-4">
                </div>
                <div class="official-export-div form-check form-group col-sm-3">
                    <input class="form-check-input" type="checkbox" (change)="changeOfficialTaskExport()"
                           formControlName="taskOfficialExport" id="taskOfficialExportChk">
                    <label class="form-check-label col-form-label-sm" for="taskOfficialExportChk">
                        Official Export
                    </label>
                </div>
                <div class="version-div form-group col-sm-5 row p-0">
                    <label class="col-sm-10 col-form-label col-form-label-sm" for="taskExportVersion">
                        &nbsp;
                    </label>
                    <!-- <div class="col-sm-7 p-0">
                      <input class="form-control form-control-sm" [required]="taskExportForm.controls['taskOfficialExport'].value == true" [ngClass]="{'invalid-input':taskExportForm.controls['taskExportVersion'].invalid}" type="text" formControlName="taskExportVersion" id="taskExportVersion" appInfoPromptBox [page]="'project'" [component]="'export'" [field]="'taskExportVersion'">
                    </div> -->
                    <div class="col-sm-2 align-self-end p-0 d-flex flex-row-reverse">
                        <a class="btn btn-info btn-sm btn-log" (click)="loadLogModel()">
                            <app-icon [code]="'log'"></app-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <br>

        <div class="form-check form-group row">
            <input class="form-check-input" type="checkbox" formControlName="partsExportEnabled" id="partExportChk">
            <label class="form-check-label col-form-label-sm" for="partExportChk">
                <b>Parts Export</b>
            </label>
        </div>

        <br>

        <div class="form-check form-group row">
            <input class="form-check-input" type="checkbox" formControlName="toolsExportEnabled"
                   (change)="changeEnableToolsExport()" id="toolsExportChk">
            <label class="form-check-label col-form-label-sm" for="toolsExportChk">
                <b>Tools Export</b>
            </label>
        </div>
        <div class="option-group">
            <div class="form-group row pr-4 pl-4">
                <label class="col-sm-4 col-form-label col-form-label-sm">Tool Categories</label>
                <div class="col-sm-8">
                    <ngx-select formControlName="toolsExportToolCategories" [items]="toolCategoriesData"
                                optionValueField="id" optionTextField="value" appInfoPromptBox [page]="'project'"
                                [component]="'export'" [field]="'toolsExportToolCategories'"></ngx-select>
                </div>
            </div>
        </div>

        <br>

        <div class="form-check form-group row">
            <input class="form-check-input" type="checkbox" formControlName="operatingMaterialsExportEnabled"
                   id="operatingMaterialsExportChk">
            <label class="form-check-label col-form-label-sm" for="operatingMaterialsExportChk">
                <b>Operating Materials Export</b>
            </label>
        </div>

        <br>

        <div class="form-check form-group row">
            <input class="form-check-input" type="checkbox" formControlName="partsAndExternalServicePriceExportEnabled"
                   id="partsAndExternalServicePriceExportChk">
            <label class="form-check-label col-form-label-sm" for="partsAndExternalServicePriceExportChk">
                <b>Price (Parts & External Service) Export</b>
            </label>
        </div>

        <br>

        <div class="form-group row pr-4 pl-4 import-div">
            <div class="col-sm-4">
                <ngx-select formControlName="partsAndExternalServicePriceCurrency" [items]="currencyData" [(ngModel)]="selectedCurrencyToImport"
                            optionValueField="code" optionTextField="code" placeholder="Select Currency"></ngx-select>
            </div>
            <div class="col-sm-4">
                <input type="text" class="form-control" readonly [(ngModel)]="selectedFileName"
                       [ngModelOptions]="{standalone: true}" placeholder="Selected File">
            </div>
            <div class="col-sm-2">
                <input type="file" #fileInput style="display: none" (change)="onChangeFileInput()"/>
                <button type="button" class="btn btn-primary browse-btn" (click)="openFileSelectDialog()">Browse
                </button>
            </div>
            <div class="col-sm-2">
                <button type="button"
                        [disabled]="selectedFileToImport == null || selectedCurrencyToImport == null || selectedCurrencyToImport == ''"
                        (click)="importFile()" class="btn btn-primary import-btn">Import
                </button>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button [disabled]="taskExportForm.invalid" class="btn btn-primary">Export Now</button>
    </div>
</form>

<div *ngIf="spinnerVisible" class="spinner-div d-flex justify-content-center align-items-center">
  <mat-progress-spinner [color]="'primary'" [mode]="'indeterminate'" class="spinner" [ngStyle]="{'top.px': spinnerPosition?.top, 'left.px': spinnerPosition?.left}"></mat-progress-spinner>
  <div class="spinner-text">{{spinnerText}}</div>
</div>

