import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InfrastructureService {

    constructor(private http: HttpClient) { }

    /**
     * Get All Infrastructure
     */
    getAllInfrastructure()
    {
        return this.http.get(environment.apiUrl + "/infrastructure/");
    }


    /**
     * Create task infrastructure
     */
    createTaskInfrastructure(data, taskDbId)
    {
        return this.http.post(environment.apiUrl + "/taskinfrastructure/" + taskDbId + "/", data);
    }

    /**
     * Get all Task infrastructures
     * @param taskDbId
     */
    getTaskInfrastructure(taskDbId)
    {
        return this.http.get(environment.apiUrl + "/taskinfrastructure/" + taskDbId + "/");
    }
}
