<div class="modal-header">
  <h5 class="modal-title pull-left">Add Project</h5>
  <button (click)="bsModalRef.hide()" aria-label="Close" class="close pull-right" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<form (ngSubmit)="onSubmit()" [formGroup]="projectAddForm" class="pr-4 pl-4">
  <div class="modal-body">
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm" for="inputEmail3">Project Number</label>
      <div class="col-sm-4">
        <input class="form-control form-control-sm"  formControlName="projectNumber" id="txtProjectNumber" placeholder="L-" type="text" appInfoPromptBox [page]="'project'" [component]="'add'" [field]="'projectNumber'" value="L-">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm" for="inputPassword3">Designation</label>
      <div class="col-sm-8">
        <input class="form-control form-control-sm" formControlName="projectDesignation" id="txtProjectDesignation" placeholder="Designation" type="text" appMandatoryField appInfoPromptBox [page]="'project'" [component]="'add'" [field]="'projectDesignation'">
        <div *ngIf="projectAddForm.controls['projectDesignation'].invalid && (projectAddForm.controls['projectDesignation'].dirty || projectAddForm.controls['projectDesignation'].touched)" class="ng-validator">
          <div *ngIf="projectAddForm.controls['projectDesignation'].errors.required">
            This field is required.
          </div>
          <div *ngIf="projectAddForm.controls['projectDesignation'].errors.maxlength">
            Should be Less Than 100 Characters.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm" for="inputEmail3">Power unit</label>
      <div class="col-sm-6">
        <ngx-select [items]="powerUnit" formControlName="powerUnit" optionTextField="powerUnit" optionValueField="powerUnit" appMandatoryField appInfoPromptBox [page]="'project'" [component]="'add'" [field]="'power_unit'" appInfoPromptBox [page]="'project'" [component]="'add'" [field]="'powerUnit'"></ngx-select>
        <div *ngIf="projectAddForm.controls['powerUnit'].invalid && (projectAddForm.controls['powerUnit'].dirty || projectAddForm.controls['powerUnit'].touched)" class="ng-validator">
          <div *ngIf="projectAddForm.controls['powerUnit'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm" for="inputEmail3">Electrification System</label>
      <div class="col-sm-6">
        <ngx-select [items]="electrification" [multiple]="true" autoClearSearch="true" formControlName="projectElectrification" optionTextField="electrificationSystem" optionValueField="electrificationSystemId" appInfoPromptBox [page]="'project'" [component]="'add'" [field]="'projectElectrification'"></ngx-select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm" for="inputEmail3">No. of cars</label>
      <div class="col-sm-5">
        <input [appDecimal]="0" class="form-control form-control-sm" formControlName="carsCount" id="inputEmail3" [appThousandSeparator]="projectAddForm.controls['carsCount'].value" placeholder="Number" type="text" appMandatoryField appInfoPromptBox [page]="'project'" [component]="'add'" [field]="'carsCount'">
        <div *ngIf="projectAddForm.controls['carsCount'].invalid && (projectAddForm.controls['carsCount'].dirty || projectAddForm.controls['carsCount'].touched)" class="ng-validator">
          <div *ngIf="projectAddForm.controls['carsCount'].errors.required">
            This field is required.
          </div>
          <div *ngIf="projectAddForm.controls['carsCount'].value != '' && projectAddForm.controls['carsCount'].errors.valueZero">
            Should be Greater Than 0
          </div>
          <div *ngIf="projectAddForm.controls['carsCount'].errors.max">
            Should be Less Than 999
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm" for="inputEmail3">Fleet Size</label>
      <div class="col-sm-5">
        <input [appDecimal]="0" class="form-control form-control-sm" formControlName="vehicleQuantity" id="inputEmail3" [appThousandSeparator]="projectAddForm.controls['vehicleQuantity'].value" placeholder="Number" type="text" appMandatoryField appInfoPromptBox [page]="'project'" [component]="'add'" [field]="'vehicleQuantity'">
        <div *ngIf="projectAddForm.controls['vehicleQuantity'].invalid && (projectAddForm.controls['vehicleQuantity'].dirty || projectAddForm.controls['vehicleQuantity'].touched)" class="ng-validator">
          <div *ngIf="projectAddForm.controls['vehicleQuantity'].errors.required">
            This field is required.
          </div>
          <div *ngIf="projectAddForm.controls['vehicleQuantity'].value != '' && projectAddForm.controls['vehicleQuantity'].errors.valueZero">
            Should be Greater Than 0
          </div>
          <div *ngIf="projectAddForm.controls['vehicleQuantity'].errors.max">
            Should be Less Than 999
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm">Specific Employee Qualification</label>
      <div class="col-sm-6">
        <ngx-select optionValueField="id" optionTextField="text" formControlName="specificEmployeeQualification" (ngModelChange)="specificEmployeeQualificationChanged()" [items]="specificEmployeeQualificationData" appInfoPromptBox [page]="'project'" [component]="'add'" [field]="'specificEmployeeQualification'"></ngx-select>
      </div>
      <div *ngIf="showSpecificEmployeeQualificationWarning" class="alert-warning">Consider this changes the (Specific) Employee Qualification in the Maintenance Tasks</div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm">Tool Categories</label>
      <div class="col-sm-6">
        <ngx-select [items]="toolCategoriesData" formControlName="toolCategories" optionTextField="str" optionValueField="id" appInfoPromptBox [page]="'project'" [component]="'add'" [field]="'toolCategories'"></ngx-select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm">Operating Materials</label>
      <div class="col-sm-6">
        <ngx-select [items]="operatingMaterialsData" formControlName="operatingMaterials" optionTextField="str" optionValueField="id" appInfoPromptBox [page]="'project'" [component]="'add'" [field]="'operatingMaterials'"></ngx-select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm">Parts</label>
      <div class="col-sm-6">
        <ngx-select [items]="partsData" formControlName="parts" optionTextField="str" optionValueField="id" appInfoPromptBox [page]="'project'" [component]="'add'" [field]="'parts'"></ngx-select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm">Freeze Function</label>
      <div class="col-sm-6">
        <ngx-select [items]="freezeFunctionData" formControlName="freezeFunction" optionTextField="str" optionValueField="id" appInfoPromptBox [page]="'project'" [component]="'add'" [field]="'freezeFunction'"></ngx-select>
        <div *ngIf="this.initialFreezeValue != projectAddForm.controls['freezeFunction'].value" class="alert-warning" style="white-space: normal;">
          Really want to change?
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm">Working Language</label>
      <div class="col-sm-6">
        <ngx-select [items]="languages" formControlName="workingLanguage" optionTextField="str" optionValueField="id" appInfoPromptBox [page]="'project'" [component]="'add'" [field]="'workingLanguage'"></ngx-select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm">Export Language</label>
      <div class="col-sm-6">
        <ngx-select [items]="languages" formControlName="exportLanguage" optionTextField="str" optionValueField="id" appInfoPromptBox [page]="'project'" [component]="'add'" [field]="'exportLanguage'"></ngx-select>
      </div>
    </div>
    <div class="form-group row">
        <label for="projectAdmins" class="col-sm-4 col-form-label col-form-label-sm">Project Admins</label>
        <div class="col-sm-6">
            <ngx-select optionValueField="userGroupId" autoClearSearch="true" [multiple]="true" optionTextField="userGroupName" formControlName="projectAdmins" [items]="userGroupData" appInfoPromptBox [page]="'project'" [component]="'add'" [field]="'projectAdmins'"></ngx-select>
        </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="mandatory-description d-flex flex-row">
      <span class="mandatory-description-asterisk mandatory-asterisk">*</span>
      <span class="mandatory-field-text">mandatory fields</span>
      <span class="mandatory-description-conditional-asterisk conditional-mandatory-asterisk">*</span>
      <span class="mandatory-field-text">conditional mandatory fields</span>
    </div>
    <button appButtonLoader [isLoading]="laddaLoading" appPreventDoubleSubmit (debounceClick)="onSubmit()" [debounceTime]="500" [disabled]="projectAddForm.invalid" class="btn btn-primary" type="submit">Save</button>
    <button (click)="bsModalRef.hide()" class="btn btn-default" type="button">Close</button>
  </div>
</form>
