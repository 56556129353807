<div class="container-fluid">
    <div class="row">
        <div class="pageTitle d-flex flex-row align-items-center">
            Overall Systems &nbsp;&nbsp;
            <ngx-select (selectionChanges)="tagSelected($event)" [items]="projectSystemTagData" optionValueField="tagId" optionTextField="tagText" [placeholder]="'Select Project System Tag'" appInfoPromptBox [page]="'overallSystems'" [component]="'grid'" [field]="'tag'"></ngx-select>
            <button (click)="addTag()" class="btn btn-info text-light btn-tag">
                <img title="Add" class="icon" src="/assets/icon/add-tag.png"/>
            </button>
            <button (click)="editTag()" [disabled]="!selectedTag" class="btn btn-info text-light btn-tag">
                <img title="Edit" class="icon" src="/assets/icon/edit.png"/>
            </button>
            <!-- <button (click)="deleteTag()" [disabled]="((!selectedTag)||(btnLoadingDelete))" class="btn btn-danger text-light btn-tag">
                <img *ngIf="!btnLoadingDelete" title="Delete" class="icon" src="/assets/icon/delete.png"/>
                <span *ngIf="btnLoadingDelete" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            </button> -->
            <button (click)="deleteTag()" [disabled]="((!selectedTag)||(btnLoadingDelete))" class="btn btn-danger text-light btn-tag">
                <img  *ngIf="!btnLoadingDelete" title="Delete" class="icon" src="/assets/icon/delete.png"/>
                <span *ngIf="btnLoadingDelete" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            </button>
        </div>
    </div>
    <form [formGroup]="overallSystemsFilterForm" class="filter-form">
        <div class="d-flex justify-content-between">
            <div class="filter-section row">
                <label class="filter-lbl col-sm-4 d-flex align-items-center">Project Numbers</label>
                <div class="col-sm-8">
                    <ngx-select [items]="filterValues.projectNumber" [multiple]="true" formControlName="projectNumbers" [autoClearSearch]="true" (typed)="projectNumbersFilterInputChange($event)" (select)="projectNumbersFilterSelect($event)"></ngx-select>
                </div>
            </div>
            <div class="filter-section row">
                <label class="filter-lbl col-sm-4 d-flex align-items-center">System Codes</label>
                <div class="col-sm-8">
                    <ngx-select [items]="filterValues.systemCode" [multiple]="true" formControlName="systemCodes" [autoClearSearch]="true" (typed)="systemCodesFilterInputChange($event)" (select)="systemCodesFilterSelect($event)"></ngx-select>
                </div>
            </div>
            <div class="filter-section row">
                <label class="filter-lbl col-sm-4 d-flex align-items-center">System Variations</label>
                <div class="col-sm-8">
                    <ngx-select [items]="filterValues.systemVariation" [multiple]="true" formControlName="systemVariations" [autoClearSearch]="true" (typed)="systemVariationsFilterInputChange($event)" (select)="systemVariationsFilterSelect($event)"></ngx-select>
                </div>
            </div>
        </div>
        <br>
        <div class="d-flex justify-content-between">
            <div class="filter-section row">
                <label class="filter-lbl col-sm-4 d-flex align-items-center">Stadler Article Ids</label>
                <div class="col-sm-8">
                    <ngx-select [items]="filterValues.stadlerArticleId" [multiple]="true" formControlName="stadlerArticleIds" [autoClearSearch]="true" (typed)="stadlerArticleIdsFilterInputChange($event)" (select)="stadlerArticleIdsFilterSelect($event)"></ngx-select>
                </div>
            </div>
            <div class="filter-section row">
                <label class="filter-lbl col-sm-4 d-flex align-items-center">Mounting Locations</label>
                <div class="col-sm-8">
                    <ngx-select [items]="filterValues.mountingLocation" [multiple]="true" formControlName="mountingLocations" [autoClearSearch]="true" (typed)="mountingLocationsFilterInputChange($event)" (select)="mountingLocationsFilterSelect($event)"></ngx-select>
                </div>
            </div>
            <div class="filter-section row">
                <label class="filter-lbl col-sm-4 d-flex align-items-center">System Quantities</label>
                <div class="col-sm-8">
                    <ngx-select [items]="filterValues.systemQuantity" [multiple]="true" formControlName="systemQuantities" [autoClearSearch]="true" (typed)="systemQuantitiesFilterInputChange($event)" (select)="systemQuantitiesFilterSelect($event)"></ngx-select>
                </div>
            </div>
        </div>
        <br>
        <div class="d-flex justify-content-between">
            <div class="filter-section row">
                <label class="filter-lbl col-sm-4 d-flex align-items-center">Ram Engineers</label>
                <div class="col-sm-8">
                    <ngx-select [items]="filterValues.ramEngineer" [multiple]="true" formControlName="ramEngineers" [autoClearSearch]="true" (typed)="ramEngineersFilterInputChange($event)" (select)="ramEngineersFilterSelect($event)"></ngx-select>
                </div>
            </div>
            <div class="filter-section row">
                <label class="filter-lbl col-sm-4 d-flex align-items-center">System Tags</label>
                <div class="col-sm-8">
                    <ngx-select [items]="filterValues.systemTag" [multiple]="true" formControlName="systemTags" [autoClearSearch]="true" (typed)="systemTagsFilterInputChange($event)" (select)="systemTagsFilterSelect($event)"></ngx-select>
                </div>
            </div>
            <div class="filter-section row">
                <div class="col-sm-12">
                    <button (click)="getOverallSystems()" class="btn apply-filter-btn" type="button"> Apply Filters </button>
                </div>
            </div>
        </div>
    </form>
    <div class="data-table">
        <app-tree-table [spinnerVisible]="spinnerVisible" [spinnerText]="spinnerText" [settings]="settings" [data]="overallSystemsData" [currentPage]="currentPage" [pageCount]="pageCount" (pageChangeEvent)="changePage($event)" (pageSizeChangeEvent)="changePageSize($event)" (filterChangeEvent)="filterOrSortChange($event)"></app-tree-table>
    </div>
</div>
