import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuppliersRoutingModule } from './suppliers-routing.module';
import { SuppliersListComponent } from './suppliers-list/suppliers-list.component';
import { AddSuppliersComponent } from './components/add-suppliers/add-suppliers.component';
import { EditSuppliersComponent } from './components/edit-suppliers/edit-suppliers.component';
import { SuppliersLogComponent } from './suppliers-log/suppliers-log.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BsModalRef, ModalModule} from 'ngx-bootstrap/modal';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxSelectModule} from 'ngx-select-ex';
import {LaddaModule} from 'angular2-ladda';
import {SharedModule} from '../../shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TreeTableModule} from "../../shared/components/tree-table/tree-table.module";



@NgModule({
  declarations: [
    SuppliersListComponent,
    AddSuppliersComponent,
    EditSuppliersComponent,
    SuppliersLogComponent
  ],
  imports: [
    CommonModule,
    SuppliersRoutingModule,
    FontAwesomeModule,
    ModalModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSelectModule,
    LaddaModule,
    SharedModule,
    NgbModule,
    TreeTableModule
  ],
  bootstrap:[
    AddSuppliersComponent,
    EditSuppliersComponent,
    SuppliersLogComponent
  ],
  providers:[
    BsModalRef
  ]
})
export class SuppliersModule { }
