import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-action-comment',
  templateUrl: './action-comment.component.html',
  styleUrls: ['./action-comment.component.scss']
})
export class ActionCommentComponent implements OnInit {

  @Input('commentRequired')
  public isCommentRequired:boolean = true;

  @Input('commentVisible')
  public isCommentVisible:boolean = true;
  @Input('btnLoading')
  public isbtnLoading:boolean = false;

  @Input('justificationCategoryRequired')
  public isJustificationCategoryRequired:boolean = true;

  @Input('justificationCategoryVisible')
  public isJustificationCategoryVisible:boolean = true;

  @Input('saveDisable')
  public isSaveDisable:boolean = false;

  @Input('noChangesFound')
  public noChangesFound:boolean = false;

  @Input('mandatoryDescriptionVisible')
  public mandatoryDescriptionVisible:boolean = false;

  @Input('okBtnName')
  public okBtnName:string = "OK";

  @Input('closeBtnName')
  public closeBtnName:string = "Cancel";

  @Output()
  public okClick = new EventEmitter();

  @Output()
  public cancelClick = new EventEmitter();

  public justificationCategoryData:any[] = [];

  actionCommentForm = new UntypedFormGroup({
    comment: new UntypedFormControl('', Validators.minLength(10)),
    justificationCategory: new UntypedFormControl(null)
  });

  constructor(public http: HttpClient) { }

  ngOnInit(): void {
    this.getJustificationCategory();
    
  }

  public getJustificationCategory(){
    this.http.get(environment.apiUrl+"/logs/justificationCategories").subscribe((res:any[])=>{
        this.justificationCategoryData = res;
    });
  }

  public okClicked(){
    let res = {
      action:'ok',
      comment: this.actionCommentForm.get('comment').value,
      justificationCategory: this.actionCommentForm.controls['justificationCategory'].value
    }
    this.actionCommentForm.controls['comment'].patchValue('');
    this.actionCommentForm.controls['justificationCategory'].patchValue(null);

    this.okClick.emit(res);
  }

  public cancelClicked(){
    let res = {
      action:'cancel',
    }
    this.actionCommentForm.controls['comment'].patchValue('');
    this.actionCommentForm.controls['justificationCategory'].patchValue(null);

    this.cancelClick.emit(res);
  }

}
