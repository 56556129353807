import { Component, OnInit, Input } from '@angular/core';
import { faPlus, faSync, faSearch,faEdit,faAngleRight, faPrint, faBackward } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProjectExportModalComponent } from '../project-export-modal/project-export-modal.component';
import {LanguageServiceService} from "../../../../services/language-service.service";
@Component({
  selector: 'app-task-export-url',
  templateUrl: './project-export-url.component.html',
  styleUrls: ['./project-export-url.component.scss']
})
export class ProjectExportUrlComponent implements OnInit {

  exportUrl:any;
  projectNumber:any;

  @Input()
  rowData: any;
  constructor(private modalService:BsModalService,
              private languageService: LanguageServiceService) { }

  ngOnInit(): void {

    this.exportUrl = environment.apiUrl+"/projects/"+this.rowData.projectNumber+"/tasks/export/excel";
  }

  loadTaskExportModal(){
    let lan  = {
      id: this.rowData['exportLangId'],
      str: this.rowData['exportLangId'] == 1 ? "German" : "English"
    }
    this.languageService.setWorkingLanguage(lan);

    const initialState = {
      param : {
        rowData : this.rowData,
      }
    };
    this.modalService.show(ProjectExportModalComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
  }

}
