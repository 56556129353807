import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OperatingMaterialsComponent } from './operating-materials/operating-materials.component';


const routes: Routes = [
  {
    path:'operating-materials',
    component:OperatingMaterialsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OperatingMaterialsRoutingModule { }
