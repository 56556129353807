import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CopyProjectSystemService {

    constructor(private http: HttpClient) { }


    /**
     * Copy project systems
     * @param data
     */
    copyProjectSystem(reason, data)
    {
        const httpOptions = {
            params: {
                comment: reason['comment'],
                justificationCategory: reason['justificationCategory'] != null ? reason['justificationCategory'] : -1
            }
        };
        return this.http.post(environment.apiUrl + "/projects/copyto/", data, httpOptions);
    }
}
