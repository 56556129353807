  <div class="container-fluid">

  <div class="row">
    <div class="pageTitle col-sm-6">
      <span class="breadcrumbText" (click)="breadcrumbService.gotoPage('Projects')">Projects</span>&nbsp;
      <span class="pageSubTitle"><fa-icon [icon]="faAngleRight"></fa-icon></span>&nbsp;
      <span>Operating Profiles</span>&nbsp;
      <button [ladda]="laddaLoading" type="button" (click)="loadAddModal()" class="btn btn-primary btn-sm" appInfoPromptBox [page]="'projectOperatingProfile'" [component]="'grid'" [field]="'addButton'"><fa-icon [icon]="faPlus"></fa-icon> &nbsp;&nbsp;Add</button>
    </div>
    <div class=" pageTitle col-sm-6 text-right">
      <button type="button" (click)="breadcrumbService.goBack()" class="btn btn-primary btn-sm"><fa-icon [icon]="faBackward"></fa-icon> &nbsp;&nbsp;Back</button>
    </div>
    <!-- <div class=" pageTitle col-sm-6 text-right">
      <button type="button" [routerLink]="previousUrl" routerLinkActive="router-link-active"  class="btn btn-primary btn-sm"><fa-icon [icon]="faBackward"></fa-icon> &nbsp;&nbsp;Back</button>
    </div> -->
  </div>
  <div  class="row">
    <div class="" style="padding-left: 15px; padding-right: 15px; ">
      <table class="table table-bordered table-striped">
        <tr>
          <th> Project </th>
          <td style="vertical-align:middle;"> {{projectNumber}} {{projectDesignation}} </td>
        </tr>
       
      </table>
    </div>
  </div>

  <div class="data-table">
    <app-tree-table [settings]="settings" [data]="tableData" [currentPage]="currentPage" [pageCount]="pageCount" (pageChangeEvent)="changePage($event)" (pageSizeChangeEvent)="changePageSize($event);" (filterChangeEvent)="filterOrSortChange($event)"></app-tree-table>
  </div>



</div> <!-- wrapper -->
