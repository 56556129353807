import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MountingLocationService {

    constructor(private http: HttpClient) { }


    /**
     * Get All mounting location
     */
    getAll()
    {
        return this.http.get(environment.apiUrl + "/mountinglocation/");
    }

    /**
     * Get All mounting location
     */
    getAllActive()
    {
        return this.http.get(environment.apiUrl + "/mountinglocation/active");
    }

    /**
     * Get project system mounting locations
     * @param projectSystemId
     */
    getProjectSystemMountingLocations(projectSystemId)
    {

        return this.http.get(environment.apiUrl + "/systemmountinglocations/systems/" + projectSystemId + "/");
    }

    /**
     * Create project system mounting locations
     * @param data
     * @param projectSystemId
     */
    createProjectSystemMountingLocations(data, projectSystemId)
    {
        return this.http.post(environment.apiUrl + "/systemmountinglocations/systems/" + projectSystemId + "/", data);
    }
}
