import { Component, OnInit } from '@angular/core';
import { OverallSystemsAndTasksService } from "../../../../services/overall-systems-and-tasks.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { AddEditTagComponent } from "../../../../shared/components/add-edit-tag/add-edit-tag.component";
import { INgxSelectOption } from "ngx-select-ex";
import { ToastrService } from "ngx-toastr";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

@Component({
	selector: 'app-overall-systems',
	templateUrl: './overall-systems.component.html',
	styleUrls: ['./overall-systems.component.scss']
})
export class OverallSystemsComponent implements OnInit {

	public projectSystemTagData = [];
	public overallSystemsData = [];

	public selectedTag = null;

	public currentPage: number = 1;
	public pageCount: number = 0;
	public pageSize: number = 20;
	public sortColumn: string = "";
	public sortOrder: string = "";
	public filterData = [];

	public spinnerVisible = false;
	public spinnerText = "Loading...";
	public btnLoading: Boolean = false;
	public btnLoadingDelete: Boolean = false;

	public settings = {
		type: 'table',
		id: 'projectSystemId',
		columnManagerEnable: true,
		variableColumnMinLength: 75,
		pagination: {
			enabled: true,
			pageSize: this.pageSize
		},
		freezeColumns: {
			enabled: true,
			left: 2,
			right: 2
		},
		filterOptionFunction: (params, filterData) => {
			const data = {
				gridFilters: filterData,
				mainFilters: this.overallSystemsFilterForm.getRawValue()
			}
			return this.overallSystemsAndTasksService.getOverallSystems("distinct-values", data, params)
		},
		enableZoom: true,
		columns: [
			{
				title: 'Project Number',
				attribute: 'projectNumber',
				type: 'text',
				filter: {
					enabled: true,
					isNumericField: false,
				},
				width: 150,
			},
			{
				title: 'Code',
				attribute: 'systemVariationNumberAndCode',
				type: 'text',
				filter: {
					enabled: true,
					isNumericField: false,
				},
				width: 150,
			},
			{
				title: 'System Variation',
				attribute: 'systemVariation',
				type: 'text',
				filter: {
					enabled: true,
					isNumericField: false,
				},
				width: 200
			},
			{
				title: 'Stadler Article ID',
				attribute: 'stadlerArticleId',
				type: 'text',
				filter: {
					enabled: true,
					isNumericField: false,
				},
				width: 200,
			},
			{
				title: 'Mounting Location',
				attribute: 'mountingLocationsStr',
				type: 'text',
				filter: {
					enabled: true,
					isNumericField: false,
				},
				width: 250
			},
			{
				title: 'System Qty',
				attribute: 'systemQuantity',
				type: 'text',
				format: "'###",
				filter: {
					enabled: true,
					isNumericField: false,
				},
				width: 110
			},
			{
				title: 'RAM Engineer',
				attribute: 'ramEngineersStr',
				type: 'text',
				filter: {
					enabled: true,
					isNumericField: false,
				},
				width: 250
			},
			{
				title: 'Supplier',
				attribute: 'systemSupplierName',
				type: 'text',
				filter: {
					enabled: true,
					isNumericField: false,
				},
				width: 250,
			},
			{
				title: 'Comment',
				attribute: 'systemComment',
				type: 'text',
				filter: {
					enabled: true,
					isNumericField: false,
				},
				width: 350
			},
			{
				title: '',
				type: 'checkbox',
				actionFunction: (event, row) => {
					this.addRemoveTagToFromProjectSystem(event, row);
				},
				checkIsCheckedFunction: (row) => {
					let systemTags: any[] = row['systemTags'];
					if (systemTags.length == 0 || !this.selectedTag) return false;

					for (let tag of systemTags) {
						if (tag['tagId'] == this.selectedTag['tagId']) {
							return true;
						}
					}
					return false;
				},
				width: 70,
			},
			{
				title: 'Tags',
				attribute: 'systemTagsStr',
				type: 'text',
				filter: {
					enabled: true,
					isNumericField: false,
				},
				width: 250
			}
		]
	};

	public filterValues = {
		projectNumber: [],
		systemCode: [],
		systemVariation: [],
		stadlerArticleId: [],
		mountingLocation: [],
		systemQuantity: [],
		ramEngineer: [],
		systemTag: [],
	}

	public originalFilterValues = {
		projectNumber: [],
		systemCode: [],
		systemVariation: [],
		stadlerArticleId: [],
		mountingLocation: [],
		systemQuantity: [],
		ramEngineer: [],
		systemTag: [],
	}

	public customValueAddedForFilter = {
		projectNumber: false,
		systemCode: false,
		systemVariation: false,
		stadlerArticleId: false,
		mountingLocation: false,
		systemQuantity: false,
		ramEngineer: false,
		systemTag: false,
	}

	public overallSystemsFilterForm = new UntypedFormGroup({
		projectNumbers: new UntypedFormControl([]),
		systemCodes: new UntypedFormControl([]),
		systemVariations: new UntypedFormControl([]),
		stadlerArticleIds: new UntypedFormControl([]),
		mountingLocations: new UntypedFormControl([]),
		systemQuantities: new UntypedFormControl([]),
		ramEngineers: new UntypedFormControl([]),
		systemTags: new UntypedFormControl([]),
	})

	constructor(public overallSystemsAndTasksService: OverallSystemsAndTasksService,
		public modalService: BsModalService,
		public notification: ToastrService) { }

	ngOnInit(): void {
		this.getAllFilterDropDownData();
		this.getProjectSystemTags();
	}

	public getProjectSystemTags() {
		this.overallSystemsAndTasksService.getAllTagsByType("project_system").subscribe((res: any[]) => {
			this.projectSystemTagData = res;
			
		}, (error => {}), () => {
			
		});
	}

	public getOverallSystems() {
		let params = {};
		if (this.currentPage) params['page'] = this.currentPage - 1;
		if (this.pageSize) params['pageSize'] = this.pageSize;
		if (this.sortColumn) params['column'] = this.sortColumn;
		if (this.sortOrder) params['sortOrder'] = this.sortOrder;

		const data = {
			gridFilters: this.filterData,
			mainFilters: this.overallSystemsFilterForm.getRawValue()
		}

		this.spinnerVisible = true;
		this.overallSystemsAndTasksService.getOverallSystems("filtered-data", data, params).subscribe((res: any[]) => {
			this.spinnerVisible = false;
			if (res && res['page']) {
				this.pageCount = res['page']['totalPages'];
				let result = res['page']['content'];

				this.overallSystemsData = result;
			}
			if (res && res['tablePropertyMap']) {
				let columns = this.settings.columns;
				let tablePropertyMap: Map<string, {}> = res['tablePropertyMap'];

				for (let i = 0; i < columns.length; i++) {
					let data = tablePropertyMap[columns[i].attribute];
					if (!data) continue;
					columns[i].filter['column'] = data['column'];
					columns[i].filter['table'] = data['table'];
				}
			}

			this.settings['isNative'] = false;
		}, (error => {
			this.spinnerVisible = false;
		}));
	}

	public addRemoveTagToFromProjectSystem(event, row) {
		if (!event || !event.target) return;

		if (!this.selectedTag) {
			this.notification.error('Please select a tag...', 'Error');
			event.preventDefault();
			return;
		}

		let checked = event.target.checked;
		if (checked) {
			this.overallSystemsAndTasksService.addTagToProjectSystem(row['projectSystemId'], this.selectedTag).subscribe((res) => {
				this.notification.success('Tag has been Added to Project System', 'Success');
				this.getOverallSystems();
			}, (error) => {
				this.notification.error('Tag has not been Added to Project System', 'Error');
			});
		}
		else {
			this.overallSystemsAndTasksService.removeTagFromProjectSystem(row['projectSystemId'], this.selectedTag).subscribe((res) => {
				this.notification.success('Tag has been Removed from Project System', 'Success');
				this.getOverallSystems();
			}, (error) => {
				this.notification.error('Tag has not been Removed from Project System', 'Error');
			});
		}
	}

	public addTag() {
		const initialState = {
			action: 'Add',
			tagType: "project_system"
		};
		let modalRef = this.modalService.show(AddEditTagComponent, {
			class: 'modal-md',
			initialState,
			ignoreBackdropClick: true
		});
		modalRef.onHidden.subscribe(() => {
			this.getProjectSystemTags();
		})
	}

	public editTag() {
		const initialState = {
			action: 'Edit',
			tagId: this.selectedTag['tagId'],
			tagText: this.selectedTag['tagText'],
			tagType: "project_system"
		};
		let modalRef = this.modalService.show(AddEditTagComponent, {
			class: 'modal-md',
			initialState,
			ignoreBackdropClick: true
		});
		modalRef.onHidden.subscribe(() => {
			this.getProjectSystemTags();
		})
	}

	public deleteTag() {
			this.btnLoadingDelete = true;
			this.overallSystemsAndTasksService.deleteTag(this.selectedTag['tagId']).subscribe((res: any[]) => {
				
				this.notification.success('Tag has been deleted', 'Success');
				this.getProjectSystemTags();

			}, (error => {
				if (error.status == 409) {
					this.notification.error('Tag is used. Cannot be deleted', 'Error');
				}
				else {
					this.notification.error('Could not delete the tag', 'Error');
				}
				this.btnLoadingDelete = false;
			}), () =>{
				this.btnLoadingDelete = false;
			});

	}

	public tagSelected(options: INgxSelectOption[]) {
		if (!options?.at(0) || !options?.at(0).data) return;
		this.selectedTag = options.at(0).data;
	}

	public changePage(currentPage) {
		this.currentPage = currentPage;
		this.getOverallSystems();
	}

	public changePageSize(pageSize) {
		this.pageSize = pageSize;
		this.getOverallSystems();
	}

	public filterOrSortChange(event) {
		if (!event) {
			this.currentPage = 1;
			this.overallSystemsData = [];
			return;
		}
		if (!event['sortChanged']) this.currentPage = 1;
		this.sortColumn = event['sortColumn'];
		this.sortOrder = event['sortOrder'];
		this.filterData = event['filterData'];

		this.getOverallSystems();
	}

	private getAllFilterDropDownData() {
		this.overallSystemsAndTasksService.getAllProjectNumbersFilterData().subscribe((res: string[]) => {
			this.filterValues.projectNumber = res;
			this.originalFilterValues.projectNumber = [...this.filterValues.projectNumber];
		});
		this.overallSystemsAndTasksService.getAllSystemCodesFilterData().subscribe((res: string[]) => {
			this.filterValues.systemCode = res;
			this.originalFilterValues.systemCode = [...this.filterValues.systemCode];
		});
		this.overallSystemsAndTasksService.getAllSystemVariationsFilterData().subscribe((res: string[]) => {
			this.filterValues.systemVariation = res;
			this.originalFilterValues.systemVariation = [...this.filterValues.systemVariation];
		});
		this.overallSystemsAndTasksService.getAllStadlerArticleIdsFilterData().subscribe((res: string[]) => {
			this.filterValues.stadlerArticleId = res;
			this.originalFilterValues.stadlerArticleId = [...this.filterValues.stadlerArticleId];
		});
		this.overallSystemsAndTasksService.getAllMountingLocationsFilterData().subscribe((res: string[]) => {
			this.filterValues.mountingLocation = res;
			this.originalFilterValues.mountingLocation = [...this.filterValues.mountingLocation];
		});
		this.overallSystemsAndTasksService.getAllSystemQuantitiesFilterData().subscribe((res: string[]) => {
			this.filterValues.systemQuantity = res;
			this.originalFilterValues.systemQuantity = [...this.filterValues.systemQuantity];
		});
		this.overallSystemsAndTasksService.getAllRamEngineersFilterData().subscribe((res: string[]) => {
			this.filterValues.ramEngineer = res;
			this.originalFilterValues.ramEngineer = [...this.filterValues.ramEngineer];
		});
		this.overallSystemsAndTasksService.getAllSystemTagsFilterData().subscribe((res: string[]) => {
			this.filterValues.systemTag = res;
			this.originalFilterValues.systemTag = [...this.filterValues.systemTag];
		});
	}

	public projectNumbersFilterInputChange(inputStr: string) {
		this.filterInputChange(inputStr, 'projectNumber')
	}

	public systemCodesFilterInputChange(inputStr: string) {
		this.filterInputChange(inputStr, 'systemCode')
	}

	public systemVariationsFilterInputChange(inputStr: string) {
		this.filterInputChange(inputStr, 'systemVariation')
	}

	public stadlerArticleIdsFilterInputChange(inputStr: string) {
		this.filterInputChange(inputStr, 'stadlerArticleId')
	}

	public mountingLocationsFilterInputChange(inputStr: string) {
		this.filterInputChange(inputStr, 'mountingLocation')
	}

	public systemQuantitiesFilterInputChange(inputStr: string) {
		this.filterInputChange(inputStr, 'systemQuantity')
	}

	public ramEngineersFilterInputChange(inputStr: string) {
		this.filterInputChange(inputStr, 'ramEngineer')
	}

	public systemTagsFilterInputChange(inputStr: string) {
		this.filterInputChange(inputStr, 'systemTag')
	}

	public filterInputChange(inputStr: string, filterType: string) {
		if (inputStr == null || inputStr.length == 0) {
			if (this.customValueAddedForFilter[filterType]) {
				this.filterValues[filterType].splice(0, 1);
				this.customValueAddedForFilter[filterType] = false;
			}
		}
		else {
			if (this.customValueAddedForFilter[filterType]) {
				this.filterValues[filterType][0] = inputStr;
			}
			else {
				this.filterValues[filterType].unshift(inputStr);
				this.customValueAddedForFilter[filterType] = true;
			}
		}
	}

	public projectNumbersFilterSelect(value: string) {
		this.filterSelect(value, 'projectNumber')
	}

	public systemCodesFilterSelect(value: string) {
		this.filterSelect(value, 'systemCode')
	}

	public systemVariationsFilterSelect(value: string) {
		this.filterSelect(value, 'systemVariation')
	}

	public stadlerArticleIdsFilterSelect(value: string) {
		this.filterSelect(value, 'stadlerArticleId')
	}

	public mountingLocationsFilterSelect(value: string) {
		this.filterSelect(value, 'mountingLocation')
	}

	public systemQuantitiesFilterSelect(value: string) {
		this.filterSelect(value, 'systemQuantity')
	}

	public ramEngineersFilterSelect(value: string) {
		this.filterSelect(value, 'ramEngineer')
	}

	public systemTagsFilterSelect(value: string) {
		this.filterSelect(value, 'systemTag')
	}

	public filterSelect(value: string, filterType: string) {
		if (this.originalFilterValues[filterType].indexOf(value) < 0) {
			this.filterValues[filterType].push(value);
		}
		if (this.customValueAddedForFilter[filterType]) {
			this.filterValues[filterType].splice(0, 1);
		}
	}
}
