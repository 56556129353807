import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {LanguageSupportComponent} from "../../../../../shared/components/language-support/language-support-component.service";
import {LanguageServiceService} from "../../../../../services/language-service.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

import {ProjectSystemSparePartsService} from "../../../../../services/project-system-spare-parts.service";
import {TreeTableComponent} from "../../../../../shared/components/tree-table/pages/tree-table/tree-table.component";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-project-system-spare-part-move',
  templateUrl: './project-system-spare-part-move.component.html',
  styleUrls: ['./project-system-spare-part-move.component.scss']
})
export class ProjectSystemSparePartMoveComponent extends LanguageSupportComponent implements OnInit {

  param: any;
  rowData:any;
  projectNumber:any;
  systemNumber:any;
  spareNumber:any;
  projectFreezeFunction:any;
  event: EventEmitter<any> = new EventEmitter();
  tableData:any;
  selectedPart:any = null;
  btnLoading:Boolean = false;

  @ViewChild(TreeTableComponent) treeTable: TreeTableComponent;

  constructor(public bsModalRef: BsModalRef,
              public languageService: LanguageServiceService,
              public modalService: BsModalService,
              private notificationService: ToastrService,
              private projectSystemSparePartService : ProjectSystemSparePartsService) {
    super(languageService, modalService);
  }

  ngOnInit(): void {
    super.saveInitialValues(null);
    this.rowData = this.param['rowData'];
    this.projectNumber = this.param['projectNumber'];
    this.systemNumber = this.param['systemNumber'];
    this.spareNumber = this.rowData['spareNumber'];

    this.getPossiblePartsToMove();
  }

  fetchChildren = (row)=>{
    let parentId = row['spareNumber'];
    return this.projectSystemSparePartService.findAllPossiblePartsToMoveByParentId(this.projectNumber,this.systemNumber, parentId, this.spareNumber);
  };

  settings = {
    type:'tree',
    id:'spareNumber',
    childrenFetchFunc:this.fetchChildren,
    tableClass:'',
    tableHeaderClass:'',
    tableRowClass:'',
    columnManagerEnable:true,
    columns:[
      {
        title:'Stadler Article ID',
        attribute:'spareStadlerArticleId',
        type:'text',
        width:250,
      },
      {
        title:'Part Designation',
        attribute:'spareDesignation',
        type:'text',
        width:250
      },
      {
        type:'radio',
        actionFunction: (event, row)=>{this.updateSelectedPart(event, row)},
        width:70,
      },
    ]
  };

  getPossiblePartsToMove(){
    this.projectSystemSparePartService.findAllPossiblePartsToMoveByParentId(this.projectNumber,this.systemNumber, -1, this.spareNumber).subscribe(res=>{
      this.tableData = res;
    });
  }

  updateSelectedPart(event, part){
    this.selectedPart = part;
  }

  moveParts(event){
    this.btnLoading = true;
    if(!this.selectedPart || !this.selectedPart['spareNumber']) return;

    this.projectSystemSparePartService.movePart(event, this.spareNumber, this.selectedPart['spareNumber']).subscribe(res => {
      if (res) {
        this.notificationService.success('Project System Parts has been Moved', 'Success');
      } else {
        this.notificationService.error('Project System Parts has not been Moved', 'Error');
      }
      this.btnLoading = false;
      this.bsModalRef.hide();
      this.event.emit();
    }, (error => {
      if(error.status == 403) {
        this.notificationService.error('You need to be in the Project Admins Group to perform the current Action','Error');
      }else{
        this.notificationService.error('Project System Parts has not been Moved', 'Error');
      }
      this.btnLoading = false;
      this.bsModalRef.hide();
      this.event.emit();
    }));
  }

  languageChanged(){
    super.languageChanged(null);
  }

  callAfterLanguageChanged() {
    this.getPossiblePartsToMove();
  }

}
