import { Component, OnInit } from '@angular/core';
import { cleanseSpaces } from "../../../../../shared/helper";
import { ProjectSystemSparePartsService } from "../../../../../services/project-system-spare-parts.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-task-part-usages-part-quantity',
  templateUrl: './task-part-usages-part-quantity.component.html',
  styleUrls: ['./task-part-usages-part-quantity.component.scss']
})
export class TaskPartUsagesPartQuantityComponent implements OnInit {

  partQuantity: any;
  rowData: any;
  projectFreezeFunction: string = "";
  quantityDisabled: boolean = true;
  invalidQuantity: boolean = false;
  emptyQuantity: boolean = false;
  zeroQuantity: boolean = false;
  smallerTaskQuantity: boolean = false;
  oldPartsQuantity: any;


  constructor(private projectSystemSparePartsService: ProjectSystemSparePartsService,
    private notification: ToastrService) { }

  ngOnInit(): void {
    this.partQuantity = this.rowData['taskPartQuantity'];
  }

  updateQuantity() {
    console.log(this.rowData);
    if (this.projectFreezeFunction == "activated") return;

    if (this.partQuantity == '' || this.partQuantity == null) {
      this.invalidQuantity = true;
      this.emptyQuantity = true;
    }
    else if (this.partQuantity == 0) {
      this.invalidQuantity = true;
      this.zeroQuantity = true;
    }
    else if (this.partQuantity > this.rowData['qtyOnVehicleLevel']) {
      this.invalidQuantity = true;
      this.smallerTaskQuantity = true;
    }
    else {
      this.quantityDisabled = true;
      this.invalidQuantity = false;
      this.emptyQuantity = false;
      this.zeroQuantity = false;
      this.smallerTaskQuantity = false;

      if (this.oldPartsQuantity != this.partQuantity) {
        this.projectSystemSparePartsService.updateTaskPartQuantity(this.rowData, this.partQuantity).subscribe(res => {
          if (res) {
            this.notification.success('Task Part has been updated', 'success');
          } else {
            this.notification.error('Task Part has not been updated', 'Error');
            this.partQuantity = this.rowData['taskPartQuantity'];
          }
        }, error => {
          this.notification.error('Task Part has not been updated', 'Error');
          this.partQuantity = this.rowData['taskPartQuantity'];
        });
      }

    }
  }

  setQuantityReadable() {
    this.quantityDisabled = false;
    this.oldPartsQuantity = this.partQuantity;
  }
}
