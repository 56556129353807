import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class ProjectExportLogService {

    constructor(private http: HttpClient) { }


    /**
     * Update Export Log Doku Version
     * @param data
     */
    updateDokuVersion(projectNumber, id, dokuVersion)
    {
        return this.http.put(environment.apiUrl + "/projects/" + projectNumber + "/tasks/export-logs/" + id + "/" + dokuVersion, null);
        // return this.http.put(environment.apiUrl+"/projects/"+id+'/', data);
    }


}

