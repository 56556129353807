import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorizationComponent } from '../authorization/authorization/authorization.component';


const routes: Routes = [
  { path: 'authorization', component: AuthorizationComponent, data: { animation: 'tasks' }},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthorizationRoutingModule { }
