<div class="container-fluid">

  <div class="row">
    <div class="pageTitle col-sm-3">
      Suppliers <button (click)="loadAddModal()" id="btnAddProject" type="button" class="btn btn-primary btn-sm"><fa-icon [icon]="faPlus"></fa-icon> &nbsp;&nbsp;Add</button>
      &nbsp;&nbsp;<button type="button" class="btn btn-primary btn-sm" (click)="showLogs()" appInfoPromptBox [page]="'masterSuppliers'" [component]="'grid'" [field]="'logsButton'"><fa-icon [icon]="faFile"></fa-icon>&nbsp;&nbsp;Logs</button>
    </div>
  </div>
<!--  <div class="row mt-4">-->
<!--    <div class="searchForm col-sm-12">-->
<!--      <form class="form-inline" [formGroup]="searchForm" (submit)="search()">-->
<!--        Name-->
<!--        <div class="form-group mx-sm-3 mb-2">-->
<!--          <input type="text" formControlName="nameUsed" class="form-control form-control-sm" id="searchNumber" placeholder="Name Used">-->
<!--        </div>-->
<!--        ERP Id-->
<!--        <div class="form-group mx-sm-3 mb-2">-->
<!--          <input type="text" formControlName="erpId" class="form-control form-control-sm" placeholder="ERP Id">-->
<!--        </div>-->
<!--       -->
<!--        <button type="submit" [ladda] = "laddaLoading" class="btn btn-primary mb-2 col-sm-1"><fa-icon [icon]="faSearch"></fa-icon>&nbsp;&nbsp;Search</button>&nbsp;-->
<!--        <button *ngIf="showReset" type="button" (click)="resetSearch()" class="btn btn-primary mb-2 col-sm-1"><fa-icon [icon]="faSync"></fa-icon>&nbsp;&nbsp;Reset</button>-->
<!--      </form>-->
<!--    </div>-->
<!--  </div>-->

  <div class="data-table">
    <app-tree-table [settings]="settings" [data]="suppliersData" [currentPage]="currentPage" [pageCount]="pageCount" (pageChangeEvent)="changePage($event)" (pageSizeChangeEvent)="changePageSize($event);"  (filterChangeEvent)="filterOrSortChange($event)"></app-tree-table>
  </div>



</div> <!-- wrapper -->
