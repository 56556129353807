import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styles: []
})
export class IconComponent implements OnInit {

  @Input() code:any;
  imgData:any;
  constructor() { }

  ngOnInit(): void {

    this.getImageUrl();
  }


  getImageUrl(){

    let imgArray = {
      export:{
        url : '/assets/icon/export.png',
        title:'Import / Export'
      },
      operatingprofiles:{
        url : '/assets/icon/operatingprofiles.png',
        title:'Operating Profiles'
      },
      operatingmaterials:{
        url : '/assets/icon/operatingmaterial.png',
        title:'Operating Materials'
      },
      parts:{
        url : '/assets/icon/parts.png',
        title:'Parts'
      },
      systems:{
        url : '/assets/icon/systems.png',
        title:'Systems'
      },
      tasks:{
        url : '/assets/icon/tasks.png',
        title:'Tasks'
      },
      tools:{
        url : '/assets/icon/tools.png',
        title:'Tools'
      },
      delete:{
        url : '/assets/icon/delete.png',
        title:'Delete'
      },
      edit:{
        url : '/assets/icon/edit.png',
        title:'Edit'
      },
      clone:{
        url : '/assets/icon/clone.png',
        title:'Clone'
      },
      copy:{
        url : '/assets/icon/copy.png',
        title:'Copy'
      },
      copyproject:{
        url : '/assets/icon/copy.png',
        title:'Copy Project'
      },
      log:{
        url : '/assets/icon/log.png',
        title:'Log'
      },
      view:{
        url : '/assets/icon/view.png',
        title:'View'
      },
      lens:{
        url : '/assets/icon/lens.png',
        title:'Usage'
      },
      filter:{
        url : '/assets/icon/filter.png',
        title:'Filter'
      },
      filter_selected:{
        url : '/assets/icon/filter-selected.png',
        title:'Selected Filter'
      },
    };

   this.imgData = imgArray[this.code];

  }



}
