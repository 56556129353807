<div class="container-fluid">

  <div class="row">
    <div class="pageTitle col-sm-6">
      <span class="breadcrumbText" (click)="breadcrumbService.gotoPage('Projects')">Projects</span>&nbsp;
      <span class="pageSubTitle"><fa-icon [icon]="faAngleRight"></fa-icon></span>&nbsp;
      <span>Systems</span>&nbsp;
      <button (click)="loadProjectSystemAddModal()" id="btnAddProject" type="button" class="btn btn-primary btn-sm"  ><fa-icon [icon]="faPlus"></fa-icon> &nbsp;&nbsp;Add</button>&nbsp;
      <button  (click)="exportProjectSystems()" id="btnExportProjectSystems" type="button" class="btn btn-primary btn-sm" appInfoPromptBox [page]="'projectSystem'" [component]="'grid'" [field]="'addButton'"><fa-icon [icon]="faFileExport" ></fa-icon> &nbsp;&nbsp;Export Project Systems &nbsp;<i *ngIf="loading" class="fas fa-cog fa-spin spinning-cog"></i></button>
    </div>
    <div class=" pageTitle col-sm-6 text-right">
      <button type="button" (click)="breadcrumbService.goBack()" class="btn btn-primary btn-sm system-back-btn"><fa-icon [icon]="faBackward"></fa-icon> &nbsp;&nbsp;Back</button>
    </div>
  </div>
  <div *ngIf="infoStatus" class="row">
    <div class="" style="padding-left: 15px; padding-right: 15px; ">
      <table class="table table-bordered table-striped">
        <tr>
          <th style="padding-top: 3px !important;vertical-align: middle;padding-bottom: 3px !important;"> Project </th>
          <td style="padding-top: 3px !important;vertical-align: middle;padding-bottom: 3px !important;"> {{projectData['projectNumber']}} {{projectData['projectDesignation']}} </td>
        </tr>
      </table>
    </div>
  </div>

  <div class=" data-table">
    <app-tree-table [spinnerVisible]="(!propertyMapLoaded)||projectSystemDeletionStarted" [spinnerText]="currentSpinnerText" [settings]="settings" [data]="tableData" [currentPage]="currentPage" [pageCount]="pageCount" (pageChangeEvent)="changePage($event)" (pageSizeChangeEvent)="changePageSize($event);" (filterChangeEvent)="filterOrSortChange($event)"></app-tree-table>
  </div>

  <!-- <div *ngIf="!(propertyMapLoaded)" class="spinner-div d-flex justify-content-center align-items-center">
      <mat-progress-spinner [color]="'primary'" [mode]="'indeterminate'" class="spinner"></mat-progress-spinner>
  </div> -->

</div> <!-- wrapper -->
