import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProjectsaddComponent } from './projectsadd/projectsadd.component';
import { ProjectslistComponent } from './projectslist/projectslist.component';
import { ProjecteditComponent } from './projectedit/projectedit.component';
import { ProjectSystemsComponent } from './project-systems/project-systems.component';
import { ProjectPricesComponent } from './project-prices/project-prices.component';
import { ProjectSystemSparePartsComponent } from './project-system-spare-parts/project-system-spare-parts.component';
import { SpecialToolsComponent } from '../special-tools/special-tools.component';


const routes: Routes = [
  { path: 'projects', component: ProjectslistComponent, data: { animation: 'projects' }},
  { path: 'projects/add', component: ProjectsaddComponent, data: { animation: 'projects' }},
  { path: 'projects/edit/:id', component: ProjecteditComponent, data: { animation: 'projects' }},
  { path: 'projects/:id/systems', component: ProjectSystemsComponent, data: { animation: 'projects' }},
  { path: 'projects/:id/prices', component: ProjectPricesComponent, data: { animation: 'projects' }},

  { path: 'projects/:projectNumber/systems/:systemNumber/spare-parts', component: ProjectSystemSparePartsComponent, data: { animation: 'projects' }},
  { path: 'special-tools', component: SpecialToolsComponent, data: { animation: 'projects' }},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectsRoutingModule { }
