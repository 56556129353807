import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProjectToolService {


    constructor(private http: HttpClient) { }


    /**
     * Get Project Tools By Project Number
     * @param projectNumber
     */
    getProjectTools(projectNumber)
    {
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/tools/");
    }

    getProjectToolsList(projectNumber, apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/projects/" + projectNumber + "/tools/" + apiType, data, {params: params});
    }

    /**
     * Get Project Tools By Project Number and Tool Number
     * @param projectNumber
     */
    getProjectToolsbyToolNumber(projectNumber, toolNumber)
    {
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/tools/" + toolNumber + "/");
    }

    /**
     * Assign Tools To Project With Cost
     * @param projectNumber
     */
    createProjectTools(data, projectNumber)
    {
        return this.http.post(environment.apiUrl + "/projects/" + projectNumber + "/tools/", data);
    }

    /**
     * Updateign Project Tools
     * @param projectNumber
     */
    updateProjectTools(data, projectNumber, toolNumber)
    {
        return this.http.put(environment.apiUrl + "/projects/" + projectNumber + "/tools/" + toolNumber + "/", data);
    }

    deleteProjectTool(projectNumber, toolNumber)
    {
        return this.http.delete(environment.apiUrl + "/projects/" + projectNumber + "/tools/" + toolNumber + "/");
    }

    /**
     *
     * @param projectNumber
     */
    exportProjectTools(projectNumber)
    {
        const headers = new HttpHeaders()
            .set("Accept", "application/pdf");
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/tools/export/", {responseType: 'blob'});
    }
}
