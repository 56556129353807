import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProjectSystemsService } from 'src/app/services/project-systems.service';
import { cleanseSpaces } from 'src/app/shared/helper';

@Component({
  selector: 'app-systems-variation',
  templateUrl: './systems-variation.component.html',
  styleUrls: ['./systems-variation.component.scss']
})
export class SystemsVariationComponent implements OnInit {

  rowData: any;
  systemVariation: any;
  variationDisabled: Boolean = true;
  projectNumber: any;
  invalidVariation: Boolean = false;
  emptyVariation: Boolean = false;
  maxLengthVariation: Boolean = false;
  systemVariationNumber: any;
  projectSystemId: any;
  projectFreezeFunction: string = "";
  oldSystemVariation: any;
  systemStatus: Number = 0;
  systemVariationClass: string = "active-system-variation";

  constructor(
    private projectSystemService: ProjectSystemsService,
    private notification: ToastrService
  ) { }

  ngOnInit(): void {
    this.systemVariation = this.rowData.systemVariation;
    this.projectNumber = this.rowData.projectNumber;
    this.projectSystemId = this.rowData.projectSystemId;
    this.systemVariationNumber = this.rowData.systemVariationNumber;
    this.systemStatus = this.rowData.isActive==null?0:this.rowData.isActive;
    if (this.systemStatus == 0) {
      this.systemVariationClass = "active-system-variation";
    }else{
      this.systemVariationClass = "inactive-system-variation";
    }
  }

  /**
   * Set variation as readble
   */
  setVariationReadble() {
    this.variationDisabled = false;
    this.oldSystemVariation = this.systemVariation;
  }

  updateVariation() {
    if (this.projectFreezeFunction == "activated") return;

    this.systemVariation = cleanseSpaces(this.systemVariation);
    // console.log("Cleansed System Variation : " + this.systemVariation);

    let data = {
      systemVariation: this.systemVariation,
      systemVariationNumber: this.systemVariationNumber
    };

    if (this.systemVariation == '') {
      this.invalidVariation = true;
      this.emptyVariation = true;
    }
    else if (this.systemVariation.length > 100) {
      this.invalidVariation = true;
      this.maxLengthVariation = true;
    } else {
      this.variationDisabled = true;
      this.invalidVariation = false;
      this.emptyVariation = false;
      this.maxLengthVariation = false;

      if (this.oldSystemVariation != this.systemVariation) {
        this.projectSystemService.updateProjectVariation(this.projectNumber, this.projectSystemId, data).subscribe(res => {
          if (res) {
            this.notification.success('Project system has been updated', 'success');
          } else {
            this.notification.error('Project system has not been updated', 'Error');
          }
        }, error => {
          console.log(error);
          if(error.error){
            this.notification.error('Project system has not been updated : ' + error.error.details, error.error.message);
          }else{
            this.notification.error('Project system has not been updated', 'Error');
          }
          
          this.systemVariation = this.oldSystemVariation;
        });
      }


    }

  }

}
