import { Component, OnInit, Input } from '@angular/core';
import { ProjectToolsComponent } from 'src/app/components/projects/project-tools/project-tools.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import {LanguageServiceService} from "../../../services/language-service.service";

@Component({
  selector: 'app-project-systems-tools-url',
  templateUrl: './project-tools-url.component.html',
  styleUrls: ['./project-tools-url.component.scss']
})
export class ProjectToolsUrlComponent implements OnInit {

  @Input()
  rowData: any;
  projectNumber:String;
  systemNumber:Number;
  constructor(private modalService:BsModalService,
              private languageService: LanguageServiceService) { }

  ngOnInit(): void {
    
  }

  loadProjectToolsModal(){
    let lan  = {
      id: this.rowData['workLangId'],
      str: this.rowData['workLangId'] == 1 ? "German" : "English"
    }
    this.languageService.setWorkingLanguage(lan);

    this.projectNumber = this.rowData.projectNumber;
    const initialState = {
      param : {
        projectNumber : this.projectNumber,
      }
    };
    this.modalService.show(ProjectToolsComponent, {class:'modal-xl', initialState,keyboard:false,ignoreBackdropClick: true});
  }

}
