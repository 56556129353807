<nav *ngIf="auth" class="navbar fixed-top navbar-expand-lg navbar-dark justify-content-between">
    <a class="navbar-brand" routerLink="/projects">
        <img class="rambase-logo" src="assets/img/rambase_logo.png"/>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
            <!-- <li class="nav-item ">
      <a class="nav-link" routerLink="" routerLinkActive="active">Home <span class="sr-only">(current)</span></a>
    </li> -->
            <li class="nav-item">
                <a class="nav-link" (click)="breadcrumbService.resetStack()" routerLink="/projects"
                   routerLinkActive="active">Projects</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" (click)="breadcrumbService.resetStack()" routerLink="/systems"
                   routerLinkActive="active">Standard Systems</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" (click)="breadcrumbService.resetStack()" routerLink="/special-tools"
                   routerLinkActive="active">Tools</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" (click)="breadcrumbService.resetStack()" routerLink="/operating-materials"
                   routerLinkActive="active">Operating Materials</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" (click)="breadcrumbService.resetStack()" routerLink="/spare-parts"
                   routerLinkActive="active">Parts</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" (click)="breadcrumbService.resetStack()" routerLink="/suppliers"
                   routerLinkActive="active">Suppliers</a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" (click)="breadcrumbService.resetStack()" routerLink="/system-logs" routerLinkActive="active">System Logs</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="breadcrumbService.resetStack()" routerLink="/users" routerLinkActive="active">Users</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="breadcrumbService.resetStack()" routerLink="/authorization" routerLinkActive="active">Authorization</a>
            </li> -->

            <li class="nav-item dropdown" style="float: right;">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">
                    More
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                    <a class="nav-link text-dark" (click)="breadcrumbService.resetStack()" routerLink="/overall-systems" routerLinkActive="active">Overall Systems</a>
                    <a class="nav-link text-dark" (click)="breadcrumbService.resetStack()" routerLink="/overall-tasks" routerLinkActive="active">Overall Tasks</a>
                    <a class="nav-link text-dark" (click)="breadcrumbService.resetStack()" routerLink="/task-dbid-change" routerLinkActive="active">Change Task DB IDs</a>
                    <div class="dropdown-divider"></div>
                    <a class="nav-link text-dark" (click)="breadcrumbService.resetStack()" routerLink="/system-logs" routerLinkActive="active">System Logs</a>
                    <div class="dropdown-divider"></div>
                    <a class="nav-link text-dark" (click)="breadcrumbService.resetStack()" routerLink="/users" routerLinkActive="active">Users</a>
                    <a class="nav-link text-dark" (click)="breadcrumbService.resetStack()" routerLink="/authorization" routerLinkActive="active">Authorization</a>
                </div>
            </li>
            <li class="nav-item dropdown" style="float: right;">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">
                    {{user.firstName}} {{user.lastName}}
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" (click)="breadcrumbService.resetStack()" routerLink="profile"><i
                            class="fa fa-user"></i> Profile</a>
                    <div class="dropdown-divider"></div>
                    <button type="button" (click)="logoutUser()" class="dropdown-item btn-link"><i
                            class="fa fa-sign-out"></i> Logout
                    </button>
                </div>
            </li>


        </ul>
    </div>
</nav>


<div appRouteChange class="container mainContainer" [@routeAnimation]="getAnimationData(routerOutlet)">
    <router-outlet #routerOutlet="outlet"></router-outlet>
</div>

<footer>
    <div class="footer-text-left pl-2">
        Version : {{ versionNumber }} Build {{ buildNumber }}
    </div>
    <!-- <img class=" stadler-train " src="assets/img/train-lineart-white.svg " /> -->
    <div class="footer-text ">
        RAMbase Systems by <img class="footer-rambase-logo" src="assets/img/stadler_logo_weiss_rgb.png"/>
    </div>

</footer>
<div *ngIf="spinnerService.showSpinner$ | async" class="global-spinner"> <!-- Use CSS to position the spinner icon -->
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>