import {Component, EventEmitter, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { ProjectService } from 'src/app/services/project.service';
import { QuantityUnitServiceService } from 'src/app/services/quantity-unit-service.service';
import { StandardSystemsService } from 'src/app/services/standard-systems.service';
import { OperatingProfilesService } from 'src/app/services/operating-profiles.service';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ProfileUnitsService } from 'src/app/services/profile-units.service';
import { ProjectSystemsService } from 'src/app/services/project-systems.service';
import { validateForm } from 'src/app/shared/helper';
import {LanguageServiceService} from "../../../../services/language-service.service";
import {LanguageSupportComponent} from "../../../../shared/components/language-support/language-support-component.service";

@Component({
  selector: 'app-edit-operating-profiles',
  templateUrl: './edit-operating-profiles.component.html',
  styleUrls: ['./edit-operating-profiles.component.scss']
})
export class EditOperatingProfilesComponent extends LanguageSupportComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();
  editOperatingProfileForm:any;
  param:any;
  disableElement:Boolean=false;
  btnLoading:Boolean=false;
  unitType:any;
  minValue:any;
  profileUnitValid:Boolean=true;
  existOperatingProfiles:any=[];
  maxValue:any;
  operatingProfileId:Number;
  projectSystemArray:any = [];
  lockUnitAddition:Boolean=false;
  taskUnitList:any=[];
  taskUnitList2:any=[];
  myProfileUnitId:any=1;
  myUnitId:any = '';
  unitList:any=[{"unitId" : "km"}, {"unitId" : "mi"}];
  projectFreezeFunction = "";
  operatingProfileUpdateStarted:Boolean=false;

  constructor(
      public bsModalRef: BsModalRef,
      private projectService: ProjectService,
      private quantityUnitService: QuantityUnitServiceService,
      private systemService: StandardSystemsService,
      private operatingProfileService: OperatingProfilesService,
      private notificationService: ToastrService,
      private ProfileUnitService: ProfileUnitsService,
      private projectSystemService: ProjectSystemsService,
      public languageService: LanguageServiceService,
      public modalService: BsModalService
  ) {
    super(languageService, modalService);
  }

  ngOnInit(): void {

    this.operatingProfileId = this.param.operatingProfileId;
    this.unitType = this.param.unitType;
    this.editOperatingProfileForm = new UntypedFormGroup({
      projectNumber: new UntypedFormControl(this.param.projectNumber),
      // value: new UntypedFormControl(),
      value: new UntypedFormControl('',[Validators.required, Validators.maxLength(100)]),
      unitAddition: new UntypedFormControl('',[Validators.maxLength(100)]),
      profileUnitId: new UntypedFormControl('',[Validators.required]),
      profileUnitText: new UntypedFormControl(''),
      valueUnit: new UntypedFormControl(''),
      projectSystemId: new UntypedFormControl('',[Validators.required]),
      operatingProfileId: new UntypedFormControl(this.operatingProfileId),
      unitType: new UntypedFormControl(this.param.unitType),

    });
    // var a = <HTMLInputElement>document.getElementById("profileUnitText");
    // a.hidden = false;

    this.initFormValues();
  }

  public currentFormValues()
  {
    return JSON.stringify(this.editOperatingProfileForm.getRawValue());
  }


  initFormValues(){
    this.getTaskUnits();
    


    if((this.unitType==0)|| (this.unitType==null)){
      this.getProjectSystems(0);
      this.disableElement=true;
      this.lockUnitAddition=true;
    }else{
      this.getProjectSystems(1);
      this.disableElement=false;
      this.lockUnitAddition=false;
    }

    validateForm(this.editOperatingProfileForm);
  }

  /**
   * Get project systems by projects
   */
  getProjectSystems(unitType){
    this.projectSystemService.getProjectSystemByProject(this.param.projectNumber).subscribe(returnDataset=>{
      let result = returnDataset["content"];
      Object.keys(result).forEach(key => {
        if( unitType == 0 || result[key].systemCode != 'AA00'){
          result[key].text = result[key].systemCode + "-" + result[key].systemVariationNumber + "-" + result[key].systemVariation;
          this.projectSystemArray.push(result[key]);
        }
      });
    });
  }

  /**
   * Get all Task Units
   */
  getTaskUnits(){
    this.ProfileUnitService.getProfileUnits().subscribe(res=>{
      this.taskUnitList = res;

      this.getOperatingProfileByID(this.operatingProfileId);
    })
  }

  /**
   * Get profile unit by id
   */
  getProfileUnitById(){
    let formValue = this.editOperatingProfileForm.getRawValue();
    let profileUnitId = formValue['profileUnitId'];

    this.ProfileUnitService.getProfileUnitById(profileUnitId).subscribe(res=>{
      this.minValue = res['minValue'];
      this.maxValue = res['maxValue'];
      this.myUnitId = res["valueUnit"];
    });
    this.validateUnitAddition(profileUnitId);
    this.editOperatingProfileForm.controls['value'].updateValueAndValidity();
    this.editOperatingProfileForm.controls['valueUnit'].updateValueAndValidity();

    // console.log(formValue);
    this.myProfileUnitId = formValue['profileUnitId'];
    if(this.myProfileUnitId==1){
      console.log(formValue['valueUnit']);
      console.log(this.taskUnitList);
      var sDe = this.taskUnitList[0].profileUnitDesignationDe;
      var sEn = this.taskUnitList[0].profileUnitDesignationEn;
      this.taskUnitList[0].profileUnitDesignationDe = sDe.replace('{UNIT}', formValue['valueUnit']);
      this.taskUnitList[0].profileUnitDesignationEn = sEn.replace('{UNIT}', formValue['valueUnit']);
      this.editOperatingProfileForm.controls['profileUnitId'].updateValueAndValidity();
      var a = <HTMLInputElement>document.getElementById("profileUnitText");
      a.value = sDe.replace('{UNIT}', formValue['valueUnit']);
      a.disabled = true;
      this.editOperatingProfileForm.controls['profileUnitText'].disable();
      this.editOperatingProfileForm.controls['valueUnit'].setValidators([Validators.required]);
      this.editOperatingProfileForm.controls['valueUnit'].updateValueAndValidity();
      // this.editOperatingProfileForm.controls['profileUnitId'].hide();
      console.log(this.taskUnitList);
    }else{
      // this.editOperatingProfileForm.controls['profileUnitId'].hide();
      var a = <HTMLInputElement>document.getElementById("profileUnitText");
      a.hidden = true;
      this.editOperatingProfileForm.controls['valueUnit'].clearValidators();
      this.editOperatingProfileForm.controls['valueUnit'].updateValueAndValidity();

    }
  }

  /**
   * Validate Unit addition by Profile unit
   * @param profileUnitId
   */
  validateUnitAddition(profileUnitId){
    if(profileUnitId==7){
      // Unit addition mandatory
      this.editOperatingProfileForm.controls['unitAddition'].setValidators([Validators.required, Validators.maxLength(100)]);
      this.editOperatingProfileForm.controls['unitAddition'].enable();
    }else{
      // Not mandatory
      this.editOperatingProfileForm.controls['unitAddition'].clearValidators();
      this.editOperatingProfileForm.patchValue({
        unitAddition: '',
      });
      this.editOperatingProfileForm.controls['unitAddition'].disable();
    }
    this.editOperatingProfileForm.controls['unitAddition'].updateValueAndValidity();
    super.saveInitialValues(JSON.stringify(this.editOperatingProfileForm.getRawValue()));
  }

  languageChanged(){
    super.languageChanged(JSON.stringify(this.editOperatingProfileForm.getRawValue()));
  }

  /**
   * Validate profile units
   */
  validateProfileUnits(){
    var formValue = this.editOperatingProfileForm.value;
    let projectSystemId = formValue.projectSystemId;
    console.log(this.existOperatingProfiles);

    var status = false;
    for(var x=0; x<this.existOperatingProfiles.length; x++){
      if(projectSystemId==this.existOperatingProfiles[x].projectSystemId){
        var status=true;
      }
    }

    if(status==true){
      for(var y=0; y<this.taskUnitList2.length; y++){
        if(this.taskUnitList2[y].profileUnitId==6){
          delete this.taskUnitList2[y];
        }
      }
    }else{
      this.getTaskUnits();
    }
  }

  /**
   * Edit Operating profile
   */
  editOperatingProfiles(event){
    this.operatingProfileUpdateStarted = true;
    this.profileUnitValid=true;
    var formData = this.editOperatingProfileForm.value;
    if(this.editOperatingProfileForm.valid){

      if(formData.profileUnitId==7){
        var recordSrting = formData.projectSystemId+'-'+formData.profileUnitId+'-'+formData.unitAddition;
        recordSrting = btoa(recordSrting);
        console.log(recordSrting);

        for(var x=0; x<this.existOperatingProfiles.length; x++){

          var row = this.existOperatingProfiles[x];
          var rowString = row.projectSystemId+'-'+row.profileUnitId+'-'+row.unitAddition;
          rowString = btoa(rowString);
          if((rowString==recordSrting) && (formData.operatingProfileId!=row.operatingProfileId)){
            this.profileUnitValid=false;
          }
        }
      }
      if(this.profileUnitValid==true){
        this.btnLoading=true;
        this.operatingProfileUpdateStarted = true;
        this.operatingProfileService.updateOperatingProfiles(event,formData,this.operatingProfileId).subscribe(res=>{
          if(res){
            this.notificationService.success('Operating Profile has been Updated','success');
            this.bsModalRef.hide();
            this.event.emit();
          }else{
            this.notificationService.error('Operating Profile has not been Updated','Error');
          }
          this.btnLoading=false;
        }, (error => {
          if(error.status == 403) {
            this.notificationService.error('You need to be in the Project Admins Group to perform the current Action','Error');
          }else{
            this.notificationService.error('Operating Profile has not been Updated','Error');
          }
          this.operatingProfileUpdateStarted = false;
          this.btnLoading=false;
        }), () => {
          this.operatingProfileUpdateStarted = false;
        });
      }
    }else{
      this.operatingProfileUpdateStarted = false;
      validateForm(this.editOperatingProfileForm);
    }
  }

  getOperatingProfileByID(operatingProfileId){
    const _this = this;
    this.operatingProfileService.getOperatingProfileByID(operatingProfileId).subscribe(res=>{

      res['projectSystemId'] = parseInt(res['projectSystemId']);
      res['value'] = res['value'] != null ? res['value'].toString() : null;

      this.editOperatingProfileForm.patchValue(res);
      this.editOperatingProfileForm.patchValue({profileUnitId: parseInt(res['profileUnitId']),});
      this.getProfileUnitById();
    });
  }

  callAfterLanguageChanged() {
    this.editOperatingProfileForm.patchValue({operatingProfileId: this.operatingProfileId,});
    this.editOperatingProfileForm.patchValue({unitType: this.param.unitType,});

    this.initFormValues();
  }
}
