import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TaskIntervalService {

    constructor(private http: HttpClient) { }


    createTaskIntervaldata(data)
    {
        return this.http.post(environment.apiUrl + "/taskintervals/", data);
    }

    createBulkTaskInterval(data, taskDbId)
    {
        return this.http.post(environment.apiUrl + "/taskintervals/tasks/" + taskDbId + "/", data);
    }

    updateBulkTaskInterval(data, taskDbId)
    {
        return this.http.put(environment.apiUrl + "/taskintervals/tasks/" + taskDbId + "/", data);
    }

    /**
     * Get task intervals by task db id
     * @param taskDbId
     */
    getTaskIntervalByTaskDbId(taskDbId)
    {
        return this.http.get(environment.apiUrl + "/taskintervals/tasks/" + taskDbId + '/');
    }

    /**
     * Update task Interval
     * @param data
     * @param intervalId
     */
    updateTaskIntervalData(data, intervalId)
    {
        return this.http.put(environment.apiUrl + "/taskintervals/" + intervalId + "/", data);
    }

    /**
     * Get task interval by taskdb Id
     * @param taskDbId
     */
    getTaskIntervalsByTask(taskDbId)
    {
        return this.http.get(environment.apiUrl + "/taskintervals/tasks/" + taskDbId + "/");
    }
}
