import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class StructureComparisonService {

    constructor(private http: HttpClient) { }

    compareStructure(data, stadlerArticleId)
    {
        return this.http.post(environment.apiUrl + "/parts/comparison/" + stadlerArticleId, data, {responseType: 'blob'});
    }

}
