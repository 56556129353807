import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SystemLogsRoutingModule } from './system-logs-routing.module';
import { SystemLogsComponent } from './system-logs/system-logs.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSelectModule } from 'ngx-select-ex';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TreeTableModule} from "../../../shared/components/tree-table/tree-table.module";
import {SharedModule} from "../../../shared/shared.module";


@NgModule({
  declarations: [SystemLogsComponent],
    imports: [
        CommonModule,
        SystemLogsRoutingModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        FormsModule,
        NgxSelectModule,
        NgbModule,
        BsDatepickerModule.forRoot(),
        TreeTableModule,
        SharedModule,
    ]
})
export class SystemLogsModule { }
