import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormGroup, UntypedFormControl} from '@angular/forms';
import {StandardSystemsService} from 'src/app/services/standard-systems.service';
import {ToastrService} from 'ngx-toastr';
import {SystemTypeService} from 'src/app/services/system-type.service';
import {validateForm} from '../../../shared/helper';
import {LanguageSupportComponent} from "../../../shared/components/language-support/language-support-component.service";
import {LanguageServiceService} from "../../../services/language-service.service";
import {BsModalService} from "ngx-bootstrap/modal";
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-system-edit',
  templateUrl: './system-edit.component.html',
  styleUrls: ['./system-edit.component.scss']
})
export class SystemEditComponent extends LanguageSupportComponent implements OnInit {

  ramEngineerData:any=[];
  sbsCodeData:any = [];
  systemID: any;
  level1Show: Boolean = false;
  level2Show: Boolean = false;
  laddaLoading = false;
  level1SystemNumberList: any;
  level2SystemNumberList: any;
  systemLevel: any = [
    {
      id: 1,
      value: 1,
    },
    {
      id: 2,
      value: 2,
    },
    {
      id: 3,
      value: 3,
    },
    {
      id: 4,
      value: 4,
    }
  ];

  systemEditForm = new UntypedFormGroup({
    systemDesignationEn: new UntypedFormControl(),
    systemDesignationDe: new UntypedFormControl(),

    systemLevel: new UntypedFormControl(),
    systemCode: new UntypedFormControl(),
    sbsCode: new UntypedFormControl(),
    ramEngineer: new UntypedFormControl([]),
    level1System: new UntypedFormControl(),
    level2System: new UntypedFormControl(),
    level3System: new UntypedFormControl(),
  });

  constructor(
      private activatedroute: ActivatedRoute,
      private systemService: StandardSystemsService,
      private notification: ToastrService,
      private router: Router,
      private systemTypeService: SystemTypeService,
      public languageService: LanguageServiceService,
      public modalService: BsModalService,
      public userService:UserService
  ) {
    super(languageService, modalService);
  }

  public currentFormValues()
  {
    return JSON.stringify(this.systemEditForm.getRawValue());
  }

  ngOnInit(): void {
    this.systemID = this.activatedroute.snapshot.paramMap.get('id');
    this.initFormValues();
  }

  initFormValues(){
    this.loadSystemData(this.systemID);

    this.getLevel1SystemNumbers();
    this.getLevel2SystemNumbers();
    this.getRamEngineers();
    this.getSBSCodes();

    validateForm(this.systemEditForm);
  }

  /**
   * Get all Ram engineers
   */
  getRamEngineers(){
    this.userService.getRamengineers().subscribe(res=>{
      this.ramEngineerData = res;
    });
  }

  getSBSCodes(){
    this.systemService.getSBSCodes().subscribe(res=>{
      this.sbsCodeData = res;
    });
  }

  onSelectSystemLevel(id) {
    if (id == 1) {
      this.level1Show = false;
      this.level2Show = false;
    } else if (id == 2) {
      this.level1Show = true;
      this.level2Show = false;
    } else if (id == 3) {
      this.level1Show = true;
      this.level2Show = true;
    } else if (id == 4) {
      this.level1Show = true;
      this.level2Show = true;
    } else {
      this.level1Show = false;
      this.level2Show = false;
    }
  }

  loadSystemData(systemNumber) {
    this.systemService.getSystemById(systemNumber).subscribe(res => {
      this.systemEditForm = new UntypedFormGroup({
        systemDesignationEn: new UntypedFormControl(res['systemDesignationEn']),
        systemDesignationDe: new UntypedFormControl(res['systemDesignationDe']),
        systemCode: new UntypedFormControl(res['systemCode']),
        sbsCode: new UntypedFormControl(res['sbsCode']),
        ramEngineer: new UntypedFormControl(res['ramEngineer']),
        systemLevel: new UntypedFormControl(res['systemLevel']),
        level1System: new UntypedFormControl(res['level1System']),
        level2System: new UntypedFormControl(res['level2System']),
        level3System: new UntypedFormControl(null),
      });
      this.onSelectSystemLevel(res['systemLevel']);
      super.saveInitialValues(JSON.stringify(this.systemEditForm.getRawValue()));
    });
  }

  languageChanged(){
    super.languageChanged(JSON.stringify(this.systemEditForm.getRawValue()));
  }

  /**
   * Modify System Record
   */
  onSubmit() {
    this.laddaLoading = true;
    this.systemService.updateSystem(this.systemEditForm.getRawValue(), this.systemID).subscribe(res => {
      if (res['systemNumber']) {
        
        this.notification.success('Systems has been Updated', 'Success');
        setTimeout(() => {
          this.laddaLoading = false;
          this.router.navigate(['/systems']);
        }, 2000);
      } else {
        this.laddaLoading = false;
        this.notification.error('Systems has not been Updated', 'Error');
      }
    });
  }


  getLevel1SystemNumbers() {
    this.systemService.getLevelSystemNumbers(1).subscribe(res => {
      this.level1SystemNumberList = res;
    });
  }

  getLevel2SystemNumbers() {
    this.systemService.getLevelSystemNumbers(2).subscribe(res => {
      this.level2SystemNumberList = res;
    });
  }

  callAfterLanguageChanged() {
    this.initFormValues();
  }

}
