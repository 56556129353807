import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class QuantityUnitServiceService {

    constructor(private http: HttpClient) { }

    /**
     * Get all quantity units
     */
    getAllQuantityUnits()
    {
        return this.http.get(environment.apiUrl + "/quantityunit/");
    }
}
