import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TaskSuffixService {

    constructor(private http: HttpClient) { }

    /**
     * Get All Task Suffix
     */
    getAllSuffix()
    {
        return this.http.get(environment.apiUrl + "/tasksuffix/");
    }
}
