import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthServiceService } from '../services/auth-service.service';
import {catchError, tap} from 'rxjs/operators';
import {ToastrService} from "ngx-toastr";

@Injectable()
export class UsersInterceptor implements HttpInterceptor {

  constructor(
    private router : Router,
    private authService : AuthServiceService,
    private injector: Injector,
    private notification : ToastrService
    ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let isLogged = this.authService.isLogged();
    if(isLogged){
      let token = this.authService.getUserToken();
      if (request.url.search(/([a-zA-Z]+).(?:jpe?g|png|gif|bmp)/gi)==-1){
        request = request.clone({
          headers: request.headers.set(
            'authToken',token,
          )
        });
      }
      
    }

    if(!isLogged){
      this.router.navigateByUrl('/');
    }

    return next.handle(request).pipe(tap((response: any) => {
    }, (response: HttpErrorResponse) => {
      if (response?.error?.status == 401 && response?.error?.error == "Unauthorized") {
        this.notification.error('You are not authorised to perform this action...', 'Error');
      }
      else if (response?.error?.statusCode == 500) {
        this.notification.error('An error occurred', 'Error');
      }
    }));
  }

}
