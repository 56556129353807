import {Component, Injectable, OnInit} from '@angular/core';
import {LanguageServiceService} from '../../../services/language-service.service';
import {UntypedFormGroup} from "@angular/forms";
import {ConfirmationBoxComponent} from "../confirmation-box/confirmation-box.component";
import {BsModalService} from "ngx-bootstrap/modal";

@Injectable({
  providedIn: 'root'
})
export abstract class LanguageSupportComponent implements OnInit {

  public languages;
  public selectedLanguage:number = 0;
  public initialValues:string;

  protected constructor(public languageService: LanguageServiceService,
                        public modalService: BsModalService) {
    this.selectedLanguage = this.languageService.getCurrentLanguage().id;
    this.languages = this.languageService.allLanguages();
  }

  ngOnInit(): void {}

  ngOnDestroy():void{
    this.languageService.resetCurrentLanguage()
  }

  saveInitialValues(initialValues:string){ // call this function after patch the form
    this.initialValues = initialValues;
  }

  languageChanged(currentValues)
  {
    if(currentValues != this.initialValues){
      const initialState = {
        title: 'There are unsaved changes. Changing language will discard unsaved vales. Are you sure you want to continue?',
        okBtnName: 'Yes',
        closeBtnName: 'No',
        isCommentVisible:false
      };
      let modalRef = this.modalService.show(ConfirmationBoxComponent, {class:'modal-md', initialState, ignoreBackdropClick:true});
      modalRef.content.event.subscribe(res =>
      {
        if(res?.result?.action == 'ok')
        {
          this.languageService.setCurrentLanguage(this.languages[this.selectedLanguage]);
          this.callAfterLanguageChanged();
        }
        else ; // do nothing
      });
    }
    else{
      this.languageService.setCurrentLanguage(this.languages[this.selectedLanguage]);
      this.callAfterLanguageChanged();
    }
  }

  abstract callAfterLanguageChanged();
}
