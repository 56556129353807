import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateMenu(pageName: string){
    if(pageName=="cardProjects"){
      this.router.navigateByUrl('/projects');
      // window.location.href = "/projects";
    }else if(pageName=="cardSystems"){
      this.router.navigateByUrl('/systems');
    }else if(pageName=="cardTasks"){
      this.router.navigateByUrl('/projects/any/systems/0/tasks');
    }
    else if(pageName=="cardSpares"){
      this.router.navigateByUrl('projects/any/systems/0/spare-parts');
    }
    else if(pageName=="cardTooles"){
      this.router.navigateByUrl('special-tools');
    }else{
      alert("Not implemented yet!");
    }
  }

}
