import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {OverallSystemsAndTasksService} from "../../../../services/overall-systems-and-tasks.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {AddEditTagComponent} from "../../../../shared/components/add-edit-tag/add-edit-tag.component";
import {INgxSelectOption} from "ngx-select-ex";
import {ProjectSystemTasksService} from 'src/app/services/project-system-tasks.service';
import {formatDate} from '@angular/common';
import * as FileSaver from 'file-saver';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";

@Component({
	selector: 'app-overall-tasks',
	templateUrl: './overall-tasks.component.html',
	styleUrls: ['./overall-tasks.component.scss']
})
export class OverallTasksComponent implements OnInit {

	public systemTaskTagData = [];
	public overallTasksData = [];

	public selectedTag = null;

	public currentPage: number = 1;
	public pageCount: number = 0;
	public pageSize: number = 20;
	public sortColumn: string = "";
	public sortOrder: string = "";
	public filterData = [];

	public tasksToAddToTag:any[] = [];
	public tasksToRemoveFromTag:any[] = [];

	public spinnerVisible = false;
	public spinnerText = "Loading...";

	public btnLoading: Boolean = false;
	public btnLoadingDelete: Boolean = false;


	public settings = {
		type: 'table',
		id: 'taskDbId',
		// childrenFetchFunc:this.fetchChildren,
		columnManagerEnable: true,
		pagination: {
			enabled: true,
			pageSize: this.pageSize
		},
		filterOptionFunction: (params, filterData) =>
		{
			const data = {
				gridFilters: filterData,
				mainFilters: this.overallTasksFilterForm.getRawValue()
			}
			return this.overallSystemsAndTasksService.getOverallTasks("distinct-values", data, params)
		},
		enableZoom: true,
		rowClassFunction: (row) => {return (row && row.isTaskActivated == 1) ? '' : 'inactive-task'},
		columns: [
			{
				title: 'Project Number',
				attribute: 'projectNumber',
				type: 'text',
				filter: {
					enabled: true,
					isNumericField: false,
				},
				width: 100,
			},
			{
				title: 'Task Manual ID',
				attribute: 'taskId',
				type: 'text',
				filter: {
					enabled: true,
					isNumericField: false,
				},
				width: 100,
			},
			{
				title: 'Element',
				attribute: 'taskElementStr',
				type: 'text',
				filter: {
					enabled: true,
					isNumericField: false,
				},
				width: 300,
			},
			{
				title: 'Task Activity',
				attribute: 'taskActivity',
				type: 'text',
				filter: {
					enabled: true,
					isNumericField: false,
				},
				width: 300,
			},
			{
				title: 'Interval A',
				attribute: 'intervalA',
				type: 'text',
				filter: {
					enabled: true,
					isNumericField: false,
				},
				width: 125,
			},
			{
				title: 'Interval B',
				attribute: 'intervalB',
				type: 'text',
				filter: {
					enabled: true,
					isNumericField: false,
				},
				width: 125,
			},
			{
				title: '',
				type: 'checkbox',
				actionFunction: (event, row) =>
				{
					this.addRemoveTagToFromSystemTask(event, row);
				},
				checkIsCheckedFunction: (row) =>
				{
					let taskTags: any[] = row['taskTags'];
					if (taskTags.length == 0 || !this.selectedTag) return false;

					for (let tag of taskTags)
					{
						if (tag['tagId'] == this.selectedTag['tagId'])
						{
							return true;
						}
					}
					return false;
				},
				width: 70,
			},
			{
				title: 'Tags',
				attribute: 'taskTagsStr',
				type: 'text',
				filter: {
					enabled: true,
					isNumericField: false,
				},
				width: 250
			}
		]
	};

	public filterValues = {
		projectNumber: [],
		taskManualId: [],
		taskElement: [],
		taskActivity: [],
		taskTag: []
	}

	public originalFilterValues = {
		projectNumber: [],
		taskManualId: [],
		taskElement: [],
		taskActivity: [],
		taskTag: []
	}

	public customValueAddedForFilter = {
		projectNumber: false,
		taskManualId: false,
		taskElement: false,
		taskActivity: false,
		taskTag: false
	}

	public overallTasksFilterForm = new UntypedFormGroup({
		projectNumbers: new UntypedFormControl([]),
		taskManualIds: new UntypedFormControl([]),
		taskElements: new UntypedFormControl([]),
		taskActivities: new UntypedFormControl([]),
		taskTags: new UntypedFormControl([]),
	})

	constructor(public overallSystemsAndTasksService: OverallSystemsAndTasksService,
	            public modalService: BsModalService,
	            public notification: ToastrService,
	            public projectSystemTaskService: ProjectSystemTasksService,)
	{ }

	ngOnInit(): void
	{
		this.getAllFilterDropDownData();
		this.getSystemTaskTags();
	}

	convertTZ(date, tzString)
	{
		return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
	}

	public getSystemTaskTags()
	{
		this.overallSystemsAndTasksService.getAllTagsByType("project_system_task").subscribe((res: any[]) =>
		{
			this.systemTaskTagData = res;
		});
	}

	public saveChanges()
	{
		this.btnLoading = true;
		const data = {
			tag: this.selectedTag.tagId,
			tasksToAdd: this.tasksToAddToTag,
			tasksToRemove: this.tasksToRemoveFromTag
		}
		this.overallSystemsAndTasksService.addAndRemoveTagsFromSystemTasks(data).subscribe((res) =>
		{
			this.notification.success('Saved changes', 'Success');
		}, (error) =>
		{
			this.notification.error('Filed to save changes', 'Error');
		},()=>{
			this.tasksToAddToTag = [];
			this.tasksToRemoveFromTag = [];
			this.btnLoading = false;
		});
	}

	public getOverallTasks()
	{
		let params = {};
		if (this.currentPage) params['page'] = this.currentPage - 1;
		if (this.pageSize) params['pageSize'] = this.pageSize;
		if (this.sortColumn) params['column'] = this.sortColumn;
		if (this.sortOrder) params['sortOrder'] = this.sortOrder;

		const data = {
			gridFilters: this.filterData,
			mainFilters: this.overallTasksFilterForm.getRawValue()
		}

		this.spinnerVisible = true;
		this.overallSystemsAndTasksService.getOverallTasks("filtered-data", data, params).subscribe((res: any[]) =>
		{
			this.spinnerVisible = false;
			if (res && res['page'])
			{
				this.pageCount = res['page']['totalPages'];
				let result = res['page']['content'];

				this.overallTasksData = result;
			}
			if (res && res['tablePropertyMap'])
			{
				let columns = this.settings.columns;
				let tablePropertyMap: Map<string, {}> = res['tablePropertyMap'];

				for (let i = 0; i < columns.length; i++)
				{
					let data = tablePropertyMap[columns[i].attribute];
					if (!data) continue;
					columns[i].filter['column'] = data['column'];
					columns[i].filter['table'] = data['table'];
				}
			}

			this.settings['isNative'] = false;
		}, (error =>
		{
			this.spinnerVisible = false;
		}));
	}

	public addRemoveTagToFromSystemTask(event, row)
	{
		if (!event || !event.target) return;

		if (!this.selectedTag)
		{
			this.notification.error('Please select a tag...', 'Error');
			event.preventDefault();
			return;
		}

		let checked = event.target.checked;
		if (checked)
		{
			if(this.tasksToRemoveFromTag.indexOf(row['taskDbId']) >= 0)
			{
				let index = this.tasksToRemoveFromTag.indexOf(row['taskDbId']);
				this.tasksToRemoveFromTag.splice(index, 1);
			}
			else
			{
				this.tasksToAddToTag.push(row['taskDbId']);
			}
		}
		else
		{
			if(this.tasksToAddToTag.indexOf(row['taskDbId']) >= 0)
			{
				let index = this.tasksToAddToTag.indexOf(row['taskDbId']);
				this.tasksToAddToTag.splice(index, 1);
			}
			else
			{
				this.tasksToRemoveFromTag.push(row['taskDbId']);
			}
		}
	}

	public addTag()
	{
		const initialState = {
			action: 'Add',
			tagType: "project_system_task"
		};
		let modalRef = this.modalService.show(AddEditTagComponent, {
			class: 'modal-md',
			initialState,
			ignoreBackdropClick: true
		});
		modalRef.onHidden.subscribe(() =>
		{
			this.getSystemTaskTags();
		})
	}

	public editTag()
	{
		const initialState = {
			action: 'Edit',
			tagId: this.selectedTag['tagId'],
			tagText: this.selectedTag['tagText'],
			tagType: "project_system_task"
		};
		let modalRef = this.modalService.show(AddEditTagComponent, {
			class: 'modal-md',
			initialState,
			ignoreBackdropClick: true
		});
		modalRef.onHidden.subscribe(() =>
		{
			this.getSystemTaskTags();
		})
	}

	public deleteTag()
	{
		this.btnLoadingDelete = true;
		this.overallSystemsAndTasksService.deleteTag(this.selectedTag['tagId']).subscribe((res: any[]) =>
		{
			this.notification.success('Tag has been deleted', 'success');
			this.getSystemTaskTags();
		}, (error =>
		{
			if (error.status == 409)
			{
				this.notification.error('Tag is used. Cannot be deleted', 'Error');
			}
			else
			{
				this.notification.error('Tag has been not deleted', 'Error');
			}
			this.btnLoadingDelete = false;
		}), () =>{
			this.btnLoadingDelete = false;
		});
	}

	public tagSelected(options: INgxSelectOption[])
	{
		if (!options?.at(0) || !options?.at(0).data) return;
		this.selectedTag = options.at(0).data;
	}

	public changePage(currentPage)
	{
		this.currentPage = currentPage;
		this.getOverallTasks();
	}

	public changePageSize(pageSize)
	{
		this.pageSize = pageSize;
		this.getOverallTasks();
	}

	public filterOrSortChange(event)
	{
		if (!event)
		{
			this.currentPage = 1;
			this.overallTasksData = [];
			return;
		}
		if (!event['sortChanged']) this.currentPage = 1;
		this.sortColumn = event['sortColumn'];
		this.sortOrder = event['sortOrder'];
		this.filterData = event['filterData'];

		this.getOverallTasks();
	}

	exportTasks()
	{
		// alert("Export Started");
		this.spinnerVisible = true;

		let formData = new FormData();
		formData.append('exportedDate', (new Date()).toLocaleString("en-US", {timeZone: "Europe/Berlin"}));
		formData.append('mainFilters', this.overallTasksFilterForm.getRawValue());

		var postData = {
			exportedDate: (new Date()).toLocaleString("en-US", {timeZone: "Europe/Berlin"}),
			mainFilters: this.overallTasksFilterForm.getRawValue()
		}

		// formData.exportedDate = (new Date()).toLocaleString("en-US", {timeZone: "Europe/Berlin"});
		this.projectSystemTaskService.exportOverallTasks(postData).subscribe(res =>
		{
			if (res['status'] == 0)
			{

				const byteCharacters = atob(res['fileContents']);
				const byteNumbers = new Array(byteCharacters.length);
				for (let i = 0; i < byteCharacters.length; i++)
				{
					byteNumbers[i] = byteCharacters.charCodeAt(i);
				}
				const byteArray = new Uint8Array(byteNumbers);
				// const blob = new Blob([byteArray], {type: 'audio/mp3'});

				const blob = new Blob([byteArray], {type: 'application/octet-stream'});
				var strFileName = "Export";
				var dt = new Date();
				dt = this.convertTZ(dt, "Europe/Berlin");

				// timeString = dt.toLocaleString('en-US', { timeZone: 'Europe/Berlin' });

				var dtString = formatDate(dt, 'yyyy-MM-dd', 'en-US', 'Europe/Berlin');
				var timeString = formatDate(dt, 'HH-mm', 'en-US', 'Europe/Berlin');

				strFileName = res['downloadFileName'] + res['downloadFileExtension'];

				const file = new File([blob], strFileName, {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
				FileSaver.saveAs(file);
				this.notification.success('Overall Tasks Document has been Downloaded', 'Success');
			}
			else
			{
				this.notification.error('Overall Tasks Document has not been Downloaded due an Error', 'Error');
				console.log(res);
			}
			this.spinnerVisible = false;
		}, error =>
		{
			this.notification.error('Overall Tasks Document has not been Downloaded due an Error', 'Error');
			this.spinnerVisible = false;
			console.log(error);
		});

		// this.spinnerVisible = false;
	}

	private getAllFilterDropDownData()
	{
		this.overallSystemsAndTasksService.getAllProjectNumbersFilterData().subscribe((res: string[]) =>
		{
			this.filterValues.projectNumber = res;
			this.originalFilterValues.projectNumber = [...this.filterValues.projectNumber];
		});
		this.overallSystemsAndTasksService.getAllTaskManualIdsFilterData().subscribe((res: string[]) =>
		{
			this.filterValues.taskManualId = res;
			this.originalFilterValues.taskManualId = [...this.filterValues.taskManualId];
		});
		this.overallSystemsAndTasksService.getAllTaskElementsFilterData().subscribe((res: string[]) =>
		{
			this.filterValues.taskElement = res;
			this.originalFilterValues.taskElement = [...this.filterValues.taskElement];
		});
		this.overallSystemsAndTasksService.getAllTaskActivitiesFilterData().subscribe((res: string[]) =>
		{
			this.filterValues.taskActivity = res;
			this.originalFilterValues.taskActivity = [...this.filterValues.taskActivity];
		});
		this.overallSystemsAndTasksService.getAllTaskTagsFilterData().subscribe((res: string[]) =>
		{
			this.filterValues.taskTag = res;
			this.originalFilterValues.taskTag = [...this.filterValues.taskTag];
		});
	}

	public projectNumbersFilterInputChange(inputStr: string)
	{
		this.filterInputChange(inputStr, 'projectNumber')
	}

	public taskManualIdsFilterInputChange(inputStr: string)
	{
		this.filterInputChange(inputStr, 'taskManualId')
	}

	public taskElementsFilterInputChange(inputStr: string)
	{
		this.filterInputChange(inputStr, 'taskElement')
	}

	public taskActivitiesFilterInputChange(inputStr: string)
	{
		this.filterInputChange(inputStr, 'taskActivity')
	}

	public taskTagsFilterInputChange(inputStr: string)
	{
		this.filterInputChange(inputStr, 'taskTag')
	}

	public filterInputChange(inputStr: string, filterType:string)
	{
		if (inputStr == null || inputStr.length == 0)
		{
			if (this.customValueAddedForFilter[filterType])
			{
				this.filterValues[filterType].splice(0, 1);
				this.customValueAddedForFilter[filterType] = false;
			}
		}
		else
		{
			if (this.customValueAddedForFilter[filterType])
			{
				this.filterValues[filterType][0] = inputStr;
			}
			else
			{
				this.filterValues[filterType].unshift(inputStr);
				this.customValueAddedForFilter[filterType] = true;
			}
		}
	}

	public projectNumbersFilterSelect(value: string)
	{
		this.filterSelect(value, 'projectNumber')
	}

	public taskManualIdsFilterSelect(value: string)
	{
		this.filterSelect(value, 'taskManualId')
	}

	public taskElementsFilterSelect(value: string)
	{
		this.filterSelect(value, 'taskElement')
	}

	public taskActivitiesFilterSelect(value: string)
	{
		this.filterSelect(value, 'taskActivity')
	}

	public taskTagsFilterSelect(value: string)
	{
		this.filterSelect(value, 'taskTag')
	}

	public filterSelect(value: string, filterType:string)
	{
		if(this.originalFilterValues[filterType].indexOf(value) < 0)
		{
			this.filterValues[filterType].push(value);
		}
		if(this.customValueAddedForFilter[filterType])
		{
			this.filterValues[filterType].splice(0, 1);
		}
	}
}
