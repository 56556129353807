<div class="container-fluid">

    <div class="row">
        <div class="pageTitle col-sm-6">
            <span>Change Task DB IDs </span> &nbsp;
        </div>
        <!-- <div class=" pageTitle col-sm-6 text-right">
        <button type="button" [routerLink]="previousUrl" routerLinkActive="router-link-active"  class="btn btn-primary btn-sm"><fa-icon [icon]="faBackward"></fa-icon> &nbsp;&nbsp;Back</button>
      </div> -->
    </div>

    <form [formGroup]="taskDbIdChangeForm" class="pr-4 pl-4">
        <div class="row">
            &nbsp;
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <div class="col-md-8">

                        <div class="row">
                            <div class="col-md-2">
                                <label>Project</label>
                            </div>
                            <div class="col-md-8">
                                <ngx-select formControlName="projectNumber" (select)="onChangeProject($event)"
                                    [items]="projects" optionTextField="projectDesignation" [placeholder]="'Select Project'"
                                    optionValueField="projectNumber" appInfoPromptBox [page]="'change-db-id'"
                                    [component]="'replace'" [field]="'selectedProject'" appMandatoryField
                                    [(ngModel)]="selectedProject"></ngx-select>
                                    <div *ngIf="taskDbIdChangeForm.controls['projectNumber'].invalid && (taskDbIdChangeForm.controls['projectNumber'].dirty || taskDbIdChangeForm.controls['projectNumber'].touched)" class="ng-validator">
                                        <div *ngIf="taskDbIdChangeForm.controls['projectNumber'].errors.required">
                                          This field is required.
                                        </div>
                                    </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        &nbsp;
        <div class="row">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <div class="col-md-8">

                        <div class="row">
                            <div class="col-md-2">
                                <label>Project System</label>
                            </div>
                            <div class="col-md-8">
                                <ngx-select formControlName="projectSystemId" (select)="onChangeProjectSystem($event)"
                                    [items]="projectSystems" optionTextField="systemVariationNumberAndCode"
                                    [placeholder]="'Select Project System'" optionValueField="projectSystemId" appMandatoryField
                                    appInfoPromptBox [page]="'change-db-id'" [component]="'replace'" 
                                    [field]="'selectedProjectSystem'" [(ngModel)]="selectedProjectSystem"></ngx-select>

                                    <div *ngIf="taskDbIdChangeForm.controls['projectSystemId'].invalid && (taskDbIdChangeForm.controls['projectSystemId'].dirty || taskDbIdChangeForm.controls['projectSystemId'].touched)" class="ng-validator">
                                        <div *ngIf="taskDbIdChangeForm.controls['projectSystemId'].errors.required">
                                          This field is required.
                                        </div>
                                    </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        &nbsp;
        <div class="row">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <div class="col-md-8">

                        <div class="row">
                            <div class="col-md-2">
                                <label>Existing Task DB ID</label>
                            </div>
                            <div class="col-md-8">
                                <ngx-select formControlName="taskDbId" (select)="onChangeTask($event)" [items]="tasks"
                                    optionTextField="taskName" [placeholder]="'Select Existing Task'" appMandatoryField
                                    optionValueField="taskDbId" appInfoPromptBox [page]="'change-db-id'"
                                    [component]="'replace'" [field]="'taskDbId'"
                                    [(ngModel)]="selectedTaskDbId"></ngx-select>

                                    <div *ngIf="taskDbIdChangeForm.controls['taskDbId'].invalid && (taskDbIdChangeForm.controls['taskDbId'].dirty || taskDbIdChangeForm.controls['taskDbId'].touched)" class="ng-validator">
                                        <div *ngIf="taskDbIdChangeForm.controls['taskDbId'].errors.required">
                                          This field is required.
                                        </div>
                                    </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        &nbsp;
        <div class="row">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <div class="col-md-8">

                        <div class="row">
                            <div class="col-md-2">
                                <label>New Task DB ID</label>
                            </div>
                            <div class="col-md-4">
                                <input type="text" formControlName="newTaskDbId" class="form-control form-control"  appMandatoryField
                                    placeholder="New Task DB ID" appInfoPromptBox [page]="'change-db-id'"
                                    [component]="'replace'" [field]="'newTaskDbId'" appDecimal="0" [(ngModel)]="newTaskDbId" (blur)="newTaskDbIdLostFocus()" (keyup)="newTaskDbIdChanged()" >

                                <div *ngIf="taskDbIdChangeForm.controls['newTaskDbId'].invalid && (taskDbIdChangeForm.controls['newTaskDbId'].dirty || taskDbIdChangeForm.controls['newTaskDbId'].touched)" class="ng-validator">
                                    <div *ngIf="taskDbIdChangeForm.controls['newTaskDbId'].errors.required">
                                        This field is required.
                                    </div>
                                </div>
                                <div *ngIf="isNewTaskDbIdExists" class="ng-validator">
                                    New Task DB ID already exists. Please use a different Task DB ID.
                                </div>
                                <div *ngIf="isMaxTaskDbIdExceeds" class="ng-validator">
                                    New Task DB ID exceeds the maximum DB ID available. Maximum DB ID available is: {{maxDbId}}.
                                </div>
                                <div *ngIf="!(isNewTaskDbIdExists || isMaxTaskDbIdExceeds) && taskDbIdStatusChecked" class="ng-validator-success">
                                    New Task DB ID does not exists and you can proceed with the replacement. Click the "Change" button to continue.
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="projectIsFrozen" class="row">
            <div class="col-md-12">
                <hr/>
            </div>
        </div>

        <div *ngIf="projectIsFrozen" class="row">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <div class="col-md-8">

                        <div class="row">
                            <div class="col-md-2">
                                <label>Comment</label>
                            </div>
                            <div class="col-md-4">
                                <input appMandatoryField class="form-control form-control" formControlName="comment" [required]="projectIsFrozen" placeholder="Reason" type="text" [(ngModel)]="myComment" (blur)="updateButtonStatus()" >
                                <div *ngIf="taskDbIdChangeForm.controls['comment'].invalid && (taskDbIdChangeForm.controls['comment'].dirty || taskDbIdChangeForm.controls['comment'].touched)" class="ng-validator">
                                    <div *ngIf="taskDbIdChangeForm.controls['comment'].errors.required">
                                        This field is required.
                                    </div>
                                    <div *ngIf="taskDbIdChangeForm.controls['comment'].errors.minlength">
                                        minimum quantity characters = 10
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <span *ngIf="projectIsFrozen">&nbsp;</span>
        <div *ngIf="projectIsFrozen" class="row">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <div class="col-md-8">

                        <div class="row">
                            <div class="col-md-2">
                                <label>Justification Category</label>
                            </div>
                            <div class="col-md-4">
                                <ngx-select (selectionChanges)="justificationCategoryChanged($event)" appMandatoryField [items]="justificationCategoryData" formControlName="justificationCategory" [required]="projectIsFrozen" optionTextField="categoryText" optionValueField="id" placeholder="Select" [(ngModel)]="justificationCategory"></ngx-select>
                                <div *ngIf="taskDbIdChangeForm.controls['justificationCategory'].invalid && (taskDbIdChangeForm.controls['justificationCategory'].dirty || taskDbIdChangeForm.controls['justificationCategory'].touched)" class="ng-validator">
                                    <div *ngIf="taskDbIdChangeForm.controls['justificationCategory'].errors.required">
                                        This field is required.
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <hr/>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <div class="col-md-8">

                        <div class="row">
                            <div class="col-md-2">
                                <label>&nbsp;</label>
                            </div>
                            <div class="col-md-4">
                                <!-- <button class="btn btn-primary" appButtonLoader
                                    [disabled]="btnChangeDisabled">Change</button> -->
                                <button (click)="changeDBIDNow()"
                                    appButtonLoader [(isLoading)]="btnChangeLoading" [isDisabled]="btnChangeDisabled"
                                    class="btn btn-primary text-light btn-tag">Change</button>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </form>

    <div *ngIf="!(projectsLoaded && projectSystemsLoaded && tasksLoaded)"
        class="spinner-div d-flex justify-content-center align-items-center">
        <mat-progress-spinner [color]="'primary'" [mode]="'indeterminate'" class="spinner"></mat-progress-spinner>
    </div>

</div> <!-- wrapper -->