import { Component, OnInit, Input } from '@angular/core';
import {BreadcrumbServiceService} from '../../../../services/breadcrumb-service.service';
import {LanguageServiceService} from "../../../../services/language-service.service";

@Component({
  selector: 'app-system-url',
  templateUrl: './system-url.component.html',
  styleUrls: ['./system-url.component.scss']
})
export class SystemUrlComponent implements OnInit {
  public linkText: string;
  public projectNumber: number;

  @Input()
  rowData: any;

  constructor(private breadcrumbService: BreadcrumbServiceService,
              private languageService: LanguageServiceService) { }

  ngOnInit(): void {
    this.projectNumber = this.rowData['projectNumber'];
  }

  redirectToSystems(){
    let lan  = {
      id: this.rowData['workLangId'],
      str: this.rowData['workLangId'] == 1 ? "German" : "English"
    }
    this.languageService.setWorkingLanguage(lan);

    this.projectNumber = this.rowData.projectNumber;
    this.breadcrumbService.navigate(1, "Systems", '/projects/'+this.projectNumber+'/systems');
  }

}
