

<div class="modal-header">
  <h5 class="modal-title pull-left">Project Tools</h5>
  <button type="button" class="close " aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="pr-4 pl-4" [formGroup]="projectToolsEditForm" (ngSubmit)="updateProjectTool()">
    <div class="row">
      <div class="col-md-12">
        <table class="table table-sm">
          <thead class="thead-blue">
          <tr *ngIf="showFormRow">
            <th>Project Number</th>
            <th>Tool Number</th>
            <th>Designation</th>
            <th>Remark</th>
            <th>Standard Cost</th>
            <th>Project Specific Price</th>
            <th>Tool Currency</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngIf="showFormRow">
            <td><input formControlName="projectNumber" type="text" readonly class="form-control" placeholder=""/></td>
            <td><input type="text" formControlName="toolNumber" readonly class="form-control" placeholder=""/></td>
            <td><input type="text" formControlName="toolDesignation" readonly class="form-control" placeholder=""/></td>
            <td>
              <input type="text" formControlName="toolRemarks" class="form-control" placeholder=""/>
              <div *ngIf="projectToolsEditForm.controls['toolRemarks'].invalid && (projectToolsEditForm.controls['toolRemarks'].dirty || projectToolsEditForm.controls['toolRemarks'].touched)" class="ng-validator">
                <div *ngIf="projectToolsEditForm.controls['toolRemarks'].errors.maxlength">
                  Should be Less Than 1000 Characters.
                </div>
              </div>
            </td>
            <td><input type="text" readonly formControlName="toolPrice" [appThousandSeparator]="projectToolsEditForm.controls['toolPrice'].value" class="form-control" placeholder=""/></td>
            <td>
              <input type="text" formControlName="toolProjectSpecificPrice" [appThousandSeparator]="projectToolsEditForm.controls['toolProjectSpecificPrice'].value" class="form-control" placeholder="" [appDecimal]="2"/>
              <div *ngIf="projectToolsEditForm.controls['toolProjectSpecificPrice'].invalid && (projectToolsEditForm.controls['toolProjectSpecificPrice'].dirty || projectToolsEditForm.controls['toolProjectSpecificPrice'].touched)" class="ng-validator">
                <div *ngIf="projectToolsEditForm.controls['toolProjectSpecificPrice'].value != '' && projectToolsEditForm.controls['toolProjectSpecificPrice'].errors.valueZero">
                  Should be Greater Than 0
                </div>
                <div *ngIf="projectToolsEditForm.controls['toolProjectSpecificPrice'].errors.max">
                  Should be Less Than 99999999.99
                </div>
              </div>
            </td>
            <td><input type="text" readonly formControlName="toolCurrency" class="form-control" placeholder=""/></td>
            <td>
              <button type="submit" appButtonLoader [isLoading]="loadingBtn" [disabled]="projectToolsEditForm.invalid == true || initialValues == currentFormValues()" class="btn btn-primary">Edit</button>
            </td>
          </tr>
          </tbody>
          <div class="alert-warning no-changes-found-warning" *ngIf="initialValues == currentFormValues()">
            <span>No changes found...</span>
          </div>
        </table>
      </div>
    </div>
  </form>
  <div class="data-table">
    <app-tree-table [settings]="settings" [data]="projectToolData" [currentPage]="currentPage" [pageCount]="pageCount" (pageChangeEvent)="changePage($event)" (pageSizeChangeEvent)="changePageSize($event);" (filterChangeEvent)="filterOrSortChange($event)"></app-tree-table>
  </div>
</div>
<div class="modal-footer">
  <button (click)="exportProjectTools()"  class="btn btn-primary btn-sm"><fa-icon [icon]="faDownload"></fa-icon> Export to PDF</button>&nbsp;
</div>

