import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SpecialToolService {

    constructor(private http: HttpClient) { }


    /**
     * Get Special tool list
     */
    getSpecialToolList(apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/tools/" + apiType, data, {params: params});
    }

    /**
     * Create Special Tool
     * @param data
     */
    createSpecialTools(reason, data)
    {
        const httpOptions = {
            params: {
                commentParam: reason['comment'],
                justificationCategory: reason['justificationCategory'] != null ? reason['justificationCategory'] : -1
            }
        };
        return this.http.post(environment.apiUrl + "/tools/", data, httpOptions);
    }

    /**
     * Get Tool Data by Tool Number
     * @param toolNumber
     */
    getSpecialToolsById(toolNumber)
    {

        return this.http.get(environment.apiUrl + "/tools/" + toolNumber);
    }

    getImageByToolNumber(toolNumber)
    {
        return this.http.get(environment.apiUrl + "/tools/" + toolNumber + "/images");
    }


    /**
     * Update Tools
     * @param data
     * @param toolNumber
     */
    updateSpecialTools(reason, data, toolNumber)
    {
        const httpOptions = {
            params: {
                commentParam: reason['comment'],
                justificationCategory: reason['justificationCategory'] != null ? reason['justificationCategory'] : -1
            }
        };
        return this.http.put(environment.apiUrl + "/tools/" + toolNumber, data, httpOptions);
    }

    deleteTool(res, toolNumber)
    {
        const httpOptions = {
            params: {
                comment: res['comment'],
                justificationCategory: res['justificationCategory'] ? res['justificationCategory'] : -1
            }
        };
        return this.http.delete(environment.apiUrl + "/tools/" + toolNumber, httpOptions);
    }

    removeToolImage(toolId, image)
    {
        return this.http.delete(environment.apiUrl + "/tools/" + toolId + "/images/" + image);
    }

    removeToolImageNew(toolFile)
    {
        var apiURL = environment.apiUrl + "/" + toolFile;
        // alert(apiURL);
        return this.http.delete(apiURL);
    }

    searchTools(data)
    {
        const params = JSON.stringify({name: 'dddd'});

        return this.http.post(environment.apiUrl + "/tools/search", data);
    }

    exportTools()
    {
        return this.http.get(environment.apiUrl + "/tools/export/", {responseType: 'blob'});
    }
}


