<div [formGroup]="actionCommentForm">
    <div class="form-group row" *ngIf="isCommentVisible">
        <label class="col-sm-4 col-form-label col-form-label-sm">Comment</label>
        <div class="col-sm-8">
            <input class="form-control form-control-sm" formControlName="comment" [required]="isCommentRequired" placeholder="Reason" type="text">
            <div *ngIf="actionCommentForm.controls['comment'].invalid && (actionCommentForm.controls['comment'].dirty || actionCommentForm.controls['comment'].touched)" class="ng-validator">
                <div *ngIf="actionCommentForm.controls['comment'].errors.required">
                    This field is required.
                </div>
                <div *ngIf="actionCommentForm.controls['comment'].errors.minlength">
                    minimum quantity characters = 10
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row" *ngIf="isCommentVisible && isJustificationCategoryVisible">
        <label class="col-sm-4 col-form-label col-form-label-sm">Justification Category</label>
        <div class="col-sm-8">
            <ngx-select [items]="justificationCategoryData" formControlName="justificationCategory" [required]="isCommentRequired && isJustificationCategoryRequired" optionTextField="categoryText" optionValueField="id" placeholder="Select"></ngx-select>
            <div *ngIf="actionCommentForm.controls['justificationCategory'].invalid && (actionCommentForm.controls['justificationCategory'].dirty || actionCommentForm.controls['justificationCategory'].touched)" class="ng-validator">
                <div *ngIf="actionCommentForm.controls['justificationCategory'].errors.required">
                    This field is required.
                </div>
            </div>
        </div>
    </div>

    <div class="btn-div d-flex flex-row-reverse">
        <div class="mandatory-description d-flex flex-row" *ngIf="mandatoryDescriptionVisible">
            <span class="mandatory-description-asterisk mandatory-asterisk">*</span>
            <span class="mandatory-field-text">mandatory fields</span>
            <span class="mandatory-description-conditional-asterisk conditional-mandatory-asterisk">*</span>
            <span class="mandatory-field-text">conditional mandatory fields</span>
        </div>

        <button *ngIf="closeBtnName!=null" type="button" class="btn btn-warning btn-sm" (click)="cancelClicked()">{{closeBtnName}}</button>
        <button *ngIf="okBtnName!=null" type="button" class="btn btn-primary btn-sm" [disabled]="actionCommentForm.controls['comment'].invalid && isCommentVisible || actionCommentForm.controls['justificationCategory'].invalid && isJustificationCategoryVisible || isSaveDisable || noChangesFound || isbtnLoading" appPreventDoubleSubmit (debounceClick)="okClicked()" [debounceTime]="500" >{{okBtnName}}</button>
        <!-- <button *ngIf="okBtnName!=null" type="button" class="btn btn-primary btn-sm" [disabled]="actionCommentForm.controls['comment'].invalid && isCommentVisible || actionCommentForm.controls['justificationCategory'].invalid && isJustificationCategoryVisible || isSaveDisable || noChangesFound" appPreventDoubleSubmit (debounceClick)="okClicked()" [debounceTime]="500">{{okBtnName}}</button> -->

        <div class="alert-warning no-changes-found-warning" *ngIf="noChangesFound">
            <span>No changes found...</span>
        </div>
    </div>
</div>


