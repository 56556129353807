import {Component, EventEmitter, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-view-url',
  templateUrl: './view-url.component.html',
  styleUrls: ['./view-url.component.scss']
})
export class ViewUrlComponent implements OnInit {

  constructor() { }

  @Input()
  rowData: any;
  logId:any;
  eventEmitter:EventEmitter<string> = new EventEmitter();

  ngOnInit(): void {
    this.logId = this.rowData['id'];
  }

  public View(){
    this.eventEmitter.emit(this.rowData);
  }
}
