

<div class="modal-header">
  <h5 class="modal-title pull-left">Copy Project : {{this.projectNumber}}</h5>
  <ngx-select style="padding-left: 25px; width: 200px; display: inline-block; float: right;" optionValueField="id" optionTextField="str" [items]="languages" [(ngModel)]="selectedLanguage" (select)="languageChanged()"></ngx-select>
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

  <form [formGroup]="projectCopyForm" (ngSubmit)="submitCopyForm()">
    <div class="modal-body">
      <div class="form-group row pr-4 pl-4">
        <label class="col-sm-4 col-form-label col-form-label-sm">Copy project to</label>
        <div class="col-sm-8">
          <ngx-select formControlName="copyProjectTo" [items]="projectData" optionValueField="projectNumber" optionTextField="projectDesignation" appMandatoryField appInfoPromptBox [page]="'project'" [component]="'copy'" [field]="'copyProjectTo'"></ngx-select>
          <div *ngIf="projectCopyForm.controls['copyProjectTo'].invalid && (projectCopyForm.controls['copyProjectTo'].dirty || projectCopyForm.controls['copyProjectTo'].touched)" class="ng-validator">
              <div *ngIf="projectCopyForm.controls['copyProjectTo'].errors.required">
                  This field is required.
              </div>
          </div>
        </div>
      </div>
      <div class=" form-group row">
        &nbsp;
      </div>
      <div class=" form-group row">
        <div class="alert alert-warning" role="alert" style="margin-bottom:15px;">
          Please check after copying whether the copied systems are available in the vehicle where they are copied.<br/> <br/>In addition check the system quantity, task quantity and Stadler Article IDs.
        </div>
        <!-- <label class="form-check-label col-form-label-sm" for="toolsExportChk">
          Please check after copying whether the copied systems are available in the vehicle where they are copied.<br/> In addition check the system quantity, task quantity and Stadler Article IDs.
        </label> -->
      </div>

      <div class=" form-group row">
        &nbsp;
      </div>
      

    </div>
    <div class="modal-footer">
      <button appButtonLoader [isLoading]="loadingBtn" [disabled]="projectCopyForm.invalid" class="btn btn-primary">Copy Now</button>
    </div>
  </form>

