import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SparePartsRoutingModule } from './spare-parts-routing.module';
import { SparePartListComponent } from './spare-part-list/spare-part-list.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';
import { ModalModule,BsModalRef} from 'ngx-bootstrap/modal';
import { AddSparePartsComponent } from './components/add-spare-parts/add-spare-parts.component';
import { EditSparePartsComponent } from './components/edit-spare-parts/edit-spare-parts.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { SharedModule } from '../../shared/shared.module';
import { SparePartLogComponent } from './spare-part-log/spare-part-log.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MasterListUsageComponent} from "../../shared/components/master-list-usage/master-list-usage/master-list-usage.component";
import {MasterListUsageUrlComponent} from "../../shared/components/master-list-usage/master-list-usage-url/master-list-usage-url.component";
import {ViewSystemUrlComponent} from "../../shared/components/master-list-usage/view-system-url/view-system-url.component";
import {TreeTableModule} from "../../shared/components/tree-table/tree-table.module";

@NgModule({
  declarations: [SparePartListComponent, AddSparePartsComponent, EditSparePartsComponent, SparePartLogComponent, MasterListUsageComponent, MasterListUsageUrlComponent, ViewSystemUrlComponent],
  imports: [
    CommonModule,
    SparePartsRoutingModule,
    FontAwesomeModule,
    ModalModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSelectModule,
    LaddaModule,
    SharedModule,
    NgbModule,
    TreeTableModule
  ],
  bootstrap:[
    AddSparePartsComponent,
    EditSparePartsComponent,
    SparePartLogComponent,
    MasterListUsageComponent,
    MasterListUsageUrlComponent,
    ViewSystemUrlComponent
  ],
  providers: [

    BsModalRef

]
})
export class SparePartsModule { }
