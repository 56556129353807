import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { LanguageServiceService } from 'src/app/services/language-service.service';
import { ProjectService } from 'src/app/services/project.service';
import { StandardSystemsService } from 'src/app/services/standard-systems.service';
import { UserService } from 'src/app/services/user.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-system-ramengineer-change',
  templateUrl: './system-ramengineer-change.component.html',
  styleUrls: ['./system-ramengineer-change.component.scss']
})
export class SystemRamengineerChangeComponent implements OnInit {

  constructor(
    private authService: AuthServiceService,
    public bsModalRef: BsModalRef,
    private activatedRoute : ActivatedRoute,
    private projectService: ProjectService,
    private standardSystemsService: StandardSystemsService,
    private languageService:LanguageServiceService,
    private userService:UserService,
    private notificationService: ToastrService
  ) {}

  ramEngineerUpdateForm = new UntypedFormGroup(
    {
      lstOptions: new UntypedFormControl({value:'3', disabled: false }),
      updatedProjects: new UntypedFormControl({value:[], disabled: true}),
      updatedSystems: new UntypedFormControl([]),
      updatedRAMEngineers: new UntypedFormControl([]),
      comment: new UntypedFormControl([]),
      justificationCategory: new UntypedFormControl([])
      
    }
);

  updateOptions:any[] = [
    {optionId : 1, optionText : "Update Both Project Systems and Standard Systems"},
    {optionId : 2, optionText : "Update only Project Systems"},
    {optionId : 3, optionText : "Update only Standard Systems"}
  ];

  selectedOption:any = 3;
  
  projects:any[] = [];
  selectedProjects:string[] = [];
  standardSystems:any[] = [];
  selectedSystems:number[] = [];
  ramEngineers:any[] = [];
  selectedRamEngineers:number[] = [];


  mySystemDesignation:any = "";
  searchString:any;
  projectsLoaded:boolean = false;
  systemsLoaded:boolean = false;
  ramEngineersLoaded:boolean = false;
  notSatisfied:boolean = true;

  laddLoading: Boolean = false;

  ngOnInit(): void {

    this.getProjects();
    this.getStandardSystems();
    this.getRamEngineers();
    
  }

  getStandardSystems(){
    this.standardSystemsService.getAllStandardSystems().subscribe(res=>{
      if(res && res['page']){
        res = res['page']['content'];
        Object.keys(res).forEach(item => {
          var mySystemDesignation = "";
          mySystemDesignation = res[item].systemCode+'-';
          // mySystemDesignation += this.languageService.getCurrentLanguage().id == 0 ? res[item].systemDesignationEn : res[item].systemDesignationDe;
          mySystemDesignation += res[item].systemDesignationDe;

          this.standardSystems.push({
            systemNumber: res[item].systemNumber,
            systemDesignation: mySystemDesignation
          });
        });
        console.log(this.standardSystems);
        this.systemsLoaded = true;
      }
    });
  }

  getProjects(){
    this.projectService.getProjectList().subscribe(res=>{
      if(res && res['page']){
        res = res['page']['content'];
        Object.keys(res).forEach(item => {
          this.projects.push({
            projectNumber: res[item].projectNumber,
            projectDesignation: res[item].projectNumber + " - " + res[item].projectDesignation
          });
        });
        console.log(this.projects);
        this.projectsLoaded = true;
      }
    });
  }

  /**
   * Get all Ram engineers
   */
  getRamEngineers(){
    this.userService.getRamengineers().subscribe(res=>{
      if(res ){
        Object.keys(res).forEach(item => {
          this.ramEngineers.push({
            stakeholderId: res[item].stakeholderId,
            stakeholderName: res[item].firstName + " " + res[item].lastName + " (" + res[item].email + ")",
            emailAddress: res[item].email
          });
        });
        console.log(this.ramEngineers);
        this.ramEngineersLoaded = true;
      }
    });
  }

  onOptionSelect(){
    if(this.selectedOption==3){
      this.ramEngineerUpdateForm.controls['updatedProjects'].disable();
    }else{
      this.ramEngineerUpdateForm.controls['updatedProjects'].enable();
    }
    this.validateForm();
  }

  doUpdate(justification){  
    this.laddLoading = true;
    console.log(justification);
    console.log(this.ramEngineerUpdateForm.value);
    var data = this.ramEngineerUpdateForm.value;
    if(justification.action == 'ok'){
      data.comment = justification.comment;
      data.justificationCategory = justification.justificationCategory;
    }

    this.standardSystemsService.updateRamEngineer(data).subscribe(res=>{
      if(res){
        console.log(res); 
        this.notificationService.success('RAM Engineers been updated successfully', 'Success');
        this.bsModalRef.hide();
        this.laddLoading = false;
      }else{
        this.laddLoading = false;
        this.notificationService.error('RAM Engineer update failed', 'Error');
      }
      
    }, (error => {
      this.notificationService.error('RAM Engineer update failed', 'Error');
      this.laddLoading = false;
    }));

    // this.bsModalRef.hide();
  }

  onProjectSelect(){
    console.log(this.selectedProjects);
    this.validateForm();
  }

  onSystemsSelect(){
    console.log(this.selectedSystems);
    this.validateForm();
  }

  onRAMEngineerSelect(){
    console.log(this.selectedRamEngineers);
    this.validateForm();
  }
  
  onClickSearch(){}

  validateForm(){
    if(this.selectedOption==3){
      this.notSatisfied = (this.selectedSystems.length == 0) || (this.selectedRamEngineers.length == 0) ;
    }else{
      this.notSatisfied = (this.selectedSystems.length == 0) || (this.selectedProjects.length == 0) || (this.selectedRamEngineers.length == 0);
    }
  }

}
