import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TaskDbidChangeComponent } from './task-dbid-change/task-dbid-change.component';



const routes: Routes = [
  { path: 'task-dbid-change', component: TaskDbidChangeComponent, data: { animation: 'projects' }},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TaskDbidChangeRoutingModule { 
  
}
