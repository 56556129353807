import { Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-common-error-modal',
  templateUrl: './common-error-modal.component.html',
  styleUrls: ['./common-error-modal.component.scss']
})
export class CommonErrorModalComponent {

  title:string = "ERROR";
  text:string = "An Error Occurred!";
  buttonText:string = "Ok";
  modalRef:BsModalRef;
  event: EventEmitter<any> = new EventEmitter();
  tableContent: any[] = [];

  constructor(modalRef:BsModalRef) {
    this.modalRef = modalRef;
  }

  ngOnInit(): void {
  }

  public buttonClicked(event){
    this.modalRef.hide();
    this.event.emit({ result: event });
  }

  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }


}
