<div class="container-fluid">

  <div class="row">
    <div class="pageTitle col-sm-6">
      <span>Operating Materials</span> &nbsp;
      <button type="button" (click)="loadAddModal()" class="btn btn-primary btn-sm"><fa-icon [icon]="faPlus"></fa-icon> &nbsp;&nbsp;Add</button>&nbsp;&nbsp;
      <button type="button" class="btn btn-primary btn-sm" (click)="showLogs()"><fa-icon [icon]="faFile"></fa-icon>&nbsp;&nbsp;Logs</button>&nbsp;&nbsp;
      <button type="button" class="btn btn-primary btn-sm" (click)="exportOperatingMaterials()" appInfoPromptBox [page]="'masterOperatingMaterials'" [component]="'grid'" [field]="'exportButton'"><fa-icon [icon]="faFileExport"></fa-icon>&nbsp;&nbsp;Export</button>
    </div>
  </div>

  <div class="data-table">
    <app-tree-table [settings]="settings" [data]="tableData" [currentPage]="currentPage" [pageCount]="pageCount" (pageChangeEvent)="changePage($event)" (pageSizeChangeEvent)="changePageSize($event);"  (filterChangeEvent)="filterOrSortChange($event)"></app-tree-table>
  </div>



</div> <!-- wrapper -->
