import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ProjectSystemsComponent} from "../components/projects/project-systems/project-systems.component";

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    public projectSystemsComponent: ProjectSystemsComponent;

    constructor(private http: HttpClient) { }

    getUsageByItem(itemType, id)
    {
        return this.http.get(environment.apiUrl + "/" + itemType + "/usage/" + id + "/");
    }
}
