import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProfileUnitsService {

    constructor(private http: HttpClient) { }

    /**
     * Get all profile units
     */
    getProfileUnits()
    {
        return this.http.get(environment.apiUrl + "/profileunits/");
    }

    /**
     * Get profile unit by Id
     */
    getProfileUnitById(unitId)
    {
        return this.http.get(environment.apiUrl + "/profileunits/" + unitId + "/");
    }

    /**
     * Get Profile units by type
     * @param type
     */
    getProfileUnitsByType(type)
    {
        return this.http.get(environment.apiUrl + "/profileunits/types/" + type + "/");
    }

    getCombinedUnits(projectSystemsId)
    {
        return this.http.get(environment.apiUrl + "/profileunits/systems/" + projectSystemsId + "/combined");
    }


    /**
     * Get Standard profile units
     * @param projectSystemsId
     */
    getStandardProfileUnits(projectSystemsId)
    {
        return this.http.get(environment.apiUrl + "/profileunits/standard/" + projectSystemsId + "/");
    }

    /**
     * Get Profile  units by systems
     * @param projectSystemId
     */
    getProfileUnitBySystems(projectSystemId)
    {
        return this.http.get(environment.apiUrl + "/profileunits/systems/" + projectSystemId + "/");
    }

}
