import {Component, EventEmitter, OnInit} from '@angular/core';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import {faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import {faAngleDoubleLeft} from '@fortawesome/free-solid-svg-icons';
import {UntypedFormGroup, UntypedFormControl, FormBuilder} from '@angular/forms';
import {PowerunitService} from 'src/app/services/powerunit.service';
import {ElectrificationSystemService} from 'src/app/services/electrification-system.service';
import {ProjectService} from 'src/app/services/project.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {Validators} from '@angular/forms';
import {validateForm} from 'src/app/shared/helper';
import {LanguageServiceService} from '../../../services/language-service.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import { UserRoleService } from 'src/app/services/user-role.service';
import {UserGroupService} from "../../../services/user-group.service";

@Component({
  selector: 'app-projectsadd',
  templateUrl: './projectsadd.component.html',
  styleUrls: ['./projectsadd.component.scss']
})
export class ProjectsaddComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();
  faAngleRight = faAngleRight;
  faAngleDoubleRight = faAngleDoubleRight;
  faAngleLeft = faAngleLeft;
  faAngleDoubleLeft = faAngleDoubleLeft;
  powerUnit: any = [];
  numberPattern: any = '/1234567890/';
  electrification: any = [];

  hidesystemArea: Boolean = false;
  projectid: any;
  laddaLoading = false;
  projectSystemData: any = [];
  languages: any = [];
  specificEmployeeQualificationData:any = [];
  initialSpecificEmployeeQualification:any = 0;
  showSpecificEmployeeQualificationWarning = false;
  toolCategoriesData = [];
  operatingMaterialsData = [];
  partsData = [];
  roleData:any [] = [];
  userGroupData:any[] = [];
  initialFreezeValue = "inactivated";
  freezeFunctionData = [
    {
      id:'activated',
      str:'Activated',
    },
    {
      id:'inactivated',
      str:'Inactivated',
    }
  ];



  projectAddForm = new UntypedFormGroup({
    projectNumber: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
    projectDesignation: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
    parentProject: new UntypedFormControl(null),
    powerUnit: new UntypedFormControl('', [Validators.required]),
    projectElectrification: new UntypedFormControl(''),
    carsCount: new UntypedFormControl('', [Validators.required, Validators.max(999)]),
    vehicleQuantity: new UntypedFormControl('', [Validators.required, Validators.max(999)]),
    projectStatus: new UntypedFormControl('0'),
    specificEmployeeQualification: new UntypedFormControl(0),
    toolCategories: new UntypedFormControl('special'),
    operatingMaterials: new UntypedFormControl('all'),
    parts: new UntypedFormControl('all'),
    freezeFunction: new UntypedFormControl('inactivated'),
    workingLanguage: new UntypedFormControl(0),
    exportLanguage: new UntypedFormControl(0),
    projectAdmins: new UntypedFormControl(),
  });

  constructor(
      private powerUnitService: PowerunitService,
      private electrificationService: ElectrificationSystemService,
      private projectService: ProjectService,
      private notification: ToastrService,
      private router: Router,
      private formBuilder: FormBuilder,
      public bsModalRef: BsModalRef,
      public languageService: LanguageServiceService,
      private userRoleService: UserRoleService,
      private userGroupService: UserGroupService,
  ) {
    this.loadPowerUnit();
    this.loadElectrification();
    this.languages = this.languageService.allLanguages();
    this.specificEmployeeQualificationData = [{id:0, text:"Inactivated"}, {id:1, text:"Activated"}];
    this.getToolCategoriesData();
    this.getOperatingMaterialsData();
    this.getPartsData();
  }

  ngOnInit(): void {
    // this.getUserRoleData();
    this.getUserGroupData();
  }

  // Create Project
  onSubmit() {
    
    let electrifiactionArray = [...this.projectAddForm.value.projectElectrification];
    let newElectrification = [];
    electrifiactionArray.forEach((value, index) => {
      newElectrification.push({
        electrificationSystemId: value
      });
    });
    let formData = this.projectAddForm.getRawValue();
    formData.projectElectrification = newElectrification;

    if (this.projectAddForm.valid) {
      this.laddaLoading = true;
      // delete formData.projectNumber;
      this.projectAddForm.disable();
      this.projectService.createProject(formData).subscribe(res => {
        if (res) {
          this.notification.success('Project has been created', 'Success');
          this.projectid = res['projectNumber'];
        }
        else {
          this.notification.error('Project has not been created', 'Error');
        }
      }, (error => {
        this.notification.error('Project has not been created', 'Error');
        this.notification.error(error["error"]["details"], 'Error');
        console.log(error["error"]["details"]);
      }), ()=>{
        this.laddaLoading = false;
        this.bsModalRef.hide();
        this.event.emit();
      });
    }
    else {
      validateForm(this.projectAddForm);
    }
  }

  loadPowerUnit() {
    this.powerUnitService.getPowerUnit().subscribe(res => {
      this.powerUnit = res;

    });
  }

  loadElectrification() {
    this.electrificationService.getElectrificationSystems().subscribe(res => {
      this.electrification = res;

    });
  }

  specificEmployeeQualificationChanged(){
    let prevValue = this.initialSpecificEmployeeQualification;
    let currentValue = this.projectAddForm.controls['specificEmployeeQualification'].value;

    this.showSpecificEmployeeQualificationWarning = prevValue != currentValue;
  }


  getToolCategoriesData(){
    this.toolCategoriesData = [{id:'none', str:'None'}, {id:'special', str:'Special'}, {id:'standard', str:'Standard'}, {id:'all', str:'All'}];
  }

  getOperatingMaterialsData(){
    this.operatingMaterialsData = [{id:'none', str:'None'},  {id:'all', str:'All'}];
  }

  getPartsData(){
    this.partsData = [{id:'none', str:'None'},  {id:'all', str:'All'}];
  }

  getUserRoleData(){
    this.userRoleService.getAll().subscribe((res:any[])=>{
      if(res) this.roleData = res;
    }, (error => {
      console.log(error);
    }))
  }

  getUserGroupData(){
    this.userGroupService.getAll().subscribe((res:any[])=>{
      if(res) this.userGroupData = res;
    }, (error => {
      console.log(error);
    }))
  }
  
}
