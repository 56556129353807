import { Component } from '@angular/core';

@Component({
  selector: 'app-authentication-failed-modal',
  templateUrl: './authentication-failed-modal.component.html',
  styleUrls: ['./authentication-failed-modal.component.scss']
})
export class AuthenticationFailedModalComponent {

  param:any;

  ngOnInit(): void {

  }

}
