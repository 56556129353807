import {Injectable} from '@angular/core';

class Language {
    public id;
    public str;

    constructor(id, str)
    {
        this.id = id;
        this.str = str;
    }
}

@Injectable({
    providedIn: 'root'
})
export class LanguageServiceService {

    private languages: Array<Language> = new Array<Language>();
    private currentLanguage: Language;
    private workingLanguage: Language;

    constructor()
    {
        this.workingLanguage = new Language(0, "English");
        this.setLanguages();
        this.resetCurrentLanguage();
    }

    public setWorkingLanguage(lang: Language)
    {
        this.workingLanguage = lang;
        this.currentLanguage = lang;
    }

    public getWorkingLanguage()
    {
        return this.workingLanguage;
    }


    public setCurrentLanguage(lang: Language)
    {
        this.currentLanguage = lang;
    }

    public getCurrentLanguage()
    {
        return this.currentLanguage;
    }

    public resetCurrentLanguage()
    {
        this.currentLanguage = this.workingLanguage;
    }

    public allLanguages()
    {
        return this.languages;
    }

    public setLanguages()
    {
        let lan1 = new Language(0, "English");
        let lan2 = new Language(1, "German");

        this.languages.push(lan1);
        this.languages.push(lan2);
    }
}
