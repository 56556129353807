import {Component, EventEmitter, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {FormArray, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-freeze-function-comments-dialog',
  templateUrl: './freeze-function-comments-dialog.component.html',
  styleUrls: ['./freeze-function-comments-dialog.component.scss']
})
export class FreezeFunctionCommentsDialogComponent implements OnInit {

  public title: string = "";
  public event: EventEmitter<any> = new EventEmitter();
  public freezeFunctionCommentsForm:UntypedFormGroup;
  public formValuesMap:any = new Map<string, {}>();
  public formValuesArray:any[] = [];
  public justificationCategoryData:any[] = [];
  public formControlMap:any =  new Map<string, any>();

  constructor(public bsModalRef: BsModalRef,
              public http: HttpClient) { }

  ngOnInit(): void {
    this.freezeFunctionCommentsForm = new UntypedFormGroup({
      comment: new FormArray([]),
      justificationCategory: new FormArray([]),
    });

    this.initFromControls();
    this.getJustificationCategoryList()
  }

  initFromControls(){
    this.formValuesMap.forEach((value:{}, key:string)=>{
      if(value['oldValue'] == value['newValue']) return;

      this.formValuesArray.push(value);

      let commentControl = new UntypedFormControl('', [Validators.required, Validators.minLength(10)]);
      let justificationCategoryControl = new UntypedFormControl('', [Validators.required]);

      this.commentsFormArray.push(commentControl);
      this.justificationCategoryFormArray.push(justificationCategoryControl);

      let controls = {
        comment: commentControl,
        justificationCategory: justificationCategoryControl
      }
      this.formControlMap.set(key, controls);
    });
  }

  public get commentsFormArray() {
    return this.freezeFunctionCommentsForm.controls["comment"] as FormArray;
  }

  public getCommentsFormControl(index) {
    return this.commentsFormArray.controls[index];
  }

  public get justificationCategoryFormArray() {
    return this.freezeFunctionCommentsForm.controls["justificationCategory"] as FormArray;
  }

  public getJustificationCategoryControl(index) {
    return this.justificationCategoryFormArray.controls[index];
  }

  public getJustificationCategoryList(){
    this.http.get(environment.apiUrl+"/logs/justificationCategories").subscribe((res:any[])=>{
      this.justificationCategoryData = res;
    });
  }

  public submitForm()
  {
    let result = [];
    this.formControlMap.forEach((controls:{}, key:string)=>{
      let comment = controls['comment'].value;
      let justificationCategory = controls['justificationCategory'].value;

      let obj = {
        attribute: key,
        comment: comment ? comment : '',
        justificationCategory: justificationCategory != null ? this.justificationCategoryData[justificationCategory] : '{}'
      }
      result.push(obj);
    });

    this.event.emit(result);

    this.bsModalRef.hide();
  }

  public closeForm(){
    let result = "CANCELLED";
    this.event.emit(result);
    this.bsModalRef.hide();
  }

}
