import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {faDownload, faEdit, faPrint, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {SpecialToolService} from 'src/app/services/special-tool.service';
import {ProjectToolService} from 'src/app/services/project-tool.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from 'src/environments/environment';
import * as FileSaver from 'file-saver';
import {LanguageServiceService} from "../../../services/language-service.service";
import {ProjectService} from "../../../services/project.service";

@Component({
  selector: 'app-project-systems-tools',
  templateUrl: './project-tools.component.html',
  styleUrls: ['./project-tools.component.scss']
})
export class ProjectToolsComponent implements OnInit {

  projectToolsEditForm:any;
  param:any;
  exportUrl:any;
  faEdit=faEdit;
  faPrint=faPrint;
  faTrashAlt=faTrashAlt;
  faDownload=faDownload;
  projectNumber:any;
  showFormRow:Boolean = false;
  projectToolData:any;
  projectToolLength:Number=0;

  filterData = [];
  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";
  initialValues:any = null;

  loadingBtn:boolean = false;

  settings = {
    type:'table',
    id:'toolNumber',
    columnManagerEnable:true,
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    enableZoom:true,
    filterOptionFunction:(params, filterData)=>{return this.projectToolService.getProjectToolsList(this.projectNumber, "distinct-values", filterData, params)},
    columns:[
      {
        title:'Project Number',
        attribute:'projectNumber',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150,
      },
      {
        title:'Tool Number',
        attribute:'toolNumber',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Tool Designation',
        attribute: 'toolDesignation',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Tool Remark',
        attribute:'toolRemarks',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Tool Price',
        attribute:'toolPrice',
        type:'text',
        format:"'###",
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:150,
      },
      {
        title:'Project Specific Price',
        attribute:'toolProjectSpecificPrice',
        type:'text',
        format:"'###",
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200
      },
      {
        title:'Tool Currency',
        attribute:'toolCurrency',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        renderComponent:'<a class="btn btn-info btn-sm text-light btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/edit.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.editProjectTool(row)},
        width:50
      }
    ]
  };

  constructor(
      public bsModalRef:BsModalRef,
      private modalService : BsModalService,
      private specialToolService: SpecialToolService,
      private projectToolService: ProjectToolService,
      private notificationService: ToastrService,
      private projectService:ProjectService,
      public languageService: LanguageServiceService
  ) { }

  ngOnInit(): void {
    this.projectNumber = this.param.projectNumber;
    this.getProjectDataById();

    this.projectToolsEditForm = new UntypedFormGroup({
      projectNumber: new UntypedFormControl(''),
      toolNumber: new UntypedFormControl(''),
      toolCategory: new UntypedFormControl(''),
      toolDesignation: new UntypedFormControl(''),
      toolPrice: new UntypedFormControl({value : '', disabled : true}),
      toolProjectSpecificPrice: new UntypedFormControl('', [Validators.max(99999999.99)]),
      toolCurrency: new UntypedFormControl({value : '', disabled : true}),
      toolRemarks: new UntypedFormControl('', [Validators.maxLength(1000)]),
    });
    this.exportUrl = environment.apiUrl + "/projects/"+this.projectNumber+"/tools/export";
  }

  public currentFormValues()
  {
    return JSON.stringify(this.projectToolsEditForm.getRawValue());
  }

  getProjectDataById(){
    this.projectService.getProjectById(this.projectNumber).subscribe(res=>{
      let lan  = {
        id: res['workLangId'],
        str: res['workLangId'] == 1 ? "German" : "English"
      }
      this.languageService.setWorkingLanguage(lan);
      this.getProjectTools();
    });
  }

  getProjectTools(){
    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;

    this.projectToolService.getProjectToolsList(this.projectNumber, "filtered-data", this.filterData, params).subscribe(res=>{
      if(res && res['page']){
        this.pageCount = res['page']['totalPages'];
        let result = res['page']['content'];
        this.projectToolData = result;
      }
      if(res && res['tablePropertyMap']){
        let columns = this.settings.columns;
        let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];

        for(let i=0; i<columns.length; i++){
          let data = tablePropertyMap[columns[i].attribute];
          if(!data) continue;
          columns[i].filter['column'] = data['column'];
          columns[i].filter['table'] = data['table'];
        }
      }
      this.settings['isNative'] = false;
    });
  }

  updateProjectTool() {
    this.loadingBtn = true;
    let submitData = this.projectToolsEditForm.getRawValue();
    let data = {
      projectNumber: submitData['projectNumber'],
      toolNumber: submitData['toolNumber'],
      toolPrice: submitData['toolPrice'],
      toolProjectSpecificPrice: submitData['toolProjectSpecificPrice'],
      toolCurrency: submitData['toolCurrency'],
      toolRemarks: submitData['toolRemarks'],
    };
    // Edit Record
    this.projectToolService.updateProjectTools(data, this.projectNumber, submitData['toolNumber']).subscribe(res => {
      if (res) {
        this.notificationService.success('Project Tool has been Updated', 'Success');
        this.getProjectTools();
        this.showFormRow = false;
      } else {
        this.notificationService.error('Project Tool has not been Updated', 'Error');
      }
      this.loadingBtn = true;
    });
  }

  editProjectTool(selectedProjectTool){
    this.showFormRow = true;
    this.projectToolsEditForm.patchValue({
      projectNumber: this.projectNumber,
      toolNumber: selectedProjectTool['toolNumber'],
      toolCategory: selectedProjectTool['categoryId'],
      toolDesignation: this.languageService.getCurrentLanguage().id == 0 ? selectedProjectTool['toolDesignationEn'] : selectedProjectTool['toolDesignationDe'],
      toolPrice: selectedProjectTool['toolPrice'],
      toolProjectSpecificPrice: selectedProjectTool['toolProjectSpecificPrice'] != null ? selectedProjectTool['toolProjectSpecificPrice'].toString() : null,
      toolCurrency: selectedProjectTool['toolCurrency'],
      toolRemarks: selectedProjectTool['toolRemarks'],
    });
    this.initialValues = JSON.stringify(this.projectToolsEditForm.getRawValue());
  }

  exportProjectTools() {
    this.projectToolService.exportProjectTools(this.projectNumber).subscribe(res => {
      var mediaType = 'application/pdf';
      var blob = new Blob([res], {type: mediaType});
      var filename = this.projectNumber + '_tools.pdf';
      FileSaver.saveAs(blob, filename);
    });
  }

  filterOrSortChange(event){
    if(!event){
      this.currentPage = 1;
      this.projectToolData = [];
      return;
    }
    if(!event['sortChanged']) this.currentPage = 1;
    this.sortColumn = event['sortColumn'];
    this.sortOrder = event['sortOrder'];
    this.filterData = event['filterData'];

    this.getProjectTools();
  }

  changePage(currentPage){
    this.currentPage = currentPage;
    this.getProjectTools();
  }

  changePageSize(pageSize){
    this.pageSize = pageSize;
    this.getProjectTools();
  }

}
