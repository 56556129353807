<div class="modal-header">
  <h5 class="modal-title pull-left">Project System Task Tools</h5>
  <div>
    <ngx-select style="padding-left: 25px; width: 200px; display: inline-block; float: right !important;"
      optionValueField="id" optionTextField="str" [items]="languages" [(ngModel)]="selectedLanguage"
      (select)="languageChanged()"></ngx-select>
  </div>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row pr-4 pl-4">
    <div class="col-md-12">
      <table class="table table table-bordered table-sm" *ngIf="projectSystemTaskToolArray?.length > 0">
        <thead class="thead-blue">
          <tr>
            <th>Tool</th>
            <th>Tool Quantity</th>
            <th>Project Specific Price</th>
            <th>Tool Currency</th>
            <th>Tool Remark</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of projectSystemTaskToolArray | keyvalue">
            <td><input type="text" value="{{item.value.toolDesignation}}" readonly class="form-control" placeholder=""/></td>
            <td><input type="text" value="{{item.value.toolQuantity}}" [appThousandSeparator]="item.value.toolQuantity" readonly class="form-control" placeholder=""/></td>
            <td><input type="text" value="{{item.value.toolProjectSpecificPrice}}" [appThousandSeparator]="item.value.toolProjectSpecificPrice" readonly class="form-control" placeholder=""/></td>
            <td><input type="text" value="{{item.value.toolCurrency}}" readonly class="form-control" placeholder=""/></td>
            <td><input type="text" value="{{item.value.toolRemarks}}" readonly class="form-control" placeholder=""/></td>
            <td>
              <button (click)="selectProjectSystemTaskTool(item.key)" class="btn btn-info btn-sm"><img title="Edit" class="icon-mini" src="/assets/icon/edit.png" /></button>&nbsp;
              <button (click)="deleteTaskTool(item.key)" class="btn btn-danger btn-sm"><img title="Delete" class="icon-mini" src="/assets/icon/delete.png" /></button>&nbsp;
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <form class="pr-4 pl-4" [formGroup]="projectSystemTaskToolForm">
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Project Number</label>
      <div class="col-sm-6">
        <input type="text" formControlName="projectNumber" class="form-control" placeholder="" appInfoPromptBox [page]="'taskTools'" [component]="'addEdit'" [field]="'projectNumber'">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Task Name</label>
      <div class="col-sm-6">
        <input type="text" formControlName="taskName" class="form-control" placeholder="" appInfoPromptBox [page]="'taskTools'" [component]="'addEdit'" [field]="'taskName'">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Tool</label>
      <div class="col-sm-6">
        <ngx-select optionValueField="toolNumber" formControlName="toolNumber" (select)="selectProjectTools($event)" [placeholder]="'Select Option'" [items]="projectToolsData" optionTextField="toolDesignation" appInfoPromptBox [page]="'taskTools'" [component]="'addEdit'" [field]="'toolName'"></ngx-select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Tool Quantity</label>
      <div class="col-sm-6">
        <input type="text" formControlName="toolQuantity" class="form-control" [appThousandSeparator]="projectSystemTaskToolForm.controls['toolQuantity'].value" placeholder="" [appDecimal]="0" appMandatoryField appInfoPromptBox [page]="'taskTools'" [component]="'addEdit'" [field]="'toolQuantity'">
        <div *ngIf="projectSystemTaskToolForm.controls['toolQuantity'].invalid && (projectSystemTaskToolForm.controls['toolQuantity'].dirty || projectSystemTaskToolForm.controls['toolQuantity'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskToolForm.controls['toolQuantity'].errors.required">
            This field is required.
          </div>
          <div *ngIf="projectSystemTaskToolForm.controls['toolQuantity'].value != '' && projectSystemTaskToolForm.controls['materialQuantity'].errors.valueZero">
            Should be Greater Than 0
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Project Specific Price</label>
      <div class="col-sm-6">
        <input type="text" formControlName="toolProjectSpecificPrice" class="form-control" [appThousandSeparator]="projectSystemTaskToolForm.controls['toolProjectSpecificPrice'].value" placeholder="" appInfoPromptBox [page]="'taskTools'" [component]="'addEdit'" [field]="'toolProjectSpecificPrice'">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Tool Currency</label>
      <div class="col-sm-6">
        <input type="text" formControlName="toolCurrency" class="form-control" placeholder="" appInfoPromptBox [page]="'taskTools'" [component]="'addEdit'" [field]="'toolCurrency'">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Tool Remark</label>
      <div class="col-sm-6">
        <input type="text" formControlName="toolRemarks" class="form-control" placeholder="" appInfoPromptBox [page]="'taskTools'" [component]="'addEdit'" [field]="'toolRemarks'">
        <div *ngIf="projectSystemTaskToolForm.controls['toolRemarks'].invalid && (projectSystemTaskToolForm.controls['toolRemarks'].dirty || projectSystemTaskToolForm.controls['toolRemarks'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskToolForm.controls['toolRemarks'].errors.maxlength">
            Maximum length is 100 characters
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <app-action-comment [btnLoading]="btnLoading" [okBtnName]="btnValue" [closeBtnName]="'Close'"
    [saveDisable]="projectSystemTaskToolForm.invalid || !showFormRow" [mandatoryDescriptionVisible]="true"
    [commentVisible]="projectFreezeFunction == 'activated'" (okClick)="addProjectSystemTaskTool($event)"
    (cancelClick)="bsModalRef.hide()"></app-action-comment>
</div>
