import {Component, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {ProjectSystemTasksService} from "../../../../services/project-system-tasks.service";
import {formatDate} from "@angular/common";
import * as FileSaver from 'file-saver';
import { DokuVersionComponent } from './doku-version/doku-version.component';

@Component({
  selector: 'app-task-export-log',
  templateUrl: './task-export-log.component.html',
  styleUrls: ['./task-export-log.component.scss']
})

export class TaskExportLogComponent implements OnInit {

  public param:any;
  public projectNumber:string;
  public taskExportLogs:any = [];

  public userSelectionsMap: any = new Map();

  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;

  settings = {
    type:'table',
    id:'id',
    columnManagerEnable:true,
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    columns:[
      {
        title:'Version',
        attribute:'version',
        type:'text',
        width:30,
      },
      {
        title:'Doku Version',
        attribute:'dokuVersion',
        renderComponent: DokuVersionComponent,
        type:'custom-text',
        // filter: {
        //   enabled:true,
        //   isNumericField:false,
        // },
        width:70
      },
      // {
      //   title:'Doku Version',
      //   attribute:'dokuVersion',
      //   type:'text',
      //   width:70,
      // },
      {
        title:'Date, Time',
        attribute:'dateAndTime',
        type:'text',
        valueChangeFunction: (content) => {
          let eurDate = (new Date(content)).toLocaleString("en-US", {timeZone: "Europe/Berlin"});
          return eurDate;
        },
        width:250
      },
      {
        title:'Exported by User',
        attribute: 'exportedByUserName',
        type:'text',
        width:200,
      },
      {
        title:'Tool Categories',
        attribute:'taskToolCategories',
        type:'text',
        width:100,
      },
      {
        title:'Operating Materials',
        attribute:'taskOperatingMaterials',
        type:'text',
        width:150,
      },
      {
        title:'Parts',
        attribute:'taskParts',
        type:'text',
        width:100,
      },
      {
        renderComponent:'<a class="btn btn-info btn-sm text-light btn-url"><img title="Export" class="btn-icon" src="/assets/icon/export.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.exportData(row)},
        width:60
      },
    ]
  };

  constructor(public projectSystemTaskService: ProjectSystemTasksService,
              public bsModalRef:BsModalRef) {
    this.createUserSelectionMap();
  }

  ngOnInit(): void {
    this.projectNumber = this.param.projectNumber;
    this.getTaskOfficialExportLogs();
  }

  convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
    }

  public exportData(rowData){
    this.projectSystemTaskService.exportOfficialTask(rowData['id'], this.projectNumber).subscribe(res=>{
      const blob = new Blob([res], { type: 'application/octet-stream' });

      // var dt = new Date();
      var dt = new Date(rowData.dateAndTime);
      dt = this.convertTZ(dt, "Europe/Berlin");

      var dtString = formatDate(dt, 'yyyy-MM-dd', 'en-US');
      var timeString = formatDate(dt, 'HH-mm', 'en-US');
      var versionString = rowData.version;
      console.log(rowData);
      var strFileName = this.projectNumber + "_RAM-Export_"+dtString+"_"+timeString+"_"+versionString+".xlsx";

      const file = new File([blob], strFileName, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      FileSaver.saveAs(file);
    });
  }

  public getTaskOfficialExportLogs(){
    let params = {};
    if (this.currentPage) params['page'] = this.currentPage - 1;
    if (this.pageSize) params['pageSize'] = this.pageSize;
    this.projectSystemTaskService.getTaskOfficialExportLogs(this.projectNumber, params).subscribe((res)=>{
      let result = res['content'];
      Object.entries(result).forEach(([key, value]) => {
        if(value['exportedByUser']){
          value['exportedByUserName'] = value['exportedByUser']['firstName'];
          value['exportedByUserName'] += value['exportedByUser']['lastName'] ? " " + value['exportedByUser']['lastName'] : "";
        }
        if(value['toolCategories']){
          value['toolCategories'] = this.userSelectionsMap.get(value['toolCategories']);
        }
        if(value['operatingMaterials']){
          value['operatingMaterials'] = this.userSelectionsMap.get(value['operatingMaterials']);
        }
        if(value['parts']){
          value['parts'] = this.userSelectionsMap.get(value['parts']);
        }
      });
      this.taskExportLogs = result;

      if(res['totalPages']){
        this.pageCount = res['totalPages'];
      }
    })
  }

  createUserSelectionMap(){
    this.userSelectionsMap.set('none', 'None');
    this.userSelectionsMap.set('special', 'Special');
    this.userSelectionsMap.set('standard', 'Standard');
    this.userSelectionsMap.set('all', 'All');
  }

  changePage(currentPage){
    this.currentPage = currentPage;
    this.getTaskOfficialExportLogs();
  }

  changePageSize(pageSize){
    this.pageSize = pageSize;
    this.getTaskOfficialExportLogs();
  }

}
