import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class ProjectSystemsService {

    constructor(private http: HttpClient)
    {

    }

    updateProjectSystems(reason, projectNumber, SystemNumber, data)
    {
        const httpOptions = {
            params: {
                comment: reason['comment'],
                justificationCategory: reason['justificationCategory'] != null ? reason['justificationCategory'] : -1
            }
        };
        return this.http.put(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + SystemNumber + "/", data, httpOptions);
    }

    /**
     * Update project sysem variation
     * @param projectNumber
     * @param projectSystemId
     * @param data
     */
    updateProjectVariation(projectNumber, projectSystemId, data)
    {

        return this.http.put(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + projectSystemId + "/variation/", data);
    }

    /**
     * Get Individual Project System
     * @param projectNumber
     * @param systemNumber
     */
    getProjectSystem(projectNumber, systemNumber)
    {

        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/");
    }

    /**
     * Get Project System Related Lists
     * @param projectNumber
     * @param systemNumber
     */
    getProjectSystemRelatedLists(projectNumber, systemNumber)
    {

        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/related-lists");
    }

    /**
     * Get project systems by project
     */
    getProjectSystemByProject(projectNumber)
    {
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/systems/");
    }

    /**
     * Delete project systems
     * @param projectSystemId
     */
    deleteProjectSystem(res, projectSystemId, projectNumber)
    {
        const httpOptions = {
            params: {
                comment: res['comment'],
                justificationCategory: res['justificationCategory'] ? res['justificationCategory'] : -1
            }
        };
        return this.http.delete(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + projectSystemId, httpOptions);
    }

    exportProjectSystems(projectNumber){
        console.log("Trying to Export Project Systems of : " + projectNumber);
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/exportprojectsystems/", {responseType: 'blob'});
    }

}
