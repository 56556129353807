<div class="modal-header">
  <h5 class="modal-title pull-left">Add Operating Materials</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="addOperatingMaterialsForm" class="pr-4 pl-4">
  <div class="modal-body">
    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-5 col-form-label col-form-label-sm">Operating Material Category</label>
      <div class="col-sm-7">
        <ngx-select optionValueField="opMaterialsCategoryId" formControlName="opMaterialsCategoryId" [items]="operatingMaterialCategoryData" optionTextField="designationDe" [placeholder]="'Operating material Category'" appInfoPromptBox [page]="'masterOperatingMaterials'" [component]="'add'" [field]="'opMaterialsCategoryId'"></ngx-select>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputEmail3" class="col-sm-5 col-form-label col-form-label-sm">Stadler Article ID</label>
      <div class="col-sm-5">
        <input type="text" (keyup)="checkExistArticleId()" class="form-control  form-control-sm" id="inputEmail3" formControlName="stadlerArticleId" placeholder="Stadler Article ID" [appDecimal]="0" appMandatoryField [disabled]="isDummyArticle" [readonly]="isDummyArticle" appInfoPromptBox [page]="'masterOperatingMaterials'" [component]="'add'" [field]="'stadlerArticleId'">
        <span *ngIf="existArticleId" class="text-danger"><small>This Stadler article id already exist</small></span>
        <div *ngIf="addOperatingMaterialsForm.controls['stadlerArticleId'].invalid && (addOperatingMaterialsForm.controls['stadlerArticleId'].dirty || addOperatingMaterialsForm.controls['stadlerArticleId'].touched)" class="ng-validator">
          <div *ngIf="addOperatingMaterialsForm.controls['stadlerArticleId'].errors.required">
            This field is required.
          </div>
          <div *ngIf="addOperatingMaterialsForm.controls['stadlerArticleId'].value != '' && addOperatingMaterialsForm.controls['stadlerArticleId'].errors.valueZero">
            Should be Greater Than 0
          </div>
          <div *ngIf="addOperatingMaterialsForm.controls['stadlerArticleId'].errors.maxlength  || addOperatingMaterialsForm.controls['stadlerArticleId'].errors.minlength">
            Article ID length should be 8 characters
          </div>
          <div *ngIf="addOperatingMaterialsForm.controls['stadlerArticleId'].errors.max">
            Stadler Article IDs should be less than 30000000
          </div>
          <div *ngIf="addOperatingMaterialsForm.controls['stadlerArticleId'].errors.min">
            Dummy Stadler Article IDs should be greater than 30000000
          </div>
        </div>
      </div>
      <div class="col-sm-2 form-switch">
        <div class="form-check" style="padding-top: 0.3rem">
          <input class="form-check-input" type="checkbox" value="" [checked] = "isDummyArticle" id="dummyArticleId" (change)="dummyArticleCheckBoxClicked($event)" >
          <label class="form-check-label" for="dummyArticleId">
            dummy
          </label>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-5 col-form-label col-form-label-sm">Designation DE</label>
      <div class="col-sm-7">
        <input type="text" formControlName="designationDe" class="form-control form-control-sm" placeholder="Designation DE" appMandatoryField appInfoPromptBox [page]="'masterOperatingMaterials'" [component]="'add'" [field]="'designationDe'">
        <div *ngIf="addOperatingMaterialsForm.controls['designationDe'].invalid && (addOperatingMaterialsForm.controls['designationDe'].dirty || addOperatingMaterialsForm.controls['designationDe'].touched)" class="ng-validator">
          <div *ngIf="addOperatingMaterialsForm.controls['designationDe'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-5 col-form-label col-form-label-sm">Designation EN</label>
      <div class="col-sm-7">
        <input type="text" formControlName="designationEn" class="form-control form-control-sm" placeholder="Designation EN" appInfoPromptBox [page]="'masterOperatingMaterials'" [component]="'add'" [field]="'designationEn'">
      </div>
    </div>
    <div class="form-group row" [hidden]="true">
      <label for="inputPassword3" class="col-sm-5 col-form-label col-form-label-sm">Quantity Unit</label>
      <div class="col-sm-7">
        <ngx-select optionValueField="quantityUnitId" formControlName="quantityUnitId" [items]="quantityUnitList" optionTextField="designationDe" [placeholder]="'Quantity Unit'" appInfoPromptBox [page]="'masterOperatingMaterials'" [component]="'add'" [field]="'quantityUnitId'"></ngx-select>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-5 col-form-label col-form-label-sm">Comment</label>
      <div class="col-sm-7">
        <textarea formControlName="comment" class="form-control" appInfoPromptBox [page]="'masterOperatingMaterials'" [component]="'add'" [field]="'comment'"></textarea>
        <div *ngIf="addOperatingMaterialsForm.controls['comment'].invalid && (addOperatingMaterialsForm.controls['comment'].dirty || addOperatingMaterialsForm.controls['comment'].touched)" class="ng-validator">
          <div *ngIf="addOperatingMaterialsForm.controls['comment'].errors.maxlength">
            Maximum length is 10'000
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <div class="mandatory-description d-flex flex-row">
      <span class="mandatory-description-asterisk mandatory-asterisk">*</span>
      <span class="mandatory-field-text">mandatory fields</span>
      <span class="mandatory-description-conditional-asterisk conditional-mandatory-asterisk">*</span>
      <span class="mandatory-field-text">conditional mandatory fields</span>
    </div> -->
    <app-action-comment [btnLoading]="btnLoading" [okBtnName]="'Save'" [closeBtnName]="'Close'" [saveDisable]="addOperatingMaterialsForm.invalid || submitDisabled" [mandatoryDescriptionVisible]="true" [commentVisible]="false" [commentRequired]="false" [justificationCategoryVisible]="false" [justificationCategoryRequired]="false" (okClick)="addOperatingMaterials($event)" (cancelClick)="bsModalRef.hide()"></app-action-comment>
  </div>
</form>
