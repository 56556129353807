<div class="modal-header">
    <h5 class="modal-title pull-left">
        {{title}}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<br>
<br>

<div class="modal-body">
    <app-tree-table [settings]="settings" [data]="data" [currentPage]="currentPage" [pageCount]="pageCount" (pageChangeEvent)="changePage($event)" (pageSizeChangeEvent)="changePageSize($event);"></app-tree-table>
</div>

<div class="modal-footer">
    <button  *ngIf="itemType==='parts'" class="btn btn-primary" (click)="doComparison()" type="button">Compare Structures</button>
    <button class="btn btn-default" (click)="bsModalRef.hide()" type="button">Close</button>
</div>
