import {Directive, ElementRef, EventEmitter, Output} from '@angular/core';

@Directive({
    selector: '[appTextTruncate]'
})

//truncates tree cell text and show full text in a tool tip
export class TextTruncateDirective {

    @Output("getTruncatedText") sendResultEvent:EventEmitter<any> = new EventEmitter();

    readonly SPACE_PX = 30; // maximum divWidth - spanWidth
    readonly TOOL_TIP_MAX_LENGTH = 40; // angular material max tool tip length

    private toolTip = "";

    constructor(private elRef: ElementRef) {

    }

    ngAfterViewInit() {
        let result = this.makeTextShortIfLengthy(this.elRef);
        this.sendResultEvent.emit(result);
    }

    // makes text length shorten until divWidth - spanWidth > SPACE_PX and appends '...'
    makeTextShortIfLengthy(elRef: ElementRef) : {}|boolean{
        if(!elRef || !elRef.nativeElement) return false;

        let div = elRef.nativeElement;
        if(!div || !div.getBoundingClientRect()) return false;

        let span = div.querySelector("span");
        if(!span || !span.getBoundingClientRect()) return false;

        if(span.innerHTML == "") return  false;

        let divWidth = div.getBoundingClientRect().width;
        let spanWidth = span.getBoundingClientRect().width;
        let divHeight = div.getBoundingClientRect().height;
        let spanHeight = span.getBoundingClientRect().height;

        if(divWidth - spanWidth > this.SPACE_PX && divHeight > spanHeight) return false;

        let text = span.innerHTML.replace("&amp;", "&");
        // alert(text);
        let strLength = text.length;
        let removingText = "";
        // console.log("Text : " + text);
        // console.log("strLength : " + strLength);
        for(let i=0; i<strLength; i++){
            //Everytime the browser inserts '&amp;' instead of a '&', hence needed to remove it before processing.
            let newText = span.innerHTML.replace("&amp;", "&");
            let lastChar = newText[newText.length-1];

            // console.log("lastChar : " + lastChar.charCodeAt(0));
            if(lastChar.charCodeAt(0)==38){
                // console.log("Condition hit lastChar = '&'");
                removingText = removingText + lastChar;
                span.innerHTML = span.innerHTML.slice(0,-5);
            }else{
                removingText = removingText + lastChar;
                span.innerHTML = span.innerHTML.slice(0,-1);
            }

            // console.log("divWidth : " + divWidth);
            // console.log("span.getBoundingClientRect().width : " + span.getBoundingClientRect().width);

            // console.log(" divWidth - span.getBoundingClientRect().width > this.SPACE_PX : " + (divWidth - span.getBoundingClientRect().width > this.SPACE_PX));
            if(divWidth - span.getBoundingClientRect().width > this.SPACE_PX && divHeight > span.getBoundingClientRect().height) break;
            // if(divWidth - span.getBoundingClientRect().width > this.SPACE_PX) break;

        }

        this.getToolTip(text);

        span.innerHTML = span.innerHTML.slice(0,-1); //removes one more char to remove new line char if exist...
        span.innerHTML += "...";
        // console.log("Original Text : " + text);
        // console.log("Display Text : " + span.innerHTML);
        // console.log("divWidth : " + divWidth);
        // console.log("span.getBoundingClientRect().width : " + span.getBoundingClientRect().width);

        let result = {
            isTruncated: true,
            toolTip: this.toolTip
        };

        return result;
    }

    // returns full text for tool tip
    getToolTip(text:string){
        if(text.length < this.TOOL_TIP_MAX_LENGTH){
            this.toolTip = this.toolTip + text;
            return;
        }
        let lastSpace = -1;
        for(let i=0; i<text.length; i++){
            if(text[i] == " ") lastSpace = i;

            if(i == this.TOOL_TIP_MAX_LENGTH){
                let splitIndex = lastSpace < 0 ? this.TOOL_TIP_MAX_LENGTH : lastSpace+1;
                this.toolTip = this.toolTip + text.substr(0, splitIndex) + "\n";
                this.getToolTip(text.substr(splitIndex, text.length-splitIndex));
                return;
            }
        }
        return;
    }
}
