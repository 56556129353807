import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskDbidChangeComponent } from './task-dbid-change/task-dbid-change.component';
import { TaskDbidChangeRoutingModule } from './task-dbid-change-routing.module';
import { NgxSelectModule } from 'ngx-select-ex';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from 'src/app/shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LaddaModule } from 'angular2-ladda';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TreeTableModule } from 'src/app/shared/components/tree-table/tree-table.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
  declarations: [
    TaskDbidChangeComponent
  ],
  imports: [
    CommonModule,
    TaskDbidChangeRoutingModule,
    NgxSelectModule,
    NgxPaginationModule,
    FormsModule,
    SharedModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    LaddaModule,
    ModalModule,
    TreeTableModule,
    MatProgressSpinnerModule
  ]
})
export class TaskDbidChangeModule { }
