import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormArray, FormBuilder, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProjectSystemTasksService } from 'src/app/services/project-system-tasks.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import {
  currencyList,
  getTaskActivatedList,
  taskInfluence,
  taskIsNonRecurring,
  taskMaintainanceLevel,
  taskNature,
  taskPPTypes,
  validateForm
} from 'src/app/shared/helper';
import { TaskTypeService } from 'src/app/services/task-type.service';
import { TaskSuffixService } from 'src/app/services/task-suffix.service';
import { EmployeeQualificationService } from 'src/app/services/employee-qualification.service';
import { EmployeeTypeService } from 'src/app/services/employee-type.service';
import { InfrastructureService } from 'src/app/services/infrastructure.service';
import { TaskSourceService } from 'src/app/services/task-source.service';
import { TaskStageService } from 'src/app/services/task-stage.service';
import { TargetManualService } from 'src/app/services/target-manual.service';
// import {TaskSourceStandardService} from 'src/app/services/task-source-standard.service';
import { QuantityUnitServiceService } from 'src/app/services/quantity-unit-service.service';
import { ProfileUnitsService } from 'src/app/services/profile-units.service';
import { TaskIntervalService } from 'src/app/services/task-interval.service';
import { ProjectSystemsService } from 'src/app/services/project-systems.service';
import { ProjectService } from "../../../../services/project.service";
import { LanguageServiceService } from "../../../../services/language-service.service";
import { LanguageSupportComponent } from "../../../../shared/components/language-support/language-support-component.service";
import { ProjectSystemSparePartsService } from "../../../../services/project-system-spare-parts.service";
import { MountingLocationService } from 'src/app/services/mounting-location.service';
import { MaintenanceLocationService } from 'src/app/services/maintenance-location.service';
import { OverallSystemsAndTasksService } from "../../../../services/overall-systems-and-tasks.service";
import { OperatingProfilesService } from 'src/app/services/operating-profiles.service';

@Component({
  selector: 'app-project-system-task-add',
  templateUrl: './project-system-task-add.component.html',
  styleUrls: ['./project-system-task-add.component.scss']
})
export class ProjectSystemTaskAddComponent extends LanguageSupportComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();
  @ViewChild('ppTasksDropdown') ppTasksDropdown: ElementRef;
  @ViewChild('cmTasksDropdown') cmTasksDropdown: ElementRef;

  param: any;
  laddLoading = false;
  onClose: Subject<any>;
  taskNatureData: any = taskNature();
  taskIsNonRecurringData: any = taskIsNonRecurring();
  taskMaintainanceLevelData: any = taskMaintainanceLevel();
  taskTypeData: any = [];
  finalIntervalData: any = [];
  invalidIntervals: Boolean = false;
  invalidMaintenanceLocation: Boolean = false;
  intervalMismatchA: Boolean = false;
  intervalMismatchB: Boolean = false;
  qualificationData: any = [];
  employeeTypeData: any = [];
  taskNameReadOnly: boolean = false;
  taskSuffixData: any = [];
  infrastructureData: any = [];
  maintenanceLocationData: any = [];

  taskSourceData: any = [];
  taskStagesData: any = [];
  targetManualData: any = [];
  currencyList: any = [];
  showTaskStageWarning: boolean = false;
  showMaintenanceLevelWarning: boolean = false;
  projectSystemId: Number;
  projectNumber: any;
  originalTaskQuantity: any;
  taskStandardData: any = [];
  prePostProcessingTaskData: any = [];
  preProcessingTaskData: any = [];
  ppTypeData: any = taskPPTypes();
  selectedPPTasks: any = [];
  selectedPreTaskCount = 0;
  currentSelectedPPTask: any = -1;
  infrastructureArray: any = [];
  taskTagList: any = [];
  maintenanceLocationArray: any = [];
  relatedCmTasks: any = [];
  taskInfluenceData: any = taskInfluence();
  taskManualId: any;
  isTaskActivatedList: any = getTaskActivatedList();
  taskElementData: any = [];
  systemPartData: any = new Map();
  systemVariation: any;
  taskElementStr: any = "";
  taskActivity: any = "";
  showTaskActivityWarning: boolean = false;
  projectFreezeFunction = "";
  isValidExecutionTime: boolean = false;
  isExecutionTimeEmpty: boolean = true;
  projectVehicleQuantity: any = 0;
  firstTime: boolean = true;
  executionTimeError: any = [];
  validExecutionTime: Boolean = true;
  btnLoading: Boolean = false;
  isExecutionTimeSourceValid: Boolean = true;
  onTrainSelected:Boolean = true;

  projectSystemTaskForm = new UntypedFormGroup(
    {
      projectNumber: new UntypedFormControl(''),
      systemNumber: new UntypedFormControl(''),
      taskName: new UntypedFormControl('', [Validators.required, Validators.maxLength(500)]),
      duration: new UntypedFormControl(''),
      lionkedTaskNumber: new UntypedFormControl(''),
      taskNature: new UntypedFormControl('', [Validators.required]),
      projectSystemId: new UntypedFormControl(),
      isNonRecurring: new UntypedFormControl('', [Validators.required]),
      maintenanceLevel: new UntypedFormControl('', [Validators.required]),
      tskMaintenanceLocation: new UntypedFormControl('', [Validators.required]),
      // executionTime: new UntypedFormControl('', [Validators.max(2147483647)]),
      executionTimeSource: new UntypedFormControl('', [Validators.maxLength(100)]),
      quantityWorkers: new UntypedFormControl(''),
      externalServiceDescription: new UntypedFormControl('', [Validators.maxLength(255)]),
      externalServiceCost: new UntypedFormControl('', Validators.max(999999.99)),
      externalServiceCurrency: new UntypedFormControl(),
      taskTypeId: new UntypedFormControl('', [Validators.required]),
      taskId: new UntypedFormControl(''),
      taskElement: new UntypedFormControl('systemVariationName', [Validators.required]),
      individualTaskElement: new UntypedFormControl({ value: '', disabled: true }),
      taskSuffixId: new UntypedFormControl(1),
      employeeQualificationId: new UntypedFormControl('',),
      specificEmployeeQualification: new UntypedFormControl('', [Validators.maxLength(255)]),
      employeeTypeId: new UntypedFormControl('', [Validators.required]),
      spareActualQuantity: new UntypedFormControl(''),
      operatingMaterialsId: new UntypedFormControl(''),
      operationalMaterialQuantity: new UntypedFormControl(''),
      toolsId: new UntypedFormControl(''),
      taskSourceId: new UntypedFormControl('', [Validators.required]),
      taskSourceStandard: new UntypedFormControl('', [Validators.maxLength(255)]),
      specificTaskReason: new UntypedFormControl({ value: '', disabled: true }, [Validators.maxLength(255)]),
      taskStageId: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
      taskInfluenceId: new UntypedFormControl('', [Validators.required]),
      fmecaId: new UntypedFormControl('', [Validators.maxLength(1000)]),
      taskSracId: new UntypedFormControl('', [Validators.maxLength(1000)]),
      targetManualId: new UntypedFormControl(1, [Validators.required]),
      supplierDocReference: new UntypedFormControl('', [Validators.maxLength(1000)]),
      differenceToSupplierDoc: new UntypedFormControl('', [Validators.maxLength(1000)]),
      taskDescription: new UntypedFormControl('', [Validators.maxLength(10000)]),
      commentsDocDepartment: new UntypedFormControl('', [Validators.maxLength(10000)]),
      commentsRamEngineer: new UntypedFormControl('', [Validators.maxLength(10000)]),
      ppTasksOfNonPpTask: new UntypedFormControl([]),
      ppTasksOfNonPpTaskOrder: new UntypedFormControl('[]'),
      ppType: new UntypedFormControl(''),
      ppTaskOfPpTask: new UntypedFormControl(''),
      relatedCmTask: new UntypedFormControl([]),
      relatedPpTask: new UntypedFormControl(''),
      taskLocationId: new UntypedFormControl(''),
      linkedTaskNumber: new UntypedFormControl(''),
      taskQuantity: new UntypedFormControl('', [Validators.required, Validators.max(2147483647)]),
      taskQuantityPerVehicle: new UntypedFormControl(1, [Validators.required, Validators.max(2147483647)]),
      taskQuantityPerFleet: new UntypedFormControl({ value: '', disabled: true }, [Validators.required, Validators.max(2147483647)]),
      isPerFleetQuantity: new UntypedFormControl(false),
      intervalvalue1: new UntypedFormControl('', [Validators.required, Validators.min(1), Validators.max(99999999)]),
      intervalvalue2: new UntypedFormControl('', [Validators.required, Validators.min(1), Validators.max(99999999)]),
      intervalunit1: new UntypedFormControl('', [Validators.required]),
      intervalunit2: new UntypedFormControl('', [Validators.required]),
      isTaskActivated: new UntypedFormControl(1),
      tskInfrastructure: new UntypedFormControl([]),
      taskTags: new UntypedFormControl([]),
      projectSystem: new UntypedFormControl(),
      bufferField01: new UntypedFormControl('', [Validators.maxLength(1000)]),
      bufferField02: new UntypedFormControl('', [Validators.maxLength(1000)]),
      bufferField03: new UntypedFormControl('', [Validators.maxLength(1000)]),
      bufferField04: new UntypedFormControl('', [Validators.maxLength(1000)]),
      bufferField05: new UntypedFormControl('', [Validators.maxLength(1000)]),
      bufferField06: new UntypedFormControl('', [Validators.maxLength(1000)]),
      bufferField07: new UntypedFormControl('', [Validators.maxLength(1000)]),
      bufferField08: new UntypedFormControl('', [Validators.maxLength(1000)]),
      executionTime: new UntypedFormArray([]),
      waitingTime: new UntypedFormControl(''),
    }
  );

  taskRelatedListsLoading: Boolean = true;
  projectSystem: any;
  projectSystemSpareparts: any;
  projectSystemMaintenceLocations: any;
  taskStageLoading: Boolean = false;
  

  taskTypeDataLoaded: boolean = false;
  taskSuffixDataLoaded: boolean = false;
  taskStagesDataLoaded: boolean = false;
  preProcessingTaskDataLoaded: boolean = false;
  qualificationDataLoaded: boolean = false;
  infrastructureDataLoaded: boolean = false;
  employeeTypeDataLoaded: boolean = false;
  taskSourceDataLoaded: boolean = false;
  targetManualDataLoaded: boolean = false;
  taskTagListLoaded: boolean = false;
  maintenanceLocationsDataLoaded: boolean = false;
  intervalsDataLoaded: boolean = false;
  defaultMaintenanceLocationsLoaded: boolean = false;
  projectSystemDataLoaded: boolean = false;
  systemPartsDataLoaded: boolean;
  projectSpecificEmployeeandVehicleDataLoaded: boolean;

  prevSearchPP: String = "";
  prevSearchCM: String = "";
  prePostProcessingTaskDataLoading: Boolean = false;
  cmTaskDataLoading: Boolean = false;


  constructor(
    public bsModalRef: BsModalRef,
    private projectSystemTaskService: ProjectSystemTasksService,
    private notificationService: ToastrService,
    private taskTypeService: TaskTypeService,
    private taskSuffixService: TaskSuffixService,
    private employeeQualificationService: EmployeeQualificationService,
    private employeeTypeService: EmployeeTypeService,
    private infrastructureService: InfrastructureService,
    private maintenanceLocationService: MaintenanceLocationService,
    private mountingLocationService: MountingLocationService,
    private taskSourceService: TaskSourceService,
    private taskStageService: TaskStageService,
    private targetManualService: TargetManualService,
    // private taskStandardService: TaskSourceStandardService,
    private quantiyUnitService: QuantityUnitServiceService,
    private profileUnitService: ProfileUnitsService,
    private taskIntervalService: TaskIntervalService,
    private projectSystemService: ProjectSystemsService,
    private projectService: ProjectService,
    private overallSystemsAndTasksService: OverallSystemsAndTasksService,
    public languageService: LanguageServiceService,
    public modalService: BsModalService,
    private projectSystemSparePartsService: ProjectSystemSparePartsService,
    private operatingProfileService: OperatingProfilesService
  ) {
    super(languageService, modalService);
    this.onClose = new Subject();
    this.addNewExecutionTimeField();
  }


  ngOnInit(): void {
    console.log(this.param);
    this.projectSystemId = this.param.systemNumber;
    this.projectNumber = this.param.projectNumber;

    this.projectSystemTaskForm.patchValue({
      projectNumber: this.param.projectNumber,
      projectSystemId: this.projectSystemId,
    });

    this.getTaskRelatedLists();

    // this.getAllMaintenanceLocation();
    // this.getTaskSuffix();
    // this.getQualification();
    // this.getAllEmployeeType();
    // this.getAllInfrastructure();

    // this.getAllTaskSource();
    // this.getAllTaskStages();
    // this.getAllTargetManual();

    // this.getAllTaskSourceStandard();
    // this.getIntervalUnit1();
    // this.getProjectSystemByProjectSystemid();
    // this.getSystemParts();
    // this.getTaskTagList();

    this.getProjectSpecificEmployeeActiveStatusAndVehicleQuantity();
    // this.getDefatltMaintenanceLocations(this.projectSystemId);
    // this.executionTimeChanged();
    // this.loadTaskElementList();
    this.loadCurrencyList();

    this.isExecutionTimeEmpty = true;
    // this.projectSystemTaskForm.controls['executionTimeSource'].disable();

    validateForm(this.projectSystemTaskForm);
    this.isTaskActivatedList = this.isTaskActivatedList.filter((status) => status.id == 0 || status.id == 1);

  }

  public getTaskRelatedLists(){
    this.projectSystemTaskService.getTaskRelatedLists(this.projectNumber, this.projectSystemId).subscribe(res => {
      if(res){
        this.maintenanceLocationData = res['mountingLocations'];
        this.taskSuffixData = res['taskSuffixes'];
        this.qualificationData = res['qualifications'];
        this.employeeTypeData = res['employeeTypes'];
        this.infrastructureData = res['infrastructures'];
        this.taskSourceData = res['taskSources'];
        this.taskStagesData = res['taskStages'];
        // this.prePostProcessingTaskData = res['ppcmTasks'];
        this.targetManualData = res['targetManuals'];
        this.finalIntervalData = res['profileUnits'];
        this.projectSystem = res['projectSystem'];
        this.projectSystemSpareparts = res['projectSystemSpareparts'];
        this.taskTagList = res['taskTags'];
        this.projectSystemMaintenceLocations = res['systemMountingLocations'];
        // this.relatedCmTasks = res['cmTasks'];
        
        

      }
    }, (error) => {}, () => {
      this.afterLoadingTasks();
      this.taskRelatedListsLoading = false;
    });
  }


  public afterLoadingTasks(){
    this.selectTaskStagebyNature();

    this.preProcessingTaskData = [];
    Object.entries(this.prePostProcessingTaskData).forEach(([key, task]) => {
      let ppTaskText = task['taskId'] + " " + task['taskElementStr'] + "-" + task['taskName'];
      task['ppTaskText'] = ppTaskText;
      if (task['taskNature'] == 'CM') task['ppType'] = 'PRE';

      if (task['ppType'] == 'PRE') this.preProcessingTaskData.push(task);
    });

    let units = this.finalIntervalData;
    Object.keys(units).forEach(item => {
      if ((units[item].taskUnitAddition != '' && units[item].taskUnitAddition != null)) {
        units[item].taskUnit = units[item].taskUnit + '-' + units[item].taskUnitAddition;
      } 
    });

    let ps = this.projectSystem;
    this.systemVariation = ps['systemVariation'];
    let projectSystemCode = ps['systemVariation'] + '-' + ps['systemVariationNumber'];
    let systemQuantity = ps['systemQuantity'];
    this.projectSystemTaskForm.patchValue({
      projectSystem: projectSystemCode,
      taskQuantityPerVehicle: systemQuantity
    });
    this.originalTaskQuantity = systemQuantity;
    
    this.taskElementData.push(
      { name: this.systemVariation, id: 'systemVariationName' }
    );
    this.taskElementData.push(
      { name: "Individual", id: 'individual' }
    );
    
    console.log("Project System Spare Parts");
    // console.log(this.projectSystemSpareparts);
    Object.entries(this.projectSystemSpareparts).forEach(([key, value]) => {
      value['text'] = value['spareDesignation'] + " - ";
      value['text'] += value['additionalDesignation'] ? value['additionalDesignation'] + ' - ' : "";
      value['text'] += value['spareStadlerArticleId'];
      this.systemPartData.set(value['spareNumber'].toString(), value);

      let option = {
        name: value['text'],
        id: value['spareNumber'].toString()
      }
      this.taskElementData.push(option);
    });

    Object.entries(this.projectSystemMaintenceLocations).forEach(([key, value]) => {
      this.maintenanceLocationArray.push(value['mountingLocationId']);
    });
    this.projectSystemTaskForm.patchValue({
      tskMaintenanceLocation: this.maintenanceLocationArray
    });

    //Save Initial Form Data
    super.saveInitialValues(JSON.stringify(this.projectSystemTaskForm.getRawValue()));
  }

  /**
   * Get Execution time feild
   */
  get executionTimeField(): FormArray {
    return this.projectSystemTaskForm.get('executionTime') as FormArray;
  }



  /**
   * Add new Execution time Field to Form
   */
  addNewExecutionTimeField(): void {
    this.executionTimeField.push(new UntypedFormBuilder().control(null));
    let executionTimeArray = this.projectSystemTaskForm.getRawValue()['executionTime'];
    this.validateExecutionTime(executionTimeArray);


  }

  /**
   * Remove Additional Execution time field
   * @param index 
   */
  removeExecutionTime(index: number): void {
    this.executionTimeField.removeAt(index);
    let executionTimeArray = this.projectSystemTaskForm.getRawValue()['executionTime'];
    this.validateExecutionTime(executionTimeArray);
  }

  /**
   * Check Execution time field is Empty or not
   * @param data 
   */
  // validateExecutionTime(data) {
  //   this.validExecutionTime = true;
  //   if (data == null || data == undefined || data == '') {
  //     //DO Nothing
  //     this.isExecutionTimeEmpty = true;
  //     this.projectSystemTaskForm.controls['quantityWorkers'].setValue(0);
  //   }
  //   else {
  //     if (data.length <= 1) {
  //       if (data[0] == null || data[0] == "") {
  //         this.validExecutionTime = true;
  //         this.executionTimeError[0] = false;
  //       } else {
  //         if (data[0] > 0) {
  //           this.validExecutionTime = true;
  //           this.executionTimeError[0] = false;
  //         } else {
  //           this.validExecutionTime = false;
  //           this.executionTimeError[0] = true;
  //         }
  //       }

  //     } else {
  //       for (let i = 0; i < data.length; i++) {
  //         if ((data[i] == null || data[i] == "")) {
  //           this.executionTimeError[i] = true;
  //           this.validExecutionTime = false;
  //         } else {
  //           if (data[i] > 0) {
  //             this.executionTimeError[i] = false;
  //             this.validExecutionTime = true;
  //           } else {
  //             this.executionTimeError[i] = true;
  //             this.validExecutionTime = false;
  //           }

  //         }
  //       }
  //     }

  //     if (data[0] != null) {
  //       this.isExecutionTimeEmpty = false;
  //       this.validateExecutionTimeSource();
  //     } else {
  //       this.isExecutionTimeEmpty = true;
  //     }

  //   }

  //   if(data == null || data == undefined || data == '' || data.length == 0 || data[0] == null || data[0] == undefined || data[0] == '' || data[0] == '0' || data[0] == 0){
  //     this.projectSystemTaskForm.controls['quantityWorkers'].setValue(0);
  //   }else{
  //     this.projectSystemTaskForm.controls['quantityWorkers'].setValue(data?data.length:0);
  //   }

  //   // console.log("Execution Time Validity");
  //   // console.log(this.executionTimeError);

  // }
  validateExecutionTime(data:any[]){
    console.log("Checking Values of DATA");
    console.log(data);
    console.log(data.length);
    console.log(data[0]);
    this.validExecutionTime=true;
    if(data == null || data == undefined ) {
      //DO Nothing
      this.isExecutionTimeEmpty = true;
      this.projectSystemTaskForm.controls['quantityWorkers'].setValue(0);
    }
    else {
    if(data.length <= 1){
      if(data[0] == null || data[0] == ""){
        this.executionTimeError[0] = 0;
      }else{
        if(parseFloat(data[0]) >= 0.1){
          this.executionTimeError[0] = 0;
        }else{
          this.executionTimeError[0] = 1;
        }
      }
      
    }else{
      
      for (let i = 0; i < data.length; i++) {
        if((data[i]==null || data[i] == "") ){
          this.executionTimeError[i] = 2;
          this.validExecutionTime=false;
        }else{
          if(parseFloat(data[i]) >= 0.1){
            this.executionTimeError[i] = 0;
          }else{
            this.executionTimeError[i] = 1;
            this.validExecutionTime=false;
          }
          if(parseFloat(data[i]) > parseFloat(data[0])){
            this.executionTimeError[i] = 3;
            this.validExecutionTime=false;
          }
        }
      }
    }
    console.log("Checking ExecutionTIME Validation Errors");
    console.log(this.executionTimeError);
    console.log(this.validExecutionTime);
    
    if(data[0]!=null ){
      this.isExecutionTimeEmpty = false;
      this.validateExecutionTimeSource();
    }else{
      this.isExecutionTimeEmpty = true;
    }
    
  }
  
  if(data == null || data == undefined || data.length == 0 || data[0] == null || data[0] == undefined || data[0] == '' || data[0] == '0' || data[0] == 0){
    this.projectSystemTaskForm.controls['quantityWorkers'].setValue(0);
  }else{
    this.projectSystemTaskForm.controls['quantityWorkers'].setValue(data?data.length:0);
  }
    
  }

  /**
   * Validate Execution Time field
   */
  validateExecutionTimeSource() {
    let executionTimeSource = this.projectSystemTaskForm.getRawValue()['executionTimeSource'];

    if (!this.isExecutionTimeEmpty) {
      if (executionTimeSource == '') {
        this.isExecutionTimeSourceValid = false;
      } else {
        this.isExecutionTimeSourceValid = true;
      }

      console.log('valid_time', this.isExecutionTimeSourceValid)
    }


  }

  /**
   * Validate Execution time field value when change the value
   * @param index 
   */
  updateExecutionTime(index) {
    let executionTimeArray = this.projectSystemTaskForm.getRawValue()['executionTime'];
    this.validateExecutionTime(executionTimeArray);
  }



  /**
   * Sybmit New Project System Task Modal
   */
  createProjectSystemTask(event) {
    this.btnLoading = true;
    if (this.projectSystemTaskForm.valid) {
      this.laddLoading = true;

      let ppTaskArray = [];
      let i = 1;
      let ppTaskList = Object();
      for (let ppTask of this.selectedPPTasks) {
        ppTaskList[ppTask['taskDbId']] = i;
        i++;
        // ppTaskArray.push(ppTask['taskDbId'])
      }
      console.log(ppTaskList);

      this.projectSystemTaskForm.controls['ppTasksOfNonPpTask'].patchValue(ppTaskList);

      let formValue = this.projectSystemTaskForm.getRawValue();
      let taskInfrastructureInput = formValue.tskInfrastructure;
      delete formValue.tskInfrastructure;

      if (taskInfrastructureInput.length > 0) {
        for (var x = 0; x < taskInfrastructureInput.length; x++) {
          this.infrastructureArray.push({ infrastructureId: taskInfrastructureInput[x] });
        }
      }

      formValue['isPerFleetQuantity'] = this.projectSystemTaskForm.controls['isPerFleetQuantity'].value ? 1 : 0;

      this.maintenanceLocationArray = [];
      // alert(this.maintenanceLocationArray.length);
      // alert(formValue.tskMaintenanceLocation.length);
      if (formValue.tskMaintenanceLocation.length > 0) {
        for (let x = 0; x < formValue.tskMaintenanceLocation.length; x++) {
          this.maintenanceLocationArray.push({ maintenanceLocationId: formValue.tskMaintenanceLocation[x] });
          // console.log("" + x + " : " + formValue.tskMaintenanceLocation[x]) ;
        }
      }
      // alert(this.maintenanceLocationArray.length);


      Object.entries(formValue).forEach(([key, value]) => {
        if (value == null) formValue[key] = '';
      });

      let taskTags = formValue.taskTags;
      let taskTagsArray = [];
      for (let taskTagId of taskTags) {
        for (let taskTag of this.taskTagList) {
          if (taskTag['tagId'] == taskTagId) taskTagsArray.push(taskTag);
        }
      }
      formValue['taskTags'] = taskTagsArray;

      formValue['taskElementStr'] = this.taskElementStr;
      formValue['taskActivity'] = this.taskActivity;
      formValue['isValidExecutionTime'] = (this.isExecutionTimeEmpty ? 0 : (this.isValidExecutionTime ? 1 : 0));
      formValue['executionTimeSource'] = (this.isExecutionTimeEmpty ? '' : formValue['executionTimeSource']);

      // formValue['ppTasksOfNonPpTaskOrder'] = JSON.stringify(formValue['ppTasksOfNonPpTask']);
      // formValue['ppTasksOfNonPpTaskOrder'] = JSON.stringify(Object.keys(formValue['ppTasksOfNonPpTask']));

      let inputValue = formValue['ppTasksOfNonPpTask'];
      let outputValue = Object.keys(inputValue)
      .sort((a, b) => inputValue[a] - inputValue[b])
      .map(Number);
      formValue['ppTasksOfNonPpTaskOrder'] = JSON.stringify(outputValue);
      console.log("Printing PP Task Order : " + formValue['ppTasksOfNonPpTaskOrder']);
      

      if (formValue['executionTime'][0] == null) {

        formValue['executionTime'] = [];
      }

      this.projectSystemTaskService.createProjectSystemTasks(event, formValue, formValue['projectNumber'], this.projectSystemId).subscribe(res => {
        if (res) {
          let taskDbId = res['taskDbId'];

          let formData = [
            {
              taskDbId: taskDbId,
              intervalUnit: formValue['intervalunit1'],
              intervalValue: formValue['intervalvalue1'],
            },
            {
              taskDbId: taskDbId,
              intervalUnit: formValue['intervalunit2'],
              intervalValue: formValue['intervalvalue2'],
            }
          ];

          this.taskIntervalService.createBulkTaskInterval(formData, taskDbId).subscribe(res => {
            this.event.emit();
          });

          this.infrastructureService.createTaskInfrastructure(this.infrastructureArray, taskDbId).subscribe(res => {
            this.laddLoading = false;
            // this.notificationService.success('Task Infrastructures were Created', 'Success');
            this.onClose.next(true);
          });

          this.maintenanceLocationService.createTaskMaintenanceLocation(this.maintenanceLocationArray, taskDbId).subscribe(res => {
            this.laddLoading = false;
            // this.notificationService.success('Task Maintenance Locations were Created', 'Success');
            this.onClose.next(true);
          });
          this.notificationService.success('Project System Task created Successfully', 'Success');
          this.btnLoading = false;
        } else {
          this.notificationService.error('Project System task has not been Created', 'Error');
          this.btnLoading = false;
        }
      },
        (error) => {
          if (error.status == 403) {
            this.btnLoading = false;
            this.notificationService.error('You need to be in the Project Admins Group to perform the current Action', 'Error');
          } else {
            this.btnLoading = false;
            this.notificationService.error('Project System task has not been Created', 'Error');
          }
        },
        () => {
          this.btnLoading = false;
          this.laddLoading = false;
          this.bsModalRef.hide();
          this.event.emit();
        }
      );

    } else {
      validateForm(this.projectSystemTaskForm);
      this.btnLoading = false;
    }
  }

  /**
   * Manage  Employee Qualification with Maintainance Level
   * @param id
   */
  // changeMaintainanceLevel(id) {
  //   this.showMaintenanceLevelWarning = (id == 3);
  //     this.getProjectSpecificEmployeeActiveStatusAndVehicleQuantity();

  // }

  changeMaintainanceLevel(id) {


    this.showMaintenanceLevelWarning = (id == 3);
    this.getProjectSpecificEmployeeActiveStatusAndVehicleQuantity();


    if (id == 1) {
      this.onTrainSelected = true;
      console.log('ON-TRAIN Selected');
      if (!this.firstTime) {
        this.projectSystemTaskForm.controls['tskMaintenanceLocation'].patchValue([]);
      }
      this.projectSystemTaskForm.controls['tskMaintenanceLocation'].enable();
    } else {
      this.onTrainSelected = false;
      this.projectSystemTaskForm.controls['tskMaintenanceLocation'].patchValue([19]);
      this.projectSystemTaskForm.controls['tskMaintenanceLocation'].disable();
    }

    this.checkMaintenanceLevelAndLocation();
    this.firstTime = false;
  }

  changeMaintainanceLocation(id) {
    this.checkMaintenanceLevelAndLocation();
    // if(id == 19){
    //   //Workstation Selected
    //   let formValue = this.projectSystemTaskForm.getRawValue();
    //   if(formValue['taskMaintainanceLevel'] == 1){
    //     this.invalidMaintenanceLocation = true;
    //   }
    // }else{
    //   this.invalidMaintenanceLocation = false;
    // }
  }

  checkMaintenanceLevelAndLocation() {
    let formValue = this.projectSystemTaskForm.getRawValue();
    console.table(formValue['tskMaintenanceLocation']);
    console.table(formValue['maintenanceLevel']);
    var selectedMaintenanceLevel = formValue['maintenanceLevel'];
    var selectedMaintenanceLocations = formValue['tskMaintenanceLocation'];

    this.invalidMaintenanceLocation = (selectedMaintenanceLevel == 1) && (selectedMaintenanceLocations.includes(19));

  }

  changeQuantities() {
    let isPerFleetQuantity = this.projectSystemTaskForm.controls['isPerFleetQuantity'].value;
    if (isPerFleetQuantity) {
      let taskQuantityPerFleet = this.projectSystemTaskForm.controls['taskQuantityPerFleet'].value;
      this.projectSystemTaskForm.controls['taskQuantityPerVehicle'].patchValue((taskQuantityPerFleet / this.projectVehicleQuantity).toFixed(3));
      this.projectSystemTaskForm.controls['taskQuantity'].patchValue(taskQuantityPerFleet);
      this.projectSystemTaskForm.controls['taskQuantityPerFleet'].enable();
      this.projectSystemTaskForm.controls['taskQuantityPerVehicle'].disable();
    }
    else {
      let taskQuantityPerVehicle = Math.round(this.projectSystemTaskForm.controls['taskQuantityPerVehicle'].value);
      this.projectSystemTaskForm.controls['taskQuantityPerVehicle'].patchValue(taskQuantityPerVehicle);
      this.projectSystemTaskForm.controls['taskQuantityPerFleet'].patchValue(taskQuantityPerVehicle * this.projectVehicleQuantity);
      this.projectSystemTaskForm.controls['taskQuantity'].patchValue(taskQuantityPerVehicle);
      this.projectSystemTaskForm.controls['taskQuantityPerVehicle'].enable();
      this.projectSystemTaskForm.controls['taskQuantityPerFleet'].disable();
    }
    this.projectSystemTaskForm.controls['taskQuantity'].updateValueAndValidity();
    this.projectSystemTaskForm.controls['taskQuantityPerVehicle'].updateValueAndValidity();
    this.projectSystemTaskForm.controls['taskQuantityPerFleet'].updateValueAndValidity();
  }

  /**
   *Validate Profile Units
   */
  validateIntervalUnits() {
    let values = this.projectSystemTaskForm.getRawValue();

    let inputA = values['intervalvalue1'];
    let selectA = values['intervalunit1'];
    let inputB = values['intervalvalue2'];
    let selectB = values['intervalunit2'];
    let taskNature = values['taskNature'];
    let valueUnitA = "";
    let valueUnitB = "";
    let projectProfileUnit = "";

    valueUnitA = (selectA != null && selectA != '' && selectA.indexOf("-") > 0) ? selectA.substring(selectA.indexOf("-") + 1) : "";
    valueUnitB = (selectB != null && selectB != '' && selectB.indexOf("-") > 0) ? selectB.substring(selectB.indexOf("-") + 1) : "";

    selectA = (selectA != null && selectA != '' && selectA.indexOf("-") > 0) ? selectA.substring(0, selectA.indexOf("-")) : selectA;
    selectB = (selectB != null && selectB != '' && selectB.indexOf("-") > 0) ? selectB.substring(0, selectB.indexOf("-")) : selectB;

    this.projectSystemTaskForm.controls['intervalvalue1'].clearValidators();
    this.projectSystemTaskForm.controls['intervalvalue2'].clearValidators();
    this.projectSystemTaskForm.controls['intervalunit1'].clearValidators();
    this.projectSystemTaskForm.controls['intervalunit2'].clearValidators();

    if ((taskNature == 'PM')) {
      this.invalidIntervals = (selectA != null && selectB != null && selectA != '' && selectB != '' && selectA == selectB);

      if (selectA != '' && selectA != null) {
        this.operatingProfileService.getOperatingProfileByID(selectA).subscribe(res => {
          projectProfileUnit = res["valueUnit"];
          console.log("Operating Profile Loaded : " + projectProfileUnit);

          if (valueUnitA != "" && valueUnitA != projectProfileUnit) {
            this.intervalMismatchA = true;
          } else {
            this.intervalMismatchA = false;
          }

        });
      }

      if (selectB != '' && selectB != null) {
        this.operatingProfileService.getOperatingProfileByID(selectB).subscribe(res => {
          projectProfileUnit = res["valueUnit"];
          console.log("Operating Profile Loaded : " + projectProfileUnit);

          if (valueUnitB != "" && valueUnitB != projectProfileUnit) {
            this.intervalMismatchB = true;
          } else {
            this.intervalMismatchB = false;
          }

        });
      }

      if (inputA != null && inputA != '') {
        this.projectSystemTaskForm.controls['intervalunit1'].setValidators([Validators.required]);
        this.projectSystemTaskForm.controls['intervalvalue1'].setValidators([Validators.min(1), Validators.max(99999999)]);
      } else if (selectA != null && selectA != '') {
        this.projectSystemTaskForm.controls['intervalvalue1'].setValidators([Validators.required, Validators.min(1), Validators.max(99999999)]);
      } else {
        this.intervalMismatchA = false;
      }

      if (inputB != null && inputB != '') {
        this.projectSystemTaskForm.controls['intervalunit2'].setValidators([Validators.required]);
        this.projectSystemTaskForm.controls['intervalvalue2'].setValidators([Validators.min(1), Validators.max(99999999)]);
      } else if (selectB != null && selectB != '') {
        this.projectSystemTaskForm.controls['intervalvalue2'].setValidators([Validators.required, Validators.min(1), Validators.max(99999999)]);
      } else {
        this.intervalMismatchB = false;
        this.projectSystemTaskForm.controls['intervalvalue1'].setValidators([Validators.required, Validators.min(1), Validators.max(99999999)]);
        this.projectSystemTaskForm.controls['intervalunit1'].setValidators([Validators.required]);
      }

      if (selectA == '' || selectA == null) this.intervalMismatchA = false;
      if (selectB == '' || selectB == null) this.intervalMismatchB = false;

    } else {
      this.invalidIntervals = false;
    }

    this.projectSystemTaskForm.controls['intervalvalue1'].updateValueAndValidity();
    this.projectSystemTaskForm.controls['intervalvalue2'].updateValueAndValidity();
    this.projectSystemTaskForm.controls['intervalunit1'].updateValueAndValidity();
    this.projectSystemTaskForm.controls['intervalunit2'].updateValueAndValidity();
  }

  /**
   * Get Interval Units for A
   */
  getIntervalUnit1() {
    this.profileUnitService.getCombinedUnits(this.projectSystemId).subscribe(res => {
      Object.keys(res).forEach(item => {
        if ((res[item].taskUnitAddition != '' && res[item].taskUnitAddition != null)) {
          res[item].taskUnit = res[item].taskUnit + '-' + res[item].taskUnitAddition;
        } else {
          res[item].taskUnit = res[item].taskUnit;
        }
      });
      this.finalIntervalData = res;
      this.intervalsDataLoaded = true;
    });
  }

  /**
   * When change the task type will be trigger
   * @param data
   */
  changeTaskType(taskTypeId) {
    if ((taskTypeId == '05') || taskTypeId == '55') {
      let taskNameValue = this.languageService.getCurrentLanguage().id == 0 ? 'Replace' : 'Ersetzen';
      this.taskNameReadOnly = true;
      this.projectSystemTaskForm.patchValue({
        taskName: taskNameValue
      });
    }
    else {
      this.taskNameReadOnly = false;
      this.projectSystemTaskForm.patchValue({
        taskName: ''
      });
    }
  }

  /**
   * Get all task types by Nature
   */
  getTaskTypesByNature(natureId) {
    if (natureId == 'PM') {
      this.projectSystemTaskForm.controls['intervalvalue1'].enable();
      this.projectSystemTaskForm.controls['intervalunit1'].enable();
      this.projectSystemTaskForm.controls['intervalvalue2'].enable();
      this.projectSystemTaskForm.controls['intervalunit2'].enable();
      this.projectSystemTaskForm.controls['relatedCmTask'].enable();
      this.projectSystemTaskForm.controls['ppType'].patchValue('');
      this.projectSystemTaskForm.controls['ppType'].disable();
      this.projectSystemTaskForm.controls['ppTasksOfNonPpTask'].enable();
      this.projectSystemTaskForm.patchValue({
        taskQuantityPerVehicle: this.originalTaskQuantity,
        taskInfluenceId: null,
        taskSourceId: null
      });
      this.projectSystemTaskForm.controls['taskSourceStandard'].enable();

      // this.projectSystemTaskForm.controls['taskInfluenceId'].patchValue('');
    } else if (natureId == 'CM') {
      this.projectSystemTaskForm.controls['intervalvalue1'].disable();
      this.projectSystemTaskForm.controls['intervalunit1'].disable();
      this.projectSystemTaskForm.controls['intervalvalue2'].disable();
      this.projectSystemTaskForm.controls['intervalunit2'].disable();
      this.projectSystemTaskForm.controls['relatedCmTask'].disable();
      this.projectSystemTaskForm.controls['ppType'].patchValue('');
      this.projectSystemTaskForm.controls['ppType'].disable();
      this.projectSystemTaskForm.controls['ppTasksOfNonPpTask'].enable();
      this.projectSystemTaskForm.patchValue({
        taskQuantityPerVehicle: 1,
        taskInfluenceId: 'R',
        taskSourceId: 2,
        intervalvalue1: null,
        intervalunit1: null,
        intervalvalue2: null,
        intervalunit2: null

      });
      this.projectSystemTaskForm.controls['taskSourceStandard'].disable();

      this.relatedCmTasks = [];
      this.prevSearchCM = "";
    } else if (natureId == 'PP') {
      this.projectSystemTaskForm.controls['intervalvalue1'].disable();
      this.projectSystemTaskForm.controls['intervalunit1'].disable();
      this.projectSystemTaskForm.controls['intervalvalue2'].disable();
      this.projectSystemTaskForm.controls['intervalunit2'].disable();
      this.projectSystemTaskForm.controls['ppType'].enable();
      this.projectSystemTaskForm.controls['ppTasksOfNonPpTask'].disable();
      this.projectSystemTaskForm.patchValue({
        taskQuantityPerVehicle: 1,
        taskInfluenceId: 'R',
        taskSourceId: 2,
        intervalvalue1: null,
        intervalunit1: null,
        intervalvalue2: null,
        intervalunit2: null
      });
      this.projectSystemTaskForm.controls['taskSourceStandard'].disable();
      this.selectedPPTasks = [];
      this.prevSearchPP = "";
    }
    this.projectSystemTaskForm.controls['taskSourceStandard'].patchValue('');
    this.changeQuantities();

    if (this.projectSystemTaskForm.controls['taskNature'].value == 'PP' && this.projectSystemTaskForm.controls['ppType'].value == 'POST') {
      this.projectSystemTaskForm.controls['ppTaskOfPpTask'].enable();
    }
    else {
      this.projectSystemTaskForm.controls['ppTaskOfPpTask'].disable();
      this.projectSystemTaskForm.controls['ppTaskOfPpTask'].patchValue('');
    }

    this.taskTypeService.getByNature(natureId).subscribe(res => {
      this.taskTypeData = res;
      this.taskTypeDataLoaded = true;
      if (natureId == 'PP') {
        this.projectSystemTaskForm.controls['taskTypeId'].disable();
        this.projectSystemTaskForm.controls['taskTypeId'].patchValue("99");
      }
      else {
        this.projectSystemTaskForm.controls['taskTypeId'].enable();
        this.projectSystemTaskForm.controls['taskTypeId'].patchValue('');
      }
    });

    this.getTaskManualId(natureId);
    this.manageIsNonRecuringValue(natureId);
    this.calculateTaskStage();
    this.validateIntervalUnits();
  }

  changePpType() {
    if (this.projectSystemTaskForm.controls['taskNature'].value == 'PP' && this.projectSystemTaskForm.controls['ppType'].value == 'POST') {
      this.projectSystemTaskForm.controls['ppTaskOfPpTask'].enable();
    }
    else {
      this.projectSystemTaskForm.controls['ppTaskOfPpTask'].disable();
      this.projectSystemTaskForm.controls['ppTaskOfPpTask'].patchValue('');
    }
  }
  

  /**
   * Calculate task stage
   */
  calculateTaskStage() {
    this.taskStageLoading = true;

    let formValue = this.projectSystemTaskForm.getRawValue();

    let intAunitStr = formValue.intervalunit1;
    let intBunitStr = formValue.intervalunit2;

    let intAvalueUnitStr = (intAunitStr != null && intAunitStr != '' && intAunitStr.indexOf("-") > 0) ? intAunitStr.substring(intAunitStr.indexOf("-") + 1) : '';
    let intBvalueUnitStr = (intBunitStr != null && intBunitStr != '' && intBunitStr.indexOf("-") > 0) ? intBunitStr.substring(intBunitStr.indexOf("-") + 1) : '';

    intAunitStr = (intAunitStr != null && intAunitStr != '' && intAunitStr.indexOf("-") > 0) ? intAunitStr.substring(0, intAunitStr.indexOf("-")) : intAunitStr;
    intBunitStr = (intBunitStr != null && intBunitStr != '' && intBunitStr.indexOf("-") > 0) ? intBunitStr.substring(0, intBunitStr.indexOf("-")) : intBunitStr;

    let data = {
      projectNumber: this.param.projectNumber,
      projectSystemId: this.projectSystemId,
      taskNature: formValue.taskNature,
      isNonRecurring: formValue.isNonRecurring,
      intAunit: intAunitStr,
      intAvalue: formValue.intervalvalue1,
      intBunit: intBunitStr,
      intBvalue: formValue.intervalvalue2,
      intAvalueUnit: intAvalueUnitStr,
      intBvalueUnit: intBvalueUnitStr
    };

    this.taskStageService.calculcateTaskStage(data, this.param.projectNumber, this.projectSystemId).subscribe(res => {
      this.showTaskStageWarning = res['taskStage'] == 13;
      this.projectSystemTaskForm.patchValue({
        taskStageId: res['taskStage']
      });
    }, (error) => {
      this.validateIntervalUnits();
      this.taskStageLoading = false;
    },() => {
      this.validateIntervalUnits();
      this.taskStageLoading = false;
    });
    
  }

  addToPPTasks() {
    let selectedTask: any = this.projectSystemTaskForm.controls['ppTasksOfNonPpTask'].value;
    const matchingIndex = this.prePostProcessingTaskData.findIndex((item) => item['taskDbId'] === selectedTask);
    if (matchingIndex >= 0) {
      let task = this.prePostProcessingTaskData[matchingIndex];
      if (task['ppType'] == 'PRE') {
        this.selectedPPTasks.unshift(task);
        this.selectedPreTaskCount++;
      }
      else this.selectedPPTasks.push(task);
      this.prePostProcessingTaskData.splice(matchingIndex, 1);
      this.projectSystemTaskForm.controls['ppTasksOfNonPpTask'].patchValue('');
      this.prePostProcessingTaskData.sort((a, b) => a['taskId'].localeCompare(b['taskId']));
    } 
  }


  removeFromPPTasks(task) {
    // console.log("Task about to Remove : ", task);
    const matchingIndex = this.selectedPPTasks?.findIndex((item) => item['taskId'] === task['taskId']);
    // console.log("Index : ", matchingIndex);
    if(matchingIndex >= 0){
      this.prePostProcessingTaskData.push(task);
      this.selectedPPTasks.splice(matchingIndex, 1);
      if (task['ppType'] == 'PRE')  this.selectedPreTaskCount--;
      this.prePostProcessingTaskData.sort((a, b) => a['taskId'].localeCompare(b['taskId']));
    }
  }

  movePPTaskToUp() {
    if (this.currentSelectedPPTask <= 0 || this.currentSelectedPPTask >= this.selectedPPTasks.length) return;

    let temp = this.selectedPPTasks[this.currentSelectedPPTask - 1];
    this.selectedPPTasks[this.currentSelectedPPTask - 1] = this.selectedPPTasks[this.currentSelectedPPTask];
    this.selectedPPTasks[this.currentSelectedPPTask] = temp;
    this.currentSelectedPPTask--;
  }

  movePPTaskToDown() {
    if (this.currentSelectedPPTask < 0 || this.currentSelectedPPTask >= this.selectedPPTasks.length - 1) return;

    let temp = this.selectedPPTasks[this.currentSelectedPPTask + 1];
    this.selectedPPTasks[this.currentSelectedPPTask + 1] = this.selectedPPTasks[this.currentSelectedPPTask];
    this.selectedPPTasks[this.currentSelectedPPTask] = temp;
    this.currentSelectedPPTask++;
  }

  /**
   * Manage Is Non recurring value
   * @param natureId
   */
  manageIsNonRecuringValue(natureId) {
    if (natureId == 'PM') {
      this.projectSystemTaskForm.controls['isNonRecurring'].enable();
      this.projectSystemTaskForm.patchValue({
        isNonRecurring: 2
      });
      this.taskIsNonRecurringData = taskIsNonRecurring();
      delete this.taskIsNonRecurringData[0];
    }
    else {
      this.taskIsNonRecurringData = taskIsNonRecurring();
      setTimeout(() => {
        this.projectSystemTaskForm.patchValue({
          isNonRecurring: 0
        });
      });
      this.projectSystemTaskForm.controls['isNonRecurring'].disable();
    }
  }

  /**
   * Get task manual ID
   */
  getTaskManualId(nature) {
    let projectNumber = this.param.projectNumber;
    let systemNumber = this.param.systemNumber;
    let natureId = nature;
    this.projectSystemTaskService.getTaskManualId(projectNumber, systemNumber, natureId).subscribe(res => {
      this.taskManualId = res;
      this.projectSystemTaskForm.patchValue({
        taskId: this.taskManualId
      });
    });
  }


  /**
   * Get all task suffix
   */
  getTaskSuffix() {
    this.taskSuffixService.getAllSuffix().subscribe(res => {
      this.taskSuffixData = res;
      this.taskSuffixDataLoaded = true;
    });
  }

  /**
   * Get all Employee qualification
   */
  getQualification() {
    this.employeeQualificationService.getAllQualification().subscribe(res => {
      this.qualificationData = res;
      this.qualificationDataLoaded = true;
    });
  }

  /**
   * Get all employee type
   */
  getAllEmployeeType() {
    this.employeeTypeService.getAllEmployeeType().subscribe(res => {
      this.employeeTypeData = res;
      this.employeeTypeDataLoaded = true;
    });
  }

  /**
   * Get All Infrastructures
   */
  getAllInfrastructure() {
    this.infrastructureService.getAllInfrastructure().subscribe(res => {
      this.infrastructureData = res;
      this.infrastructureDataLoaded = true;
    });
  }

  /**
   * Get All Mounting Locations
   */
  getAllMaintenanceLocation() {
    this.mountingLocationService.getAll().subscribe(res => {
      this.maintenanceLocationData = res;
      this.maintenanceLocationsDataLoaded = true;
    });
  }

  /**
   * Get all task source data
   */
  getAllTaskSource() {
    this.taskSourceService.getAllTaskSource().subscribe(res => {
      this.taskSourceData = res;
      this.taskSourceDataLoaded = true;
    });
  }

  /**
   * Get all task stages
   */
  getAllTaskStages() {
    this.taskStageService.getAllTaskStage().subscribe(res => {
      if (res) {
        this.taskStagesDataLoaded = true;
        this.taskStagesData = res;
        this.selectTaskStagebyNature();
      }
    });
  }

  /**
   * Select Task Stages By Task Nature and Is Recurring
   */
  selectTaskStagebyNature() {
    let projectNumber = this.param.projectNumber;
    let systemNumber = this.param.systemNumber;
    let formData = this.projectSystemTaskForm.getRawValue();
    let taskNature = formData['taskNature'] == '' ? 'NULL' : formData['taskNature'];
    let isNonRecurring = formData['isNonRecurring'] == '' ? 0 : formData['isNonRecurring'];

    let taskStage = 0;

    //Initial Task Stage
    // IF not a PM Task then set the task stage to 11
    // IF PM Task and Non Recurring then set the task stage to 1
    // IF PM Task and Recurring then set the task stage to 0
    taskStage = (taskNature == 'PM')? ((isNonRecurring == 1)?1:0) : 11;

    this.projectSystemTaskForm.patchValue({
      taskStageId: taskStage
    });
    super.saveInitialValues(JSON.stringify(this.projectSystemTaskForm.getRawValue()));

    
    // this.taskStageService.getTaskStageByNature(projectNumber, systemNumber, taskNature, isNonRecurring).subscribe(selectedId => {
    //   this.projectSystemTaskForm.patchValue({
    //     taskStageId: selectedId
    //   });
    //   super.saveInitialValues(JSON.stringify(this.projectSystemTaskForm.getRawValue()));
    // });


  }

  /**
   * Get all target manuals
   */
  getAllTargetManual() {
    this.targetManualService.getAll().subscribe(res => {
      this.targetManualData = res;
      this.targetManualDataLoaded = true;
    });
  }

  /**
   * get All task standard data
   */
  // getAllTaskSourceStandard() {
  //   this.taskStandardService.getAllTaskSourceStandard().subscribe(res => {
  //     this.taskStandardData = res;
  //   });
  // }

  /**
   * get project system by project system id
   */
  getProjectSystemByProjectSystemid() {
    this.projectSystemService.getProjectSystem(this.param.projectNumber, this.projectSystemId).subscribe(res => {
      this.systemVariation = res['systemVariation'];
      let projectSystemCode = res['systemVariation'] + '-' + res['systemVariationNumber'];
      let systemQuantity = res['systemQuantity'];
      this.projectSystemTaskForm.patchValue({
        projectSystem: projectSystemCode,
        taskQuantityPerVehicle: systemQuantity
      });
      this.originalTaskQuantity = systemQuantity;
      this.taskElementData[0] = {
        name: this.systemVariation,
        id: 'systemVariationName'
      }
      super.saveInitialValues(JSON.stringify(this.projectSystemTaskForm.getRawValue()));
      this.projectSystemDataLoaded = true;
    });
  }

  /**
   * Change Task Event
   * @param id
   */
  changeTaskSource(id) {
    if (id == 5) {
      this.projectSystemTaskForm.controls['specificTaskReason'].enable();
    }
    else {
      this.projectSystemTaskForm.controls['specificTaskReason'].disable();
      this.projectSystemTaskForm.controls['specificTaskReason'].patchValue('');
    }

    if (id == 4) {
      this.projectSystemTaskForm.controls['taskSourceStandard'].enable();
    }
    else {
      this.projectSystemTaskForm.controls['taskSourceStandard'].disable();
      this.projectSystemTaskForm.controls['taskSourceStandard'].patchValue('');
    }
  }

  taskElementChange(event) {
    if (event == "individual") {
      this.projectSystemTaskForm.controls['individualTaskElement'].enable();
    }
    else {
      this.projectSystemTaskForm.controls['individualTaskElement'].patchValue('');
      this.projectSystemTaskForm.controls['individualTaskElement'].disable();
    }
    this.checkTaskElementAndActivity();
  }

  languageChanged() {
    super.languageChanged(JSON.stringify(this.projectSystemTaskForm.getRawValue()));
  }

  loadTaskElementList() {
    this.taskElementData = [
      {
        name: "",
        id: 'systemVariationName'
      },
      {
        name: "Individual",
        id: 'individual'
      }
    ]
  }

  getSystemParts() {
    this.projectSystemSparePartsService.getProjectSystemSpareParts(this.projectNumber, this.projectSystemId).subscribe((res) => {
      Object.entries(res).forEach(([key, value]) => {
        value['text'] = value['spareDesignation'] + " - ";
        value['text'] += value['additionalDesignation'] ? value['additionalDesignation'] + ' - ' : "";
        value['text'] += value['spareStadlerArticleId'];
        this.systemPartData.set(value['spareNumber'].toString(), value);

        let option = {
          name: value['text'],
          id: value['spareNumber'].toString()
        }
        this.taskElementData.push(option);
      });
      this.systemPartsDataLoaded = true;
    })
  }

  loadCurrencyList() {
    this.currencyList = currencyList();
  }

  getProjectSpecificEmployeeActiveStatusAndVehicleQuantity() {
    this.projectService.getProjectById(this.param.projectNumber).subscribe(res => {
      if (res && res['specificEmployeeQualification'] && res['specificEmployeeQualification'] == 1) {
        var mntLevel = this.projectSystemTaskForm.value['maintenanceLevel'];
        if (mntLevel == 3) {
          this.projectSystemTaskForm.controls['employeeQualificationId'].enable();
          this.projectSystemTaskForm.controls['employeeQualificationId'].reset();
          this.projectSystemTaskForm.controls['employeeQualificationId'].patchValue(5);

          this.projectSystemTaskForm.controls['tskInfrastructure'].patchValue('');
          this.projectSystemTaskForm.controls['tskInfrastructure'].disable();
        } else {
          this.projectSystemTaskForm.controls['employeeQualificationId'].enable();
          this.projectSystemTaskForm.controls['employeeQualificationId'].patchValue('');
          this.projectSystemTaskForm.controls['tskInfrastructure'].enable();
        }
        this.projectSystemTaskForm.controls['employeeQualificationId'].removeValidators([Validators.required]);
        this.projectSystemTaskForm.controls['employeeQualificationId'].disable();
        this.projectSystemTaskForm.controls['specificEmployeeQualification'].enable();
        this.projectSystemTaskForm.controls['specificEmployeeQualification'].setValidators([Validators.required, Validators.maxLength(255)]);
        this.projectSystemTaskForm.controls['specificEmployeeQualification'].updateValueAndValidity();
      }
      else {
        var mntLevel = this.projectSystemTaskForm.value['maintenanceLevel'];
        if (mntLevel == 3) {
          this.projectSystemTaskForm.controls['employeeQualificationId'].enable();
          this.projectSystemTaskForm.controls['employeeQualificationId'].reset();
          this.projectSystemTaskForm.controls['employeeQualificationId'].patchValue(5);
          this.projectSystemTaskForm.controls['employeeQualificationId'].disable();

          this.projectSystemTaskForm.controls['tskInfrastructure'].patchValue('');
          this.projectSystemTaskForm.controls['tskInfrastructure'].disable();
        } else {
          this.projectSystemTaskForm.controls['employeeQualificationId'].enable();
          this.projectSystemTaskForm.controls['employeeQualificationId'].patchValue('');
          this.projectSystemTaskForm.controls['employeeQualificationId'].setValidators([Validators.required]);
          this.projectSystemTaskForm.controls['employeeQualificationId'].updateValueAndValidity();
          this.projectSystemTaskForm.controls['tskInfrastructure'].enable();
        }
        this.projectSystemTaskForm.controls['specificEmployeeQualification'].removeValidators([Validators.required, Validators.maxLength(255)]);
        this.projectSystemTaskForm.controls['specificEmployeeQualification'].disable();

      }
      if (res && res['vehicleQuantity']) {
        this.projectVehicleQuantity = res['vehicleQuantity'];
        let taskQuantityPerVehicle = this.projectSystemTaskForm.controls['taskQuantityPerVehicle'].value;
        this.projectSystemTaskForm.controls['taskQuantityPerFleet'].patchValue(taskQuantityPerVehicle * this.projectVehicleQuantity);
      }
      this.projectSpecificEmployeeandVehicleDataLoaded = true;
    });
  }

  public checkTaskElementAndActivity() {
    let task = this.projectSystemTaskForm.getRawValue();
    task['ppTasksOfNonPpTask'] = Array.isArray(task['ppTasksOfNonPpTask']) ? task['ppTasksOfNonPpTask'] : [];
    task['isPerFleetQuantity'] = task['isPerFleetQuantity'] ? 1 : 0;

    this.projectSystemTaskService.setTaskElementAndActivity(task).subscribe((res) => {
      if (res && res['taskElementStr'] && res['taskActivity']){
        this.showTaskActivityWarning = (res['taskElementStr'].length + res['taskActivity'].length) > 140;
        console.log((res['taskElementStr'].length + res['taskActivity'].length))
      }
    })
  }

  callAfterLanguageChanged() {
    this.getTaskRelatedLists();
    // this.getAllMaintenanceLocation();
    // this.getTaskSuffix();
    // this.getQualification();
    // this.getAllEmployeeType();
    // this.getAllInfrastructure();
    // this.getAllTaskSource();
    // this.getAllTaskStages();
  
    // this.getAllTargetManual();
    // // this.getAllTaskSourceStandard();
    // this.getIntervalUnit1();
    // this.loadTaskElementList();
    // this.getProjectSystemByProjectSystemid();
    // this.getSystemParts();
    // this.loadCurrencyList();
    // this.getProjectSpecificEmployeeActiveStatusAndVehicleQuantity();
    // this.getTaskTagList();


  }


  getDefatltMaintenanceLocations(projectSystemId) {
    this.maintenanceLocationArray = [];
    this.mountingLocationService.getProjectSystemMountingLocations(projectSystemId).subscribe(res => {
      Object.entries(res).forEach(([key, value]) => {
        this.maintenanceLocationArray.push(value['mountingLocationId']);
      });
      this.projectSystemTaskForm.patchValue({
        tskMaintenanceLocation: this.maintenanceLocationArray
      });
      super.saveInitialValues(JSON.stringify(this.projectSystemTaskForm.getRawValue()));
      this.defaultMaintenanceLocationsLoaded = true;
    });
  }

  validExecutionTimeClicked(event: any) {
    if (event.target.checked) {
      this.isValidExecutionTime = true;
    } else {
      this.isValidExecutionTime = false;
    }
    this.executionTimeChanged();
  }

  getTaskTagList() {
    this.overallSystemsAndTasksService.getAllTagsByType("project_system_task").subscribe((res) => {
      this.taskTagList = res;
      this.taskTagListLoaded = true;
    })
  }

  // executionTimeChanged(){
  //   let m = this.projectSystemTaskForm.controls['executionTime'].value;
  //   if(m && (m > 0 )){
  //     this.isExecutionTimeEmpty = false;
  //     this.projectSystemTaskForm.controls['executionTimeSource'].enable();
  //     this.projectSystemTaskForm.controls['executionTimeSource'].clearValidators();
  //     this.projectSystemTaskForm.controls['executionTimeSource'].setValidators([Validators.required, Validators.maxLength(100)]);
  //     this.projectSystemTaskForm.controls['executionTimeSource'].markAsTouched();
  //     validateForm(this.projectSystemTaskForm);
  //   }else{
  //     // this.isValidExecutionTime = false;
  //     this.isExecutionTimeEmpty = true;
  //     this.projectSystemTaskForm.controls['executionTimeSource'].disable();
  //     this.projectSystemTaskForm.controls['executionTimeSource'].clearValidators();
  //   }

  // }

  executionTimeChanged() {
    let m = this.projectSystemTaskForm.controls['executionTime'].value;
    let n = this.isValidExecutionTime;
    console.log("Is Valued Checked : " + n);
    if (m && (m.length > 0 )) {
      this.isExecutionTimeEmpty = false;
      this.projectSystemTaskForm.controls['executionTimeSource'].enable();
      this.projectSystemTaskForm.controls['executionTimeSource'].clearValidators();
      if (n) {
        console.log("Execution Time Source Validator Set");
        this.projectSystemTaskForm.controls['executionTimeSource'].setValidators([Validators.required, Validators.maxLength(100)]);
      }
      this.projectSystemTaskForm.controls['executionTimeSource'].markAsTouched();
      validateForm(this.projectSystemTaskForm);
      console.log("Form Validated");
    } else {
      // this.isValidExecutionTime = false;
      this.isExecutionTimeEmpty = true;
      this.isValidExecutionTime = false;
      this.projectSystemTaskForm.controls['executionTimeSource'].disable();
      this.projectSystemTaskForm.controls['executionTimeSource'].clearValidators();
      this.projectSystemTaskForm.controls['executionTimeSource'].setValue('');
      validateForm(this.projectSystemTaskForm);
    }

  }

  onKeyUpPPTasks(event) {
    const value = event.target.value;
    if (value.length == 3 && this.prevSearchPP != value) {
      // Get data from server when 3rd character is typed
      this.prevSearchPP = value;
      this.populatePPTasks(value, false);
    }
  }

  ppTaskOnPaste(event){
    // ng-paste='handlePaste($event.clipboardData.getData('text/plain'))'
    const value = event.clipboardData.getData('text/plain');
    if (value.length >= 1 && this.prevSearchPP != value) {
      // Get data from server when 3rd character is typed
      this.prevSearchPP = value;
      this.populatePPTasks(value, true);
    }
  }

  populatePPTasks(value, isPaste:Boolean = false) {
    this.prePostProcessingTaskDataLoading = true;
    
    this.projectSystemTaskService.getFilteredPrePostProcessingTasks(this.projectNumber, this.projectSystemId, "PPCM", value).subscribe(res => {
      let resData:any = res;
      console.log("Pre Post Processing Tasks", resData);
      Object.entries(res).forEach(([key, task]) => {
        let taskText = task['taskId'] + " " + task['taskElementStr'] + "-" + task['taskName'];
        task['taskText'] = taskText;
        if (task['taskNature'] == 'CM') task['ppType'] = 'PRE';
        let matchingIndex = this.prePostProcessingTaskData.findIndex((item) => item['taskDbId'] === task['taskDbId']);
        if (matchingIndex < 0) {
          if (task['ppType'] == 'PRE') this.preProcessingTaskData.push(task);
          this.prePostProcessingTaskData.push(task);
        }
      });

      if(isPaste){
        this.ppTasksDropdown.nativeElement.focus();
        const event = new KeyboardEvent('keydown', { key: 'ArrowRight' });
        this.ppTasksDropdown.nativeElement.dispatchEvent(event);
      }

    }, error => {
      this.prePostProcessingTaskDataLoading = false;
    }, () => {
      this.prePostProcessingTaskDataLoading = false;
    });
  }

  onKeyUpCMTasks(event) {
    const value = event.target.value;
    if (value.length == 3 && this.prevSearchCM != value) {
      // Get data from server when 3rd character is typed
      this.prevSearchCM = value;
      this.populateCMTasks(value, false);
    }
  }

  cmTaskOnPaste(event){
    // ng-paste='handlePaste($event.clipboardData.getData('text/plain'))'
    const value = event.clipboardData.getData('text/plain');
    if (value.length >= 1 && this.prevSearchCM != value) {
      // Get data from server when 3rd character is typed
      this.prevSearchCM = value;
      this.populateCMTasks(value, true);
    }
  }

  populateCMTasks(value, isPaste: Boolean = false) {
    this.cmTaskDataLoading = true;
    this.projectSystemTaskService.getFilteredPrePostProcessingTasks(this.projectNumber, this.projectSystemId, "CM", value).subscribe(res => {
      Object.entries(res).forEach(([key, task]) => {
        let taskText = task['taskId'] + " " + task['taskElementStr'] + "-" + task['taskName'];
        task['taskText'] = taskText;
        if (task['taskNature'] == 'CM') task['ppType'] = 'PRE';
        const matchingIndex = this.relatedCmTasks.findIndex((item) => item['taskId'] === task['taskId']);
        if (matchingIndex < 0)  {
          this.relatedCmTasks.push(task);
        }else{
          console.log("Task Already Exists", task['taskId'] + " " + task['taskElementStr'] + "-" + task['taskName']);
        }
      });

      if(isPaste){
        this.cmTasksDropdown.nativeElement.focus();
        const event = new KeyboardEvent('keydown', { key: 'ArrowRight' });
        this.cmTasksDropdown.nativeElement.dispatchEvent(event);
      }

    }, error => {
      this.cmTaskDataLoading = false;
    }, () => {
      this.cmTaskDataLoading = false;
    });
  }

}
