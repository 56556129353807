

<div class="modal-header">
  <h5 class="modal-title pull-left">Project System Task Parts</h5>
  <div>
    <ngx-select style="padding-left: 25px; width: 200px; display: inline-block; float: right !important;" optionValueField="id" optionTextField="str" [items]="languages" [(ngModel)]="selectedLanguage" (select)="languageChanged()"></ngx-select>
  </div>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="data-table">
    <app-tree-table [settings]="settings" [data]="projectSystemSparePartList"></app-tree-table>
  </div>
</div>
<div class="modal-footer">
  <app-action-comment [btnLoading]="btnLoading" [okBtnName]="'Save'" [closeBtnName]="'Close'" [saveDisable]="isFormInvalid || (partsListToDelete.length == 0 && partsListToEdit.length == 0 && partsListToAdd.length == 0)" [mandatoryDescriptionVisible]="true" [commentVisible]="projectFreezeFunction == 'activated'" (okClick)="saveChanges($event)" (cancelClick)="bsModalRef.hide()"></app-action-comment>
</div>

