import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {CommonLogComponent} from '../../../shared/components/common-log/common-log.component';
import {AuthServiceService} from '../../../services/auth-service.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {LogServiceService} from '../../../services/log-service.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-system-log',
  templateUrl: '../../../shared/components/common-log/common-log.component.html',
  styleUrls: ['../../../shared/components/common-log/common-log.component.scss']
})
export class SystemLogComponent extends CommonLogComponent implements OnInit {

  constructor(
    public authService: AuthServiceService,
    public bsModalRef: BsModalRef,
    public activatedRoute : ActivatedRoute,
    public logService: LogServiceService,
    public cdr: ChangeDetectorRef
  ) {
    super(authService, bsModalRef, activatedRoute, logService, cdr);
  }

  
  ngOnInit(): void {
    super.setLogType("Standard System");
    super.setEntities(["Standard System"]);
    super.ngOnInit();
  }
}
