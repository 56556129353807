import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SystemsRoutingModule } from './systems-routing.module';
import { SystemsComponent } from './systems/systems.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SystemAddComponent } from './system-add/system-add.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { SystemEditComponent } from './system-edit/system-edit.component';
import { LaddaModule } from 'angular2-ladda';
import { SystemLogComponent } from './system-log/system-log.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {TreeTableModule} from "../../shared/components/tree-table/tree-table.module";
import { SharedModule } from 'src/app/shared/shared.module';
import { SystemRamengineerChangeComponent } from './system-ramengineer-change/system-ramengineer-change.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
  declarations: [SystemsComponent, SystemAddComponent, SystemEditComponent, SystemLogComponent, SystemRamengineerChangeComponent],
    imports: [
        CommonModule,
        SystemsRoutingModule,
        FontAwesomeModule,
        SharedModule,
        ReactiveFormsModule,
        FormsModule,
        NgxSelectModule,
        LaddaModule,
        NgbModule,
        TreeTableModule,
        MatProgressSpinnerModule
    ],
  bootstrap: [SystemLogComponent]
})
export class SystemsModule { }
