import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SystemLogsComponent } from './system-logs/system-logs.component';


const routes: Routes = [
  { path: 'system-logs', component: SystemLogsComponent, data: { animation: 'projects' }},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SystemLogsRoutingModule { }
