
import {Component, EventEmitter, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {SuppliersService} from 'src/app/services/suppliers.service';
import {ToastrService} from 'ngx-toastr';
import {validateForm} from 'src/app/shared/helper';

@Component({
  selector: 'app-add-suppliers',
  templateUrl: './add-suppliers.component.html',
  styleUrls: ['./add-suppliers.component.scss']
})
export class AddSuppliersComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();
  addSupplierForm: any;
  btnLoading: Boolean = false;

  constructor(
      public bsModalRef: BsModalRef,
      private supplierService: SuppliersService,
      private notificationService: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.addSupplierForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required]),
      erpId: new UntypedFormControl('' ),
    });
  }

  /**
   * Submit Add
   */
  public createSupplier() {
    if (this.addSupplierForm.valid) {
      this.btnLoading = true;
      this.supplierService.create(this.addSupplierForm.getRawValue()).subscribe(res => {
        if (res) {
          this.notificationService.success('Supplier has been created', 'success');
          this.btnLoading = false;
          this.event.emit(res);
          this.bsModalRef.hide();
        } else {
          this.notificationService.error('Supplier has not been created', 'Error');
          this.btnLoading = false;
        }
      });
    } else {
      validateForm(this.addSupplierForm);
    }
  }

}
