import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taskStageFilter'
})
export class TaskStageFilterPipe implements PipeTransform {

  transform(value: any[], param: Boolean): any {
    if(param){
      return value.filter(mountingLocation => mountingLocation['mountingLocationId']!=19);
    }else{
      return value;
    }
    
  }

}
