import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OperatingProfileListComponent } from './operating-profile-list/operating-profile-list.component';


const routes: Routes = [
  {
    path: 'operating-profiles/:projectNumber',
    component: OperatingProfileListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OperatingProfileRoutingModule { }
