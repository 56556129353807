import {Component, EventEmitter, OnInit} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EditProjectSystemsComponent } from '../edit-project-systems/edit-project-systems.component';

@Component({
  selector: 'app-project-system-edit-url',
  templateUrl: './project-system-edit-url.component.html',
  styleUrls: ['./project-system-edit-url.component.scss']
})
export class ProjectSystemEditUrlComponent implements OnInit {

  rowData: any;
  eventEmitter:EventEmitter<string> = new EventEmitter();
  projectFreezeFunction = "";

  constructor(private modalService:BsModalService) { }

  ngOnInit(): void {
  }

  editProjectSystems(){
    const initialState = {
      param : this.rowData,
      projectFreezeFunction : this.projectFreezeFunction
    };
    let modalRef = this.modalService.show(EditProjectSystemsComponent, {class:'modal-lg',initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.eventEmitter.subscribe(res =>
    {
      this.eventEmitter.emit('refresh');
    });
  }
}
