import {Component, ElementRef, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {MountingLocationService} from 'src/app/services/mounting-location.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ProjectSystemsService} from 'src/app/services/project-systems.service';
import {ToastrService} from 'ngx-toastr';
import {SuppliersService} from 'src/app/services/suppliers.service';
import {StakeHolderService} from 'src/app/services/stake-holder.service';
import {StadlerArticleService} from 'src/app/services/stadler-article.service';
import {validateForm,getProjectSystemActivatedList} from 'src/app/shared/helper';
import {
  LanguageSupportComponent
} from '../../../../../shared/components/language-support/language-support-component.service';
import {LanguageServiceService} from '../../../../../services/language-service.service';
import {UserService} from 'src/app/services/user.service';
import {StandardSystemsService} from 'src/app/services/standard-systems.service';
import {ProjectSystemSparePartsService} from "src/app/services/project-system-spare-parts.service";
import {OverallSystemsAndTasksService} from "../../../../../services/overall-systems-and-tasks.service";
import { Subject } from 'rxjs';

@Component({
  selector: 'app-edit-project-systems',
  templateUrl: './edit-project-systems.component.html',
  styleUrls: ['./edit-project-systems.component.scss']
})
export class EditProjectSystemsComponent extends LanguageSupportComponent implements OnInit {

  @ViewChild('stadlerArticleIdDropdown') stadlerArticleIdDropdown: ElementRef;

  param: any;
  ladda: Boolean = false;
  mountingLocations: any = [];
  ramEngineerData:any=[];
  sbsCodeData:any = [];
  projectSystemEditForm: any;
  suppliers: any = [];
  laddLoading: Boolean = false;
  isprojectSystemActivatedList: any = getProjectSystemActivatedList();
  systemEngineerList: any = [];
  machanicalEngineerList: any = [];
  systemMountingLocationArray: any = [];
  electricalEngineerList: any = [];
  projectSystemMountingLocations: any = [];
  ramEngineerList: any = [];
  systemTagList: any = [];
  stadlerArticleData: any = [];
  eventEmitter:EventEmitter<string> = new EventEmitter();
  initialStadlerArticleId:any;
  isProjectSystemActivated:Number=0;
  projectFreezeFunction = "";
  stadlerArticleChangedWarning:boolean = false;

  mountingLocationsDataLoading:boolean = true;
  suppliersDataLoading:boolean = true;
  projectSystemsDataLoading:boolean = true;
  systemEngineersDataLoading:boolean = true;
  mechanicalEngineersDataLoading:boolean = true;
  electricalEngineersDataLoading:boolean = true;
  sbsCodesDataLoading:boolean = true;
  ramEngineersDataLoading:boolean = true;
  systemTagsDataLoading:boolean = true;
  stadlerArticleDataLoading:boolean = false;
  stalderArticleItemsListGenerated:boolean = false;
  stadlerArticleLoopProcessing:boolean = true;
  projectSystemFinallyLoaded:boolean = false;
  projectSystemSparepartsLoading:boolean = true;
  sparepartsLoading:boolean = true;
  childPartStadlerIdsLoading:boolean = true;
  stalderArticleItemsListLoading:boolean = true;
  ifBaseSystem:boolean = false;

  projectNumber: String;
  projectSystemId: Number;
  projectSystemRelatedListsLoading: Boolean = true;
  fullStadlerArticleList: any = [];
  projectSystemSpareparts: any = [];
  projectSystem: any = [];
  individualStadlerArticle: any;
  prevFilter: string = "";

  projectSystemLoaded$: Subject<boolean> = new Subject<boolean>();


  spinnerPosition:any = {'top':0, 'left':0};

  @ViewChild('outerDiv', { static: false }) outerDivElement: ElementRef;

  
  constructor(
      public bsModalRef: BsModalRef,
      private mountingLocationService: MountingLocationService,
      private projectSystemService: ProjectSystemsService,
      private projectSystemSpareService: ProjectSystemSparePartsService,
      private notificationService: ToastrService,
      private supplierService: SuppliersService,
      private stakeHolderService: StakeHolderService,
      private overallSystemsAndTasksService:OverallSystemsAndTasksService,
      private stadlerArticleService: StadlerArticleService,
      public languageService: LanguageServiceService,
      public modalService: BsModalService,
      public userService: UserService,
      private standardSystemService: StandardSystemsService
  ) {
    super(languageService, modalService);
  }

  ngOnInit(): void {
    this.projectNumber = this.param.projectNumber;
    this.projectSystemId = this.param.projectSystemId;
    this.initFormValues();

    

  }

  async initFormValues(){
    this.projectSystemEditForm = new UntypedFormGroup(
        {
          systemNumber: new UntypedFormControl(this.param.systemNumber),
          projectNumber: new UntypedFormControl(this.param.projectNumber),
          mountingLocationId: new UntypedFormControl([]),
          systemQuantity: new UntypedFormControl(this.param.systemQuantity, [Validators.max(999)]),
          systemDesignation: new UntypedFormControl(this.selectedLanguage == 0 ? this.param.systemDesignationEn : this.param.systemDesignationDe),
          systemEngineeringContact: new UntypedFormControl(),
          mechanicalEngineeringContact: new UntypedFormControl(),
          electricalEngineeringContact: new UntypedFormControl(),
          ramEngineeringContact: new UntypedFormControl(),
          specificDesignation: new UntypedFormControl(),
          stadlerArticleId: new UntypedFormControl(),
          sbsCode: new UntypedFormControl(),
          ramengineers: new UntypedFormControl([]),
          systemTags: new UntypedFormControl([]),
          systemSupplier: new UntypedFormControl({value: '', disabled: true}),
          systemVariation: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
          systemVariationNumber: new UntypedFormControl(),
          systemComment: new UntypedFormControl('', [Validators.maxLength(1000)]),
          isActive: new UntypedFormControl(1),
        }
    );

    
    await this.initDataAsync();    
    validateForm(this.projectSystemEditForm);

    
  }

  positionSpinner(){
    let divHeight = document.documentElement.clientHeight;
    let divWidth =document.documentElement.clientWidth;
    let divTop = 0;
    let divLeft = 0;

    this.spinnerPosition.top = divHeight/2 + divTop - 50;
    this.spinnerPosition.left = divWidth/2 + divLeft - 50;

    console.log("Spinner Position : ");
    console.log(this.spinnerPosition);
  }

  initDataAsync(){
    // this.getAllMountingLocations();
    // this.getAllSuppliers();
    // this.getSBSCodes();
    // this.getRamEngineerList();
    // this.getSystemTagList();
    this.stadlerArticleData = [];
    this.prevFilter = "";
    this.getProjectSystemRelatedLists();
    // this.getProjectSystem();

  }

  getProjectSystemRelatedLists(){
    this.projectSystemRelatedListsLoading = true;
    
    this.projectSystemService.getProjectSystemRelatedLists(this.projectNumber, this.projectSystemId).subscribe(res => {
      if (res) {
        this.mountingLocations = res['mountingLocations'];
        this.projectSystemMountingLocations = res['projectSystemMountingLocations'];
        this.suppliers = res['suppliers'];
        this.sbsCodeData = res['sbsCodes'];
        this.ramEngineerList = res['ramEngineers'];
        this.systemTagList = res['systemTags'];
        
        this.projectSystemSpareparts = res['sparePartArticleIds'];
        this.projectSystem = res['projectSystem'];
        this.individualStadlerArticle = res['individualStadlerArticle'];
        
        
        this.projectSystemRelatedListsLoading = false;

        this.processAfterDataLoading();
      }
    }, (error) => {
      this.projectSystemRelatedListsLoading = false;
    }, () => {
      this.projectSystemRelatedListsLoading = false;
      console.log("Project System Necessary Data Loaded - FINISHED");
      console.log(this.stadlerArticleData);
    });
    
  }

  processAfterDataLoading(){

    //Set Initial Stadler Article Id (Parent Sparepart Stadler Article ID)
    this.initialStadlerArticleId = this.projectSystem['stadlerArticleId'];
    this.projectSystem["mountingLocationId"] = this.projectSystemMountingLocations;
    //Set Base system true if the system variation number is AA00-1
    this.ifBaseSystem = this.projectSystem['systemVariationNumberAndCode'] == "AA00-1" ? true : false;
    //Set Active state of the system
    this.projectSystem['isActive'] = (this.projectSystem['isActive']==null || this.projectSystem['isActive']=='') ? 0 : this.projectSystem['isActive'];

    //Add the Project System Stadler Article into the Stadler Article List Initially
    if( (!(this.stadlerArticleData != null && this.stadlerArticleData.length > 0)) && this.individualStadlerArticle != null){
      let article = this.individualStadlerArticle;
      let tempDesgEn = (article['designationEn'] == null || article['designationEn'] == "-")?"Designation EN not set":article['designationEn'];
      let tempDesgDe = (article['designationDe'] == null || article['designationDe'] == "-")?"Designation DE not set":article['designationDe'];
      let designation = (this.selectedLanguage==0?tempDesgEn:tempDesgDe) + ((this.selectedLanguage==0?tempDesgEn:tempDesgDe)==""?"":"-") + article['stadlerArticleId'] + " - " + article['supplierArticleId'];
      article['designation'] = designation;
      this.stadlerArticleData.push(article);
      
      // this.individualStadlerArticle['designation'] = (this.selectedLanguage==0?this.individualStadlerArticle['designationEn']:this.individualStadlerArticle['designationDe']) + '-' + this.individualStadlerArticle['stadlerArticleId'];
      // this.stadlerArticleData.push(this.individualStadlerArticle);
      console.log("Individual Stadler Article added to the List");
    }

    let systemTagsArray = this.projectSystem['systemTags'];
    let systemTagsData = [];
    for(let systemTag of systemTagsArray){
      systemTagsData.push(systemTag['tagId']);
    }
    delete this.projectSystem['systemTags'];
    this.projectSystemEditForm.patchValue({
      systemTags:systemTagsData
    });

    let ramEngineerArray = this.projectSystem['ramEngineers'];
    let engineerData = [];
    if (ramEngineerArray?.length > 0)
    {
      for (var x = 0; x < ramEngineerArray.length; x++)
      {
        if (engineerData.indexOf(ramEngineerArray[x]['stakeholderId']) < 0)
        {
          engineerData.push(ramEngineerArray[x]['stakeholderId']);
        }
      }
      delete this.projectSystem['ramEngineers'];
      this.projectSystemEditForm.patchValue({
        ramengineers: engineerData
      });
    }
    else
    {
      let systemNumber = this.projectSystem['systemNumber'];
      this.ramEngineersDataLoading = true;
      this.standardSystemService.getSystemById(systemNumber).subscribe(res =>
      {
        if (res['ramEngineer']?.length > 0)
        {
          this.projectSystemEditForm.patchValue({
            ramengineers: res['ramEngineer']
          });
        }
      }, (error) => {}, () => {
        this.ramEngineersDataLoading = false;
      });
    }

    // this.refreshStadlerArticleData();

    this.projectSystemEditForm.patchValue({
      mountingLocationId: this.projectSystemMountingLocations
    });

    this.projectSystem['systemQuantity'] = this.projectSystem['systemQuantity'] != null ? this.projectSystem['systemQuantity'].toString() : null;

    this.initialStadlerArticleId = this.projectSystem['stadlerArticleId'];

    

    this.systemActiveStateChanged(this.projectSystem['isActive']);

    this.projectSystemEditForm.patchValue(this.projectSystem);

    super.saveInitialValues(JSON.stringify(this.projectSystemEditForm.getRawValue()));

    console.log("After loading processing is DONE", this.projectSystem);
  }


  public currentFormValues()
  {
    return JSON.stringify(this.projectSystemEditForm.getRawValue());
  }



  /**
   * Get Stadler article data by id
   */
  getStadlerArticleById() {
    this.stadlerArticleDataLoading = true;
    let articleId = this.projectSystemEditForm.value.stadlerArticleId;
    if (articleId == null || articleId == "") {
      this.projectSystemEditForm.patchValue({
        specificDesignation: '',
        systemSupplier: ''
      });
    }else{
      this.stadlerArticleService.getArticleById(articleId).subscribe(res => {
        if(res){
          this.projectSystemEditForm.patchValue({
            specificDesignation: res['spareDesignationDe'],
            systemSupplier: res['supplierId']
          });
        }
        
      }, (error) => {}, () => {
        this.stadlerArticleDataLoading = false;
      
      });
    }
    

    if(this.initialStadlerArticleId && this.initialStadlerArticleId != "" && this.initialStadlerArticleId != articleId){
      this.stadlerArticleChangedWarning = true;
    }else{
      this.stadlerArticleChangedWarning = false;
    }
  }


  stadlerArticleOnKeyUp(event){
    const value = event.target.value;
    if (value.length == 3 && this.prevFilter != value) {
      // Get data from server when 3rd character is typed
      this.prevFilter = value;
      this.getFilteredStadlerArticleList(value, false);
    }
  }

  stadlerArticleOnPaste(event){
    // ng-paste='handlePaste($event.clipboardData.getData('text/plain'))'
    const value = event.clipboardData.getData('text/plain');
    if (value.length >= 1 && this.prevFilter != value) {
      // Get data from server when 3rd character is typed
      this.prevFilter = value;
      this.getFilteredStadlerArticleList(value, true);
    }
  }



  getFilteredStadlerArticleList(filter, isPaste: Boolean = false){
    this.stadlerArticleDataLoading = true;
    this.stadlerArticleService.getStadlerArticleIdsbyProjectSystemFiltered(this.projectSystemId, filter).subscribe(res => {
      if(res){
        this.stadlerArticleData = [];
        // this.fullStadlerArticleList = res;
        Object.entries(res).forEach(([key, article]) => {
        // this.fullStadlerArticleList?.forEach(article => {
          let tempDesgEn = (article['designationEn'] == null || article['designationEn'] == "-")?"Designation EN not set":article['designationEn'];
          let tempDesgDe = (article['designationDe'] == null || article['designationDe'] == "-")?"Designation DE not set":article['designationDe'];
          let designation = (this.selectedLanguage==0?tempDesgEn:tempDesgDe) + ((this.selectedLanguage==0?tempDesgEn:tempDesgDe)==""?"":"-") + article['stadlerArticleId'] + " - " + article['supplierArticleId'];
          article['designation'] = designation;
          this.stadlerArticleData.push(article);
        });

        if(isPaste){
          this.stadlerArticleIdDropdown.nativeElement.focus();
          const event = new KeyboardEvent('keydown', { key: 'ArrowRight' });
          this.stadlerArticleIdDropdown.nativeElement.dispatchEvent(event);
        }
      
        // console.log("Stadler Articles added to the List");
        // console.log(this.stadlerArticleData);
      }
      
    }, (error) => {
      this.stadlerArticleDataLoading = false;
    }, () => {
      this.stadlerArticleDataLoading = false;
    });
  }

  

  

  /**
   * Submit Project System Edit form
   */
  updateProjectSystem(event) {
    if (this.projectSystemEditForm.valid) {
      let formValue = this.projectSystemEditForm.getRawValue();

      let mountingLocationInput = formValue.mountingLocationId;
      let ramEngineers = formValue.ramengineers;
      delete formValue.tskInfrastructure;
      let ramEngineerArray = [];

      if(ramEngineers){
        if (ramEngineers.length > 0) {
          for (let ramEngId of ramEngineers) {
            for(let ramEng of this.ramEngineerList){
              if(ramEng['stakeholderId'] == ramEngId) ramEngineerArray.push(ramEng);
            }
          }
          formValue.ramengineers = ramEngineerArray;
        }else{
          formValue.ramengineers = {};
        }
      }

      let systemTags = formValue.systemTags;
      let systemTagsArray = [];
      for (let systemTagId of systemTags) {
        for(let systemTag of this.systemTagList){
          if(systemTag['tagId'] == systemTagId) systemTagsArray.push(systemTag);
        }
      }

      if(mountingLocationInput){
        if (mountingLocationInput.length > 0) {
          for (var x = 0; x < mountingLocationInput.length; x++) {
            this.systemMountingLocationArray.push(
                {
                  mountingLocationId: mountingLocationInput[x],
                  projectNumber: this.param.projectNumber,
                  systemNumber: this.param.systemNumber,
                }
            );
          }
        }
      }

      this.laddLoading = true;
      let projectNumber = this.param.projectNumber;
      let submitData = this.projectSystemEditForm.getRawValue();
      let data = {
        systemQuantity: parseInt(submitData['systemQuantity']),
        systemEngineeringContact: parseInt(submitData['systemEngineeringContact']),
        mechanicalEngineeringContact: parseInt(submitData['mechanicalEngineeringContact']),
        electricalEngineeringContact: parseInt(submitData['electricalEngineeringContact']),
        ramEngineeringContact: parseInt(submitData['ramEngineeringContact']),
        ramEngineers: ramEngineerArray,
        specificDesignation: submitData['specificDesignation'],
        systemVariation: submitData['systemVariation'],
        sbsCode: submitData['sbsCode'],
        stadlerArticleId: submitData['stadlerArticleId'],
        systemComment: submitData['systemComment'],
        systemVariationNumber: submitData['systemVariationNumber'],
        systemNumber: this.param.systemNumber,
        systemSupplier: parseInt(submitData['systemSupplier']),
        systemTags: systemTagsArray,
        isActive: submitData['isActive'],
      };
      this.projectSystemService.updateProjectSystems(event, projectNumber, this.param.projectSystemId, data).subscribe(res => {
        if (res) {
          this.mountingLocationService.createProjectSystemMountingLocations(this.systemMountingLocationArray, this.param.projectSystemId).subscribe(res => {
            this.notificationService.success('Project System has been updated', 'Success');
            this.bsModalRef.hide();
            this.eventEmitter.emit();
          });
        } else {
          this.notificationService.error('Project System has not been updated!', 'Error');
          this.laddLoading = false;
        }
        
      }, (error => {
        // console.log(error);
        if(error.error.details != null ) 
          this.notificationService.error(error.error.details, 'Error'); 
        else 
          this.notificationService.error('Project System has not been updated', 'Error');
        
        this.laddLoading = false;
      }), () => {
        this.laddLoading = false;
      });
    } else {
      validateForm(this.projectSystemEditForm);
    }
  }


  languageChanged(){
    super.languageChanged(JSON.stringify(this.projectSystemEditForm.getRawValue()));
  }


  callAfterLanguageChanged() {
    this.initFormValues();
  }

  systemActiveStateChanged(isprojectSystemActivated:Number){
    this.isProjectSystemActivated = isprojectSystemActivated;

    if(this.isProjectSystemActivated==0){
      this.projectSystemEditForm.controls['sbsCode']?.enable()
      this.projectSystemEditForm.controls['stadlerArticleId']?.enable()
      this.projectSystemEditForm.controls['systemVariation']?.enable()
      this.projectSystemEditForm.controls['mountingLocationId']?.enable()
      this.projectSystemEditForm.controls['systemQuantity']?.enable()
      this.projectSystemEditForm.controls['ramengineers']?.enable()
      this.projectSystemEditForm.controls['systemTags']?.enable()
      this.projectSystemEditForm.controls['systemComment']?.enable()
      this.projectSystemEditForm.controls['ramEngineers']?.enable()
    }else{
      this.projectSystemEditForm.controls['sbsCode']?.disable()
      this.projectSystemEditForm.controls['stadlerArticleId']?.disable()
      this.projectSystemEditForm.controls['systemVariation']?.disable()
      this.projectSystemEditForm.controls['mountingLocationId']?.disable()
      this.projectSystemEditForm.controls['systemQuantity']?.disable()
      this.projectSystemEditForm.controls['systemTags']?.disable()
      this.projectSystemEditForm.controls['systemComment']?.disable()
      this.projectSystemEditForm.controls['ramEngineers']?.disable()
    }
    
   
    // super.saveInitialValues(JSON.stringify(this.projectSystemEditForm.getRawValue()));
     console.log('form_data',this.projectSystemEditForm.getRawValue());
    
  }
}
