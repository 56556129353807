import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {OverallTasksComponent} from "./overall-tasks/overall-tasks.component";


const routes: Routes = [
  { path: 'overall-tasks', component: OverallTasksComponent, data: { animation: 'tasks' }},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OverallTasksRoutingModule { }
