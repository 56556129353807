

<div class="modal-header">
  <h5 class="modal-title pull-left">Task Operating Material</h5>
  <div>
    <ngx-select style="padding-left: 25px; width: 200px; display: inline-block; float: right !important;" optionValueField="id" optionTextField="str" [items]="languages" [(ngModel)]="selectedLanguage" (select)="languageChanged()"></ngx-select>
  </div>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <!-- <form class="pr-4 pl-4"> -->
    <div class="row">
      <div class="col-md-12">
        <table class="table table table-bordered table-sm" *ngIf="taskOperatingMaterials.length > 0">
          <thead class="thead-blue">
          <tr>
            <th>Operating Material</th>
            <th>Quantity as Required</th>
            <th>Material Quantity</th>
            <th>Quantity Unit</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let taskMaterial of taskOperatingMaterials | keyvalue">
            <td><input type="text" value="{{taskMaterial.value.designation}}" readonly class="form-control" placeholder=""/></td>
            <td><input type="text" value="{{taskMaterial.value.quantityAsRequired==1?'Yes':''}}"  readonly class="form-control" placeholder=""/></td>
            <td><input type="text" value="{{taskMaterial.value.materialQuantity}}" [appThousandSeparator]="taskMaterial.value.materialQuantity" readonly class="form-control" placeholder=""/></td>
            <td>
              <input type="hidden" value="{{taskMaterial.value.quantityUnit}}" readonly class="form-control" placeholder=""/>
              <input type="text" value="{{taskMaterial.value.unitDesignation}}" readonly class="form-control" placeholder=""/>
            </td>
            <td>
              <button [disabled]="disableEditButtons" (click)="edittaskOperatingMaterial(taskMaterial.key)" class="btn btn-info"><img title="Edit" class="icon-mini" src="/assets/icon/edit.png"/></button>&nbsp;
              <button (click)="deleteMaterial(taskMaterial.key)" class="btn btn-danger"><img title="Delete" class="icon-mini" src="/assets/icon/delete.png"/></button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  <!-- </form> -->
  <form class="pr-4 pl-4" [formGroup]="taskOperatingMaterialForm">
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Project Number</label>
      <div class="col-sm-6">
        <input type="text" formControlName="projectNumber" class="form-control" placeholder="" appInfoPromptBox [page]="'taskOperatingMaterial'" [component]="'addEdit'" [field]="'projectNumber'">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Task Name</label>
      <div class="col-sm-6">
        <input type="text" formControlName="taskName" class="form-control" placeholder="" appInfoPromptBox [page]="'taskOperatingMaterial'" [component]="'addEdit'" [field]="'taskName'">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Operating Material</label>
      <div class="col-sm-6">
        <ngx-select [items]="operatingMaterialsData" formControlName="operatingMaterialsId" optionTextField="designation" optionValueField="operatingMaterialsId" (select)="selectOperatingMaterial($event)"  appInfoPromptBox [page]="'taskOperatingMaterial'" [component]="'addEdit'" [field]="'operatingMaterialsId'"></ngx-select>
        <div *ngIf="taskOperatingMaterialForm.controls['operatingMaterialsId'].invalid && (taskOperatingMaterialForm.controls['operatingMaterialsId'].dirty || taskOperatingMaterialForm.controls['operatingMaterialsId'].touched)" class="ng-validator">
          <div *ngIf="taskOperatingMaterialForm.controls['operatingMaterialsId'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Quantity as required</label>
      <div class="col-sm-6">
        <input type="checkbox" formControlName="quantityAsRequired" (change)="updateQuantityValidation()" appInfoPromptBox [page]="'taskOperatingMaterial'" [component]="'addEdit'" [field]="'quantityAsRequired'">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Quantity Unit</label>
      <div class="col-sm-6">
        <ngx-select [items]="quantityUnitData" (select)="validateQuantity()" formControlName="quantityUnit" optionTextField="designationDe" optionValueField="quantityUnitId" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'taskOperatingMaterial'" [component]="'addEdit'" [field]="'quantityUnit'"></ngx-select>
        <div *ngIf="taskOperatingMaterialForm.controls['quantityUnit'].invalid && (taskOperatingMaterialForm.controls['quantityUnit'].dirty || taskOperatingMaterialForm.controls['quantityUnit'].touched)" class="ng-validator">
          <div *ngIf="taskOperatingMaterialForm.controls['quantityUnit'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Material Quantity</label>
      <div class="col-sm-6">
        <input type="text" formControlName="materialQuantity" class="form-control" [appThousandSeparator]="taskOperatingMaterialForm.controls['materialQuantity'].value" placeholder="" [appDecimal]="materialQuantityDecimalLength" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'taskOperatingMaterial'" [component]="'addEdit'" [field]="'materialQuantity'">
        <div *ngIf="taskOperatingMaterialForm.controls['materialQuantity'].invalid && (taskOperatingMaterialForm.controls['materialQuantity'].dirty || taskOperatingMaterialForm.controls['materialQuantity'].touched)" class="ng-validator">
          <div *ngIf="taskOperatingMaterialForm.controls['materialQuantity'].errors.required">
            This field is required.
          </div>
          <div *ngIf="taskOperatingMaterialForm.controls['materialQuantity'].value != '' && taskOperatingMaterialForm.controls['materialQuantity'].errors.valueZero">
            Should be Greater Than 0
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <app-action-comment [btnLoading]="btnLoading" [okBtnName]="btnValue" [closeBtnName]="'Close'" [saveDisable]="taskOperatingMaterialForm.invalid" [mandatoryDescriptionVisible]="true" [commentVisible]="projectFreezeFunction == 'activated'" (okClick)="submitTaskMaterialForm($event)" (cancelClick)="bsModalRef.hide()"></app-action-comment>
</div>

