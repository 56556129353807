import {Component, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import {PartsImportDataSourcePromptBoxComponent} from "../../../../../shared/components/parts-import-data-source-prompt-box/parts-import-data-source-prompt-box.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {faFileImport} from '@fortawesome/free-solid-svg-icons';
import {ProjectSystemSparePartsService} from "../../../../../services/project-system-spare-parts.service";
import {ConfirmationBoxComponent} from "../../../../../shared/components/confirmation-box/confirmation-box.component";
import {ProjectSystemSparePartsComponent} from "../../project-system-spare-parts.component";

@Component({
    selector: 'app-project-system-spare-parts-import-url',
    templateUrl: './project-system-spare-parts-import-url.component.html',
    styleUrls: ['./project-system-spare-parts-import-url.component.scss']
})
export class ProjectSystemSparePartsImportUrlComponent implements OnInit {

    @Input() rowData: any;
    @ViewChild('fileInput') fileInput;

    public event: EventEmitter<any> = new EventEmitter();
    public selectedFile: File = null;
    public  sparePartsData: any = [];
    public faFileImport = faFileImport;
    public sparePartId = "";

    public spinnerVisible = false;
    public spinnerText = "";

    constructor(private projectSystemSparePartsService: ProjectSystemSparePartsService,
                private modalService: BsModalService,
                private notificationService: ToastrService) {
    }

    ngOnInit(): void {
        this.sparePartId = this.rowData['spareNumber'];
    }

    public onChangeFileInput() {
        if (!this.fileInput || !this.fileInput.nativeElement || !this.fileInput.nativeElement.files) return;

        const files: { [key: string]: File } = this.fileInput.nativeElement.files;
        this.selectedFile = files[0];

        if (!this.selectedFile) return;

        this.spinnerText = "Uploading and Validating Data";
        this.spinnerVisible = true;

        let formData = new FormData();
        formData.append('file', this.selectedFile);
        this.projectSystemSparePartsService.uploadSpareParts(formData, this.sparePartId).subscribe((res: any) => {
            if (!res) {
                this.spinnerVisible = false;
                return;
            } else {
                let data: any[] = res.data;
                // console.log(data);
                let startRow : any = res.startRowNumber;
                let endRow : any = res.endRowNumber;

                const initialState = {
                    title: 'Do you want to continue ?',
                    text: 'Start row: ' + startRow + ', End row: ' + endRow,
                    okBtnName: 'OK',
                    closeBtnName: 'Cancel',
                    // titleBackground: 'bg-danger',
                    isCommentVisible: false
                };
                this.spinnerVisible = false;
                let modalRef = this.modalService.show(ConfirmationBoxComponent, {
                    class: 'modal-md',
                    initialState,
                    ignoreBackdropClick: true
                });
                modalRef.content.event.subscribe(res => {
                    if (res?.result?.action == 'cancel') {
                        this.spinnerVisible = false;
                        return;
                    } else
                    {
                        this.spinnerVisible = true;
                        let needToAskFinalDesignation_DESource = false;
                        let needToAskFinalDesignation_ENSource = false;
                        let needToAskFinalSupplierArticleIDSource = false;
                        let needToAskFinalSupplierIDSource = false;
                        let needToAskFinalSystemCodeSource = false;
                        let needToAskFinalSystemNumberSource = false;
                        let needToAskFinalWeightSource = false;

                        for (let obj of data) {
                            if (obj && obj['stadlerArticleIdExists']) {

                                let masterDesignation_DE = obj['masterDesignation_DE']; let designation_DE = obj['designation_DE'];
                                masterDesignation_DE = masterDesignation_DE?masterDesignation_DE.replace('null',''):'';
                                designation_DE = designation_DE?designation_DE.replace('null',''):'';

                                let masterDesignation_EN = obj['masterDesignation_EN']; let designation_EN = obj['designation_EN'];
                                masterDesignation_EN = masterDesignation_EN?masterDesignation_EN.replace('null',''):'';
                                designation_EN = designation_EN?designation_EN.replace('null',''):'';

                                let masterSupplierArticleID = obj['masterSupplierArticleID']; let supplier_article_ID = obj['supplier_article_ID'];
                                masterSupplierArticleID = masterSupplierArticleID?masterSupplierArticleID.replace('null',''):'';
                                supplier_article_ID = supplier_article_ID?supplier_article_ID.replace('null',''):'';

                                let masterSupplierID = obj['masterSupplierID']; let supplier_ID = obj['supplier_ID'];
                                masterSupplierID = masterSupplierID?(""+masterSupplierID).replace('null',''):'';
                                supplier_ID = supplier_ID?(""+supplier_ID).replace('null',''):'';

                                let masterSystemCode = obj['masterSystemCode']; let systemCode = obj['systemCode'];
                                masterSystemCode = masterSystemCode?masterSystemCode.replace('null',''):'';
                                systemCode = systemCode?systemCode.replace('null',''):'';

                                let masterSystemNumber = obj['masterSystemNumber']; let systemNumber = obj['systemNumber'];
                                masterSystemNumber = masterSystemNumber?(""+masterSystemNumber).replace('null',''):'';
                                systemNumber = systemNumber?(""+systemNumber).replace('null',''):'';

                                let masterWeight = obj['masterWeight']; let weight = obj['weight'];
                                masterWeight = masterWeight?(""+masterWeight).replace('null',''):'';
                                weight = weight?(""+weight).replace('null',''):'';

                                if(masterDesignation_DE != designation_DE && masterDesignation_DE != '' && masterDesignation_DE != null &&  designation_DE != '' && designation_DE != null) needToAskFinalDesignation_DESource = true;
                                if(masterDesignation_EN != designation_EN && masterDesignation_EN != '' && masterDesignation_EN != null &&  designation_EN != '' && designation_EN != null)  needToAskFinalDesignation_ENSource = true;
                                if(masterSupplierArticleID != supplier_article_ID && masterSupplierArticleID != '' && masterSupplierArticleID != null &&  supplier_article_ID != '' && supplier_article_ID != null)  needToAskFinalSupplierArticleIDSource = true;
                                if(masterSupplierID != supplier_ID && masterSupplierID != '' && masterSupplierID != null &&  supplier_ID != '' && supplier_ID != null)  needToAskFinalSupplierIDSource = true;
                                if(masterSystemCode != systemCode && masterSystemCode != '' && masterSystemCode != null &&  systemCode != '' && systemCode != null)  needToAskFinalSystemCodeSource = true;
                                if(masterSystemNumber != systemNumber && masterSystemNumber != '' && masterSystemNumber != null &&  systemNumber != '' && systemNumber != null)  needToAskFinalSystemNumberSource = true;
                                if(masterWeight != weight && masterWeight != '' && masterWeight != null &&  weight != '' && weight != null)  needToAskFinalWeightSource = true;
                                

                                // if (obj['masterDesignation_DE'] != obj['designation_DE']) needToAskFinalDesignation_DESource = true;
                                // if (obj['masterDesignation_EN'] != obj['designation_EN']) needToAskFinalDesignation_ENSource = true;
                                // if (obj['masterSupplierArticleID'] != obj['supplier_article_ID']) needToAskFinalSupplierArticleIDSource = true;
                                // if (obj['masterSupplierID'] != obj['supplier_ID']) needToAskFinalSupplierIDSource = true;
                                // if (obj['masterSystemCode'] != obj['systemCode']) needToAskFinalSystemCodeSource = true;
                                // if (obj['masterSystemNumber'] != obj['systemNumber']) needToAskFinalSystemNumberSource = true;
                            }
                        }

                        if (!needToAskFinalDesignation_DESource && !needToAskFinalDesignation_ENSource && !needToAskFinalSupplierArticleIDSource && !needToAskFinalSupplierIDSource && !needToAskFinalSystemCodeSource && !needToAskFinalSystemNumberSource &&  !needToAskFinalWeightSource) {
                            this.spinnerText = "Importing Data";
                            this.projectSystemSparePartsService.importSpareParts(data, this.sparePartId).subscribe((res) => {
                                this.notificationService.success("Successfully Uploaded..", "Success");
                                this.spinnerVisible = false;
                                this.event.emit('refresh');
                            }, ((error) => {
                                const initialState = {
                                    title: 'Upload Unsuccessful..',
                                    okBtnName: 'OK',
                                    closeBtnName: null,
                                    titleBackground: 'bg-danger',
                                    isCommentVisible: false
                                };
                                this.modalService.show(ConfirmationBoxComponent, {
                                    class: 'modal-md',
                                    initialState,
                                    ignoreBackdropClick: true
                                });
                                this.spinnerVisible = false;
                            }))
                            return;
                        }

                        const initialState = {
                            data: data,
                            finalDesignation_DEEnable: needToAskFinalDesignation_DESource,
                            finalDesignation_ENEnable: needToAskFinalDesignation_ENSource,
                            finalSupplierArticleIDEnable: needToAskFinalSupplierArticleIDSource,
                            finalSupplierIDEnable: needToAskFinalSupplierIDSource,
                            finalSystemCodeEnable: needToAskFinalSystemCodeSource,
                            finalSystemNumberEnable: needToAskFinalSystemNumberSource,
                            finalWeightEnable: needToAskFinalWeightSource
                        };

                        let modalRef = this.modalService.show(PartsImportDataSourcePromptBoxComponent, {
                            class: 'modal-xl',
                            initialState,
                            ignoreBackdropClick: true
                        });
                        modalRef.content.event.subscribe(res => {
                            if (res && res?.action == 'ok' && res?.result) {
                                let finalData = res?.result;

                                this.spinnerText = "Importing Data";
                                this.projectSystemSparePartsService.importSpareParts(finalData, this.sparePartId).subscribe((res) => {
                                    this.notificationService.success("Successfully Uploaded..", "Success");
                                    this.spinnerVisible = false;
                                    this.event.emit('refresh');
                                }, ((error) => {
                                    const initialState = {
                                        title: 'Upload Unsuccessful..',
                                        okBtnName: 'OK',
                                        closeBtnName: null,
                                        titleBackground: 'bg-danger',
                                        isCommentVisible: false
                                    };
                                    this.modalService.show(ConfirmationBoxComponent, {
                                        class: 'modal-md',
                                        initialState,
                                        ignoreBackdropClick: true
                                    });
                                    this.spinnerVisible = false;
                                }))
                            } else {
                                this.spinnerVisible = false;
                                return;
                            }
                        });
                    }
                })
            }
        }, ((error) => {
            if (error.error?.exceptionOccurred) {
                const initialState = {
                    title: error.error.message,
                    okBtnName: 'OK',
                    closeBtnName: null,
                    titleBackground: 'bg-danger',
                    isCommentVisible: false
                };
                this.modalService.show(ConfirmationBoxComponent, {
                    class: 'modal-md',
                    initialState,
                    ignoreBackdropClick: true
                });
            } else {
                const initialState = {
                    title: 'Upload Failed',
                    okBtnName: 'OK',
                    closeBtnName: null,
                    titleBackground: 'bg-danger',
                    isCommentVisible: false
                };
                this.modalService.show(ConfirmationBoxComponent, {
                    class: 'modal-md',
                    initialState,
                    ignoreBackdropClick: true
                });
            }
            this.spinnerVisible = false;
            return;
        }));
    }

    public openFileSelectDialog() {
        if (!this.fileInput || !this.fileInput.nativeElement) return;

        this.fileInput.nativeElement.value = null;
        this.fileInput.nativeElement.click();
    }

}
