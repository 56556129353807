<div class="container-fluid wrapper">
  <div class="row title-row">
    <div class="pageTitle col-sm-12">
      <span class="pageSubTitle"> Edit Systems</span>
    </div>
    <div>
      <ngx-select style="padding-left: 25px; width: 200px; display: inline-block; float: right !important;" optionValueField="id" optionTextField="str" [items]="languages" [(ngModel)]="selectedLanguage" (select)="languageChanged()"></ngx-select>
    </div>
  </div>
  <form [formGroup]="systemEditForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-sm-9 col-md-9 offset-3">
        <div class="form-group row">
          <label for="inputEmail3" class="col-sm-3 col-form-label col-form-label-sm">System Level</label>
          <div class="col-sm-5">
            <ngx-select optionValueField="id" placeholder="Select System Level" (select)="onSelectSystemLevel($event)"  optionTextField="value" [items]="systemLevel" formControlName="systemLevel" appInfoPromptBox [page]="'standardSystems'" [component]="'edit'" [field]="'systemLevel'"></ngx-select>
          </div>
        </div>
        <div class="form-group row" *ngIf="level1Show" >
          <label for="inputEmail3" class="col-sm-3 col-form-label col-form-label-sm">Level 1 System</label>
          <div class="col-sm-5">
            <ngx-select optionValueField="systemNumber" [items]="level1SystemNumberList" placeholder="Select Level 1 System Number" optionTextField="systemCode" formControlName="level1System" appInfoPromptBox [page]="'standardSystems'" [component]="'edit'" [field]="'level1System'"></ngx-select>
          </div>
        </div>
        <div class="form-group row" *ngIf="level2Show">
          <label for="inputEmail3" class="col-sm-3 col-form-label col-form-label-sm">Level 2 System</label>
          <div class="col-sm-5">
            <ngx-select optionValueField="systemNumber" [items]="level2SystemNumberList" placeholder="Select Level 2 System Number" optionTextField="systemCode" formControlName="level2System" appInfoPromptBox [page]="'standardSystems'" [component]="'edit'" [field]="'level2System'"></ngx-select>
          </div>
        </div>

        <div class="form-group row">
          <label for="systemDesignationDe" class="col-sm-3 col-form-label col-form-label-sm">System Designation DE</label>
          <div class="col-sm-5">
            <input type="text" class="form-control" formControlName="systemDesignationDe" id="systemDesignationDe" placeholder="Designation DE" appInfoPromptBox [page]="'standardSystems'" [component]="'edit'" [field]="'systemDesignationDe'">
          </div>
        </div>

        <div class="form-group row">
          <label for="systemDesignationEn" class="col-sm-3 col-form-label col-form-label-sm">System Designation EN</label>
          <div class="col-sm-5">
            <input type="text" class="form-control" formControlName="systemDesignationEn" id="systemDesignationEn" placeholder="Designation EN" appInfoPromptBox [page]="'standardSystems'" [component]="'edit'" [field]="'systemDesignationEn'">
          </div>
        </div>

        <div class="form-group row">
          <label for="inputEmail3" class="col-sm-3 col-form-label col-form-label-sm">System Code</label>
          <div class="col-sm-5">
            <input type="text" class="form-control" formControlName="systemCode" id="inputEmail3" placeholder="System Code" appInfoPromptBox [page]="'standardSystems'" [component]="'edit'" [field]="'systemCode'">
          </div>
        </div>
        <div class="form-group row">
          <label for="inputEmail3" class="col-sm-3 col-form-label col-form-label-sm">SBS Code</label>
          <div class="col-sm-5">
            <ngx-select [items]="sbsCodeData" placeholder="Select SBS Code" optionValueField="sbsCode" optionTextField="codeAndDesignation" formControlName="sbsCode" appInfoPromptBox [page]="'standardSystems'" [component]="'edit'" [field]="'sbsCode'"></ngx-select>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputEmail3" class="col-sm-3 col-form-label col-form-label-sm">RAM Engineer</label>
          <div class="col-sm-5">
            <ngx-select optionValueField="stakeholderId" [multiple]="true" [items]="ramEngineerData" placeholder="Select RAM Engineer " optionTextField="fullName" formControlName="ramEngineer" [autoClearSearch]="true" appInfoPromptBox [page]="'standardSystems'" [component]="'edit'" [field]="'ramEngineer'"></ngx-select>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-baseline actionBtnBar">
      <div class="alert-warning no-changes-found-warning" *ngIf="initialValues == currentFormValues()">
        <span>No changes found...</span>
      </div>
      <button appButtonLoader [isLoading]="laddaLoading" id="btnSubmitProject" [disabled]="systemEditForm.invalid == true || initialValues == currentFormValues()" type="submit" class="btn btn-primary col-sm-2 actionBtn">Save</button>
      <button id="btnReset" type="button" routerLink="/systems" class="btn btn-secondary col-sm-2 actionBtn">Close</button>
    </div>
  </form>
</div>
