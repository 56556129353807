import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguageServiceService } from '../services/language-service.service';

@Injectable()
export class AddLangInterceptor implements HttpInterceptor {

  constructor(private languageService: LanguageServiceService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let lan = this.languageService.getCurrentLanguage();

    const authReq = request.clone({
      headers: request.headers.set('Language', lan["id"] ? lan["id"].toString() : "0")
    });

    return next.handle(authReq);
  }
}
