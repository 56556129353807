import { Component, OnInit, Input } from '@angular/core';
import {BreadcrumbServiceService} from '../../../../services/breadcrumb-service.service';

@Component({
  selector: 'app-project-system-spare-url',
  templateUrl: './project-system-spare-url.component.html',
  styleUrls: ['./project-system-spare-url.component.scss']
})
export class ProjectSystemSpareUrlComponent implements OnInit {

  @Input()
  rowData: any;
  projectNumber:any;
  systemNumber:any;
  constructor(public breadcrumbService: BreadcrumbServiceService) { }

  ngOnInit(): void {
    this.projectNumber = this.rowData.projectNumber;
    this.systemNumber = this.rowData.projectSystemId;
  }

  redirectToParts(){
    this.projectNumber = this.rowData.projectNumber;
    this.breadcrumbService.navigate(2, "Parts", "/projects/"+this.projectNumber+"/systems/"+this.systemNumber+"/spare-parts");
  }
}
