import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TaskOperatingMaterialService {

    constructor(
        private http: HttpClient
    )
    { }

    /**
     * Get all task operating materials by task
     * @param taskId
     */
    getTaskOperatingMaterialsByTask(taskId)
    {

        return this.http.get(environment.apiUrl + "/taskoperatingmaterials/task/" + taskId + '/');
    }

    /**
     * Create new task operating material
     * @param data
     */
    createTaskOperatingMaterial(reason, data)
    {
        const httpOptions = {
            params: {
                comment: reason['comment'],
                justificationCategory: reason['justificationCategory'] != null ? reason['justificationCategory'] : -1
            }
        };
        return this.http.post(environment.apiUrl + "/taskoperatingmaterials/", data, httpOptions);
    }

    /**
     * Update existing task operating material
     * @param data
     */
    updateTaskOperatingMaterial(reason, data)
    {
        const httpOptions = {
            params: {
                comment: reason['comment'],
                justificationCategory: reason['justificationCategory'] != null ? reason['justificationCategory'] : -1
            }
        };
        return this.http.put(environment.apiUrl + "/taskoperatingmaterials/", data, httpOptions);
    }


    removeTaskOperatingMaterials(res, taskDbId, operatingMaterialId)
    {
        const httpOptions = {
            params: {
                comment: res['comment'],
                justificationCategory: res['justificationCategory'] ? res['justificationCategory'] : -1
            }
        };
        return this.http.delete(environment.apiUrl + "/taskoperatingmaterials/task/" + taskDbId + "/operatingmaterials/" + operatingMaterialId, httpOptions);
    }
}
