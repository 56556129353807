import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComingSoonComponent } from '../coming-soon/coming-soon.component';
import { ProjectSystemTaskComponent } from './project-system-task/project-system-task.component';


const routes: Routes = [
  { path: 'tasks', component: ComingSoonComponent, data: { animation: 'tasks' }},
  { path: 'projects/:projectNumber/systems/:systemNumber/tasks', component: ProjectSystemTaskComponent, data: { animation: 'projects' }},
  { path: 'projects/:projectNumber/systems/:systemNumber/tools', component: ProjectSystemTaskComponent, data: { animation: 'projects' }},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TasksRoutingModule { }
