import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class JustificationCategoryService {

    constructor(private http: HttpClient) { }

    /**
     * Get All Justification Categories
     */
    getJustificationCategories()
    {
        return this.http.get(environment.apiUrl + "/logs/justificationCategories");
    }
}
