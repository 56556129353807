import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { faPlus, faSync, faSearch, faFile } from '@fortawesome/free-solid-svg-icons';
import { StandardSystemsService } from 'src/app/services/standard-systems.service';
import {ActivatedRoute, Router} from '@angular/router';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SystemLogComponent} from '../system-log/system-log.component';
import { HttpClient } from '@angular/common/http';
import { StakeHolderService } from 'src/app/services/stake-holder.service';
import {TreeTableComponent} from "../../../shared/components/tree-table/pages/tree-table/tree-table.component";
import { SystemRamengineerChangeComponent } from '../system-ramengineer-change/system-ramengineer-change.component';
import { AuthServiceService } from 'src/app/services/auth-service.service';


@Component({
  selector: 'app-systems',
  templateUrl: './systems.component.html',
  styleUrls: ['./systems.component.scss']
})
export class SystemsComponent implements OnInit, AfterViewInit {

  faPlus = faPlus;
  faSync = faSync;
  faFile = faFile;
  ramEngineerList:any=[];
  faSearch = faSearch;
  tableData:any[] = [];
  loading=true;
  searchLadda=false;
  showReset:Boolean = false;
  searchForm:any;
  modalRef:any;
  filterData = [];

  @ViewChild(TreeTableComponent) treeTable: TreeTableComponent;
  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";
  ramEngineerUpdateAuthorised:boolean = false;

  settings = {
    type:'table',
    id:'systemNumber',
    columnManagerEnable:true,
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    freezeColumns:{
      enabled:true,
      left:3,
      right:1
    },
    enableZoom:true,
    filterOptionFunction:(params, filterData)=>{return this.systemService.getAllStandardSystems("distinct-values", filterData, params)},
    columns:[
      {
        title:'Number',
        attribute:'systemNumber',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:100,
      },
      {
        title:'Code',
        attribute:'systemCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:100
      },
      {
        title:'SBS Code',
        attribute:'sbsCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:100
      },
      {
        title:'System Designation DE',
        attribute:'systemDesignationDe',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:250,
      },
      {
        title:'System Designation EN',
        attribute:'systemDesignationEn',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:250,
      },
      // {
      //   title:'System Type',
      //   attribute:'systemType',
      //   type:'text',
      //   filter: {
      //     enabled:true,
      //     isNumericField:false,
      //   },
      //   width:200,
      // },
      {
        title:'RAM Engineer',
        attribute:'ramEngineersStr',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:100
      },
      {
        renderComponent:'<a class="btn btn-info btn-sm text-light btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/edit.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.editSystem(row)},
        width:50
      }
    ]
  };


  constructor(
      private systemService : StandardSystemsService,
      private router : Router,
      private http : HttpClient,
      private modalService:BsModalService,
      private stakeHolderService: StakeHolderService,
      private activatedRoute : ActivatedRoute,
      private authService: AuthServiceService,
  ) {

    activatedRoute.queryParams.subscribe(params => {
      if(params['page']) this.currentPage = +params['page'];
      if(params['column']) this.sortColumn = params['column'];
      if(params['sortOrder']) this.sortOrder = params['sortOrder'];
    });

    this.refreshTable();
  }

  ngOnInit(): void {
    this.searchForm = new UntypedFormGroup({
      systemDesignation : new UntypedFormControl(null),
      systemCode : new UntypedFormControl(null)
    });
    // this.getRamEngineerList();
    this.checkRAMEngineerUpdatePrivileges();
  }

  ngAfterViewInit(){
  }

  /**
   * Load all System list
   */
  refreshTable()
  {
    this.loading = true;
    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;

    this.systemService.getAllStandardSystems("filtered-data", this.filterData, params).subscribe((res:any)=>{
      if(res && res['page']){
        this.pageCount = res['page']['totalPages'];
        this.tableData = res['page']['content'];
      }
      if(res && res['tablePropertyMap']){
        let columns = this.settings.columns;
        let needFillColumnAndTable = false;
        for(let column of columns){
          if(column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
        }
        if(!needFillColumnAndTable) return;

        let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];
        for(let i=0; i<columns.length; i++){
          let data = tablePropertyMap[columns[i].attribute];
          if(!data) continue;
          columns[i].filter['column'] = res['isNative'] ? data['table'] + "." + data['column'] : data['column'];
          columns[i].filter['table'] = data['table'];
        }
        this.loading = false;
      }
      this.settings['isNative'] = res && res['isNative'];
      this.loading = false;
    },error => {
      this.loading = false;
    },()=>{
      this.loading = false;
    });
  }
  filterOrSortChange(event){
    if(!event){
      this.currentPage = 1;
      this.tableData = [];
      return;
    }
    if(!event['sortChanged']) this.currentPage = 1;
    this.sortColumn = event['sortColumn'];
    this.sortOrder = event['sortOrder'];
    this.filterData = event['filterData'];

    this.refreshTable();
  }

  changePage(currentPage){
    this.currentPage = currentPage;
    this.refreshTable();
  }

  changePageSize(pageSize){
    this.pageSize = pageSize;
    this.refreshTable();
  }

  getRamEngineerList() {
    this.stakeHolderService.getStakeHoldersByRole(8).subscribe(res => {
      Object.entries(res).forEach(([key, value]) => {
        this.ramEngineerList.push({value:value['stakeholderId'],title:value['firstName']+'-'+value['lastName']});
      });
      this.settings=Object.assign({},this.settings)
    });
  }

  editSystem(data){
    let systemNumber = data.systemNumber;
    this.router.navigateByUrl('/systems/edit/'+systemNumber);
  }

  public showLogs()
  {
    const initialState = {};
    this.modalRef = this.modalService.show(SystemLogComponent, {class:'modal-xl', initialState,keyboard:false,ignoreBackdropClick: true});
  }

  public showRamEngineerPopup(){
    const initialState = {};
    this.modalRef = this.modalService.show(SystemRamengineerChangeComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
  }

  public checkRAMEngineerUpdatePrivileges(){
    try{
    this.authService.hasPermission('RAM_ENGINEER_BULK_UPDATE').subscribe(res=>{
      this.ramEngineerUpdateAuthorised = res["hasPermission"];
    },(error)=>{
      this.ramEngineerUpdateAuthorised = false;
    });
  }catch (e) {
    this.ramEngineerUpdateAuthorised = false;
  }


    // this.ramEngineerUpdateAuthorised = this.authService.hasPermission('RAM_ENGINEER_BULK_UPDATE').valueOf();
    // console.log(this.ramEngineerUpdateAuthorised);
  }
}
