<div class="modal-header">
  <h5 class="modal-title pull-left">Add Users</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form class="p-3" [formGroup]="userAddFrom" (ngSubmit)="createNewUser()">
  <div class="modal-body">


    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">First Name</label>
      <div class="col-sm-8">
        <input type="text" class="form-control " formControlName="firstName" placeholder="First Name" appInfoPromptBox [page]="'users'" [component]="'add'" [field]="'firstName'"/>

      </div>
    </div>
    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">Last Name</label>
      <div class="col-sm-8">
        <input type="text" class="form-control " formControlName="lastName" placeholder="Last Name" appInfoPromptBox [page]="'users'" [component]="'add'" [field]="'lastName'"/>

      </div>
    </div>


    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">Email</label>
      <div class="col-sm-8">
        <input type="text" class="form-control " formControlName="userEmail" placeholder="Email" appInfoPromptBox [page]="'users'" [component]="'add'" [field]="'userEmail'"/>

      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm">Role</label>
      <div class="col-sm-8">
        <ngx-select optionValueField="systemRoleId" placeholder="Role" optionTextField="systemRole" [items]="roleData" formControlName="primaryRole" appInfoPromptBox [page]="'users'" [component]="'add'" [field]="'primaryRole'"></ngx-select>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm">User Group</label>
      <div class="col-sm-8">
        <ngx-select optionValueField="userGroupId" placeholder="User Group" optionTextField="userGroupName" [items]="userGroupData" formControlName="userGroupId" appInfoPromptBox [page]="'users'" [component]="'add'" [field]="'userGroupId'"></ngx-select>
      </div>
    </div>

    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">Password</label>
      <div class="col-sm-8">
        <input type="password" (keyup)="isValidPassword()" class="form-control " formControlName="password" placeholder="password" appInfoPromptBox [page]="'users'" [component]="'add'" [field]="'password'"/>
      </div>
    </div>

    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">Confirm Password</label>
      <div class="col-sm-8">
        <input type="password" (keyup)="isValidPassword()" class="form-control " formControlName="confirm" placeholder="Confirm" appInfoPromptBox [page]="'users'" [component]="'add'" [field]="'confirm'"/>
        <!-- <span *ngIf="!ismatch" class="ng-validator">Your password and confirmation password do not match</span> -->
      </div>
    </div>
    <div class="form-group row">
      <span *ngIf="!ismatch" class="col-sm-12 ng-validator">Your password and confirmation password do not match</span>
    </div>

    <div class="form-group row">
      <label for="chkActive" class="col-sm-4 col-form-label col-form-label-sm">Active</label>
      <div class="col-sm-8">
        <input style="margin-left : 0px;" type="checkbox" class="form-check-input" formControlName="active" appInfoPromptBox [page]="'users'" [component]="'add'" [field]="'active'"/>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="submit" appButtonLoader [isLoading]="loadingBtn" [disabled]="disableButton" class="btn btn-primary">Save</button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
  </div>
</form>
