import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {TreeNodeComponent} from "../tree-node/tree-node.component";
import { faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit , AfterViewInit {

  // readonly DEFAULT_SCROLL_STEP = 25;
  readonly DEFAULT_SCROLL_STEP = 250;

  faLeftArrow = faChevronLeft;
  faRightArrow = faChevronRight;

  @Output() filterChangeEvent = new EventEmitter<any>();

  @Input() zoomRatio = 1;
  @Input() settings;
  @Input() data;
  @ViewChild('nonFreezeTreeRow', { static: false }) nonFreezeTreeRowEl: ElementRef;
  @ViewChild('freezeTreeRow', { static: false }) freezeTreeRowEl: ElementRef;
  @ViewChild('filterMenu', { static: false }) filterMenuElement: ElementRef;
  @ViewChild('leftFreezeDiv', { static: false }) leftFreezeDivElement: ElementRef;
  @ViewChild('middleScrollDiv', { static: false }) middleScrollDivElement: ElementRef;
  @ViewChild('middleScrollHeader', { static: false }) middleScrollHeaderElement: ElementRef;
  @ViewChild('filterSelectAll', { static: false }) filterSelectAllElement: ElementRef;
  @ViewChild('filterDrpDownSearchInput', { static: false }) filterDrpDownSearchInputElement: ElementRef;
  @ViewChildren(TreeNodeComponent) treeNodes: QueryList<TreeNodeComponent>;

  leftColumns:any[] = [];
  middleColumns:any[] = [];
  rightColumns:any[] = [];

  public columnWidth = 50;
  public rowWidth = 0;
  public scrollDivWidth = 0;
  public freezeTreeHeaderPosition = {top:0, left:0};
  public initialColumnWidth:any[] = [];
  public showFilterMenu = false;
  public showHorizontalFriezedHeader = false;
  public disableScrollRightButton = false;
  public disableScrollLeftButton = true;
  public filter;
  public activeMouseOverRowId = -1;
  public activeMouseOverRowParentId = -1;
  public filterMenuPosition;
  public activeFilters = [];
  public activeSort = null;
  public activeSortOrder = null;
  public filterInputValueMap = new Map<string, string>();
  public filterInputValue = "";
  public filterInputWaitingForResponse = false;
  private horizontalScrollValue = 0;
  public filterOptionLoading = false;
  public showTreeNode = true;
  public variableColumnMinLength;
  public moreThan100RecordsAvailableInFilterOptions = false;
  public tree:TreeComponent;
  public waitingForFilterApply = false;
  public applyFilterResponse = null;

  constructor(private http:HttpClient) { }

  ngOnInit(): void {
    this.settings.loading = true; // to prevent cell text loading before calculateColumnWidths() done
    this.variableColumnMinLength = this.settings.variableColumnMinLength ? this.settings.variableColumnMinLength : 100;
    this.tree = this;
  }

  ngAfterViewInit(){
    this.calculateColumnWidths();
    this.registerFilterMenuCloseEvent();
  }

  redrawNodes(){
    this.showTreeNode = false;
    setTimeout(()=>{
      this.showTreeNode = true;
    },0)
  }

  calculateColumnWidths(){ // if with is not set, set by considering free space.
    if(!this.settings || !this.settings.columns) return;

    let contentWidth = 0;
    if(this.settings.freezeColumns && this.settings.freezeColumns.enabled){
      if(!this.freezeTreeRowEl || !this.freezeTreeRowEl.nativeElement || !this.freezeTreeRowEl.nativeElement.getBoundingClientRect()) return;
      contentWidth = this.freezeTreeRowEl.nativeElement.getBoundingClientRect().width;
    }
    else {
      if(!this.nonFreezeTreeRowEl || !this.nonFreezeTreeRowEl.nativeElement || !this.nonFreezeTreeRowEl.nativeElement.getBoundingClientRect()) return;
      contentWidth = this.nonFreezeTreeRowEl.nativeElement.getBoundingClientRect().width;
    }

    setTimeout(()=>{
      let width = 0;
      let columnCountWithWidth = 0;
      let totalVisibleColumnCount = 0;
      for( let column of this.settings.columns){
        if(!column.isHidden && column.width > 0){
          width = width + column.width;
          columnCountWithWidth++;
        }
        if(!column.isHidden) totalVisibleColumnCount++;
      }

      this.columnWidth = (contentWidth - 40 - width)/(totalVisibleColumnCount - columnCountWithWidth);

      this.changeColumnWidth(totalVisibleColumnCount);
      this.makeFreezeColumns();

      if(this.leftFreezeDivElement && this.leftFreezeDivElement.nativeElement && this.leftFreezeDivElement.nativeElement.getBoundingClientRect()){
        this.freezeTreeHeaderPosition.top = this.leftFreezeDivElement.nativeElement.getBoundingClientRect().top;
        this.freezeTreeHeaderPosition.left = this.leftFreezeDivElement.nativeElement.getBoundingClientRect().left;
        this.showHorizontalFriezedHeader = true;
      }
      else this.showHorizontalFriezedHeader = false;

      this.settings.loading = false;
    },0);
  }

  changeColumnWidth(totalVisibleColumnCount) { // if with is set for all columns, adjust them according the content with
    if(this.settings.freezeColumns && this.settings.freezeColumns.enabled){
      if(!this.freezeTreeRowEl || !this.freezeTreeRowEl.nativeElement || !this.freezeTreeRowEl.nativeElement.getBoundingClientRect()) return;
      this.rowWidth = this.freezeTreeRowEl.nativeElement.getBoundingClientRect().width;
    }
    else {
      if(!this.nonFreezeTreeRowEl || !this.nonFreezeTreeRowEl.nativeElement || !this.nonFreezeTreeRowEl.nativeElement.getBoundingClientRect()) return;
      this.rowWidth = this.nonFreezeTreeRowEl.nativeElement.getBoundingClientRect().width;
    }

    let totalColumnWidth = 0;

    let columnWidthNullFound = false;
    let firstTime = this.initialColumnWidth.length == 0;
    for (let i=0; i<this.settings['columns'].length; i++) {
      let column = this.settings['columns'][i];
      if (column['width'] == null) {
        columnWidthNullFound = true;
      }

      if(firstTime) this.initialColumnWidth.push(column['width']);
      else column['width'] = this.initialColumnWidth[i];

      if(column.isHidden) continue;

      totalColumnWidth += column['width'];
      if (column['type'] != 'text' && column['type'] != 'custom-text') totalVisibleColumnCount--;
    }

    let space = this.rowWidth - 40 - totalColumnWidth;
    let toAdd = space / totalVisibleColumnCount;

    if(this.settings.freezeColumns && this.settings.freezeColumns.enabled && toAdd < 0) return;

    if(columnWidthNullFound && toAdd > 0){
      for (let column of this.settings['columns']) {
        if (column['width'] == null && (column['type'] == 'text' || column['type'] == 'custom-text')) {
          column['width'] += space;
        }
      }
    }
    else if(columnWidthNullFound && toAdd < 0){
      toAdd = (space - this.variableColumnMinLength) / totalVisibleColumnCount;
      for (let column of this.settings['columns']) {
        if (column['width'] && (column['type'] == 'text' || column['type'] == 'custom-text')) {
          column['width'] += toAdd;
        }
        else if (column['width'] == null && (column['type'] == 'text' || column['type'] == 'custom-text')) {
          column['width'] = this.variableColumnMinLength;
        }
      }
    }
    else{
      for (let column of this.settings['columns']) {
        if (column['width'] && (column['type'] == 'text' || column['type'] == 'custom-text')) {
          column['width'] += toAdd;
        }
      }
    }
  }

  makeFreezeColumns(){ // if freezeColumns setting is enabled, creates frozen columns
    if(!this.settings.freezeColumns || !this.settings.freezeColumns.enabled) return;

    let leftColumnCount = this.settings.freezeColumns.left ? this.settings.freezeColumns.left : 1;
    let rightColumnCount = this.settings.freezeColumns.right ? this.settings.freezeColumns.right : this.settings.columns.length;

    let columns = this.settings.columns;
    this.leftColumns = [];
    this.middleColumns = [];
    this.rightColumns = [];
    for(let index=0; index < columns.length; index++)
    {
      if(index+1 <= leftColumnCount){
        this.leftColumns.push(columns[index]);
        if(!columns[index].isHidden) this.rowWidth = this.rowWidth - (columns[index].width ? columns[index].width : this.columnWidth);
      }
      else if(index+1 <= columns.length - rightColumnCount){
        this.middleColumns.push(columns[index]);
      }
      else{
        this.rightColumns.push(columns[index]);
        if(!columns[index].isHidden) this.rowWidth = this.rowWidth - (columns[index].width ? columns[index].width : this.columnWidth);
      }
      this.scrollDivWidth = this.rowWidth - 40;
      // this.scrollDivWidth = this.rowWidth;
    }
  }

  nodeToggled(res){ // toggle tree nodes (only applicable for tree type)
    if(this.settings.freezeColumns && this.settings.freezeColumns.enabled) this.addChildrenToMiddleAndRight(this.treeNodes, res);

    res.parent['expanded'] = res.expanded;
    res.parent['children'] = res.expanded ? res.children : [];
  }

  expandAllNodes(treeNode, path = null){ // expand all tree nodes (only applicable for tree type)
    if(treeNode == null) treeNode = this.treeNodes.get(0);

    if(treeNode.hasChild && !treeNode.expanded) {
      treeNode.treeNodeExpanded();
      this.expandChildren(treeNode, path);
    }
    else if(treeNode.expanded){
      this.expandChildren(treeNode, path);
    }
    else return;
  }

  expandChildren(treeNode, path = null){ // expand all child tree nodes (only applicable for tree type)
    if(!treeNode.hasChild) return;

    setTimeout(()=>{
      if(treeNode.children?.length > 0) {
        for(let child of treeNode.treeNodes){
          let id = -1;
          if(path){
            let parentId = child.rowData['parentId'];
            let idArray = path.split('-');
            for(let i=0; i<idArray.length-1; i++){
              if(idArray[i] == parentId) {
                id = idArray[i+1];
                break;
              }
            }
          }

          if(id == -1 || (id && id == child.rowData[this.settings.id])) this.expandAllNodes(child, path);
        }
        return;
      }
      else this.expandChildren(treeNode, path);
    },0)
  }

  addChildrenToMiddleAndRight(treeNodes:QueryList<TreeNodeComponent>, res:any) : boolean{ 
    // adds freeze column children for middle and right sections
    let id = res['id'];
    let children = res['children'];
    let expanded = res['expanded'];

    if(!id || !children || !treeNodes || treeNodes.length <= 0) return false;

    let found = false;
    for(let node of treeNodes){
      if(!node) continue;
      this.addChildrenToMiddleAndRight(node.treeNodes, res);

      let data = node.rowData;
      if(data[this.settings.id] == id && (node.columnType == 'right' || node.columnType == 'middle')){
        node.children = children;
        node.expanded = expanded;
        found = true;

        setTimeout(()=>{
          for(let childNode of node.treeNodes){
            childNode.parentId =  id;
          }
        },0)
      }
    }
    return found;
  }

  scrollMiddleSectionLeft(){
    if(this.middleScrollDivElement && this.middleScrollDivElement.nativeElement && this.middleScrollHeaderElement && this.middleScrollHeaderElement.nativeElement ){
      if(this.horizontalScrollValue == 0) {
        this.disableScrollLeftButton = true;
        return;
      }

      this.disableScrollRightButton = false;
      this.horizontalScrollValue = this.horizontalScrollValue - this.DEFAULT_SCROLL_STEP;
      this.middleScrollDivElement.nativeElement.scroll(this.horizontalScrollValue, 0);
      this.middleScrollHeaderElement.nativeElement.scroll(this.horizontalScrollValue, 0);
    }
  }

  scrollMiddleSectionRight(){
    if(this.middleScrollDivElement && this.middleScrollDivElement.nativeElement && this.middleScrollHeaderElement && this.middleScrollHeaderElement.nativeElement ){
      let freeSpaceToScroll = this.middleScrollHeaderElement.nativeElement.scrollWidth - this.middleScrollHeaderElement.nativeElement.getBoundingClientRect().width + 500;
      if(this.horizontalScrollValue + this.DEFAULT_SCROLL_STEP > freeSpaceToScroll){
        this.disableScrollRightButton = true;
        return;
      }

      this.disableScrollLeftButton = false;
      this.horizontalScrollValue = this.horizontalScrollValue + this.DEFAULT_SCROLL_STEP;
      this.middleScrollDivElement.nativeElement.scroll(this.horizontalScrollValue, 0);
      this.middleScrollHeaderElement.nativeElement.scroll(this.horizontalScrollValue, 0);
    }
  }

  showFilters(data){
    if(!data || !data['position'] || !data['filter'] ) return;
    this.filter = data['filter'];

    if(!this.filter['table'] || !this.filter['column']) return;

    this.filterMenuPosition = data['position'];
    if(this.filterMenuPosition.left < 200) this.filterMenuPosition.left = 200;

    this.showFilterMenu = true;
    this.filterOptionLoading = true;

    this.filterInputValue =  this.filterInputValueMap.get(this.filter['column']) ? this.filterInputValueMap.get(this.filter['column']) : ''; // change filter input box value according to the column

    this.getFilterValues(this.filter['column']).subscribe((res:any[])=>{
      if(!res || !res['distinctValues']) {
        this.filterOptionLoading = false;
        return;
      }

      let filterOptions = res['distinctValues'];
      if(!filterOptions || !Array.isArray(filterOptions)) {
        this.filterOptionLoading = false;
        return;
      }

      this.moreThan100RecordsAvailableInFilterOptions = filterOptions.length == 100;

      filterOptions.sort();
      let optionSet = [];
      let emptyValueAdded = false;
      for(let option of filterOptions){
        if(option == null || (!this.filter.isNumericField && option == "")){
          if(!emptyValueAdded) {
            this.filter.isNumericField ? optionSet.push(null) : optionSet.push("");
          }
          emptyValueAdded = true;
        }
        else optionSet.push(option);
      }

      let optionList = [];
      for(let optionText of optionSet){
        let obj = {
          value: optionText,
          selected : true,
          toolTip: ""
        }
        optionList.push(obj);
      }
      this.filter['options'] = optionList;

      setTimeout(()=>{
        this.setSelectAllState();
        this.filterDrpDownSearchInputElement?.nativeElement?.focus();
      }, 0);

      this.filterOptionLoading = false;
    });
  }

  setSelectAllState(){
    let isChecked = false;
    let isUnchecked = false;
    for(let option of this.filter['options']){
      if(option['selected']) isChecked = true;
      else isUnchecked = true;
    }

    if(isChecked && isUnchecked) this.changeFilterSelectAllState('indeterminate');
    else if(isChecked) this.changeFilterSelectAllState('checked');
    else this.changeFilterSelectAllState('unchecked');
  }

  setToolTipToFilterOptionsIfTruncated(event, option){
    if(!event || !option) return;
    setTimeout(()=>{
      option['toolTip'] = event.toolTip;
    },0);
  }

  filterAllSelectToggle(checked){
    let filterItem = null;
    for(let i=0; i<this.activeFilters.length; i++){ // find selected filter in active filters
      if(this.activeFilters[i].column == this.filter['column']){
        filterItem = this.activeFilters[i];
      }
    }

    if(checked){
      if(!filterItem) return;

      if(this.filterInputValue && this.filterInputValue != ""){
        let values = [];
        for(let opt of this.filter.options){
          values.push(opt['value']);
        }
        filterItem.values = values;
      }
      else{
        let index = this.activeFilters.indexOf(filterItem);
        if(index < 0) return;

        this.activeFilters.splice(index, 1);
      }
    }
    else{
      if(!filterItem) {
        filterItem = {
          column: this.filter['column'],
          operation: 'IN',
        }
        this.activeFilters.push(filterItem);
      }
      filterItem.values = [];
    }

    for(let option of this.filter.options) {
      option['selected'] = checked;
    }

    this.filter['selected'] = !checked;
    this.applyFilter(false, !checked);
  }

  filterOptionChange(option, state){
    let filterItem = null;
    let itemIndex = -1;
    for(let i=0; i<this.activeFilters.length; i++){ // find selected filter in active filters
      if(this.activeFilters[i].column == this.filter['column']){
        itemIndex = i;
        filterItem = this.activeFilters[i];
      }
    }
    option['selected'] = state;
    if(state == true){
      if(filterItem){
        filterItem['values'].push(option['value']); //  another option selected -> add selected value to active filter item value list
        this.filter['selected'] = !(filterItem['values'].length == this.filter['options'].length);
      }
      else{
        let values = [];
        for(let opt of this.filter.options){
          if(opt['selected']) values.push(opt['value']);
        }
        values.push(option['value']);

        filterItem = { // add input value to match one option -> unselect all -> check value -> clear input -> check another option
          column: this.filter['column'],
          operation: 'IN',
          values: values
        }
        this.activeFilters.push(filterItem);
        this.filter['selected'] = true;
      }
      if(filterItem['values'].length ==  this.filter['options'].length) {
        let index = this.activeFilters.indexOf(filterItem);
        if(index < 0) return;

        if(this.filterInputValue == null || this.filterInputValue == ''){
          this.activeFilters.splice(index, 1);
        }
        this.changeFilterSelectAllState('checked');
      }
      else this.changeFilterSelectAllState('indeterminate');
    }
    else{
      if(filterItem){
        let index = filterItem['values'].indexOf(option['value']); // an option deselected -> remove value from active filter item values
        if(index < 0) return;

        filterItem['values'].splice(index, 1);
        this.filter['selected'] = true;

        if(filterItem['values'].length > 0) this.changeFilterSelectAllState('indeterminate');
        else this.changeFilterSelectAllState('unchecked');
      }
      else {
        let values = [];
        for(let opt of this.filter.options){
          if(option != opt) values.push(opt['value']);
        }
        filterItem = {
          column: this.filter['column'],
          operation: 'IN',
          values: values
        }
        this.activeFilters.push(filterItem); // first option unselected -> create new active filter item with all values except clicked one
        this.filter['selected'] = true;

        this.changeFilterSelectAllState('indeterminate');
      }
    }

    this.applyFilter(false,filterItem['values'].length == 0);
  }

  sortChange(order){
    this.activeSortOrder = order;
    this.activeSort = this.filter['column'];
    this.applyFilter(true, false);
  }

  filterInputValueChange(){
    this.getFilterValues(this.filter['column']).subscribe((res:any[])=>{
      if(!res || !res['distinctValues']) {
        return;
      }

      let filterOptions = res['distinctValues'];
      if(!filterOptions || !Array.isArray(filterOptions)) {
        return;
      }

      this.filterInputValueMap.set(this.filter['column'], this.filterInputValue);
      this.moreThan100RecordsAvailableInFilterOptions = filterOptions.length == 100;

      filterOptions.sort();

      let optionList = [];
      for(let optionText of filterOptions){
        let obj = {
          value: optionText,
          selected : true,
          toolTip: ""
        }
        optionList.push(obj);
      }

      this.filter['options'] = optionList;

      let filterItem = null;
      let itemIndex = -1;
      for(let i=0; i<this.activeFilters.length; i++){
        if(this.activeFilters[i].column == this.filter['column']){
          itemIndex = i;
          filterItem = this.activeFilters[i];
        }
      }
      if(!filterItem){
        filterItem = {
          column: this.filter['column'],
          operation: 'IN',
        }
        this.activeFilters.push(filterItem);
      }
      filterItem['values'] = filterOptions;

      let index = this.activeFilters.indexOf(filterItem);
      if(index < 0) return;

      if(this.filterInputValue == null || this.filterInputValue == ""){
        this.activeFilters.splice(index, 1);
      }

      this.changeFilterSelectAllState('checked');

      this.applyFilter(false, filterOptions.length == 0);

      this.filter['selected'] = this.filterInputValue && this.filterInputValue != '';
    });
  }

  applyFilter(sortChanged, isEmptyGrid){
    this.applyFilterResponse = isEmptyGrid ? null : {
      sortChanged:sortChanged,
      filterData:this.activeFilters,
      sortColumn:this.activeSort,
      sortOrder:this.activeSortOrder
    };

    if(this.waitingForFilterApply) return;

    this.waitingForFilterApply = true;
    setTimeout(()=>{
      this.filterChangeEvent.emit(this.applyFilterResponse);
      this.waitingForFilterApply = false;
    },1000)
  }

  resetFilters(){
    this.activeFilters = [];
    this.activeSort = null;
    this.activeSortOrder = null;
    this.filterInputValueMap = new Map<string, string>();
    this.filterInputValue = "";
    this.filterInputWaitingForResponse = false;

    for(let column of this.settings.columns){
      if(column && column.filter) {
        column.filter.options = null;
        column.filter.selected = false;
      }
    }

    this.applyFilter(false, false);
  }

  registerFilterMenuCloseEvent(){
    document.addEventListener("mousedown", (event) => {
      if (!this.filterMenuElement.nativeElement.contains(event.target)) {
        this.showFilterMenu = false;
      }
    });
  }

  getFilterValues(column, searchString = this.filterInputValue){
    let params = {
      'column':column,
      'inputValue':searchString
    };
    return this.settings.filterOptionFunction(params, this.activeFilters);
  }

  changeFilterSelectAllState(state){
    if(!this.filterSelectAllElement || !this.filterSelectAllElement.nativeElement) return;

    let checkBox = this.filterSelectAllElement.nativeElement;

    checkBox['indeterminate'] = false;
    checkBox['checked'] = false;

    if(state == 'checked'){
      checkBox['checked'] = true;
    }
    else if(state == "indeterminate"){
      checkBox['indeterminate'] = true;
    }
    else{
      checkBox['checked'] = false;
    }

  }

  filterBySearchString(table:string, column:string, searchString:string, attribute:string){ // to call from outside the tree
    this.getFilterValues(column, searchString).subscribe((res:any[])=>{
      if(!res || !res['distinctValues']) return;

      let filterOptions = res['distinctValues'];
      if(!filterOptions || !Array.isArray(filterOptions)) return;

      filterOptions.sort();

      this.filterInputValue = searchString;
      this.filter['table'] = table;
      this.filter['column'] = column;
      this.filter['selected'] = true;

      this.filter['options'] = filterOptions;

      setTimeout(()=>{
        this.setSelectAllState();
      }, 0);

      let filterItem = null;

      this.filterInputValueMap.set(this.filter['column'], this.filterInputValue);
      console.log("Filter Column : " + this.filter['column']);
      console.log("Filter Value : " + this.filterInputValue);

      if(this.filter['column']=="ramengineers_str"){
        filterItem = {
          column: this.filter['column'],
          operation: 'LIKE',
          values: filterOptions
        }
      }else{
        filterItem = {
          column: this.filter['column'],
          operation: 'IN',
          values:filterOptions
        }
      }
      
      this.activeFilters.push(filterItem);

      for(let col of this.settings.columns){
        if(col.attribute == attribute){
          col.filter = this.filter;
        }
      }

      this.applyFilter(false, filterOptions.length == 0);
    })
  }

  deselectAllRadioButtons(){
    for(let i=0; i<this.treeNodes.length; i++){
      let treeNode:TreeNodeComponent = this.treeNodes.get(i);
      treeNode.deselectAllRadioButtons();
    }
  }
}
