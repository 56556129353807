<div class="wrapper">

    <div class="row myRow">

      <div class="col-sm-3 cardCover firstCard" (click)="navigateMenu('cardProjects')">
        <div class="card " id="cardProjects">
          <div class="card-body">
            <h5 class="card-title">Projects</h5>
          </div>
          <img class="cardImage card-img-top" src="assets/img/menu/lowres/projects.jpg" alt="Card image cap">
          <!-- <div class="card-footer">
            <p class="cardSubTitle">Project Information</p>
            <p>Project designation, the client and other information relevant to a project.</p>
          </div> -->
        </div>
      </div>

      <div class="col-sm-3 cardCover" (click)="navigateMenu('cardSystems')">
        <div class="card" id="cardSystems">
          <div class="card-body">
            <h5 class="card-title">Standard Systems</h5>
          </div>
          <img class="cardImage card-img-top" src="assets/img/menu/lowres/systems.jpg" alt="Card image cap">
          <!-- <div class="card-footer">
            <p class="cardSubTitle">Standard Systems</p>
            <p>&nbsp;</p>
          </div> -->
        </div>
      </div>

      <div class="col-sm-3 cardCover" (click)="navigateMenu('cardTasks')">
        <div class="card " id="cardTasks">
          <div class="card-body">
            <h5 class="card-title">Tasks</h5>
          </div>
          <img class="cardImage card-img-top" src="assets/img/menu/lowres/tasks.jpg" alt="Card image cap">
          <!-- <div class="card-footer">
            <p class="cardSubTitle">Project Tasks</p>
            <p>&nbsp;</p>
          </div> -->
        </div>
      </div>
 
    </div> <!-- ROW 01 -->

    <div class="row mb-2 myRow">

      <div class="col-sm-3 cardCover lastCard" (click)="navigateMenu('cardSpares')">
        <div class="card " id="cardSpares">
          <div class="card-body">
            <h5 class="card-title">Spares</h5>
          </div>
          <img class="cardImage card-img-top" src="assets/img/menu/lowres/spares.jpg" alt="Card image cap">
          <!-- <div class="card-footer">
            <p class="cardSubTitle">Parts</p>
            <p>&nbsp;</p>
          </div> -->
        </div>
      </div>

      <div class="col-sm-3 cardCover lastCard" (click)="navigateMenu('cardTooles')">
        <div class="card " id="cardSpares">
          <div class="card-body">
            <h5 class="card-title">Tools</h5>
          </div>
          <img class="cardImage card-img-top" src="assets/img/menu/lowres/tools.jpg" alt="Card image cap">
          <!-- <div class="card-footer">
            <p class="cardSubTitle">Tools</p>
            <p>&nbsp;</p>
          </div> -->
        </div>
      </div>

    </div><!-- ROW 02 -->

  </div> <!-- wrapper -->
