import { Component, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { timeout, timer } from 'rxjs';

import { UserRoleService } from 'src/app/services/user-role.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss']
})
export class AuthorizationComponent implements OnInit {

  p: number = 1;
  selectedRole: any = '';
  selectedCategory: any = 'ALL';

  userRoles: any = [];
  roleCategories: any = [];
  bulkSelected: Boolean = false;
  authorization: any = [];
  actionDisabled: Boolean = true;
  public loadingBtn: Boolean = false;
  public formInvalid: Boolean = true;
  public event: EventEmitter<any> = new EventEmitter();
  public lstSelectedCategory = new UntypedFormControl();
  

  constructor(
    private userRoleService: UserRoleService,
    private userService: UserService,
    private notification: ToastrService
  ) {
    this.getAuthorization();
    this.getCategories();
  }

  ngOnInit(): void {

    this.getUserRoles();

  }



  getUserRoles() {

    this.userRoleService.getAll().subscribe(res => {
      this.userRoles = res;

    });

  }

  getAuthorization() {
    this.authorization = [];
    this.userService.getAuthorization().subscribe(res => {

      Object.entries(res).forEach(([key, value]) => {
        value['selected'] = '';
        this.authorization.push(value);

      });

    });
  }

  getCategories() {
    this.roleCategories = [];
    this.userService.getAuthCategories().subscribe(res => {

      Object.entries(res).forEach(([key, value]) => {
        this.roleCategories.push(value);
      });

      this.lstSelectedCategory.setValue(' ALL');
    });
  }

  onChangeRole(id) {
    this.bulkSelected = false;
    
    this.selectedRole = id;
    this.checkFormValidation();

    this.authorization.forEach(function (element) {
      element.selected = '';
    });
    // alert(id);
    if (id != null && id > -1) {
      this.userService.getUserAuthorization(id).subscribe(res => {

        if (res.toString().length == 0) {

          this.authorization.forEach(function (element) {
            element.selected = '';
          });

        } else {

          Object.entries(res).forEach(([key, value]) => {

            this.authorization.forEach(function (element) {

              if (value.authId == element.id) {
                element.selected = true;
              }

            });

            // console.log('ddddd',this.authorization);

          });
        }

      });
    }

    
  }


  /**
   * Update User authrization
   */
  updateAuthorization() {
    this.loadingBtn = true;
    console.log(this.loadingBtn);
    let data = [];
    let roleId = this.selectedRole;

    this.authorization.forEach(function (element) {

      if (element.selected == true) {

        data.push({

          roleId: roleId,
          authId: element.id,
          actionName: element.actionName,

        });
      }

    });

    if (data.length == 0) {
      
      return this.notification.error('Please select Items');
    }

    try {

      this.userService.updateUserAuthorization(data, this.selectedRole).subscribe(res => {
        if (res) {
          this.notification.success('User Authorization has been updated');
        } else {
          this.notification.error('User Authorization has not been updated');
        }
        this.loadingBtn = false;
      }, error => {
        this.notification.error('User Authorization has not been updated due to Error');
        this.loadingBtn = false;
      });
    } catch (error) {
      //Do Nothing
      this.notification.error('User Authorization has not been updated due to Error');
      timer(1000).subscribe(() => {
        this.loadingBtn = false;
      });
    } finally {
      // Delay execution for 1000 milliseconds (1 second)
      
    }

  }


  selectbulkItems(event) {
    if (event.target.checked) {
      this.authorization.forEach(function (element) {

        element.selected = true;

      });
    } else {
      this.authorization.forEach(function (element) {

        element.selected = '';

      });
    }
  }

  onSelectItem(event, key) {

    if (event.target.checked) {
      this.authorization[key].selected = true;
    } else {
      this.authorization[key].selected = false;
    }
  }

  resetAuth() {
    this.onChangeRole(this.selectedRole);
  }

  onChangeCategory(selectedCategoryText) {
    var id = this.selectedRole;
    this.selectedCategory = selectedCategoryText;

    this.checkFormValidation();

    if (selectedCategoryText == " ALL") {
      console.log(this.selectedRole);

      this.userService.getAuthorization().subscribe(res => {
        this.authorization = [];
        Object.entries(res).forEach(([key, value]) => {
          value['selected'] = '';
          this.authorization.push(value);
        });
        this.onChangeRole(id);
      });
      
    } else {

      this.userService.getAuthorizationFiltered(selectedCategoryText).subscribe(res => {
        this.authorization = [];
        Object.entries(res).forEach(([key, value]) => {
          value['selected'] = '';
          this.authorization.push(value);
        });
        this.onChangeRole(id);
      });
      
    }



    // var lstSelectedRole = document.getElementById("lstSelectedRole") as HTMLSelectElement;
    // alert(lstSelectedRole.value);


  }

  checkFormValidation(){
    console.log(this.selectedCategory);
    console.log(this.selectedRole);

    if((this.selectedCategory.toUpperCase().trim()) == 'ALL'){
      this.formInvalid = (((this.selectedRole==null) || (this.selectedRole == '')) ? true : false);
    }else{
      this.formInvalid = true;
    }
  }



}
