import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class OverallSystemsAndTasksService {

    constructor(private http: HttpClient) { }

    getAllTagsByType(type)
    {
        return this.http.get(environment.apiUrl + "/tags/" + type);
    }

    addTag(newTag)
    {
        return this.http.post(environment.apiUrl + "/tags/", newTag);
    }

    updateTag(tagId, newTag)
    {
        if (!tagId) return;
        return this.http.put(environment.apiUrl + "/tags/" + tagId, newTag);
    }

    deleteTag(tagId)
    {
        if (!tagId) return;
        return this.http.delete(environment.apiUrl + "/tags/" + tagId);
    }

    getOverallTasks(apiType, data, params)
    {
        return this.http.post(environment.apiUrl + "/projects/overallTasks/" + apiType, data, {params: params});
    }

    addAndRemoveTagsFromSystemTasks(data)
    {
        return this.http.post(environment.apiUrl + "/projects/tasks/add-and-remove-tags/", data);
    }

    addTagToSystemTask(taskDbId, newTag)
    {
        return this.http.post(environment.apiUrl + "/projects/addTagToSystemTask/" + taskDbId, newTag);
    }

    removeTagFromSystemTask(taskDbId, tagToRemove)
    {
        return this.http.post(environment.apiUrl + "/projects/removeTagFromSystemTask/" + taskDbId, tagToRemove);
    }

    getOverallSystems(apiType, data, params)
    {
        return this.http.post(environment.apiUrl + "/projects/overallSystems/" + apiType, data, {params: params});
    }

    addTagToProjectSystem(projectSystemId, newTag)
    {
        return this.http.post(environment.apiUrl + "/projects/addTagToProjectSystem/" + projectSystemId, newTag);
    }

    removeTagFromProjectSystem(projectSystemId, tagToRemove)
    {
        return this.http.post(environment.apiUrl + "/projects/removeTagFromProjectSystem/" + projectSystemId, tagToRemove);
    }

    public getAllProjectNumbersFilterData()
    {
        return this.http.get(environment.apiUrl + "/overall-systems-and-tasks/filter-data/project-numbers");
    }

    public getAllTaskManualIdsFilterData()
    {
        return this.http.get(environment.apiUrl + "/overall-systems-and-tasks/filter-data/task-manual-ids");
    }

    public getAllTaskElementsFilterData()
    {
        return this.http.get(environment.apiUrl + "/overall-systems-and-tasks/filter-data/task-elements");
    }

    public getAllTaskActivitiesFilterData()
    {
        return this.http.get(environment.apiUrl + "/overall-systems-and-tasks/filter-data/task-activities");
    }

    public getAllTaskTagsFilterData()
    {
        return this.http.get(environment.apiUrl + "/overall-systems-and-tasks/filter-data/task-tags");
    }

    public getAllSystemCodesFilterData()
    {
        return this.http.get(environment.apiUrl + "/overall-systems-and-tasks/filter-data/system-codes");
    }

    public getAllSystemVariationsFilterData()
    {
        return this.http.get(environment.apiUrl + "/overall-systems-and-tasks/filter-data/system-variations");
    }

    public getAllStadlerArticleIdsFilterData()
    {
        return this.http.get(environment.apiUrl + "/overall-systems-and-tasks/filter-data/stadler-article-ids");
    }

    public getAllMountingLocationsFilterData()
    {
        return this.http.get(environment.apiUrl + "/overall-systems-and-tasks/filter-data/mounting-locations");
    }

    public getAllSystemQuantitiesFilterData()
    {
        return this.http.get(environment.apiUrl + "/overall-systems-and-tasks/filter-data/system-quantities");
    }

    public getAllRamEngineersFilterData()
    {
        return this.http.get(environment.apiUrl + "/overall-systems-and-tasks/filter-data/ram-engineers");
    }

    public getAllSystemTagsFilterData()
    {
        return this.http.get(environment.apiUrl + "/overall-systems-and-tasks/filter-data/system-tags");
    }
}
