import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, Validators} from '@angular/forms';

@Directive({
  selector: '[minMax]',
  providers: [{provide: NG_VALIDATORS, useExisting: MinMaxDirective, multi: true}]

})
export class MinMaxDirective  implements Validator{

  @Input('min') min: number;
  @Input('max') max: number;

  validate(control: AbstractControl): ValidationErrors | null {
    if(control.value == null) return null;

    if(this.min != null && control.value < this.min) {
      return {'minMax':true};
    }
    else if(this.max != null && control.value > this.max) {
      return {'minMax':true};
    }
    else return null;
  }

}
