import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class StadlerArticleService {

    constructor(private http: HttpClient) { }

    getAll()
    {
        return this.http.get(environment.apiUrl + "/stadlerarticle/");
    }

    getArticleById(articleId)
    {
        return this.http.get(environment.apiUrl + "/parts/stadlerarticleid/" + articleId + "/");
    }

    getAllByType(type: Number)
    { // (0 - Part, 1 - Tool, 2 - Operating Material)
        return this.http.get(environment.apiUrl + "/stadlerarticle/type/" + type + "/");
    }

    getStadlerArticleIdsbyProjectSystem(projectSystemId: Number)
    {
        return this.http.get(environment.apiUrl + "/stadlerarticle/projectsystem/" + projectSystemId + "/");
    }

    getStadlerArticleIdsbyProjectSystemFiltered(projectSystemId: Number, filter: String)
    {
        return this.http.get(environment.apiUrl + "/stadlerarticle/projectsystem/" + projectSystemId + "/filtered/" + filter);
    }
    

    getStadlerArticleIdByPart(spareNumber, likeFilter: string = "")
    {
        if (likeFilter == "") likeFilter = "__ALL__";
        return this.http.get(environment.apiUrl + "/stadlerarticle/type/0/spare-part/" + spareNumber + "/filtered/" + likeFilter);
    }
}

