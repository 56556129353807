import { Component, OnInit, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProjectSystemTaskIntervalComponent } from '../project-system-task-interval/project-system-task-interval.component';

@Component({
  selector: 'app-project-system-task-interval-url',
  templateUrl: './project-system-task-interval-url.component.html',
  styleUrls: ['./project-system-task-interval-url.component.scss']
})
export class ProjectSystemTaskIntervalUrlComponent implements OnInit {

  @Input()
  rowData:any;
  constructor(
      private modalService: BsModalService
  ) { }

  ngOnInit(): void {
  }

  /**
   *
   */
  loadTaskIntervalModal(){
    const initialState = {
      param : {
        data:this.rowData
      }
    };
    this.modalService.show(ProjectSystemTaskIntervalComponent, { class: 'modal-xl', initialState,keyboard:false,ignoreBackdropClick: true });
  }

}
