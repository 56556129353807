<div class="modal-header">
  <h5 class="modal-title pull-left">Add Task</h5>
  <div>
    <ngx-select style="padding-left: 25px; width: 200px; display: inline-block; float: right !important;" optionValueField="id" optionTextField="str" [items]="languages" [(ngModel)]="selectedLanguage" (select)="languageChanged()"></ngx-select>
  </div>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form class=" pr-4 pl-4" [formGroup]="projectSystemTaskForm">
  <div class="modal-body scroll">
    &nbsp;
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Status</label>
      <div class="col-sm-7">
        <ngx-select optionValueField="id" optionTextField="value" [items]="isTaskActivatedList" formControlName="isTaskActivated" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'isTaskActivated'"></ngx-select>
      </div>
    </div>
    
    <div class="form-group row">
      <label for="inputEmail3" class="col-sm-4 text-right col-form-label col-form-label-sm">Project Number</label>
      <div class="col-sm-7">
        <input type="text" class="form-control  " readonly formControlName="projectNumber" id="inputEmail3" placeholder="Project Number" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'projectNumber'">
      </div>
    </div>
    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-4 text-right col-form-label col-form-label-sm">Project System</label>
      <div class="col-sm-7">
        <input type="text" class="form-control " readonly formControlName="projectSystem" placeholder="System Number" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'projectSystem'">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Task Manual ID</label>
      <div class="col-sm-7">
        <input type="text" [value]="taskManualId" class="form-control" readonly formControlName="taskId" placeholder="" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'taskId'">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Element</label>
      <div class="col-sm-7">
        <ngx-select (select)="taskElementChange($event)" formControlName="taskElement" optionValueField="id" optionTextField="name" [items]="taskElementData" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'taskElement'"></ngx-select>
        <div *ngIf="projectSystemTaskForm.controls['taskElement'].invalid && (projectSystemTaskForm.controls['taskElement'].dirty || projectSystemTaskForm.controls['taskElement'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['taskElement'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Individual Task Element</label>
      <div class="col-sm-7">
        <input [required]="projectSystemTaskForm.controls['taskElement'].value=='individual'" (blur)="checkTaskElementAndActivity()" type="text" class="form-control" formControlName="individualTaskElement" placeholder="" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'individualTaskElement'">
        <div *ngIf="projectSystemTaskForm.controls['individualTaskElement'].invalid && (projectSystemTaskForm.controls['individualTaskElement'].dirty || projectSystemTaskForm.controls['individualTaskElement'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['individualTaskElement'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Task Nature</label>
      <div class="col-sm-7">
        <ngx-select (select)="getTaskTypesByNature($event)" formControlName="taskNature" placeholder="Select Task Nature" optionValueField="id" optionTextField="name" [items]="taskNatureData" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'taskNature'"></ngx-select>
        <div *ngIf="projectSystemTaskForm.controls['taskNature'].invalid && (projectSystemTaskForm.controls['taskNature'].dirty || projectSystemTaskForm.controls['taskNature'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['taskNature'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Is Non Recurring</label>
      <div class="col-sm-7">
        <ngx-select placeholder="Select" (select)="calculateTaskStage(); checkTaskElementAndActivity()" optionValueField="id" formControlName="isNonRecurring" optionTextField="name" [items]="taskIsNonRecurringData" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'isNonRecurring'"></ngx-select>
        <div *ngIf="showTaskStageWarning" class="alert-warning task-alerts" style="white-space: normal;">
          consider that mileage is not set
        </div>
        <div *ngIf="projectSystemTaskForm.controls['taskNature'].invalid && (projectSystemTaskForm.controls['taskNature'].dirty || projectSystemTaskForm.controls['taskNature'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['taskNature'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Task Type</label>
      <div class="col-sm-7">
        <ngx-select placeholder="Select task type" (select)="changeTaskType($event)" formControlName="taskTypeId" [items]="taskTypeData" optionValueField="taskTypeId" optionTextField="typeDescriptionDe" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'taskTypeId'"></ngx-select>
        <div *ngIf="projectSystemTaskForm.controls['taskTypeId'].invalid && (projectSystemTaskForm.controls['taskTypeId'].dirty || projectSystemTaskForm.controls['taskTypeId'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['taskTypeId'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-4 text-right col-form-label col-form-label-sm">Task Name</label>
      <div class="col-sm-7">
        <input type="text" class="form-control " [readOnly]="taskNameReadOnly" (keyup)="checkTaskElementAndActivity()" formControlName="taskName" placeholder="Task Name" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'taskName'">
        <div *ngIf="projectSystemTaskForm.controls['taskName'].invalid && (projectSystemTaskForm.controls['taskName'].dirty || projectSystemTaskForm.controls['taskName'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['taskName'].errors.required">
            This field is required.
          </div>
          <div *ngIf="projectSystemTaskForm.controls['taskName'].errors.maxlength">
            Should be Less Than 500 Characters.
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Task Suffix</label>
      <div class="col-sm-7">
        <ngx-select  optionValueField="taskSuffixId" (select)="checkTaskElementAndActivity()" formControlName="taskSuffixId" optionTextField="designationDe" placeholder="Select Task Suffix" [items]="taskSuffixData" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'taskSuffixId'"></ngx-select>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-4 text-right col-form-label col-form-label-sm">Interval A</label>
      <div class="col-sm-2">
        <input type = "text" (keyup)="calculateTaskStage()" class="form-control" formControlName="intervalvalue1" [appThousandSeparator]="projectSystemTaskForm.controls['intervalvalue1'].value" placeholder="value" [appDecimal]="0" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'intervalvalue1'">
        <div *ngIf="projectSystemTaskForm.controls['intervalvalue1'].invalid && (projectSystemTaskForm.controls['intervalvalue1'].dirty || projectSystemTaskForm.controls['intervalvalue1'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['intervalvalue1'].errors.required">
            This field is required.
          </div>
          <div *ngIf="projectSystemTaskForm.controls['intervalvalue1'].value != '' && projectSystemTaskForm.controls['intervalvalue1'].errors.valueZero">
            Should be Greater Than 0
          </div>
          <div *ngIf="projectSystemTaskForm.controls['intervalvalue1'].errors.max">
            Should be Less Than 99999999
          </div>
          <div *ngIf="projectSystemTaskForm.controls['intervalvalue1'].errors.min">
            Should be Greater Than 0
          </div>
        </div>
      </div>
      <div class="col-sm-5">
        <ngx-select [allowClear]="true" (select)="calculateTaskStage()" (remove)="calculateTaskStage()" formControlName="intervalunit1" placeholder="Select Interval unit" [items]="finalIntervalData" optionValueField="id" optionTextField="taskUnit" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'intervalunit1'"></ngx-select>
        <div *ngIf="invalidIntervals" class="ng-validator">
          Interval units cannot be duplicate
        </div>
        <div *ngIf="intervalMismatchA" class="ng-validator">
          Mileage unit - mileage interval unit missmatch
        </div>
        <div *ngIf="projectSystemTaskForm.controls['intervalunit1'].invalid && (projectSystemTaskForm.controls['intervalunit1'].dirty || projectSystemTaskForm.controls['intervalunit1'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['intervalunit1'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-4 text-right col-form-label col-form-label-sm">Interval B</label>
      <div class="col-sm-2">
        <input type = "text" (keyup)="calculateTaskStage()" class="form-control" formControlName="intervalvalue2" [appThousandSeparator]="projectSystemTaskForm.controls['intervalvalue2'].value" placeholder="value" [appDecimal]="0" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'intervalvalue2'">
        <div *ngIf="projectSystemTaskForm.controls['intervalvalue2'].invalid && (projectSystemTaskForm.controls['intervalvalue2'].dirty || projectSystemTaskForm.controls['intervalvalue2'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['intervalvalue2'].errors.required">
            This field is required.
          </div>
          <div *ngIf="projectSystemTaskForm.controls['intervalvalue2'].value != '' && projectSystemTaskForm.controls['intervalvalue2'].errors.valueZero">
            Should be Greater Than 0
          </div>
          <div *ngIf="projectSystemTaskForm.controls['intervalvalue2'].errors.max">
            Should be Less Than 99999999
          </div>
          <div *ngIf="projectSystemTaskForm.controls['intervalvalue2'].errors.min">
            Should be Greater Than 0
          </div>
        </div>
      </div>
      <div class="col-sm-5">
        <ngx-select [allowClear]="true" (select)="calculateTaskStage()" (remove)="calculateTaskStage()" formControlName="intervalunit2" placeholder="Select Interval unit" [items]="finalIntervalData" optionValueField="id" optionTextField="taskUnit" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'intervalunit2'"></ngx-select>
        <div *ngIf="invalidIntervals" class="ng-validator">
          Interval units cannot be duplicate
        </div>
        <div *ngIf="intervalMismatchB" class="ng-validator">
          Mileage unit - mileage interval unit missmatch
        </div>
        <div *ngIf="projectSystemTaskForm.controls['intervalunit2'].invalid && (projectSystemTaskForm.controls['intervalunit2'].dirty || projectSystemTaskForm.controls['intervalunit2'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['intervalunit2'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Stages</label>
      <div class="col-sm-7">
        <ngx-select placeholder="Select Task Stage" formControlName="taskStageId" optionValueField="taskStageId"  optionTextField="designation" [items]="taskStagesData" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'taskStageId'"></ngx-select>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Pre- / Postprocessing</label>
      <div class="col-sm-7">
        <ngx-select #ppTasksDropdown formControlName="ppTasksOfNonPpTask" optionValueField="taskDbId"  optionTextField="taskText" [items]="prePostProcessingTaskData" (select)="addToPPTasks()" (remove)="addToPPTasks()" (keyup)="onKeyUpPPTasks($event)" (paste)="ppTaskOnPaste($event)" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'ppTasksOfNonPpTask'"></ngx-select>
      </div>
    </div>
    <div class="ppTasks row" *ngIf="selectedPPTasks?.length > 0">
      <div class="col-sm-10 table-div">
        <table class="table">
          <thead>
          <tr>
            <th>#</th>
            <th>Task Manual ID</th>
            <th>Type</th>
            <th>Task Name</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let task of selectedPPTasks as array; let i = index" (click)="currentSelectedPPTask=i" [ngClass]="{'row-selected':currentSelectedPPTask == i}">
            <td><b>{{i+1}}</b></td>
            <td>{{task['taskId']}}</td>
            <td>{{task['ppType']}}</td>
            <td>{{task['taskName']}}</td>
            <td><button type="button" class="close-btn" (click)="removeFromPPTasks(task)"><i class="fa fa-times"></i></button></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="col-sm-2 d-flex align-items-center" *ngIf="selectedPPTasks.length > 1">
        <div class="ppTask-buttons d-flex flex-column ">
          <button [disabled]="currentSelectedPPTask<=0 || selectedPreTaskCount == currentSelectedPPTask" type="button" class="icon-btn" (click)="movePPTaskToUp()"><i class="fa fa-chevron-circle-up"></i></button>
          <button [disabled]="currentSelectedPPTask<0 || currentSelectedPPTask == selectedPPTasks.length-1 || selectedPreTaskCount-1 == currentSelectedPPTask" type="button" class="icon-btn" (click)="movePPTaskToDown()"><i class="fa fa-chevron-circle-down"></i></button>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">PP Type</label>
      <div class="col-sm-7">
        <ngx-select placeholder="Select PP Type" [required]="projectSystemTaskForm.controls['taskNature'].value == 'PP'" (select)="changePpType()" formControlName="ppType" [items]="ppTypeData" optionValueField="value" optionTextField="text" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'ppType'"></ngx-select>
        <div *ngIf="projectSystemTaskForm.controls['ppType'].invalid && (projectSystemTaskForm.controls['ppType'].dirty || projectSystemTaskForm.controls['ppType'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['ppType'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Post Processing task reverse order from referred pre processing task</label>
      <div class="col-sm-7">
        <ngx-select placeholder="Select Pre Processing Task" formControlName="ppTaskOfPpTask" [items]="preProcessingTaskData" optionValueField="taskDbId" optionTextField="taskId" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'ppTaskOfPpTask'"></ngx-select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Maintenance Level</label>
      <div class="col-sm-7">
        <ngx-select (select)="changeMaintainanceLevel($event)" placeholder="Select Maintenance Level" formControlName="maintenanceLevel" optionValueField="id" optionTextField="name" [items]="taskMaintainanceLevelData" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'maintenanceLevel'"></ngx-select>
        <div *ngIf="showMaintenanceLevelWarning" class="alert-warning task-alerts" style="white-space: normal;">
          consider that existing articles in task parts, task operating materials, task tools and infrastructure will be cleared
        </div>
        <div *ngIf="projectSystemTaskForm.controls['maintenanceLevel'].invalid && (projectSystemTaskForm.controls['maintenanceLevel'].dirty || projectSystemTaskForm.controls['maintenanceLevel'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['maintenanceLevel'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>


    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Maintenance Location</label>
      <div class="col-sm-7">
        <ngx-select  (select)="changeMaintainanceLocation($event)" (remove)="changeMaintainanceLocation($event)" [items]="maintenanceLocationData | taskStageFilter:onTrainSelected" autoClearSearch = "true" [multiple]="true" placeholder="Select Location" formControlName="tskMaintenanceLocation" optionValueField="mountingLocationId" optionTextField="designationDe" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'tskMaintenanceLocation'"></ngx-select>
        <div *ngIf="projectSystemTaskForm.controls['tskMaintenanceLocation'].invalid && (projectSystemTaskForm.controls['tskMaintenanceLocation'].dirty || projectSystemTaskForm.controls['tskMaintenanceLocation'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['tskMaintenanceLocation'].errors?.required">
            This field is required.
          </div>
        </div>
        <div *ngIf="invalidMaintenanceLocation" class="ng-validator">
          cannot be selected, because maintenance level is on-train.
        </div>
      
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Execution time </label>
      <div class="col-sm-5">
        <button style="float:left" type="button" class="btn btn-auxiliary" (click)="addNewExecutionTimeField()">+</button>
        <div *ngIf="validExecutionTime==false" class="ng-validator" style="float: left; margin-left: 10px; margin-top : 5px;">
            Invalid Execution Time found. You have to remove the empty rows (if any).
        </div>
      </div>
    </div>
    
    <div formArrayName="executionTime">
      <div class="form-group row" *ngFor="let field of executionTimeField.controls; let i = index">
        <label class="col-sm-4 col-form-label col-form-label-sm text-right">Worker {{i+1}} [min]</label>
        <div class="col-sm-5">
        <input type = "text" [appDecimal]="1"  class="form-control" (keyup)="updateExecutionTime(i)" [formControlName]="i" placeholder="Execution time">
        <!-- <ng-container *ngIf="executionTimeError[i]">
          <div class="ng-validator">
            This field is required
          </div>
          <div *ngIf="projectSystemTaskForm.controls[i].value != '' && projectSystemTaskForm.controls[i].value < 1">
            Should be Greater Than or Equal 1
          </div>
        </ng-container> -->
        <!-- <ng-container *ngIf="executionTimeError.length > 0 && ( !executionTimeError[i] || (projectSystemTaskForm.controls[i].value != null && projectSystemTaskForm.controls[i].value != '' && projectSystemTaskForm.controls[i].value < 1))">
          <div class="ng-validator">
            Should be greater than or equals to 1
          </div>
        </ng-container>
         -->
        <ng-container *ngIf="executionTimeError[i]==2">
          <div class="ng-validator">
            This field is required. <span *ngIf="i>0">You have to remove the empty rows (if any).</span>
          </div>
        </ng-container>
        <ng-container *ngIf="executionTimeError[i]==1">
          <div class="ng-validator">
            This should be greater than or equals to 0.1
          </div>
        </ng-container>
        <ng-container *ngIf="executionTimeError[i]==3">
          <div class="ng-validator">
            This should be Less than the first worker execution time
          </div>
        </ng-container>
        
      </div>
       <div class="col-sm-1">
        <button *ngIf="i" type="button" class="btn btn-auxiliary" (click)="removeExecutionTime(i)">-</button>
       </div>
       
        
      </div>
    </div>
   
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Waiting Time [min]</label>
      <div class="col-sm-7">
        <input type = "text" class="form-control" formControlName="waitingTime" [appThousandSeparator]="projectSystemTaskForm.controls['waitingTime'].value" placeholder="Waiting Time" [appDecimal]="1" minMax [min]="1" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'waitingTime'">
        <div *ngIf="projectSystemTaskForm.controls['waitingTime'].invalid && (projectSystemTaskForm.controls['waitingTime'].dirty || projectSystemTaskForm.controls['waitingTime'].touched)" class="ng-validator">
         
        </div>
      </div>
    </div>



    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Execution time source</label>
      <div class="col-sm-5">
        <input type = "text" class="form-control" formControlName="executionTimeSource" (keyup)="validateExecutionTimeSource()" placeholder="Execution time source" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'executionTimeSource'" appMandatoryField [conditional]="true">
        <ng-container *ngIf="!isExecutionTimeEmpty && !isExecutionTimeSourceValid">
          <div class="ng-validator">
            This field is required 
          </div>
          </ng-container>
          
        <!-- <div *ngIf="projectSystemTaskForm.controls['executionTimeSource'].invalid && (projectSystemTaskForm.controls['executionTimeSource'].dirty || projectSystemTaskForm.controls['executionTimeSource'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['executionTimeSource'].errors.maxlength">
            Maximum Length is 100
          </div>
          <div *ngIf="projectSystemTaskForm.controls['executionTimeSource'].errors?.required" class="ng-validator">
            This field is required.
          </div>
        </div> -->
      </div>
      <div class="col-sm-2 form-switch">
        <div class="form-check" style="padding-top: 0.3rem">
          <input class="form-check-input" type="checkbox" value="" [disabled]="isExecutionTimeEmpty" [checked] = "isValidExecutionTime && !isExecutionTimeEmpty" id="validExecutionTime" (change)="validExecutionTimeClicked($event)" (click)="validExecutionTimeClicked($event)">
          <label class="form-check-label" for="validExecutionTime">
            Valid
          </label>
        </div>
      </div>

    </div>

    <!-- Hidden from display as per the task S05:BUFFER 07: in task add / edit -->
    <div style="display: none;" class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Quantity Workers</label>
      <div class="col-sm-7">
        <input type = "text" readonly class="form-control" formControlName="quantityWorkers" [appThousandSeparator]="projectSystemTaskForm.controls['quantityWorkers'].value" placeholder="Quantity Workers" [appDecimal]="1" minMax [min]="1" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'quantityWorkers'">
        <div *ngIf="projectSystemTaskForm.controls['quantityWorkers'].invalid && (projectSystemTaskForm.controls['quantityWorkers'].dirty || projectSystemTaskForm.controls['quantityWorkers'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['quantityWorkers'].value != '' && projectSystemTaskForm.controls['quantityWorkers'].errors.minMax">
            Should be Greater Than or Equal 1
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Description Of External Service</label>
      <div class="col-sm-7">
        <input type = "text" class="form-control" formControlName="externalServiceDescription" placeholder="Description" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'externalServiceDescription'">
        <div *ngIf="projectSystemTaskForm.controls['externalServiceDescription'].invalid && (projectSystemTaskForm.controls['externalServiceDescription'].dirty || projectSystemTaskForm.controls['externalServiceDescription'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['externalServiceDescription'].errors.maxlength">
            Maximum Length is 255
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 text-right col-form-label col-form-label-sm">External Service Cost</label>
      <div class="col-sm-2">
        <input [required]="projectSystemTaskForm.controls['externalServiceCurrency'].value && !projectSystemTaskForm.controls['externalServiceCost'].value" type = "text" [appThousandSeparator]="projectSystemTaskForm.controls['externalServiceCost'].value" class="form-control" formControlName="externalServiceCost" placeholder="Cost" [appDecimal]="2" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'externalServiceCost'">
        <div *ngIf="projectSystemTaskForm.controls['externalServiceCost'].errors?.required" class="ng-validator">
          This field is required.
        </div>
        <div *ngIf="projectSystemTaskForm.controls['externalServiceCost'].invalid && (projectSystemTaskForm.controls['externalServiceCost'].dirty || projectSystemTaskForm.controls['externalServiceCost'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['externalServiceCost'].value != '' && projectSystemTaskForm.controls['externalServiceCost'].errors.valueZero">
            Should be Greater Than 0
          </div>
          <div *ngIf="projectSystemTaskForm.controls['externalServiceCost'].errors.max">
            Should be Less Than 999999.99
          </div>
        </div>
      </div>
      <div class="col-sm-5">
        <ngx-select [required]="projectSystemTaskForm.controls['externalServiceCost'].value > 0 && !projectSystemTaskForm.controls['externalServiceCurrency'].value" [allowClear]="true" formControlName="externalServiceCurrency" placeholder="Select Currency" [items]="currencyList" optionValueField="code" optionTextField="code" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'externalServiceCurrency'"></ngx-select>
        <div *ngIf="projectSystemTaskForm.controls['externalServiceCurrency'].errors?.required" class="ng-validator">
          This field is required.
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Employee Qualification</label>
      <div class="col-sm-7">
        <ngx-select placeholder="Select Employee Qualification" formControlName="employeeQualificationId" [items]="qualificationData"  optionValueField="employeeQualificationId" optionTextField="designationDe" class="mandatory-field" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'employeeQualificationId'"></ngx-select><span *ngIf="projectSystemTaskForm.controls['employeeQualificationId'].enabled " class="mandatory-asterisk">*</span>
        <div *ngIf="projectSystemTaskForm.controls['employeeQualificationId'].invalid && (projectSystemTaskForm.controls['employeeQualificationId'].dirty || projectSystemTaskForm.controls['employeeQualificationId'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['employeeQualificationId'].errors?.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Specific Employee Qualification</label>
      <div class="col-sm-7">
        <input type="text" formControlName="specificEmployeeQualification" class="form-control input-box mandatory-field" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'specificEmployeeQualification'"><span *ngIf="projectSystemTaskForm.controls['specificEmployeeQualification'].enabled" class="mandatory-asterisk">*</span>
        <div *ngIf="projectSystemTaskForm.controls['specificEmployeeQualification'].invalid && (projectSystemTaskForm.controls['specificEmployeeQualification'].dirty || projectSystemTaskForm.controls['specificEmployeeQualification'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['specificEmployeeQualification'].errors.maxlength">
            Maximum Length is 255
          </div>
          <div *ngIf="projectSystemTaskForm.controls['specificEmployeeQualification'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Employee Type</label>
      <div class="col-sm-7">
        <ngx-select placeholder="Select Employee Type" formControlName="employeeTypeId" optionValueField="employeeTypeId" optionTextField="designationDe" [items]="employeeTypeData" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'employeeTypeId'"></ngx-select>
        <div *ngIf="projectSystemTaskForm.controls['employeeTypeId'].invalid && (projectSystemTaskForm.controls['employeeTypeId'].dirty || projectSystemTaskForm.controls['employeeTypeId'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['employeeTypeId'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Infrastructure</label>
      <div class="col-sm-7">
        <ngx-select [items]="infrastructureData" autoClearSearch = "true" [multiple]="true" placeholder="Select Infrastructure" formControlName="tskInfrastructure" optionValueField="infrastructureId" optionTextField="designationDe" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'tskInfrastructure'"></ngx-select>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Quantity</label>
      <div class="col-sm-2">
        <input type="text" class="form-control" (blur)="changeQuantities()" formControlName="taskQuantityPerVehicle" [appThousandSeparator]="projectSystemTaskForm.controls['taskQuantityPerVehicle'].value" placeholder="" [appDecimal]="0" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'taskQuantityPerVehicle'">
        <div *ngIf="projectSystemTaskForm.controls['taskQuantityPerVehicle'].invalid && (projectSystemTaskForm.controls['taskQuantityPerVehicle'].dirty || projectSystemTaskForm.controls['taskQuantityPerVehicle'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['taskQuantityPerVehicle'].errors.required">
            This field is required.
          </div>
          <div *ngIf="projectSystemTaskForm.controls['taskQuantityPerVehicle'].value != '' && projectSystemTaskForm.controls['taskQuantityPerVehicle'].errors.valueZero">
            Should be Greater Than 0
          </div>
          <div *ngIf="projectSystemTaskForm.controls['taskQuantityPerVehicle'].errors.max">
            Should be Less Than or Equal 2147483647
          </div>
        </div>
      </div>
      <div class="col-sm-1 p-0 d-flex align-items-center">
        <label class="col-form-label col-form-label-sm text-right">Per Vehicle</label>
      </div>
      <div class="col-sm-2">
        <input type="text" class="form-control" (blur)="changeQuantities()" formControlName="taskQuantityPerFleet" [appThousandSeparator]="projectSystemTaskForm.controls['taskQuantityPerFleet'].value" placeholder="" [appDecimal]="0" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'taskQuantityPerFleet'">
        <div *ngIf="projectSystemTaskForm.controls['taskQuantityPerFleet'].invalid && (projectSystemTaskForm.controls['taskQuantityPerFleet'].dirty || projectSystemTaskForm.controls['taskQuantityPerFleet'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['taskQuantityPerFleet'].errors.required">
            This field is required.
          </div>
          <div *ngIf="projectSystemTaskForm.controls['taskQuantityPerFleet'].value != '' && projectSystemTaskForm.controls['taskQuantityPerFleet'].errors.valueZero">
            Should be Greater Than 0
          </div>
          <div *ngIf="projectSystemTaskForm.controls['taskQuantityPerFleet'].errors.max">
            Should be Less Than or Equal 2147483647
          </div>
        </div>
      </div>
      <div class="col-sm-1 p-0 d-flex align-items-center">
        <label class="col-form-label col-form-label-sm text-right">Per Fleet</label>
      </div>
      <div class="col-sm-1 d-flex align-items-center">
        <input class="form-check-input" type="checkbox" (change)="changeQuantities()" formControlName="isPerFleetQuantity">
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Source</label>
      <div class="col-sm-7">
        <ngx-select formControlName="taskSourceId"  placeholder="Select Task Source" (select)="changeTaskSource($event)" optionValueField="taskSourceId" optionTextField="designationDe" [items]="taskSourceData" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'taskSourceId'"></ngx-select>
        <div *ngIf="projectSystemTaskForm.controls['taskSourceId'].invalid && (projectSystemTaskForm.controls['taskSourceId'].dirty || projectSystemTaskForm.controls['taskSourceId'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['taskSourceId'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Source Standard</label>
      <div class="col-sm-7" *ngIf="projectSystemTaskForm.controls['taskSourceId'].value == 4">
        <ngx-select [items]="taskStandardData" optionTextField="standardCode" optionValueField="taskSourceStandardsId" formControlName="taskSourceStandardId"></ngx-select>
      </div>
      <div class="col-sm-7" *ngIf="projectSystemTaskForm.controls['taskSourceId'].value != 4">
        <input type="text" formControlName="taskSourceStandardId" class="form-control">
        <div *ngIf="projectSystemTaskForm.controls['taskSourceStandardId'].invalid && (projectSystemTaskForm.controls['taskSourceStandardId'].dirty || projectSystemTaskForm.controls['taskSourceStandardId'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['taskSourceStandardId'].errors.max">
            Should be Less Than or Equal 2147483647
          </div>
        </div>
      </div>
    </div>
     -->
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Source Standard</label>
      <div class="col-sm-7" >
        <input type = "text" class="form-control" formControlName="taskSourceStandard" placeholder="Task Source Standard" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'taskSourceStandard'">
        <div *ngIf="projectSystemTaskForm.controls['taskSourceStandard'].invalid && (projectSystemTaskForm.controls['taskSourceStandard'].dirty || projectSystemTaskForm.controls['taskSourceStandard'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['taskSourceStandard'].errors.maxlength">
            Maximum Length is 255
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Specific Task Reason</label>
      <div class="col-sm-7">
        <input type="text" formControlName="specificTaskReason" class="form-control" placeholder="Reason" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'specificTaskReason'">
        <div *ngIf="projectSystemTaskForm.controls['specificTaskReason'].invalid && (projectSystemTaskForm.controls['specificTaskReason'].dirty || projectSystemTaskForm.controls['specificTaskReason'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['specificTaskReason'].errors.maxlength">
            Maximum Length is 255
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Influence</label>
      <div class="col-sm-7">
        <ngx-select [items]="taskInfluenceData" optionValueField="id" optionTextField="name" formControlName="taskInfluenceId" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'taskInfluenceId'"></ngx-select>
        <div *ngIf="projectSystemTaskForm.controls['taskInfluenceId'].invalid && (projectSystemTaskForm.controls['taskInfluenceId'].dirty || projectSystemTaskForm.controls['taskInfluenceId'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['taskInfluenceId'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">FMECA ID</label>
      <div class="col-sm-7">
        <input type="text" formControlName="fmecaId" class="form-control" placeholder="FMECA ID" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'fmecaId'">
        <div *ngIf="projectSystemTaskForm.controls['fmecaId'].invalid && (projectSystemTaskForm.controls['fmecaId'].dirty || projectSystemTaskForm.controls['fmecaId'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['fmecaId'].errors.maxlength">
            Quantity of characters exceeds 1000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">SRAC ID</label>
      <div class="col-sm-7">
        <input type="text" formControlName="taskSracId" class="form-control" placeholder="SRAC ID" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'taskSracId'">
        <div *ngIf="projectSystemTaskForm.controls['taskSracId'].invalid && (projectSystemTaskForm.controls['taskSracId'].dirty || projectSystemTaskForm.controls['taskSracId'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['taskSracId'].errors.maxlength">
            Maximum Length is 1000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Target Manual</label>
      <div class="col-sm-7">
        <ngx-select formControlName="targetManualId"  placeholder="Select Target manual" optionValueField="targetManualId" optionTextField="designationDe" [items]="targetManualData" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'targetManualId'"></ngx-select>
        <div *ngIf="projectSystemTaskForm.controls['targetManualId'].invalid && (projectSystemTaskForm.controls['targetManualId'].dirty || projectSystemTaskForm.controls['targetManualId'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['targetManualId'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Tag</label>
      <div class="col-sm-7">
        <ngx-select [items]="taskTagList" [multiple]="true" formControlName="taskTags" optionValueField="tagId" optionTextField="tagText" [placeholder]="'Task Tags'" [autoClearSearch]="true" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'taskTags'"></ngx-select>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Reference Documents</label>
      <div class="col-sm-7">
        <textarea formControlName="supplierDocReference" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'supplierDocReference'"></textarea>
        <div *ngIf="projectSystemTaskForm.controls['supplierDocReference'].invalid && (projectSystemTaskForm.controls['supplierDocReference'].dirty || projectSystemTaskForm.controls['supplierDocReference'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['supplierDocReference'].errors.maxlength">
            Maximum length is 1000
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Difference To Supplier Doc</label>
      <div class="col-sm-7">
        <textarea formControlName="differenceToSupplierDoc" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'differenceToSupplierDoc'"></textarea>
        <div *ngIf="projectSystemTaskForm.controls['differenceToSupplierDoc'].invalid && (projectSystemTaskForm.controls['differenceToSupplierDoc'].dirty || projectSystemTaskForm.controls['differenceToSupplierDoc'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['differenceToSupplierDoc'].errors.maxlength">
            Maximum length is 1000
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Description</label>
      <div class="col-sm-7">
        <textarea formControlName="taskDescription" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'taskDescription'"></textarea>
        <div *ngIf="projectSystemTaskForm.controls['taskDescription'].invalid && (projectSystemTaskForm.controls['taskDescription'].dirty || projectSystemTaskForm.controls['taskDescription'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['taskDescription'].errors.maxlength">
            Maximum length is 10'000
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Comments Doc Department</label>
      <div class="col-sm-7">
        <textarea formControlName="commentsDocDepartment" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'commentsDocDepartment'"></textarea>
        <div *ngIf="projectSystemTaskForm.controls['commentsDocDepartment'].invalid && (projectSystemTaskForm.controls['commentsDocDepartment'].dirty || projectSystemTaskForm.controls['commentsDocDepartment'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['commentsDocDepartment'].errors.maxlength">
            Maximum length is 10'000
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Comments RAM Engineer</label>
      <div class="col-sm-7">
        <textarea formControlName="commentsRamEngineer" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'commentsRamEngineer'"></textarea>
        <div *ngIf="projectSystemTaskForm.controls['commentsRamEngineer'].invalid && (projectSystemTaskForm.controls['commentsRamEngineer'].dirty || projectSystemTaskForm.controls['commentsRamEngineer'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['commentsRamEngineer'].errors.maxlength">
            Maximum length is 10'000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Buffer Field 1</label>
      <div class="col-sm-7">
        <textarea formControlName="bufferField01" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'bufferField01'"></textarea>
        <div *ngIf="projectSystemTaskForm.controls['bufferField01'].invalid && (projectSystemTaskForm.controls['bufferField01'].dirty || projectSystemTaskForm.controls['bufferField01'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['bufferField01'].errors.maxlength">
            Maximum Length is 1000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Buffer Field 2</label>
      <div class="col-sm-7">
        <textarea formControlName="bufferField02" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'bufferField02'"></textarea>
        <div *ngIf="projectSystemTaskForm.controls['bufferField02'].invalid && (projectSystemTaskForm.controls['bufferField02'].dirty || projectSystemTaskForm.controls['bufferField02'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['bufferField02'].errors.maxlength">
            Maximum Length is 1000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Buffer Field 3</label>
      <div class="col-sm-7">
        <textarea formControlName="bufferField03" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'bufferField03'"></textarea>
        <div *ngIf="projectSystemTaskForm.controls['bufferField03'].invalid && (projectSystemTaskForm.controls['bufferField03'].dirty || projectSystemTaskForm.controls['bufferField03'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['bufferField03'].errors.maxlength">
            Maximum Length is 1000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Buffer Field 4</label>
      <div class="col-sm-7">
        <textarea formControlName="bufferField04" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'bufferField04'"></textarea>
        <div *ngIf="projectSystemTaskForm.controls['bufferField04'].invalid && (projectSystemTaskForm.controls['bufferField04'].dirty || projectSystemTaskForm.controls['bufferField04'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['bufferField04'].errors.maxlength">
            Maximum Length is 1000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Buffer Field 5</label>
      <div class="col-sm-7">
        <textarea formControlName="bufferField05" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'bufferField05'"></textarea>
        <div *ngIf="projectSystemTaskForm.controls['bufferField05'].invalid && (projectSystemTaskForm.controls['bufferField05'].dirty || projectSystemTaskForm.controls['bufferField05'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['bufferField05'].errors.maxlength">
            Maximum Length is 1000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Buffer Field 6</label>
      <div class="col-sm-7">
        <textarea formControlName="bufferField06" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'bufferField06'"></textarea>
        <div *ngIf="projectSystemTaskForm.controls['bufferField06'].invalid && (projectSystemTaskForm.controls['bufferField06'].dirty || projectSystemTaskForm.controls['bufferField06'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['bufferField06'].errors.maxlength">
            Maximum Length is 1000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Buffer Field 7</label>
      <div class="col-sm-7">
        <textarea formControlName="bufferField07" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'bufferField07'"></textarea>
        <div *ngIf="projectSystemTaskForm.controls['bufferField07'].invalid && (projectSystemTaskForm.controls['bufferField07'].dirty || projectSystemTaskForm.controls['bufferField07'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['bufferField07'].errors.maxlength">
            Maximum Length is 1000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Buffer Field 8</label>
      <div class="col-sm-7">
        <textarea formControlName="bufferField08" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'bufferField08'"></textarea>
        <div *ngIf="projectSystemTaskForm.controls['bufferField08'].invalid && (projectSystemTaskForm.controls['bufferField08'].dirty || projectSystemTaskForm.controls['bufferField08'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskForm.controls['bufferField08'].errors.maxlength">
            Maximum Length is 1000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Related CM Task</label>
      <div class="col-sm-7">
        <ngx-select formControlName="relatedCmTask" autoClearSearch = "true" [multiple]="true" (keyup)="onKeyUpCMTasks($event)"  (paste)="ppTaskOnPaste($event)"  placeholder="Related CM Task" optionValueField="taskDbId" optionTextField="taskText" [items]="relatedCmTasks" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'relatedCmTask'"></ngx-select>
      </div>
    </div>
    
  </div>
  <div class="modal-footer">
    <div *ngIf="showTaskActivityWarning" class="alert-warning task-alerts footer-alert" style="white-space: normal;">
      Attention FUNK-MSN + AOR >140 signs
    </div>
    <app-action-comment [btnLoading]="btnLoading || taskStageLoading"  [okBtnName]="'Save'" [closeBtnName]="'Close'" [saveDisable]="projectSystemTaskForm.invalid || invalidIntervals || invalidMaintenanceLocation || !validExecutionTime || (!isExecutionTimeEmpty && !isExecutionTimeSourceValid) || taskStageLoading || prePostProcessingTaskDataLoading || cmTaskDataLoading" [mandatoryDescriptionVisible]="true" [commentVisible]="projectFreezeFunction == 'activated'" (okClick)="createProjectSystemTask($event)" (cancelClick)="bsModalRef.hide()"></app-action-comment>
  </div>

  <div *ngIf="taskRelatedListsLoading" class="spinner-div d-flex justify-content-center align-items-center">
    <mat-progress-spinner [color]="'primary'" [mode]="'indeterminate'" class="spinner"></mat-progress-spinner>
  </div>

</form>


