import {Component, OnInit} from '@angular/core';
import {faEdit, faTrash, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ProjectSystemTaskToolsService} from 'src/app/services/project-system-task-tools.service';
import {ProjectToolService} from 'src/app/services/project-tool.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {SpecialToolService} from 'src/app/services/special-tool.service';
import {validateForm} from 'src/app/shared/helper';
import {ConfirmationBoxComponent} from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import {LanguageServiceService} from 'src/app/services/language-service.service';
import {LanguageSupportComponent} from "../../../../../shared/components/language-support/language-support-component.service";
import {ProjectService} from "../../../../../services/project.service";
import { ProjectSystemsService } from 'src/app/services/project-systems.service';


@Component({
  selector: 'app-project-system-task-tools',
  templateUrl: './project-system-task-tools.component.html',
  styleUrls: ['./project-system-task-tools.component.scss']
})
export class ProjectSystemTaskToolsComponent extends LanguageSupportComponent implements OnInit {

  faEdit = faEdit;
  faTrashAlt = faTrashAlt;
  faTrash = faTrash;
  projectSystem:any;
  projectSystemTaskToolForm:any;
  param:any;
  showFormRow = false;
  btnValue:any ='Add';
  projectNumber:any;
  taskDbId:any;
  systemNumber:any;
  systemCode:any;
  toolNumber:any;
  projectName:any;
  taskName:any;
  systemName:any;
  projectSystemTaskToolArray:any[] = [];
  projectFreezeFunction = "";
  filteredProjectToolsArray:any[] = [];
  originalProjectToolsArray:any[] = [];
  projectToolsData:any[] = [];

  btnLoading:Boolean = false;

  constructor(
      public bsModalRef: BsModalRef,
      private projectSystemTaskToolsService: ProjectSystemTaskToolsService,
      private projectToolService: ProjectToolService,
      private projectService:ProjectService,
      private specialToolService: SpecialToolService,
      private projectSystemsService : ProjectSystemsService,
      private notificationService: ToastrService,
      public languageService: LanguageServiceService,
      public modalService: BsModalService
  ) {
    super(languageService, modalService);
  }

  ngOnInit(): void {
    this.projectNumber = this.param.data['projectNumber'];
    this.taskDbId = this.param.data['taskDbId'];
    this.systemNumber = this.param.data['projectSystemId'];
    this.getProjectByProjectNumber();

    this.projectName = this.param.data.projectDesignation;
    this.taskName = this.param.data.taskName;
    this.systemName = this.languageService.getCurrentLanguage().id == 0 ? this.param.data.systemDesignationEn : this.param.data.systemDesignationDe;

    

    this.projectSystemTaskToolForm = new UntypedFormGroup({
      projectNumber: new UntypedFormControl({value : this.projectNumber, disabled : true}),
      projectSystemId: new UntypedFormControl({value : this.systemNumber, disabled : true}),
      taskDbId: new UntypedFormControl({value : this.taskDbId, disabled : true}),
      taskName: new UntypedFormControl({value : this.taskName, disabled : true}),
      toolNumber: new UntypedFormControl(''),
      toolName: new UntypedFormControl(''),
      toolRemarks: new UntypedFormControl('', [Validators.maxLength(100)]),
      toolQuantity: new UntypedFormControl('1',[Validators.required]),
      toolProjectSpecificPrice:new UntypedFormControl({value : '', disabled : true}),
      toolCurrency: new UntypedFormControl({value : '', disabled : true}),
      action: new UntypedFormControl('ADD'),
    });
    super.saveInitialValues("");
  }

  getProjectByProjectNumber() {
    this.projectService.getProjectById(this.projectNumber).subscribe(res => {
      this.projectFreezeFunction = res['freezeFunction'];
      let lan  = {
        id: res['workLangId'],
        str: res['workLangId'] == 1 ? "German" : "English"
      }
      this.languageService.setWorkingLanguage(lan);

      this.getProjectSystemTaskTools(this.projectNumber,this.systemNumber,this.taskDbId);
    });

    this.getProjectSystemByID(this.projectNumber,this.systemNumber);
  }

  languageChanged(){
    super.languageChanged(""); // does not lose user changes after change language
  }

  getProjectTools(projectNumber){
    this.filteredProjectToolsArray = [];
    this.specialToolService.getSpecialToolList().subscribe(res=>{
      if(res && res['page']){
        let result = res['page']['content'];
        Object.keys(result).forEach(item => {
          let toolDesignation = this.selectedLanguage == 0 ? result[item].designationEn : result[item].designationDe;
          result[item].toolDesignation = toolDesignation + '-' + result[item].toolArticleId;

          let found = false;
          for(let taskTool of this.projectSystemTaskToolArray){
            if(taskTool.toolNumber == result[item].toolNumber) found = true;
          }
          if(!found) this.filteredProjectToolsArray.push(result[item])
        });
        this.originalProjectToolsArray = result;
        this.projectToolsData = this.filteredProjectToolsArray;
      }
    });
  }

  getProjectSystemByID(projectNumber,systemNumber){
    this.projectSystemsService.getProjectSystem(projectNumber,systemNumber).subscribe(res=>{
      this.projectSystem = res;
      if(res) {
        let sysVariation = res['systemVariation'];
        let sysDesignation = this.languageService.getCurrentLanguage().id == 0 ? res['systemDesignationEn'] : res['systemDesignationDe'];
        this.systemName = (sysVariation != null && sysVariation != sysDesignation) ? sysDesignation + " - " + sysVariation : sysDesignation ;
        this.systemCode = "" + res['systemCode'] + '-' + res['systemVariationNumber'];
      }
    });
  }

  /**
   * Delete task tool
   * @param key
   */
  deleteTaskTool(key){
    this.btnLoading = true;
    let taskTool = this.projectSystemTaskToolArray[key];
    let toolNumber = taskTool['toolNumber'];
    const initialState = {
      title: 'Are you sure?',
      okBtnName: 'Yes',
      closeBtnName: 'No',
      isCommentVisible: this.projectFreezeFunction == 'activated'
    };
    let bsModalRef = this.modalService.show(ConfirmationBoxComponent, {class:'modal-md', initialState, ignoreBackdropClick:true});
    bsModalRef.content.event.subscribe(res =>
    {
      if(res?.result?.action == 'ok')
      {
        this.projectSystemTaskToolsService.delete(res?.result,this.projectNumber,this.systemNumber,this.taskDbId,toolNumber).subscribe(res => {}, error =>
        {
          if(error.status == 401 || error.statusCode == 500) {
            this.notificationService.error('The Task tool you tried to delete is in use, hence cannot be deleted','Error');
          }else if(error.status == 403) {
            this.notificationService.error('You need to be in the Project Admins Group to perform the current Action','Error');
          }else if(error.status == 200 || error.status == 201 || error.status == 202 ) { 
            this.notificationService.success('The Task tool has been deleted','Success');
            this.showFormRow =false;
          }else { 
            this.notificationService.error('The Task tool has not been deleted','Error');
          }
          this.getProjectSystemTaskTools(this.projectNumber,this.systemNumber,this.taskDbId);
          this.btnLoading = false;
        });
      }
      else this.btnLoading = false; // do nothing
    });
  }

  selectProjectTools(toolNumber) {
    this.btnValue = "Add";
    this.showFormRow = true;
    this.specialToolService.getSpecialToolsById(toolNumber).subscribe(res => {
      this.projectSystemTaskToolForm.patchValue({
        toolNumber: res['toolNumber'],
        toolRemarks: '',
        toolQuantity: '1',
        toolProjectSpecificPrice: res['cost'],
        toolCurrency: res['currency'],
        action: 'ADD',
      });
    });
    this.projectToolsData = this.filteredProjectToolsArray;
  }

  addProjectSystemTaskTool(event){
    if(this.projectSystemTaskToolForm.valid){
      let submitData = this.projectSystemTaskToolForm.value;
      let data = {
        projectNumber: submitData['projectNumber'],
        projectSystemId: submitData['projectSystemId'],
        taskDbId: submitData['taskDbId'],
        toolNumber: submitData['toolNumber'],
        toolRemarks: submitData['toolRemarks'],
        toolQuantity: submitData['toolQuantity']
      }

      if(submitData['action']=='ADD'){
        this.projectSystemTaskToolsService.addProjectSystemTaskTools(event,this.projectNumber,this.systemNumber,this.taskDbId,data)
            .subscribe(res=>{
              if(res){
                this.notificationService.success('Project system task tool has been Created', 'Success');
                this.getProjectSystemTaskTools(this.projectNumber,this.systemNumber,this.taskDbId);
                this.showFormRow = false;
                this.projectSystemTaskToolForm.patchValue({
                  toolName: null,
                  toolNumber: null,
                  toolRemarks: null,
                  toolQuantity: null,
                  toolProjectSpecificPrice: null,
                  toolCurrency: null,
                  action: 'ADD',
                });
              }else{
                this.notificationService.error('Project system task tool has not been Created', 'Error');
              }
            },(error => {
              if(error.status == 403) {
                this.notificationService.error('You need to be in the Project Admins Group to perform the current Action','Error');
              }else{
                this.notificationService.error('Project system task tool has not been Created','Error');
              }
            }));
        
      }
      else if(submitData['action']=='EDIT'){
        this.projectSystemTaskToolsService.updateProjectSystemTaskTools(event,this.projectNumber,this.systemNumber,this.taskDbId,submitData['toolNumber'],data)
            .subscribe(res=>{
              if(res){
                this.notificationService.success('Project system task tool has been Updated', 'Success');
                this.getProjectSystemTaskTools(this.projectNumber,this.systemNumber,this.taskDbId);
                this.showFormRow = false;

                this.projectSystemTaskToolForm.patchValue({
                  toolName: null,
                  toolNumber: null,
                  toolRemarks: null,
                  toolQuantity: null,
                  toolProjectSpecificPrice: null,
                  toolCurrency: null,
                  action: 'EDIT',
                });

              }else{
                this.notificationService.error('Project system task tool has not been Updated due an Error', 'Error');
              }
            },(error => {
              if(error.status == 403) {
                this.notificationService.error('You need to be in the Project Admins Group to perform the current Action','Error');
              }else{
                this.notificationService.error('Project system task tool has not been Updated','Error');
              }
            }));
        
      }
    }
    else{
      validateForm(this.projectSystemTaskToolForm);
    }
  }

  getProjectSystemTaskTools(projectNumber, systemNumber, taskDbId) {
    this.projectSystemTaskToolsService.getProjectSystemTaskTools(projectNumber, systemNumber, taskDbId).subscribe((res:any[]) => {

      Object.keys(res).forEach(item => {
        let toolDesignation = this.selectedLanguage == 0 ? res[item].toolDesignationEn : res[item].toolDesignationDe;
        res[item].toolDesignation = toolDesignation + '-' + res[item].toolArticleId;
      });
      
      this.projectSystemTaskToolArray = res;
      this.getProjectTools(this.projectNumber);
    });
  }

  selectProjectSystemTaskTool(key){
    this.btnValue ="Edit";
    let selectedProjectSystemTaskTool = this.projectSystemTaskToolArray[key];
    this.showFormRow = true;
    this.projectSystemTaskToolForm.patchValue({
      toolName: selectedProjectSystemTaskTool['toolName'],
      toolNumber: selectedProjectSystemTaskTool['toolNumber'],
      toolRemarks: selectedProjectSystemTaskTool['toolRemarks'],
      toolQuantity: selectedProjectSystemTaskTool['toolQuantity'],
      toolProjectSpecificPrice: selectedProjectSystemTaskTool['toolProjectSpecificPrice'],
      toolCurrency: selectedProjectSystemTaskTool['toolCurrency'],
      action: 'EDIT',
    });

    this.projectToolsData = this.filteredProjectToolsArray;
    this.projectToolsData.push(selectedProjectSystemTaskTool);
  }

  changeSelectedToolName(){
    let toolNumber = this.projectSystemTaskToolForm.get('toolNumber').value;
    this.specialToolService.getSpecialToolsById(toolNumber).subscribe((res)=>{
      this.projectSystemTaskToolForm.patchValue({
        toolName: res[this.selectedLanguage == 0 ? 'designationEn' : 'designationDe'],
      });
    });
  }

  callAfterLanguageChanged() {
    this.getProjectSystemTaskTools(this.projectNumber,this.systemNumber,this.taskDbId);
    this.changeSelectedToolName();
    this.systemName = this.languageService.getCurrentLanguage().id == 0 ? this.param.data.systemDesignationEn : this.param.data.systemDesignationDe;
    this.projectService.getProjectById(this.projectNumber).subscribe(res => {
      this.projectName = res['projectDesignation'];
    });
  }

}
