import {Component, EventEmitter, OnInit} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ProjectSystemSparePartsService} from 'src/app/services/project-system-spare-parts.service';
import {ToastrService} from 'ngx-toastr';
import {SuppliersService} from 'src/app/services/suppliers.service';
import {currencyList, validateForm} from 'src/app/shared/helper';
import {StadlerArticleService} from 'src/app/services/stadler-article.service';
import {QuantityUnitServiceService} from 'src/app/services/quantity-unit-service.service';
import {ProjectSystemsService} from 'src/app/services/project-systems.service';
import {LanguageSupportComponent} from "../../../../../shared/components/language-support/language-support-component.service";
import {LanguageServiceService} from "../../../../../services/language-service.service";
import {
  TaskPartUsagesPartQuantityComponent
} from "../task-part-usages-part-quantity/task-part-usages-part-quantity.component";
import { ProjectSystemTasksService } from 'src/app/services/project-system-tasks.service';

@Component({
  selector: 'app-edit-project-system-spare-parts',
  templateUrl: './edit-project-system-spare-parts.component.html',
  styleUrls: ['./edit-project-system-spare-parts.component.scss']
})
export class EditProjectSystemSparePartsComponent extends LanguageSupportComponent implements OnInit {


  projectSystemSparePartEditForm = new UntypedFormGroup({
    projectNumber: new UntypedFormControl({value: '', disabled: true}),
    projectSystemId: new UntypedFormControl({value: '', disabled: true}),
    spareDesignation: new UntypedFormControl({value: '', disabled: true}),
    parentId: new UntypedFormControl({value: '', disabled: true}),
    parentStadlerArticleId: new UntypedFormControl({value: '', disabled: true}),
    spareStadlerArticleId: new UntypedFormControl(''),
    quantity: new UntypedFormControl('', [Validators.required, Validators.max(999999.99)]),
    spareUnit: new UntypedFormControl({value: '', disabled: true}),
    unitCost: new UntypedFormControl('', [Validators.max(999999.99)]),
    currency: new UntypedFormControl(''),
    failureRate: new UntypedFormControl('', [Validators.max(999999.99999)]),
    failureRateUnit: new UntypedFormControl(''),
    lifeSpan: new UntypedFormControl('', Validators.max(99999999)),
    lifeSpanUnit: new UntypedFormControl(''),
    additionalDesignation: new UntypedFormControl('', Validators.maxLength(100)),
    isReplaceable: new UntypedFormControl(''),
  });

  event: EventEmitter<any> = new EventEmitter();
  parent:any;
  param: any;
  spareNumber: any;
  currencyList: any;
  laddaLoading = false;
  spareUnitList: any;
  stadlerArticleData: any = [];
  failureRateUnitsData: any = [];
  lifeSpanUnitsData: any = [];
  materialQuantityDecimalLength = 2;
  initialStadlerArticalId = '';
  showStadlerArticalIdWarning=false;
  projectFreezeFunction="";
  taskPartUsageTableData:any;
  biggestTaskPartQuantity = -1;

  partIsReplaceable:Boolean = false;
  partAutogenCMTask:String = "";
  partAutogenCMTaskisActive:Boolean = true;
  partAutogenCMTaskObject:any;
  partAutogenCMTaskManualId:String = "";


  spareUnitsLoading:boolean = true;
  failureRateUnitsLoading:boolean = true;
  lifeSpanUnitsLoading:boolean = true;
  stadlerArticleDataLoading:boolean = true;
  taskPartsUsagesLoading:boolean = true;

  btnLoading:Boolean = false;

  prevFilter: string = "";


  taskPartUsageSettings = {
    type:'table',
    tableClass:'',
    tableHeaderClass:'',
    tableRowClass:'',
    columnManagerEnable:false,
    columns:[
      {
        title:'Task Manual ID / Element / Activity',
        attribute:'taskManualId',
        type:'text',
        width:300,
      },
      {
        title:'Task Part Qty',
        renderComponent: TaskPartUsagesPartQuantityComponent,
        onComponentInitFunction : (instance) => {
          instance.projectFreezeFunction = this.projectFreezeFunction;
        },
        type:'custom-text',
        width:150
      },
    ]
  };

  constructor(
      public bsModalRef: BsModalRef,
      private projceSystemSpareService: ProjectSystemSparePartsService,
      private notificationService: ToastrService,
      private supplierService: SuppliersService,
      private stadlerArticleService: StadlerArticleService,
      private quantityUnitService: QuantityUnitServiceService,
      private projectSystemService: ProjectSystemsService,
      public languageService: LanguageServiceService,
      public modalService: BsModalService,
      private projectSystemSparePartsService: ProjectSystemSparePartsService,
      private projectSystemTaskService: ProjectSystemTasksService
  ) {
    super(languageService, modalService);
  }

  ngOnInit(): void {
    this.fillFormByRowData();
    this.loadSpareUnits();
    this.loadCurrencyList();
    this.loadFailureRateUnits();
    this.loadLifeSpanUnits();
    this.getStadlerArticleData();
    this.getTaskPartsUsages();

    validateForm(this.projectSystemSparePartEditForm);
  }

  public currentFormValues()
  {
    return JSON.stringify(this.projectSystemSparePartEditForm.getRawValue());
  }

  stadlerArticleOnKeyUp(event){
    const value = event.target.value;
    if (value.length == 3 && this.prevFilter != value) {
      // Get data from server when 3rd character is typed
      this.prevFilter = value;
      this.getStadlerArticleData(value, false);
    }
  }

  stadlerArticleOnPaste(event){
    // ng-paste='handlePaste($event.clipboardData.getData('text/plain'))'
    const value = event.clipboardData.getData('text/plain');
    if (value.length >= 1 && this.prevFilter != value) {
      // Get data from server when 3rd character is typed
      this.prevFilter = value;
      this.getStadlerArticleData(value, true);
    }
  }


  /**
   * Get Stadler article id data
   */
  getStadlerArticleData(likeFilter: string = "", isPaste: boolean = false) {
    let stadlerArticleData = [];
    if( !this.spareNumber ) return;
    if (likeFilter == "") likeFilter = this.initialStadlerArticalId;
    this.stadlerArticleService.getStadlerArticleIdByPart(this.spareNumber, likeFilter).subscribe(res=>{
      Object.keys(res).forEach(key => {
        if(this.languageService.getCurrentLanguage().id == 0){
          res[key].stadlerArticleText = res[key].designationEn + " - " + res[key].stadlerArticleId;
        }else{
          res[key].stadlerArticleText = res[key].designationDe + " - " + res[key].stadlerArticleId;
        }
        res[key].stadlerArticleText += (res[key].supplier_article_id == null || res[key].supplier_article_id.trim() == "" || res[key].supplier_article_id.trim() == "-") ? "" : (" - " + res[key].supplier_article_id);
        if(res[key].sparePartRelation == 'CURRENT' || res[key].sparePartRelation == null) stadlerArticleData.push(res[key]);
      });
      this.stadlerArticleData = stadlerArticleData;

      this.stadlerArticleDataLoading= false;
      
    })
  }

  getTaskPartsUsages(){
    let data = this.param['rowData'];
    if(!data || !data['spareNumber']) return;

    let spareNumber = data['spareNumber'];
    let qtyOnVehicleLevel = data['quantityOnVehicleLevel'];
    
    this.projectSystemSparePartsService.getTaskPartsUsages(spareNumber).subscribe((res)=>{
      
      Object.keys(res).forEach(key => {
        if(res[key].taskPartQuantity > this.biggestTaskPartQuantity) this.biggestTaskPartQuantity = res[key].taskPartQuantity;
        res[key].qtyOnVehicleLevel = qtyOnVehicleLevel;
      });
      this.taskPartUsageTableData = res;
      // console.log(res);

      this.projectSystemSparePartEditForm.controls['quantity'].addValidators([Validators.min(this.biggestTaskPartQuantity)]);

      this.taskPartsUsagesLoading= false;

    });
  }

  /**
   * Get Stadler article data by id
   */
  stadlerArticleIdChanged() {
    let articleId = this.projectSystemSparePartEditForm.value.spareStadlerArticleId;
    if(articleId==null || articleId=="") return;

    this.showStadlerArticalIdWarning = this.initialStadlerArticalId != articleId;

    this.stadlerArticleService.getArticleById(articleId).subscribe(res => {
      if(res!=null) {
        this.projectSystemSparePartEditForm.patchValue({
          spareUnit: res['quantityUnit'] ? res['quantityUnit'] : ''
        });
      }
      this.validateQuantity()
    });
  }

  fillFormByRowData(){
    let data = this.param['rowData'];

    if (data != null)
    {
      Object.keys(data).forEach(function(key) {
        if(data[key] == "") data[key] = null;
      })
      data['unitCost'] = data['unitCost'] != null ? data['unitCost'].toString() : null;
      data['failureRate'] = data['failureRate'] != null ? data['failureRate'].toString() : null;
      data['lifeSpan'] = data['lifeSpan'] != null ? data['lifeSpan'].toString() : null;
      data['additionalDesignation'] = data['additionalDesignation'] != null ? data['additionalDesignation'].toString() : '';

      this.partIsReplaceable = data['isReplaceable']==1 ? true : false;
      this.partAutogenCMTask = data['autogenCmTask'];

      this.projectSystemSparePartEditForm.patchValue(data);
      this.initialStadlerArticalId = data['spareStadlerArticleId'];
      this.spareNumber = data['spareNumber'];

      this.projectSystemSparePartEditForm.controls['spareStadlerArticleId'].setValue(data['spareStadlerArticleId']);

      let parentSpareId = data['parentId'];
      if(parentSpareId != -1){
        this.projceSystemSpareService.getProjectSystemSparePartsById(parentSpareId).subscribe((res)=>{
          this.parent = res;
          this.projectSystemSparePartEditForm.controls['parentStadlerArticleId'].patchValue(this.parent?.spareDesignation + " - " + this.parent?.spareStadlerArticleId);

          super.saveInitialValues(JSON.stringify(this.projectSystemSparePartEditForm.getRawValue()));
        });
      }
      else{
        this.projectSystemSparePartEditForm.controls['quantity'].disable();
        this.projectSystemSparePartEditForm.controls['spareStadlerArticleId'].disable();
      }

      this.projectSystemSparePartEditForm.controls['isReplaceable'].enable();
      if(this.partAutogenCMTask!=null && this.partAutogenCMTask!=''){
        console.log(data);
        this.partAutogenCMTaskisActive = false;
        this.projectSystemTaskService.getIndividualProjectSystemTaskById(data['projectNumber'], data['projectSystemId'], this.partAutogenCMTask).subscribe(res => {
          console.log(res);
          if(res){
            console.log(res["isTaskActivated"]);
            console.log("Task activated status is AI : " + (res["isTaskActivated"]=="AI"));
            this.partAutogenCMTaskObject = res;
            this.partAutogenCMTaskManualId = res["task_id"];
            this.partAutogenCMTaskisActive = (res["isTaskActivated"]=="AI" || res["isTaskActivated"]=="AD") ? false : true;
            console.log("Autogen Task Status is active : " + this.partAutogenCMTaskisActive);
            if(this.partAutogenCMTaskisActive){
              this.projectSystemSparePartEditForm.controls['isReplaceable'].disable();
            }else{
              this.projectSystemSparePartEditForm.controls['isReplaceable'].enable();
            }
          }else{
            this.projectSystemSparePartEditForm.controls['isReplaceable'].enable();
          }
        });
        // this.projectSystemSparePartEditForm.controls['isReplaceable'].disable();
      }

      this.validateQuantity();
      super.saveInitialValues(JSON.stringify(this.projectSystemSparePartEditForm.getRawValue()));
    }
  }

  languageChanged(){
    super.languageChanged(JSON.stringify(this.projectSystemSparePartEditForm.getRawValue()));
  }
  /**
   * * Validate quantity
   * */

  validateQuantity() {
    let unit = this.projectSystemSparePartEditForm.getRawValue()['spareUnit'];
    this.materialQuantityDecimalLength = unit == 1 ? 0 : 1;
    if (unit == 1) {
      let value = this.projectSystemSparePartEditForm.controls["quantity"].value;
      let newQuantityValue = Math.round(value) <=  0 ? null : Math.round(value);;
      this.projectSystemSparePartEditForm.patchValue({quantity: newQuantityValue});
    }
  }

  /**
   * Submit Project SystemS Parts
   */
  updateProjectSystemSpareParts(event) {

    this.btnLoading = true;
    if (this.projectSystemSparePartEditForm.valid) {
      this.laddaLoading = true;
      let projectSystemSparepartData = this.projectSystemSparePartEditForm.getRawValue();
      projectSystemSparepartData['isReplaceable'] = this.projectSystemSparePartEditForm.controls["isReplaceable"].value ? 1 : 0;
      this.projceSystemSpareService.updateProjectSystemSpareParts(event, projectSystemSparepartData, this.spareNumber).subscribe(res => {
        if (res) {
          this.notificationService.success('Project System Parts has been Updated', 'Success');
          this.bsModalRef.hide();
          this.event.emit();
        } else {
          this.notificationService.error('Project System Parts has not been Updated', 'Error');
        }
        this.btnLoading = false;
        this.laddaLoading = false;
      }, (error => {
        if(error.status == 403) {
          this.notificationService.error('You need to be in the Project Admins Group to perform the current Action','Error');
        }else{
          this.notificationService.error('Project System Parts has not been Updated', 'Error');
        }
        this.btnLoading = false;
        this.laddaLoading = false;
      }));
    } else {
      this.btnLoading = false;
      validateForm(this.projectSystemSparePartEditForm);
    }
  }

  /**
   * LoadParts Unit List
   */
  loadSpareUnits() {
    this.quantityUnitService.getAllQuantityUnits().subscribe(res => {
      this.spareUnitList = res;
      this.spareUnitsLoading= false;
    });
  }

  /**
   * Load Currency List
   */
  loadCurrencyList() {
    this.currencyList = currencyList();
  }

  /**
   * LoadParts Unit List
   */
  loadFailureRateUnits() {
    this.projceSystemSpareService.getFailureRateUnits().subscribe(res => {
      this.failureRateUnitsData = res;
      this.failureRateUnitsLoading= false;
    },error => {
      console.log(error);
      this.failureRateUnitsLoading= false;
    });
  }

  /**
   * Load Currency List
   */
  loadLifeSpanUnits() {
    this.projceSystemSpareService.getLifeSpanUnits().subscribe(res => {
      this.lifeSpanUnitsData = res;
      this.lifeSpanUnitsLoading= false;
    },error => {
      console.log(error);
      this.lifeSpanUnitsLoading= false;
    });
  }

  callAfterLanguageChanged() {
    this.loadSpareUnits();
    this.loadCurrencyList();
    this.loadFailureRateUnits();
    this.loadLifeSpanUnits();
    this.getStadlerArticleData();
    this.fillFormByRowData();
    this.getTaskPartsUsages();

    validateForm(this.projectSystemSparePartEditForm);
  }

}
