import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SuppliersService {

    constructor(private http: HttpClient) { }


    getAllSuppliers(apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/stakeholders/suppliers/" + apiType, data, {params: params});
    }

    getAllSuppliers2()
    {
        return this.http.get(environment.apiUrl + "/suppliers/");
    }

    /**
     * Create new Supplier
     * @param data
     */
    create(data)
    {
        return this.http.post(environment.apiUrl + "/suppliers/", data);
    }

    /**
     * Delete Supplier
     * @param id
     */
    delete(id)
    {
        return this.http.delete(environment.apiUrl + "/suppliers/" + id + "/");
    }

    /**
     * Update Supplier
     * @param data
     * @param id
     */
    update(data, id)
    {
        return this.http.put(environment.apiUrl + "/suppliers/" + id + "/", data);
    }

    /**
     * Search Supplier
     * @param data
     */
    search(data)
    {
        return this.http.post(environment.apiUrl + "/suppliers/search/", data);
    }
}
