import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SystemsComponent } from './systems/systems.component';
import { ComingSoonComponent } from '../coming-soon/coming-soon.component';
import { SystemAddComponent } from './system-add/system-add.component';
import { SystemEditComponent } from './system-edit/system-edit.component';



const routes: Routes = [
  { path: 'systems', component: SystemsComponent, data: { animation: 'projects' }},
  { path: 'systems/add', component: SystemAddComponent, data: { animation: 'projects' }},
  { path: 'systems/edit/:id', component: SystemEditComponent, data: { animation: 'projects' }},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SystemsRoutingModule { }
