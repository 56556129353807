import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ElectrificationSystemService {

    constructor(private http: HttpClient) { }

    /**
     * Get All Electrification list
     */
    getElectrificationSystems()
    {

        return this.http.get(environment.apiUrl + "/electrification/");

    }

    /**
     * Get electirification by projects
     * @param projectNumber
     */
    getElectrificationByProjects(projectNumber)
    {
        return this.http.get(environment.apiUrl + "/projectelectrification/" + projectNumber + '/');
    }
}
