import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { StandardSystemsService } from 'src/app/services/standard-systems.service';
import { Router } from '@angular/router';
import { SystemTypeService } from 'src/app/services/system-type.service';
import { validateForm } from 'src/app/shared/helper';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-system-add',
  templateUrl: './system-add.component.html',
  styleUrls: ['./system-add.component.scss']
})
export class SystemAddComponent implements OnInit {

  level1Show: Boolean = false;
  level2Show: Boolean = false;
  laddaLoading = false;
  ramEngineerData: any = [];
  sbsCodeData: any = [];
  level1SystemNumberList: any;
  level2SystemNumberList: any;
  btnLoading: Boolean = false;

  systemLevelData: any = [
    {
      id: 1,
      value: 1,
    },
    {
      id: 2,
      value: 2,
    },
    {
      id: 3,
      value: 3,
    },
    {
      id: 4,
      value: 4,
    }
  ];

  systemAddForm = new UntypedFormGroup({
    systemDesignationEn: new UntypedFormControl('', [Validators.required]),
    systemDesignationDe: new UntypedFormControl('', [Validators.required]),
    systemCode: new UntypedFormControl('', [Validators.required]),
    sbsCode: new UntypedFormControl(''),
    systemLevel: new UntypedFormControl('', [Validators.required]),
    ramEngineer: new UntypedFormControl([]),
    systemStatus: new UntypedFormControl(0),
    level1System: new UntypedFormControl(),
    level2System: new UntypedFormControl(),
    level3System: new UntypedFormControl(),

  });

  constructor(
    private notification: ToastrService,
    private systemService: StandardSystemsService,
    private router: Router,
    private systemTypeService: SystemTypeService,
    private userService: UserService
  ) {

  }

  ngOnInit(): void {
    this.getLevel1SystemNumbers();
    this.getLevel2SystemNumbers();
    this.getRamEngineers();
    this.getSBSCodes();
  }


  onSelectSystemLevel(id) {
    if (id == 1) {
      this.level1Show = false;
      this.level2Show = false;
    } else if (id == 2) {
      this.level1Show = true;
      this.level2Show = false;
    } else if (id == 3) {
      this.level1Show = true;
      this.level2Show = true;
    } else if (id == 4) {
      this.level1Show = true;
      this.level2Show = true;
    } else {
      this.level1Show = false;
      this.level2Show = false;
    }
  }

  getRamEngineers() {
    this.userService.getRamengineers().subscribe(res => {
      this.ramEngineerData = res;
    });
  }

  getSBSCodes() {
    this.systemService.getSBSCodes().subscribe(res => {
      this.sbsCodeData = res;
    });
  }

  onSubmit() {
    if (this.systemAddForm.valid) {
      this.btnLoading = true;

      try {

        this.systemService.createSystems(this.systemAddForm.getRawValue()).subscribe(res => {
          if (res) {
            this.notification.success('System has been created', 'Success');
            this.btnLoading = false;
            setTimeout(() => {
              this.btnLoading = false;
              this.router.navigateByUrl('/systems');
            }, 2000);
          } else {
            this.btnLoading = false;
            this.notification.error('System has not been created', 'Error');
          }
        }, (error => {
          // if (error.status == 403) {
          //   this.notification.error('You need to be in the Project Admins Group to perform the current Action', 'Error');
          // } else {
          //   this.notification.error('System has not been created', 'Error');
          // }
          this.btnLoading = false;
          this.router.navigateByUrl('/systems');
        })
        );

      } catch (e) {
        this.btnLoading = false;
        this.notification.error('System has not been created', 'Error');
      }
    } else {
      this.btnLoading = false;
      validateForm(this.systemAddForm);
    }
  }

  getLevel1SystemNumbers() {
    this.systemService.getLevelSystemNumbers(1).subscribe(res => {
      this.level1SystemNumberList = res;
    });
  }

  getLevel2SystemNumbers() {
    this.systemService.getLevelSystemNumbers(2).subscribe(res => {
      this.level2SystemNumberList = res;
    });
  }

}
