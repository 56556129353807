import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OperatingMaterialService {

    constructor(private http: HttpClient) { }

    getAllOperatingMaterials(apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/operatingmaterials/" + apiType, data, {params: params});
    }

    deleteOperatingMaterial(res, id)
    {
        const httpOptions = {
            params: {
                comment: res['comment'],
                justificationCategory: res['justificationCategory'] ? res['justificationCategory'] : -1
            }
        };
        return this.http.delete(environment.apiUrl + "/operatingmaterials/" + id, httpOptions);
    }

    createOperatingMaterials(reason, data)
    {
        const httpOptions = {
            params: {
                comment: reason['comment'],
                justificationCategory: reason['justificationCategory'] != null ? reason['justificationCategory'] : -1
            }
        };
        return this.http.post(environment.apiUrl + "/operatingmaterials/", data, httpOptions);
    }

    updateOperatingMaterials(reason, data, id)
    {
        const httpOptions = {
            params: {
                comment: reason['comment'],
                justificationCategory: reason['justificationCategory'] != null ? reason['justificationCategory'] : -1
            }
        };
        return this.http.put(environment.apiUrl + "/operatingmaterials/" + id + "/", data, httpOptions);
    }

    search(data)
    {
        return this.http.post(environment.apiUrl + "/operatingmaterials/search", data);
    }

    exportOperatingMaterials()
    {
        return this.http.get(environment.apiUrl + "/operatingmaterials/export/", {responseType: 'blob'});
    }
}
