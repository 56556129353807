<div class="modal-header">
    <h5 class="modal-title pull-left">{{action}} Tag</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="d-flex flex-row align-items-center input-span-div">
        <p>Tag:</p>
        <input type="text" class="form-control form-control-sm" [(ngModel)]="tagText" appInfoPromptBox [page]="'tags'" [component]="'add-edit'" [field]="'tag'">
    </div>
</div>
<div class="modal-footer">
    <app-action-comment [btnLoading]="btnLoading" [saveDisable]="tagText=='' || tagText==initialTagText" [okBtnName]="'Save'" [closeBtnName]="'Close'" [commentRequired]="false" [commentVisible]="false" (okClick)="okClicked($event)" (cancelClick)="this.modalRef.hide()"></app-action-comment>
</div>
