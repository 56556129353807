import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class StakeHolderService {

    constructor(private http: HttpClient)
    {

    }

    /**
     * Get all ram engineers
     */
    getRamEngineers()
    {
        return this.http.get(environment.apiUrl + "/stakeholders/ramengineers");
    }

    /**
     * Get Stakeholders by RoleID
     * @param roleId
     */
    getStakeHoldersByRole(roleId)
    {
        return this.http.get(environment.apiUrl + "/stakeholders/role/" + roleId + "");
    }
}
