import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TreeTableComponent} from './pages/tree-table/tree-table.component';
import {TreeComponent} from './pages/tree/tree.component';
import {TreeNodeComponent} from './pages/tree-node/tree-node.component';
import {TreeCellComponent} from './pages/tree-cell/tree-cell.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {FormsModule} from "@angular/forms";
import { TextTruncateDirective } from './directives/text-truncate.directive';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatLegacySliderModule} from "@angular/material/legacy-slider";


@NgModule({
    declarations: [
        TreeTableComponent,
        TreeComponent,
        TreeNodeComponent,
        TreeCellComponent,
        TextTruncateDirective,
    ],
    exports: [
        TreeTableComponent
    ],
	imports: [
		CommonModule,
		FontAwesomeModule,
		FormsModule,
		MatTooltipModule,
		MatProgressSpinnerModule,
		MatLegacySliderModule
	]
})
export class TreeTableModule { }
