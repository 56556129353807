import {Component, OnInit} from '@angular/core';
import {faAngleRight, faBackward, faEdit, faPlus} from '@fortawesome/free-solid-svg-icons';
import {OperatingProfilesService} from 'src/app/services/operating-profiles.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AddOperatingProfilesComponent} from '../components/add-operating-profiles/add-operating-profiles.component';
import {EditOperatingProfilesComponent} from '../components/edit-operating-profiles/edit-operating-profiles.component';
import {ActivatedRoute} from '@angular/router';
import {RouterService} from 'src/app/services/router.service';
import {ProjectService} from 'src/app/services/project.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationBoxComponent} from '../../../shared/components/confirmation-box/confirmation-box.component';
import {BreadcrumbServiceService} from '../../../services/breadcrumb-service.service';
import {LanguageServiceService} from "../../../services/language-service.service";

@Component({
  selector: 'app-operating-profile-list',
  templateUrl: './operating-profile-list.component.html',
  styleUrls: ['./operating-profile-list.component.scss']
})
export class OperatingProfileListComponent implements OnInit {

  faAngleRight = faAngleRight;
  faPlus=faPlus;
  faEdit=faEdit;
  faBackward=faBackward;
  previousUrl:any;
  laddaLoading:Boolean=false;
  loading:Boolean=true;
  tableData:any=[];
  projectNumber:any;
  projectDesignation:any;

  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";
  filterData = [];
  projectFreezeFunction = "";

  settings = {
    type:'table',
    id:'operatingProfileId',
    columnManagerEnable:true,
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    freezeColumns:{
      enabled:true,
      left:1,
      right:2
    },
    enableZoom:true,
    filterOptionFunction:(params, filterData)=>{return this.operatingProfileService.getAllOperatingProfiles(this.projectNumber,"distinct-values", filterData, params)},
    columns:[
      {
        title:'System',
        attribute:'systemText',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:350,
      },
      {
        title:'Value',
        attribute:'value',
        type:'text',
        format:"'###",
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:100
      },
      {
        title:'Profile Unit',
        attribute:'profileUnitDesignation',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:250,
      },
      {
        title:'Unit Addition',
        attribute:'unitAddition',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:100,
      },
      {
        renderComponent:'<a class="btn btn-info btn-sm text-light btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/edit.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.loadEditModal(row)},
        width:30
      },
      {
        renderComponent:'<a class="btn btn-danger btn-sm text-light btn-url"><img title="Delete" class="delete-icon" src="/assets/icon/delete.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.deleteRecord(row)},
        width:50,
        hideFunction: (row)=>{return row.unitType == 0}
      }
    ]
  };

  constructor(
      private operatingProfileService: OperatingProfilesService,
      private modalService: BsModalService,
      private activatedRoute: ActivatedRoute,
      private routerService: RouterService,
      private projectService: ProjectService,
      private notificationService: ToastrService,
      public breadcrumbService: BreadcrumbServiceService,
      private languageService:LanguageServiceService
  ) {
    activatedRoute.queryParams.subscribe(params => {
      if(params['page']) this.currentPage = +params['page'];
      if(params['column']) this.sortColumn = params['column'];
      if(params['sortOrder']) this.sortOrder = params['sortOrder'];
    });
  }

  ngOnInit(): void {
    this.projectNumber = this.activatedRoute.snapshot.paramMap.get('projectNumber');
    this.getProjectByProjectNumber();
    this.previousUrl = this.routerService.getPreviousUrl();

    if(this.breadcrumbService.isStackEmpty()){
      this.breadcrumbService.pushPage(1, "Operating Profiles", "/operating-profiles/"+this.projectNumber);
    }
  }

  /**
   * Get Project details by project ID
   */
  getProjectByProjectNumber() {
    this.projectService.getProjectById(this.projectNumber).subscribe(res => {
      this.projectDesignation = res['projectDesignation'];
      this.projectFreezeFunction = res['freezeFunction'];
      let lan  = {
        id: res['workLangId'],
        str: res['workLangId'] == 1 ? "German" : "English"
      }
      this.languageService.setWorkingLanguage(lan);
      this.getAllOperatingProfile();
    });
  }

  getAllOperatingProfile(){
    this.loading = true;

    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;

    this.operatingProfileService.getAllOperatingProfiles(this.projectNumber, 'filtered-data', this.filterData, params).subscribe(res=>{
      if(res && res['page']){
        this.pageCount = res['page']['totalPages'];
        let result = res['page']['content'];
        this.tableData = result;
      }
      if(res && res['tablePropertyMap']){
        let columns = this.settings.columns;
        let needFillColumnAndTable = false;
        for(let column of columns){
          if(column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
        }
        if(!needFillColumnAndTable) return;

        let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];
        for(let i=0; i<columns.length; i++){
          let data = tablePropertyMap[columns[i].attribute];
          if(!data) continue;
          columns[i].filter['column'] = data['column'];
          columns[i].filter['table'] = data['table'];
        }
      }
      this.settings['isNative'] = false;
    });
  }


  filterOrSortChange(event){
    if(!event){
      this.currentPage = 1;
      this.tableData = [];
      return;
    }
    if(!event['sortChanged']) this.currentPage = 1;
    this.sortColumn = event['sortColumn'];
    this.sortOrder = event['sortOrder'];
    this.filterData = event['filterData'];

    this.getAllOperatingProfile();
  }

  /**
   * Load Add modal
   */
  loadAddModal(){
    this.laddaLoading=true;
    const initialState = {
      param : {
        projectNumber: this.projectNumber,
        operatingProfileList:this.tableData
      },
      projectFreezeFunction : this.projectFreezeFunction
    };
    let modalRef = this.modalService.show(AddOperatingProfilesComponent, {class:'modal-lg',initialState,keyboard:false,ignoreBackdropClick: true});

    this.laddaLoading = false;
    modalRef.content.event.subscribe(res =>{
      this.getAllOperatingProfile();
    });
  }

  deleteRecord(rowData) {
    const initialState = {
      title: 'Are you sure?',
      okBtnName: 'Yes',
      closeBtnName: 'No',
      isCommentVisible: this.projectFreezeFunction == 'activated'
    };
    let modalRef = this.modalService.show(ConfirmationBoxComponent, {class:'modal-md', initialState, ignoreBackdropClick:true});
    modalRef.content.event.subscribe(res =>
    {
      if(res?.result?.action == 'ok')
      {
        let operatingProfileId = rowData.operatingProfileId;
        this.operatingProfileService.deleteOperatingProfile(res?.result, operatingProfileId).subscribe(res => {}, error =>
        {
          console.log(error);
          if(error.status == 500) {
            this.notificationService.error('The Operating Profile you tried to delete is in use, hence cannot be deleted','Error');
          }else if(error.status == 412){
            this.notificationService.error('The Operating Profile you tried to delete is in use, hence cannot be deleted','Error');
          }else if(error.status == 401){
            this.notificationService.error('Operating Profile could not be deleted','Error');
          }
          else {
            this.notificationService.success('The Operating Profile has been deleted','success');
            this.getAllOperatingProfile();
          }
        });
      }
      else ; // do nothing
    },(error => {
      if(error.status == 403) {
        this.notificationService.error('You need to be in the Project Admins Group to perform the current Action','Error');
      }else{
        this.notificationService.error('Operating Profile has not been deleted','Error');
      }
    }));
  }

  loadEditModal(rowData){
    const initialState = {
      param : rowData,
      projectFreezeFunction : this.projectFreezeFunction
    };
    let modalRef = this.modalService.show(EditOperatingProfilesComponent, {class:'modal-lg',initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res =>
    {
      this.getAllOperatingProfile();
    });
  }

  changePage(currentPage){
    this.currentPage = currentPage;
    this.getAllOperatingProfile();
  }

  changePageSize(pageSize){
    this.pageSize = pageSize;
    this.getAllOperatingProfile();
  }

}
