import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class BreadcrumbServiceService {

    private readonly pageStack: Stack<Page>;

    constructor(private router: Router)
    {
        this.pageStack = new Stack<Page>();
    }

    public navigate(index: number, title: string, url: string)
    {
        if (this.pushPage(index, title, url)) this.router.navigateByUrl(url);
    }

    public pushPage(index: number, title: string, url: string): boolean
    {
        if (index < 0 || !title || !url) return false;

        if (index > 0 && this.pageStack.isEmpty())
        {
            this.pushMissingPages(index, title, url);
        }

        let page: Page = new Page();
        page.index = index;
        page.title = title;
        page.url = url;

        this.pageStack.push(page);
        return true;
    }

    private pushMissingPages(index: number, title: string, url: string)
    {
        if (index > 0)
        { // project page
            let page: Page = new Page();
            page.index = 0;
            page.title = "Projects";
            page.url = "/projects";

            this.pageStack.push(page);
        }

        if (index > 1)
        { // system page
            if (title == "Task" || title == "Parts")
            {
                let page: Page = new Page();
                page.index = 1;
                page.title = "Systems";

                let urlSegments = url.split("systems");
                page.url = urlSegments[0] + "systems";

                this.pageStack.push(page);
            }
        }
    }

    public goBack()
    {
        if (this.pageStack.isEmpty()) return;

        this.pageStack.pop();
        let lastPage = this.pageStack.at(this.pageStack.size() - 1);
        if (lastPage) this.router.navigateByUrl(lastPage.url);
    }

    public gotoPage(title: string)
    {
        for (let index = 0; index < this.pageStack.size(); index++)
        {
            let page: Page = this.pageStack.at(index);
            if (page && page.title == title)
            {
                this.pageStack.popTo(index);
                this.router.navigateByUrl(page.url);
            }
        }
    }

    public replacePage(title: string, newUrl: string)
    {
        let size = this.pageStack.size();
        for (let index = 0; index < size; index++)
        {
            let page = this.pageStack.at(index);
            if (page.title == title)
            {
                page.url = newUrl;
                break;
            }
        }
    }

    public resetStack()
    {
        this.pageStack.reset();
    }

    public isStackEmpty(): boolean
    {
        if (this.pageStack && !this.pageStack.isEmpty())
        {
            return false;
        }
        else
        {
            return true;
        }
    }
}

class Stack<T> {
    private _store: T[] = [];

    public push(val: T)
    {
        this._store.push(val);
    }

    public pop(): T | undefined
    {
        return this._store.pop();
    }

    public popTo(index: number): T | undefined
    {
        if (index >= this.size() - 1 || index < 0) return undefined;
        this._store.splice(index + 1);
        return this._store[index];
    }

    public at(index): T | undefined
    {
        return this._store[index];
    }

    public size()
    {
        return this._store.length;
    }

    public isEmpty(): boolean
    {
        return this._store.length == 0;
    }

    public reset()
    {
        this._store = [];
    }
}

class Page {
    public index: number;
    public title: string;
    public url: string;
}
