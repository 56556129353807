import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProjectSystemSparePartsService {

    constructor(private http: HttpClient) { }

    /**
     * Get All Project System Parts
     * @param projectNumber
     * @param systemNumber
     */
    getProjectSystemSpareParts(projectNumber, systemNumber)
    {

        if (systemNumber == null)
        {
            systemNumber = 0;
        }

        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/parts/");

    }

    getProjectSystemSparePartsByParent(projectNumber, systemNumber, parentId)
    {
        if (!projectNumber || !systemNumber || !parentId) return;
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/parent-parts/" + parentId + "/parts/");
    }

    getTaskPartsUsages(spareNumber)
    {
        if (!spareNumber) return;
        return this.http.get(environment.apiUrl + "/projectsystemspareparts/taskpartsUsages/" + spareNumber);
    }

    getChildPartsUtilization(spareNumber)
    {
        if (!spareNumber) return;
        return this.http.get(environment.apiUrl + "/projectsystemspareparts/childpartsutilization/" + spareNumber);
    }

    updateTaskPartQuantity(rowData, partQuantity)
    {
        if (!rowData || !rowData['taskDbId'] || !rowData['spareNumber'] || !partQuantity) return;

        return this.http.put(environment.apiUrl + "/projectsystemspareparts/taskpartsUsages/updateQuantity/" + rowData['taskDbId'] + "/" + rowData['spareNumber'] + "/" + partQuantity, null);
    }

    /**
     * Delete project system id
     * @param projectNumber
     * @param projectSystemId
     */
    delete(res, projectNumber, projectSystemId, spareNumber)
    {
        const httpOptions = {
            params: {
                comment: res['comment'],
                justificationCategory: res['justificationCategory'] ? res['justificationCategory'] : -1
            }
        };
        return this.http.delete(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + projectSystemId + "/parts/" + spareNumber, httpOptions);
    }

    findAllPossiblePartsToMoveByParentId(projectNumber, projectSystemId, parentId, spareNumber)
    {
        if (!projectNumber || !projectSystemId || !spareNumber) return;
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + projectSystemId + "/possiblePartsToMove/" + parentId + "/" + spareNumber);
    }

    movePart(res, spareNumber, newParentId)
    {
        const httpOptions = {
            params: {
                comment: res['comment'],
                justificationCategory: res['justificationCategory'] ? res['justificationCategory'] : -1
            }
        };
        return this.http.post(environment.apiUrl + "/projectsystemspareparts/" + spareNumber + "/movePart/" + newParentId, httpOptions);
    }

    /**
     * Create New Project system spare parts record
     * @param data
     */
    createProjectSystemSpareParts(data)
    {
        return this.http.post(environment.apiUrl + "/projectsystemspareparts/", data);
    }

    /**
     * Create New Project system spare parts record
     * @param data
     */
    createProjectSystemSparePartByParent(reason, data)
    {
        const httpOptions = {
            params: {
                comment: reason['comment'],
                justificationCategory: reason['justificationCategory'] != null ? reason['justificationCategory'] : -1
            }
        };
        return this.http.post(environment.apiUrl + "/projectsystemspareparts/all/", data, httpOptions);
    }

    updateProjectSystemSpareParts(reason, data, spareNumber)
    {
        const httpOptions = {
            params: {
                comment: reason['comment'],
                justificationCategory: reason['justificationCategory'] != null ? reason['justificationCategory'] : -1
            }
        };
        return this.http.put(environment.apiUrl + "/projectsystemspareparts/" + spareNumber, data, httpOptions);
    }

    /**
     * Get Project System Parts By Part ID
     * @param spareId
     */
    getProjectSystemSparePartsById(spareId)
    {
        return this.http.get(environment.apiUrl + "/projectsystemspareparts/" + spareId + "/");
    }

    getProjectSystemSparePartsByStadlerArticleId(id)
    {
        return this.http.get(environment.apiUrl + "/parts/stadlerarticleid/" + id + "/");
    }

    searchSpareparts(data)
    {
        return this.http.post(environment.apiUrl + "/projectsystemspareparts/search", data);
    }

    getFailureRateUnits()
    {
        return this.http.get(environment.apiUrl + "/failure-rate-unit/");
    }

    getLifeSpanUnits()
    {
        return this.http.get(environment.apiUrl + "/life-span-unit/");
    }

    isSparePartSelectedInTasks(spareId)
    {
        return this.http.get(environment.apiUrl + "/projectsystemspareparts/" + spareId + "/hasUsedTask");
    }

    exportParts(data, projectNumber, projectSystemId)
    {
        return this.http.post(environment.apiUrl + "/projectsystemspareparts/export/" + projectNumber + "/" + projectSystemId, data, {responseType: 'blob'});
    }

    uploadSpareParts(data, sparePartId)
    {
        return this.http.post(environment.apiUrl + "/projectsystemspareparts/" + sparePartId + "/upload", data);
    }

    importSpareParts(data, sparePartId)
    {
        return this.http.post(environment.apiUrl + "/projectsystemspareparts/" + sparePartId + "/import", data);
    }
}
