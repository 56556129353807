import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {

  public rowData;
  public state:boolean = false;
  public states:{} = {db_value:"DB Value", import_value:"Import Value"};
  public toggleEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public changeState(){
    this.state = !this.state;
    this.state ? this.toggleEvent.emit('import_value') : this.toggleEvent.emit('db_value');
  }

}
