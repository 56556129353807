<div *ngIf="!loading && type == 'text'" class="cell-content text-cell-content" appTextTruncate (getTruncatedText)="setToolTipIfTruncated($event);" matTooltip="{{toolTip}}">
    <span>{{valueChangeFunction ? valueChangeFunction(content) : content}}</span>
    <button *ngIf="filter && filter.enabled" (click)="popupFilter($event)" class="filter-btn">
        <img class="icon-mini" *ngIf="!filter.selected" src="/assets/icon/filter.png"/>
        <img class="icon-mini" *ngIf="filter.selected" src="/assets/icon/filter-selected.png"/>
    </button>
</div>
<div *ngIf="!loading && type == 'custom' && (!hideFunction || !hideFunction(rowData))" class="cell-content">
    <div class="custom-element">
        <ng-container #vc></ng-container>
    </div>
</div>
<div *ngIf="!loading && type == 'custom-text' && (!hideFunction || !hideFunction(rowData))" class="cell-content">
    <div class="custom-element">
        <ng-container #vc></ng-container>
    </div>
</div>
<div *ngIf="!loading && type == 'html' && (!hideFunction || !hideFunction(rowData))" class="cell-content">
    <div [innerHtml]="renderComponent" (click)="actionFunction(rowData)" class="html-element"></div>
</div>
<div *ngIf="!loading && type == 'checkbox' && (!hideFunction || !hideFunction(rowData))" class="cell-content">
    <div class="html-element">
        <input [disabled]="this.isDisabledFunction && this.isDisabledFunction(this.rowData)" (click)="actionFunction($event, rowData)" type="checkbox" [checked]="this.checkIsCheckedFunction && this.checkIsCheckedFunction(this.rowData)">
    </div>
</div>
<div *ngIf="!loading && type == 'radio' && (!hideFunction || !hideFunction(rowData))" class="cell-content">
    <div class="html-element">
        <input #radioButton (click)="actionFunction($event, rowData)" (mousedown)="deselectOtherRadioButtons()" type="radio" [checked]="this.checkIsCheckedFunction && this.checkIsCheckedFunction(this.rowData)">
    </div>
</div>
