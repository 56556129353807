import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {environment} from 'src/environments/environment';
import {StakeHolderService} from 'src/app/services/stake-holder.service';
import {ProjectSystemTasksService} from 'src/app/services/project-system-tasks.service';
import * as FileSaver from 'file-saver';
import {LanguageSupportComponent} from "../../../../shared/components/language-support/language-support-component.service";
import {LanguageServiceService} from "../../../../services/language-service.service";
import {TaskExportLogComponent} from "../task-export-log/task-export-log.component";
import {formatDate} from '@angular/common';
import {currencyList} from "../../../../shared/helper";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-task-export-modal',
  templateUrl: './project-export-modal.component.html',
  styleUrls: ['./project-export-modal.component.scss']
})


export class ProjectExportModalComponent extends LanguageSupportComponent  implements OnInit {

  @ViewChild('fileInput') fileInput;
  @ViewChild('modalBody', { static: false }) modalBodyElement: ElementRef;

  btnLoading:Boolean=false;
  param:any;
  projectNumber:any;
  taskType:any;
  taskTypeData:any=[];
  ramEngineersData:any=[];
  toolCategoriesData:any=[];
  operatingMaterialsData:any=[];
  partsData:any=[];
  apiURL:any;
  rowData:any;
  currencyData:any[];
  taskExportForm:any;
  selectedFileToImport:File;
  selectedFileName:string = "";
  spinnerText:string = "";
  spinnerVisible:boolean = false;
  spinnerPosition:any = {'top':0, 'left':0};
  selectedCurrencyToImport:string = "";

  constructor(
      public bsModalRef:BsModalRef,
      public StackHolderService: StakeHolderService,
      public projectSystemTaskService: ProjectSystemTasksService,
      public languageService: LanguageServiceService,
      public modalService: BsModalService,
      public notification: ToastrService,
  ) {
    super(languageService, modalService);
  }

  ngOnInit(): void {
    this.rowData = this.param.rowData;
    this.projectNumber = this.rowData.projectNumber;

    console.log(this.rowData);

    this.apiURL = environment.apiUrl;
    this.taskExportForm = new UntypedFormGroup({
      taskExportEnabled: new UntypedFormControl(false),
      taskExportTaskType: new UntypedFormControl({value: 'all', disabled: true}),
      taskExportRamEngineer: new UntypedFormControl({value: 'all', disabled: true}),
      taskExportToolCategories: new UntypedFormControl({value: 'none', disabled: true}),
      taskExportOperatingMaterials: new UntypedFormControl({value: 'none', disabled: true}),
      taskExportParts: new UntypedFormControl({value: 'none', disabled: true}),
      taskOfficialExport: new UntypedFormControl({value: false, disabled: true}),
      taskExportVersion: new UntypedFormControl({value: '', disabled: true}),
      partsExportEnabled: new UntypedFormControl(false),
      toolsExportEnabled: new UntypedFormControl(false),
      toolsExportToolCategories: new UntypedFormControl({value: 'none', disabled: true}),
      operatingMaterialsExportEnabled: new UntypedFormControl(false),
      partsAndExternalServicePriceExportEnabled: new UntypedFormControl(false),
      partsAndExternalServicePriceCurrency: new UntypedFormControl({value: ''}),
    });
    this.initFormValues();
  }

  ngAfterViewInit(){
    setTimeout(()=>{
      this.placeSpinner();
    },0)
  }

  initFormValues(){
    this.getTaskTypeData();
    this.getRamEngineerData();
    this.getToolCategoriesData();
    this.getOperatingMaterialsData();
    this.getPartsData();
    this.getCurrencyData();
  }

   convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
    }

  submitExportForm(){
    this.spinnerVisible = true;

    let formData = this.taskExportForm.getRawValue();
    formData.exportedDate = (new Date()).toLocaleString("en-US", {timeZone: "Europe/Berlin"});
    this.projectSystemTaskService.exportTask(formData, this.projectNumber).subscribe(res=>{
      if(res['status'] == 0){

        const byteCharacters = atob(res['fileContents']);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        // const blob = new Blob([byteArray], {type: 'audio/mp3'});

        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        var strFileName = "Export";
        var dt = new Date();
        dt = this.convertTZ(dt, "Europe/Berlin");
        
        // timeString = dt.toLocaleString('en-US', { timeZone: 'Europe/Berlin' });

        var dtString = formatDate(dt, 'yyyy-MM-dd', 'en-US', 'Europe/Berlin');
        var timeString = formatDate(dt, 'HH-mm', 'en-US', 'Europe/Berlin');
        var versionString = formData.taskExportVersion;

        strFileName = res['downloadFileName'] + res['downloadFileExtension'];

        const file = new File([blob], strFileName, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        FileSaver.saveAs(file);
        this.notification.success('Document has been Downloaded', 'Success');
      }else{
        this.notification.error('Document has not been Downloaded', 'Error');
      }
      this.spinnerVisible = false;
    }, error => {
      this.notification.error('Document has not been Downloaded', 'Error');
      this.spinnerVisible = false;
    });
  }

  getCurrencyData() {
    this.currencyData = currencyList();
  }

  getTaskTypeData(){
    this.taskTypeData=[
      {
        id:'all',
        value:'All'
      },
      {
        id:'cm',
        value:'Corrective Maintenance'
      },
      {
        id:'pm',
        value:'Preventive Maintenance'
      }
    ];
    setTimeout(()=>{
      this.taskExportForm.patchValue({
        taskExportTaskType:'all',
      });
      super.saveInitialValues(JSON.stringify(this.taskExportForm.getRawValue()));
    },0);
  }

  languageChanged(){
    super.languageChanged(JSON.stringify(this.taskExportForm.getRawValue()));
  }

  getRamEngineerData(){
    this.StackHolderService.getRamEngineers().subscribe(res=>{
      this.ramEngineersData = res;
      this.ramEngineersData.unshift({stakeholderId:0,nameUsed:'All', fullName:'All'})
    });
    setTimeout(()=>{
      this.taskExportForm.patchValue({
        taskExportRamEngineer:0,
      });
      super.saveInitialValues(JSON.stringify(this.taskExportForm.getRawValue()));
    },0);
  }

  getToolCategoriesData(){
    this.toolCategoriesData = [{id:'none', value:'None'}, {id:'special', value:'Special'}, {id:'standard', value:'Standard'}, {id:'all', value:'All'}];
    setTimeout(()=>{
      this.taskExportForm.patchValue({
        taskExportToolCategories: this.rowData['toolCategories'] ? this.rowData['toolCategories'] : 'none',
        toolsExportToolCategories: this.rowData['toolCategories'] ? this.rowData['toolCategories'] : 'none',
      });
      super.saveInitialValues(JSON.stringify(this.taskExportForm.getRawValue()));
    },0);
  }

  getOperatingMaterialsData(){
    this.operatingMaterialsData = [{id:'none', value:'None'},  {id:'all', value:'All'}];
    setTimeout(()=>{
      this.taskExportForm.patchValue({
        taskExportOperatingMaterials: this.rowData['operatingMaterials'] ? this.rowData['operatingMaterials'] : 'none',
      });
      super.saveInitialValues(JSON.stringify(this.taskExportForm.getRawValue()));
    },0);
  }

  getPartsData(){
    this.partsData = [{id:'none', value:'None'},  {id:'all', value:'All'}];
    setTimeout(()=>{
      this.taskExportForm.patchValue({
        taskExportParts: this.rowData['parts'] ? this.rowData['parts'] : 'none'
      });
      super.saveInitialValues(JSON.stringify(this.taskExportForm.getRawValue()));
    },0);
  }

  changeEnableTaskExport(){
    if(this.taskExportForm.controls['taskExportEnabled'].value){
      this.taskExportForm.controls['taskExportTaskType'].enable();
      this.taskExportForm.controls['taskExportRamEngineer'].enable();
      this.taskExportForm.controls['taskExportToolCategories'].enable();
      this.taskExportForm.controls['taskExportOperatingMaterials'].enable();
      this.taskExportForm.controls['taskExportParts'].enable();

      if(this.taskExportForm.controls['taskExportRamEngineer'].value == 0){
        this.taskExportForm.controls['taskOfficialExport'].enable();
      }
    }
    else{
      this.taskExportForm.controls['taskExportTaskType'].disable();
      this.taskExportForm.controls['taskExportRamEngineer'].disable();
      this.taskExportForm.controls['taskExportToolCategories'].disable();
      this.taskExportForm.controls['taskExportOperatingMaterials'].disable();
      this.taskExportForm.controls['taskExportParts'].disable();

      this.taskExportForm.controls['taskOfficialExport'].patchValue(false);
      this.taskExportForm.controls['taskOfficialExport'].disable();

      this.taskExportForm.controls['taskExportVersion'].patchValue('');
      this.taskExportForm.controls['taskExportVersion'].disable();
    }
  }

  changeOfficialTaskExport(){
    if(this.taskExportForm.controls['taskOfficialExport'].value){
      this.taskExportForm.controls['taskExportRamEngineer'].patchValue(0);
      this.taskExportForm.controls['taskExportRamEngineer'].disable();

      this.taskExportForm.controls['taskExportVersion'].enable();
    }
    else{
      this.taskExportForm.controls['taskExportRamEngineer'].enable();

      this.taskExportForm.controls['taskExportVersion'].patchValue('');
      this.taskExportForm.controls['taskExportVersion'].disable();
    }
  }

  changeEnableToolsExport(){
    if(this.taskExportForm.controls['toolsExportEnabled'].value){
      this.taskExportForm.controls['toolsExportToolCategories'].enable();
    }
    else{
      this.taskExportForm.controls['toolsExportToolCategories'].disable();
    }
  }

  changeRamEngineerExport(){
    if(this.taskExportForm.controls['taskExportRamEngineer'].value == 0){
      this.taskExportForm.controls['taskOfficialExport'].enable();
    }
    else{
      this.taskExportForm.controls['taskOfficialExport'].patchValue(false);
      this.taskExportForm.controls['taskOfficialExport'].disable();
    }
  }

  loadLogModel(){
    const initialState = {
      param : {
        projectNumber : this.projectNumber,
      }
    };
    this.modalService.show(TaskExportLogComponent, {class:'modal-xl', initialState, keyboard:false,ignoreBackdropClick: true});
  }

  public openFileSelectDialog() {
    if (!this.fileInput || !this.fileInput.nativeElement) return;

    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }

  public onChangeFileInput() {
    if (!this.fileInput || !this.fileInput.nativeElement || !this.fileInput.nativeElement.files) return;

    const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    this.selectedFileToImport = files[0];
    this.selectedFileName = this.selectedFileToImport?.name;
  }

  importFile() {
    if (!this.selectedFileToImport) return;

    this.selectedCurrencyToImport = this.taskExportForm.controls['partsAndExternalServicePriceCurrency'].value;
    if (!this.selectedCurrencyToImport || this.selectedCurrencyToImport == "") return;

    this.spinnerText = "Uploading and Validating Data";
    this.spinnerVisible = true;

    let formData = new FormData();
    formData.append('file', this.selectedFileToImport);
    formData.append('currency', this.selectedCurrencyToImport);

    this.projectSystemTaskService.importValueWithMarginForTaskAndParts(formData, this.projectNumber).subscribe((res: any) => {
      if (res) {
        this.notification.success('Document has been Uploaded', 'Success');
      } else {
        this.notification.error('Document has not been Uploaded', 'Error');
      }

      this.selectedFileName = "";
      this.selectedFileToImport = null;
      this.taskExportForm.controls['partsAndExternalServicePriceCurrency'].patchValue(null);

      this.spinnerVisible = false;
      this.bsModalRef.hide();
    }, ((error)=>{
      this.notification.error(error?.error?.message, 'Error');
      this.selectedFileName = "";
      this.selectedFileToImport = null;
      this.taskExportForm.controls['partsAndExternalServicePriceCurrency'].patchValue(null);
      this.spinnerVisible = false;
    }))
  }

  callAfterLanguageChanged() {
    this.initFormValues();
  }

  private dateFormat(date: Date) {
    const day = date && date.getDate() || -1;
    const dayWithZero = day.toString().length > 1 ? day : '0' + day;
    const month = date && date.getMonth() + 1 || -1;
    const monthWithZero = month.toString().length > 1 ? month : '0' + month;
    const year = date && date.getFullYear() || -1;

    return `${year}-${monthWithZero}-${dayWithZero}`;
  }

  private timeFormat(date: Date) {
    const hour = date && date.getHours() || -1;
    const hourWithZero = hour.toString().length > 1 ? hour : '0' + hour;
    const minute = date && date.getMinutes() || -1;
    const minuteWithZero = minute.toString().length > 1 ? minute : '0' + minute;

    return `${hourWithZero}-${minuteWithZero}`;
  }

  placeSpinner(){ // sets spinner location
    if(!this.modalBodyElement || !this.modalBodyElement.nativeElement || !this.modalBodyElement.nativeElement.getBoundingClientRect()) return;

    let divHeight = this.modalBodyElement.nativeElement.getBoundingClientRect().height;
    let divWidth = this.modalBodyElement.nativeElement.getBoundingClientRect().width;

    this.spinnerPosition.top = divHeight/2 - 50;
    this.spinnerPosition.left = divWidth/2 - 50;
  }

}
