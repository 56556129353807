import {Component, EventEmitter, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ProjectService } from 'src/app/services/project.service';
import { QuantityUnitServiceService } from 'src/app/services/quantity-unit-service.service';
import { StandardSystemsService } from 'src/app/services/standard-systems.service';
import { OperatingProfilesService } from 'src/app/services/operating-profiles.service';
import { ToastrService } from 'ngx-toastr';
import { ProfileUnitsService } from 'src/app/services/profile-units.service';
import { ProjectSystemsService } from 'src/app/services/project-systems.service';
import { validateForm,countString } from 'src/app/shared/helper';
import { isNumber } from '@ng-bootstrap/ng-bootstrap/util/util';
import {LanguageSupportComponent} from "../../../../shared/components/language-support/language-support-component.service";
import {LanguageServiceService} from "../../../../services/language-service.service";

@Component({
  selector: 'app-add-operating-profiles',
  templateUrl: './add-operating-profiles.component.html',
  styleUrls: ['./add-operating-profiles.component.scss']
})
export class AddOperatingProfilesComponent extends LanguageSupportComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();
  addOperatingProfileForm:any;
  btnLoading:Boolean=false;
  minValue:any;
  maxValue:any;
  profileUnitValid:Boolean=true;
  projectSystemArray:any = [];
  taskUnitList:any=[];
  existOperatingProfiles:any=[];
  param:any;
  projectFreezeFunction = "";

  constructor(
      public bsModalRef: BsModalRef,
      private projectService: ProjectService,
      private quantityUnitService: QuantityUnitServiceService,
      private systemService: StandardSystemsService,
      private operatingProfileService: OperatingProfilesService,
      private notificationService: ToastrService,
      private ProfileUnitService: ProfileUnitsService,
      private projectSystemService: ProjectSystemsService,
      public languageService: LanguageServiceService,
      public modalService: BsModalService
  ) {
    super(languageService, modalService);
  }

  ngOnInit(): void {
    this.addOperatingProfileForm = new UntypedFormGroup({
      projectNumber: new UntypedFormControl(),
      // value: new UntypedFormControl(),
      value: new UntypedFormControl('',[Validators.required, Validators.maxLength(100)]),
      unitAddition: new UntypedFormControl('', [Validators.maxLength(100)]),
      profileUnitId: new UntypedFormControl('',[Validators.required]),
      projectSystemId: new UntypedFormControl('',[Validators.required]),
    });
    this.getAllProjects();
    this.getTaskUnits();
    this.getProjectSystems();
    console.log('param',this.param);
    this.existOperatingProfiles = this.param.operatingProfileList;
  }

  /**
   * Submit operating profile add form
   */
  addOperatingProfiles(event){
    this.profileUnitValid=true;
    var formData = this.addOperatingProfileForm.value;
    if(this.addOperatingProfileForm.valid){

      if(formData.profileUnitId==7){
        var recordSrting = formData.projectSystemId+'-'+formData.profileUnitId+'-'+formData.unitAddition;
        recordSrting = btoa(recordSrting);
        console.log(recordSrting);

        for(var x=0; x<this.existOperatingProfiles.length; x++){

          var row = this.existOperatingProfiles[x];
          var rowString = row.projectSystemId+'-'+row.profileUnitId+'-'+row.unitAddition;
          rowString = btoa(rowString);
          if(rowString==recordSrting){
            this.profileUnitValid=false;
          }
        }
      }
      if(this.profileUnitValid){
        this.btnLoading=true;
        this.operatingProfileService.createOperatingProfiles(event, formData).subscribe(res=>{
          if(res){
            this.notificationService.success('Operating Profile has been created','success');
            this.bsModalRef.hide();
            this.event.emit();
          }else{
            this.notificationService.error('Operating Profile has not been created','Error');
          }
          this.btnLoading=false;
        },(error => {
          if(error.status == 403) {
            this.notificationService.error('You need to be in the Project Admins Group to perform the current Action','Error');
          }else{
            this.notificationService.error('Operating Profile has not been created','Error');
          }
          this.btnLoading=false;
        }));
      }
    }else{
      validateForm(this.addOperatingProfileForm);
    }
  }

  /**
   * Validate profile units
   */
  validateProfileUnits(){

    var formValue = this.addOperatingProfileForm.value;
    let projectSystemId = formValue.projectSystemId;

    var status = false;
    for(var x=0; x<this.existOperatingProfiles.length; x++){
      if((projectSystemId==this.existOperatingProfiles[x].projectSystemId) && (this.existOperatingProfiles[x].profileUnitId==6)){
        var status=true;
      }
    }

    if(status==true){
      for(var y=0; y<this.taskUnitList.length; y++){
        console.log(this.taskUnitList[y]);
        if(this.taskUnitList[y].profileUnitId==6){
          delete this.taskUnitList[y];
        }
      }
    }else{
      this.getTaskUnits();
    }

  }

  /**
   * Get All ProjectList
   */
  getAllProjects(){
    setTimeout(()=>{
      this.addOperatingProfileForm.patchValue({
        projectNumber: this.param.projectNumber
      });
      super.saveInitialValues(JSON.stringify(this.addOperatingProfileForm.getRawValue()));
    });
  }

  languageChanged(){
    super.languageChanged(JSON.stringify(this.addOperatingProfileForm.getRawValue()));
  }

  /**
   * Get project systems by projects
   */
  getProjectSystems(){
    this.projectSystemService.getProjectSystemByProject(this.param.projectNumber).subscribe(returnDataset=>{
      let result = returnDataset["content"];
      Object.keys(result).forEach(key => {
        if( result[key].systemCode != 'AA00'){
          result[key].text = result[key].systemCode + "-" + result[key].systemVariationNumber + "-" + result[key].systemVariation;
          this.projectSystemArray.push(result[key]);
        }
      });
    });
  }

  /**
   * Get all Task Units
   */
  getTaskUnits(){
    this.ProfileUnitService.getProfileUnitsByType(1).subscribe(res=>{
      this.taskUnitList = res;
    })
  }

  /**
   * Get profile unit by id
   */
  getProfileUnitById(){
    let formValue = this.addOperatingProfileForm.getRawValue();
    let profileUnitId = formValue['profileUnitId'];
    this.ProfileUnitService.getProfileUnitById(profileUnitId).subscribe(res=>{
      this.minValue = res['minValue'];
      this.maxValue = res['maxValue'];
    });
    this.validateUnitAddition(profileUnitId);
    this.addOperatingProfileForm.controls['value'].updateValueAndValidity();
  }


  /**
   * Validate Unit addition by Profile unit
   * @param profileUnitId
   */
  validateUnitAddition(profileUnitId){
    if(profileUnitId==7){
      // Unit addition mandotory
      this.addOperatingProfileForm.controls['unitAddition'].setValidators([Validators.required, Validators.maxLength(100)]);
      this.addOperatingProfileForm.controls['unitAddition'].enable();
    }else{
      // Not Mandotory
      this.addOperatingProfileForm.controls['unitAddition'].clearValidators();
      this.addOperatingProfileForm.patchValue({
        unitAddition: '',
      });
      this.addOperatingProfileForm.controls['unitAddition'].disable();
    }

    this.addOperatingProfileForm.controls['unitAddition'].updateValueAndValidity();
  }

  callAfterLanguageChanged() {
    this.getAllProjects();
    this.getTaskUnits();
    this.getProjectSystems();
  }

}
