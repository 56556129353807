
<div class="modal-header {{titleBackground}}">
    <h5 class="modal-title pull-left">{{title}}</h5>
    <button aria-hidden="true" type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div *ngIf="text" class="modal-body">
    <p>{{text}}</p>
</div>
<div class="modal-footer">
    <app-action-comment [btnLoading]="btnLoading"  [okBtnName]="okBtnName" [closeBtnName]="closeBtnName" [commentRequired]="isCommentRequired" [commentVisible]="isCommentVisible" (okClick)="okClicked($event)" (cancelClick)="cancelClicked($event)"></app-action-comment>
</div>
