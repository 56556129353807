import {Component, EventEmitter, OnInit} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-clone-project-systems',
  templateUrl: './clone-project-systems.component.html',
  styleUrls: ['./clone-project-systems.component.scss']
})
export class CloneProjectSystemsComponent implements OnInit {

  constructor(
      private projectService:ProjectService,
      private notification: ToastrService
  ) { }

  eventEmitter:EventEmitter<string> = new EventEmitter();
  rowData:any;
  projectSystemId:any;
  finalProjectSystem:any=[];
  projectSystemData:any=[];
  isButtonHidden:Boolean;
  projectNumber:any;
  ngOnInit(): void {
    this.projectSystemId = this.rowData.projectSystemId;
    this.projectNumber = this.rowData.projectNumber;
    this.isButtonHidden = this.rowData.systemCode == "AA00";
  }

  /**
   * Clone Project systems
   */
  cloneProjectSystems(){
    let selectedItem = Object.assign({}, this.rowData);
    let selectedSystemNumber = selectedItem.systemNumber;

    var exitItems = [];
    for(var x=0; x<this.finalProjectSystem.length;x++){
      if(selectedSystemNumber == this.finalProjectSystem[x].systemNumber){
        exitItems.push(this.finalProjectSystem[x].systemVariationNumber);
      }

    }
    var maxValue = Math.max(...exitItems);
    selectedItem.systemVariationNumber =maxValue+1;
    this.finalProjectSystem.push(selectedItem);
    console.log('clone',this.finalProjectSystem);
    console.log('SELECTED',selectedItem);

    this.projectSystemData = [];
    // for(var x=0; x < this.finalProjectSystem.length; x++){

    let singleProjectSystem = new FormData();

    singleProjectSystem.append('systemNumber',selectedItem.systemNumber);
    singleProjectSystem.append('systemVariationNumber',selectedItem.systemVariationNumber);
    singleProjectSystem.append('systemVariation',selectedItem.systemVariation);


    // }

    this.projectService.addSingleProjectSystem(singleProjectSystem,this.projectNumber).subscribe(res=>{
      this.eventEmitter.emit("refresh");
    });
  }

}
