import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { JustificationCategoryService } from 'src/app/services/justification-category.service';
import { LanguageServiceService } from 'src/app/services/language-service.service';
import { ProjectSystemTasksService } from 'src/app/services/project-system-tasks.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-task-dbid-change',
  templateUrl: './task-dbid-change.component.html',
  styleUrls: ['./task-dbid-change.component.scss']
})
export class TaskDbidChangeComponent {


  public lstSelectedProject = new UntypedFormControl();

  projects: any[] = [];
  projectIsFrozen: Boolean = false;
  projectSystems: any[] = [];
  tasks: any[] = [];
  
  btnChangeDisabled: Boolean = true;
  btnChangeLoading: Boolean = false;

  selectedProject: any;
  selectedProjectSystem: any;
  
  projectsLoaded: Boolean = false;
  projectSystemsLoaded: Boolean = true;
  tasksLoaded: Boolean = true;
  selectedTaskDbId: any;
  newTaskDbId: any;
  justificationCategoryData: any[] = [];
  isNewTaskDbIdExists: Boolean = false;
  isMaxTaskDbIdExceeds: Boolean = false;
  maxDbId: Number = 0;
  errornousExistingTaskId:String = "";
  myComment:String = "";
  justificationCategory: number = -1;
  taskDbIdStatusChecked = false;
  ifProjectAdmin: Boolean = true;


  taskDbIdChangeForm = new UntypedFormGroup({
    projectNumber : new UntypedFormControl('',[Validators.required]),
    projectSystemId : new UntypedFormControl('',[Validators.required]),
    taskDbId : new UntypedFormControl('',[Validators.required]),
    newTaskDbId : new UntypedFormControl('',[Validators.required, Validators.minLength(5)]),
    comment : new UntypedFormControl('',[Validators.required, Validators.minLength(10)]),
    justificationCategory : new UntypedFormControl(null, Validators.required)
  });


  constructor(
    private authService: AuthServiceService,
    public bsModalRef: BsModalRef,
    private activatedRoute : ActivatedRoute,
    private projectService: ProjectService,
    private languageService:LanguageServiceService,
    private userService:UserService,
    private notificationService: ToastrService,
    private taskService: ProjectSystemTasksService,
    private justificationCategoryService: JustificationCategoryService
  ) {}

  ngOnInit(): void {
    this.getJustificationCategory();
    this.getProjects();
    
  }

  resetForm(){
    this.taskDbIdChangeForm.reset();
  }

  onChangeProject(event: any) {
    // console.log("Selected Project: ", this.selectedProject);
    
    //Check if the project is Frozen
    //search for projects object array for this project's freezeFunction attribute.
    let filteredProjects:any[] = this.projects.filter(project => project.projectNumber == this.selectedProject);
    let thisProject : any;
    if(filteredProjects.length > 0){
      thisProject = filteredProjects[0];
    }
    console.log("Freeze Function: " + thisProject.freezeFunction);

    this.projectIsFrozen = false;
    if(this.selectedProject && thisProject && thisProject.freezeFunction == "activated"){
      this.projectIsFrozen = true;
    }


    this.checkIfProjectAdmin(this.selectedProject);
    this.loadProjectSystems(this.selectedProject);
    this.updateButtonStatus();

  }

  checkIfProjectAdmin(projectNumber: string){
    this.projectService.checkIfProjectAdmin(projectNumber).subscribe(res=>{
      
      console.log("ifProjectAdmin : " + res);
      if(res && (res == "true" || res==true)){
        this.ifProjectAdmin = true;
      }else{
        this.ifProjectAdmin = false;
        this.notificationService.error("You need to be in the Project Admins Group to perform the current Action", "Error");
      };
    },(error => {
      this.ifProjectAdmin = false;
      console.log(error);
      this.notificationService.error("You need to be in the Project Admins Group to perform the current Action", "Error");
    }),() => {});
  }

  onChangeProjectSystem(event: any) {
    // console.log("Selected Project System: ", this.selectedProjectSystem);
    this.loadTasks(this.selectedProject, this.selectedProjectSystem);
    this.updateButtonStatus();
  }

  onChangeTask(event: any) {
    console.log("Selected Task: ", this.selectedTaskDbId);
    this.updateButtonStatus();
  }

  getProjects(){
    this.projectService.getProjectList().subscribe(res=>{
      if(res && res['page']){
        res = res['page']['content'];
        Object.keys(res).forEach(item => {
          // console.log(res[item]);
          this.projects.push({
            projectNumber: res[item].projectNumber,
            projectDesignation: res[item].projectNumber + " - " + res[item].projectDesignation + (res[item].freezeFunction=="inactivated"?"":" [Frozen]"),
            freezeFunction : res[item].freezeFunction
          });
        });
        // console.log(this.projects);
        this.projectsLoaded = true;
      }
    });
  }

  /**
   * Load Project System By Project Number
   */
  loadProjectSystems(projectNumber: string){  
    this.projectSystemsLoaded = false;
    let params = {};
    let filteredData = [];
  
    this.projectService.getProjectSystems(projectNumber, "filtered-data", filteredData, params).subscribe(res=>{
      if(res && res['page']){
        let result = res['page']['content'];
        
        Object.keys(result).forEach(item => {
          // console.log(res[item]);
          result[item].systemVariationNumberAndCode = result[item].systemVariationNumberAndCode + ' ' +  result[item].systemVariation;
        });
        this.projectSystems = result;
      }
      this.projectSystemsLoaded = true;
      // console.log(this.projectSystems);
      // this.projectSystems.forEach(item => item['systemVariationNumberAndCode'] = item['projectSystemId'] + ' | ' + item['systemVariationNumberAndCode']);
    },(error => {
      //If error occurred
      console.log(error);
      this.projectSystemsLoaded = true;
    }),() => {
      //Finally
      this.projectSystemsLoaded = true;
    });
    
  }

  /**
   * Load Task By Project System
   */
  loadTasks(projectNumber: string, projectSystemId: string){
    this.tasksLoaded = false;
    let params = {};
    let filteredData = [];
  
    this.taskService.getAllProjectSystemTasks(projectNumber, projectSystemId , "filtered-data", filteredData, params).subscribe(res=>{
      if(res && res['page']){
        let result = res['page']['content'];
        this.tasks = result;
      }
      this.tasks.forEach(item => item['taskName'] = item['taskDbId'] + ' ' + item['taskId'] + ' ' + item['taskElementStr'] + ' - ' + item['taskActivity'] + ' [ ' + item['isTaskActivated'] + ' ]');
      this.tasksLoaded = true;
      // console.log(this.tasks);
    },(error => {
      //If error occurred
      console.log(error);
      this.tasksLoaded = true;
    }),() => {
      //Finally
      this.tasksLoaded = true;
    });
  }

  /**
   * Change Task DBID
   */
  changeDBIDNow(){
    this.btnChangeLoading = true;


    try {
      this.taskService.changeTaskDbId(this.selectedProject, this.selectedProjectSystem, this.selectedTaskDbId, this.newTaskDbId, this.myComment, this.justificationCategory).subscribe(res=>{
        if(res){
          this.notificationService.success("Task DBID Changed Successfully!");
          setTimeout(() => {
            window.location.reload(); 
          }, 3000);
        }
      },(error => {
        //If error occurred
        if(error.status == 200){
          console.log(error);
          this.notificationService.success("New Task DB Id updated successfully", "Success");
          this.btnChangeDisabled = true;
          this.btnChangeLoading = false;
          setTimeout(() => {
            window.location.reload(); 
         }, 3000);
          
        }else{
          console.log(error);
          if(error.error.details)
            this.notificationService.error(error.error.details);
          this.btnChangeDisabled = true;
          this.btnChangeLoading = false;
          //   setTimeout(() => {
          //     window.location.reload(); 
          //  }, 3000);
        }
        this.btnChangeLoading = false;
      }),() => {
        //Finally
        this.btnChangeLoading = false;
      });
    } catch (error) {
      // console.log(error);
      if(error.error != null){
        this.notificationService.error(error.error.details);
      }
    }
    
    
  }

  //Get Justification Category
  getJustificationCategory(){
    this.justificationCategoryService.getJustificationCategories().subscribe((res:any[])=>{
        this.justificationCategoryData = res;
    });
  }

  justificationCategoryChanged(selectedItems: any){
    this.justificationCategory = selectedItems[0].value;
    this.updateButtonStatus();
  }

  updateButtonStatus(){
    if(this.projectIsFrozen){
      if(!(this.myComment && this.myComment.length >= 10 && this.justificationCategory >= 0)){
        this.btnChangeDisabled = true;
        return true;
      }
    }
    if(this.selectedProject && this.selectedProjectSystem && this.selectedTaskDbId && this.newTaskDbId && this.isNewTaskDbIdExists == false && this.isMaxTaskDbIdExceeds == false){
      this.btnChangeDisabled = false;
    }else{
      this.btnChangeDisabled = true;
    }
  }

  newTaskDbIdChanged(){
    this.btnChangeDisabled = true;
    this.taskDbIdStatusChecked = false;
    this.isNewTaskDbIdExists = false;
    this.updateButtonStatus();
  }

  newTaskDbIdLostFocus(){
    this.btnChangeDisabled = true;
    this.taskDbIdStatusChecked = false;
    this.checkNewDbIDExists();
  }

  checkNewDbIDExists(){
    this.isNewTaskDbIdExists = false;
    this.isMaxTaskDbIdExceeds = false;
    this.taskDbIdStatusChecked = false;
    this.tasksLoaded = false;
    if(this.newTaskDbId){
      this.taskService.checkNewTaskDbIdStatus(this.selectedProject, this.selectedProjectSystem, this.newTaskDbId).subscribe(res=>{
        if(res &&  res["taskDbIdExists"]==true){
          this.errornousExistingTaskId = res["taskId"];
          this.isNewTaskDbIdExists = true;
        }else if(res &&  res["taskDbIdExceedsMax"]==true){
          this.isMaxTaskDbIdExceeds = true;
          this.maxDbId = res["maxTaskDbId"];
        }else{
          this.errornousExistingTaskId = "";
          this.maxDbId = 999999;
          this.isNewTaskDbIdExists = false;
          this.isMaxTaskDbIdExceeds = false;
        }
        this.updateButtonStatus();
      },(error => {
        this.updateButtonStatus();
      }),() => {
        this.updateButtonStatus();
        this.tasksLoaded = true;
        this.taskDbIdStatusChecked = true;
      });
      
    }else{
      this.tasksLoaded = true;
    }
  }


}
