import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss']
})
export class ImageViewComponent implements OnInit {


  param:any;
  image:any;
  constructor(public bsModalRef :BsModalRef) { }

  ngOnInit(): void {
    console.log(this.param);
    this.image=this.param.imageUrl;
  }

}
