import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import {BreadcrumbServiceService} from '../../../../services/breadcrumb-service.service';
import {LanguageServiceService} from "../../../../services/language-service.service";
import { ProjectCopyModalComponent } from '../project-copy-modal/project-copy-modal.component';

@Component({
  selector: 'app-project-copy-url',
  templateUrl: './project-copy-url.component.html',
  styleUrls: ['./project-copy-url.component.scss']
})
export class ProjectCopyUrlComponent implements OnInit {

  @Input() rowData:any;
  projectNumber:any;
  constructor(private modalService:BsModalService, private breadcrumbService: BreadcrumbServiceService,
              private languageService: LanguageServiceService) { }

  ngOnInit(): void {

  }

  /**
   * Redirect to Operating Profiles
   */
  loadCopyProjectModal(){
    let lan  = {
      id: this.rowData['workLangId'],
      str: this.rowData['workLangId'] == 1 ? "German" : "English"
    }
    this.languageService.setWorkingLanguage(lan);
    this.projectNumber = this.rowData.projectNumber;
    // alert("Copying the Project : " + this.projectNumber);

    const initialState = {
      param : {
        rowData : this.rowData,
      }
    };
    this.modalService.show(ProjectCopyModalComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});

    // this.breadcrumbService.navigate(1, "Operating Profiles", '/operating-profiles/'+this.projectNumber);
  }

}
