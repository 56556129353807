import { Component, EventEmitter, OnInit } from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { StakeHolderService } from 'src/app/services/stake-holder.service';
import { ProjectSystemTasksService } from 'src/app/services/project-system-tasks.service';
import {LanguageSupportComponent} from "../../../../shared/components/language-support/language-support-component.service";
import {LanguageServiceService} from "../../../../services/language-service.service";
import { ProjectService } from 'src/app/services/project.service';
import { validateForm } from 'src/app/shared/helper';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-copy-project-modal',
  templateUrl: './project-copy-modal.component.html',
  styleUrls: ['./project-copy-modal.component.scss']
})


export class ProjectCopyModalComponent extends LanguageSupportComponent  implements OnInit {

  event: EventEmitter<any> = new EventEmitter();

  btnLoading:Boolean=false;
  param:any;
  projectNumber:any;
  loading:Boolean=false;

  loadingBtn:Boolean=false;
  
  apiURL:any;
  rowData:any;
  projectData:any=[];

  projectCopyForm = new UntypedFormGroup({
    copyProjectTo : new UntypedFormControl('',[Validators.required]),
  });

  constructor(
      public bsModalRef:BsModalRef,
      public languageService: LanguageServiceService,
      public modalService: BsModalService,
      public projectService: ProjectService,
      private notificationService: ToastrService,
  ) {
    super(languageService, modalService);
  }

  ngOnInit(): void {
    this.rowData = this.param.rowData;
    this.projectNumber = this.rowData.projectNumber;

    console.log(this.rowData);

    this.apiURL = environment.apiUrl;
    // this.projectCopyForm = new UntypedFormGroup({
    //   copyProjectTo: new UntypedFormControl({value: '', disabled: true})
    // });
    this.initFormValues();
   
  }

  initFormValues(){
    this.getProjectData();
    validateForm(this.projectCopyForm);
  }

   convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
    }

  submitCopyForm(){
    this.loadingBtn=true;
    this.loading=true;
    if(this.projectCopyForm.valid){
      let formData = this.projectCopyForm.getRawValue();

      formData.exportedDate = (new Date()).toLocaleString("en-US", {timeZone: "Europe/Berlin"});
     
      this.projectService.copyProject(this.projectNumber, formData.copyProjectTo ).subscribe({
        next: (success) => {
          this.notificationService.success('Project : ' + this.projectNumber + ' Copied Successfully into : ' + formData.copyProjectTo, 'Success');
          // this.bsModalRef.hide();
          this.event.emit();
        },
        error: (error) => {
          console.log(error);
          this.loadingBtn=false;
          this.bsModalRef.hide();
          this.notificationService.error('Project Copy Failed : ' + error.error, 'Error');
          this.loading=false;
        }, complete: () => {
          this.loadingBtn=false;
          this.bsModalRef.hide();
        }
        
      });

        // this.projectService.copyProject(this.projectNumber, formData.copyProjectTo ).subscribe(res=>{
          
        //   this.loading=false;
        //   this.notificationService.success('Project Copied Successfully', 'Success');
         
        //   this.bsModalRef.hide();
        //   this.event.emit();
          
        // },((error) => {
        //   console.log(error);
        //   if(error.status == 200){
        //     this.notificationService.success('Project : ' + this.projectNumber + ' Copied Successfully into : ' + formData.copyProjectTo, 'Success');
        //     this.bsModalRef.hide();
        //     this.event.emit();
        //   }else{
        //     
        //   }
          
        // }));
     
    }else{
      this.notificationService.error('Destination Project is Required', 'Error');
    }
    this.loading=false;
    
  }

  languageChanged(){
    super.languageChanged(JSON.stringify(this.projectCopyForm.getRawValue()));
  }

  getProjectData(){
    this.projectService.getEmptyProjects().subscribe(res=>{
      this.projectData = res;
    });
    setTimeout(()=>{
      super.saveInitialValues(JSON.stringify(this.projectCopyForm.getRawValue()));
    },0);
  }

  callAfterLanguageChanged() {
    this.initFormValues();
  }

  private dateFormat(date: Date) {
    const day = date && date.getDate() || -1;
    const dayWithZero = day.toString().length > 1 ? day : '0' + day;
    const month = date && date.getMonth() + 1 || -1;
    const monthWithZero = month.toString().length > 1 ? month : '0' + month;
    const year = date && date.getFullYear() || -1;

    return `${year}-${monthWithZero}-${dayWithZero}`;
  }

  private timeFormat(date: Date) {
    const hour = date && date.getHours() || -1;
    const hourWithZero = hour.toString().length > 1 ? hour : '0' + hour;
    const minute = date && date.getMinutes() || -1;
    const minuteWithZero = minute.toString().length > 1 ? minute : '0' + minute;

    return `${hourWithZero}-${minuteWithZero}`;
  }

}
