<div class="modal-header">
    <h5 class="modal-title pull-left">Edit Project</h5>
    <ngx-select style="padding-left: 25px; width: 200px; display: inline-block; float: right;" optionValueField="id" optionTextField="str" [items]="languages" [(ngModel)]="selectedLanguage" (select)="languageChanged()"></ngx-select>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<form [formGroup]="projectEditForm" (ngSubmit)="onSubmit()" class="pr-4 pl-4">
    <div class="modal-body">
        <div class="form-group row">
            <label for="inputEmail3" class="col-sm-4 col-form-label col-form-label-sm">Project Number</label>
            <div class="col-sm-4">
                <input type="text" class="form-control form-control-sm" formControlName="projectNumber" id="inputEmail3" placeholder="Number" appInfoPromptBox [page]="'project'" [component]="'edit'" [field]="'projectNumber'">
            </div>
        </div>
        <div class="form-group row">
            <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">Designation</label>
            <div class="col-sm-8">
                <input type="text" class="form-control form-control-sm" formControlName="projectDesignation" id="inputPassword3" placeholder="Designation" appMandatoryField appInfoPromptBox [page]="'project'" [component]="'edit'" [field]="'projectDesignation'">
                <div *ngIf="projectEditForm.controls['projectDesignation'].invalid && (projectEditForm.controls['projectDesignation'].dirty || projectEditForm.controls['projectDesignation'].touched)" class="ng-validator">
                    <div *ngIf="projectEditForm.controls['projectDesignation'].errors.required">
                        This field is required.
                    </div>
                    <div *ngIf="projectEditForm.controls['projectDesignation'].errors.maxlength">
                        Should be Less Than 100 Characters.
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="inputEmail3" class="col-sm-4 col-form-label col-form-label-sm">Power unit</label>
            <div class="col-sm-6">
                <ngx-select optionValueField="powerUnit" optionTextField="powerUnit" formControlName="powerUnit" [items]="powerUnit" appMandatoryField appInfoPromptBox [page]="'project'" [component]="'edit'" [field]="'powerUnit'"></ngx-select>
            </div>
        </div>
        <div class="form-group row">
            <label for="inputEmail3" class="col-sm-4 col-form-label col-form-label-sm">Electrification System</label>
            <div class="col-sm-6">
                <ngx-select optionValueField="electrificationSystemId" autoClearSearch="true" [multiple]="true" optionTextField="electrificationSystem" formControlName="electrification" [items]="electrification" appInfoPromptBox [page]="'project'" [component]="'edit'" [field]="'electrification'"></ngx-select>
            </div>
        </div>

        <div class="form-group row">
            <label for="inputEmail3" class="col-sm-4 col-form-label col-form-label-sm">No. of cars</label>
            <div class="col-sm-5">
                <input type="text" class="form-control form-control-sm" formControlName="carsCount" id="inputEmail3" placeholder="Number" [appThousandSeparator]="projectEditForm.controls['carsCount'].value" [appDecimal]="0" appMandatoryField appInfoPromptBox [page]="'project'" [component]="'edit'" [field]="'carsCount'">
                <div *ngIf="projectEditForm.controls['carsCount'].invalid && (projectEditForm.controls['carsCount'].dirty || projectEditForm.controls['carsCount'].touched)" class="ng-validator">
                    <div *ngIf="projectEditForm.controls['carsCount'].errors.required">
                        This field is required.
                    </div>
                    <div *ngIf="projectEditForm.controls['carsCount'].value != '' && projectEditForm.controls['carsCount'].errors.valueZero">
                        Should be Greater Than 0
                    </div>
                    <div *ngIf="projectEditForm.controls['carsCount'].errors.max">
                        Should be Less Than 999
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="inputEmail3" class="col-sm-4 col-form-label col-form-label-sm">Fleet Size</label>
            <div class="col-sm-5">
                <input type="text" formControlName="vehicleQuantity" class="form-control form-control-sm" id="inputEmail3" placeholder="Number" [appThousandSeparator]="projectEditForm.controls['vehicleQuantity'].value" [appDecimal]="0" appMandatoryField appInfoPromptBox [page]="'project'" [component]="'edit'" [field]="'vehicleQuantity'">
                <div *ngIf="projectEditForm.controls['vehicleQuantity'].invalid && (projectEditForm.controls['vehicleQuantity'].dirty || projectEditForm.controls['vehicleQuantity'].touched)" class="ng-validator">
                    <div *ngIf="projectEditForm.controls['vehicleQuantity'].errors.required">
                        This field is required.
                    </div>
                    <div *ngIf="projectEditForm.controls['vehicleQuantity'].value != '' && projectEditForm.controls['vehicleQuantity'].errors.valueZero">
                        Should be Greater Than 0
                    </div>
                    <div *ngIf="projectEditForm.controls['vehicleQuantity'].errors.max">
                        Should be Less Than 999
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-4 col-form-label col-form-label-sm">Specific Employee Qualification</label>
            <div class="col-sm-6">
                <ngx-select optionValueField="id" optionTextField="text" formControlName="specificEmployeeQualification" (ngModelChange)="specificEmployeeQualificationChanged()" [items]="specificEmployeeQualificationData" appInfoPromptBox [page]="'project'" [component]="'edit'" [field]="'specificEmployeeQualification'"></ngx-select>
            </div>
            <div *ngIf="showSpecificEmployeeQualificationWarning" class="alert-warning">Consider this changes the (Specific) Employee Qualification in the Maintenance Tasks</div>
        </div>
        <div class="form-group row">
            <label class="col-sm-4 col-form-label col-form-label-sm">Tool Categories</label>
            <div class="col-sm-6">
                <ngx-select [items]="toolCategoriesData" formControlName="toolCategories" optionTextField="str" optionValueField="id" appInfoPromptBox [page]="'project'" [component]="'edit'" [field]="'toolCategories'"></ngx-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-4 col-form-label col-form-label-sm">Operating Materials</label>
            <div class="col-sm-6">
                <ngx-select [items]="operatingMaterialsData" formControlName="operatingMaterials" optionTextField="str" optionValueField="id" appInfoPromptBox [page]="'project'" [component]="'edit'" [field]="'operatingMaterials'"></ngx-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-4 col-form-label col-form-label-sm">Parts</label>
            <div class="col-sm-6">
                <ngx-select [items]="partsData" formControlName="parts" optionTextField="str" optionValueField="id" appInfoPromptBox [page]="'project'" [component]="'edit'" [field]="'parts'"></ngx-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-4 col-form-label col-form-label-sm">Freeze Function</label>
            <div class="col-sm-6">
                <ngx-select [items]="freezeFunctionData" formControlName="freezeFunction" optionTextField="str" optionValueField="id" appInfoPromptBox [page]="'project'" [component]="'edit'" [field]="'freezeFunction'"></ngx-select>
                <div *ngIf="this.initialFreezeValue != projectEditForm.controls['freezeFunction'].value" class="alert-warning" style="white-space: normal;">
                    Really want to change?
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-4 col-form-label col-form-label-sm">Working Language</label>
            <div class="col-sm-6">
                <ngx-select optionValueField="id" optionTextField="str" formControlName="workingLanguage" [items]="languages" appInfoPromptBox [page]="'project'" [component]="'edit'" [field]="'workingLanguage'"></ngx-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-4 col-form-label col-form-label-sm">Export Language</label>
            <div class="col-sm-6">
                <ngx-select optionValueField="id" optionTextField="str" formControlName="exportLanguage" [items]="languages" appInfoPromptBox [page]="'project'" [component]="'edit'" [field]="'exportLanguage'"></ngx-select>
            </div>
        </div>
        <div class="form-group row">
            <label for="projectAdmins" class="col-sm-4 col-form-label col-form-label-sm">Project Admins</label>
            <div class="col-sm-6">
                <ngx-select optionValueField="userGroupId" autoClearSearch="true" [multiple]="true" optionTextField="userGroupName" formControlName="projectAdmins" [items]="userGroupData" appInfoPromptBox [page]="'project'" [component]="'edit'" [field]="'projectAdmins'"></ngx-select>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="mandatory-description d-flex flex-row align-items-baseline">
            <span class="mandatory-description-asterisk mandatory-asterisk">*</span>
            <span class="mandatory-field-text">mandatory fields</span>
            <span class="mandatory-description-conditional-asterisk conditional-mandatory-asterisk">*</span>
            <span class="mandatory-field-text">conditional mandatory fields</span>
        </div>
        <div class="alert-warning no-changes-found-warning" *ngIf="initialValues == currentFormValues()">
            <span>No changes found...</span>
        </div>
        <button appButtonLoader [isLoading]="submitLoading" [disabled]="(userGroupDataLoading || projectElectrificationDataLoading || powerUnitDataLoading || electrificationDataLoading) || projectEditForm.invalid == true || initialValues == currentFormValues()" class="btn btn-primary" type="submit">Save</button>
        <button class="btn btn-default" (click)="bsModalRef.hide()" type="button">Close</button>
    </div>
</form>

