<div class="container emp-profile">
  <form method="post">
      <div class="row">
          <div class="col-md-4">
              <div class="profile-img">
                  <img style="max-width:200px;" class="img-thumbnail" src="/assets/img/user.jpg" alt=""/>

              </div>
          </div>
          <div class="col-md-6">
              <div class="profile-head">
                          <h5>
                            {{loginUser.firstName}} {{loginUser.lastName}}
                          </h5>
                          <h6>
                              <!-- Software Engineer -->
                          </h6>

                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                          <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">About</a>
                      </li>
                      <li class="nav-item">
                          <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Manage Password</a>
                      </li>
                  </ul>
              </div>
              <div class="row">

                <div class="col-md-12">
                    <div class="tab-content profile-tab" id="myTabContent">
                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                                    <div class="row">
                                        <div class="col-md-4">
                                            <label>Name</label>
                                        </div>
                                        <div class="col-md-6">
                                            <p>{{loginUser.firstName}} {{loginUser.lastName}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label>Email</label>
                                        </div>
                                        <div class="col-md-6">
                                            <p>{{loginUser.userEmail}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label>Primary Role</label>
                                        </div>
                                        <div class="col-md-6">
                                            <p>{{loginUser.primaryRoleDesignation}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label>User Group</label>
                                        </div>
                                        <div class="col-md-6">
                                            <p>{{loginUser.usergroupDesignation}}</p>
                                        </div>
                                    </div> 
                        </div>
                        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div class="row card-body">
                                        <form (ngSubmit)="changePassword()" [formGroup]="passwordResetForm">
                                          <div class="form-group row">
                                            <div class="col-md-6">
                                              <label>New Password</label>
                                            </div>
                                            <div class="col-md-6">
                                              <input (keyup)="validateConfirmPassword()" formControlName="newPassword" type="password" class="form-control" />
                                              <div *ngIf="passwordResetForm.controls['newPassword'].invalid && (passwordResetForm.controls['newPassword'].dirty || passwordResetForm.controls['newPassword'].touched)" class="ng-validator">
                                                <div *ngIf="passwordResetForm.controls['newPassword'].errors.required">
                                                  This field is required.
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group row">
                                            <div class="col-md-6">
                                              <label>Confirm Password</label>
                                            </div>
                                            <div class="col-md-6">
                                              <input (keyup)="validateConfirmPassword()" formControlName="newPasswordConfirm" type="password" class="form-control" />
                                              <div *ngIf="passwordResetForm.controls['newPasswordConfirm'].invalid && (passwordResetForm.controls['newPasswordConfirm'].dirty || passwordResetForm.controls['newPasswordConfirm'].touched)" class="ng-validator">
                                                <div *ngIf="passwordResetForm.controls['newPasswordConfirm'].errors.required">
                                                  This field is required.
                                                </div>
                                              </div>
                                              <span class="ng-validator" *ngIf="disableSubmit">Password mismatch</span>
                                            </div>
                                          </div>
                                          <div class="form-group row">
                                            <div class="col-md-6 offset-6 text-right">
                                              <button type="submit" [ladda]="btnLadda" [disabled]="passwordResetForm.invalid || disableSubmit" class="btn btn-primary btn-block">Update Password</button>
                                            </div>

                                          </div>
                                        </form>
                                    </div>

                        </div>
                    </div>
                </div>
            </div>
          </div>



      </div>

  </form>
</div>
