<div class="container-fluid">
    <div class="row">
        <div class="pageTitle d-flex flex-row align-items-center">
            Overall Tasks &nbsp;&nbsp;
            <ngx-select (selectionChanges)="tagSelected($event)" [items]="systemTaskTagData" optionValueField="tagId" optionTextField="tagText" [placeholder]="'Select Project System Tag'" appInfoPromptBox [page]="'overallSystems'" [component]="'grid'" [field]="'tag'"></ngx-select>
            <button (click)="addTag()" class="btn btn-info text-light btn-tag">
                <img title="Add" class="icon" src="/assets/icon/add-tag.png"/>
            </button>
            <button (click)="editTag()" [disabled]="!selectedTag" class="btn btn-info text-light btn-tag">
                <img title="Edit" class="icon" src="/assets/icon/edit.png"/>
            </button>
            <button (click)="deleteTag()" [disabled]="((!selectedTag)||(btnLoadingDelete))" class="btn btn-danger text-light btn-tag">
                <img  *ngIf="!btnLoadingDelete" title="Delete" class="icon" src="/assets/icon/delete.png"/>
                <span *ngIf="btnLoadingDelete" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            </button>
            <button (click)="saveChanges()" [disabled]="((!selectedTag)||(btnLoading))" class="btn btn-success text-light btn-tag">
                <img *ngIf="!btnLoading" title="Save" class="icon" src="/assets/icon/save.png"/>
                <span *ngIf="btnLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            </button>
        </div>
    </div>
    <form [formGroup]="overallTasksFilterForm" class="filter-form">
        <div class="d-flex justify-content-between">
            <div class="filter-section row">
                <label class="filter-lbl col-sm-4 d-flex align-items-center">Project Numbers</label>
                <div class="col-sm-8">
                    <ngx-select [items]="filterValues.projectNumber" [multiple]="true" formControlName="projectNumbers" [autoClearSearch]="true" (typed)="projectNumbersFilterInputChange($event)" (select)="projectNumbersFilterSelect($event)"></ngx-select>
                </div>
            </div>
            <div class="filter-section row">
                <label class="filter-lbl col-sm-4 d-flex align-items-center">Task Manual IDs</label>
                <div class="col-sm-8">
                    <ngx-select [items]="filterValues.taskManualId" [multiple]="true" formControlName="taskManualIds" [autoClearSearch]="true" (typed)="taskManualIdsFilterInputChange($event)" (select)="taskManualIdsFilterSelect($event)"></ngx-select>
                </div>
            </div>
            <div class="filter-section row">
                <label class="filter-lbl col-sm-4 d-flex align-items-center">Task Elements</label>
                <div class="col-sm-8">
                    <ngx-select [items]="filterValues.taskElement" [multiple]="true" formControlName="taskElements" [autoClearSearch]="true" (typed)="taskElementsFilterInputChange($event)" (select)="taskElementsFilterSelect($event)"></ngx-select>
                </div>
            </div>
        </div>
        <br>
        <div class="d-flex justify-content-between">
            <div class="filter-section row">
                <label class="filter-lbl col-sm-4 d-flex align-items-center">Task Activities</label>
                <div class="col-sm-8">
                    <ngx-select [items]="filterValues.taskActivity" [multiple]="true" formControlName="taskActivities" [autoClearSearch]="true" (typed)="taskActivitiesFilterInputChange($event)" (select)="taskActivitiesFilterSelect($event)"></ngx-select>
                </div>
            </div>
            <div class="filter-section row">
                <label class="filter-lbl col-sm-4 d-flex align-items-center">Task Tags</label>
                <div class="col-sm-8">
                    <ngx-select [items]="filterValues.taskTag" [multiple]="true" formControlName="taskTags" [autoClearSearch]="true" (typed)="taskTagsFilterInputChange($event)" (select)="taskTagsFilterSelect($event)"></ngx-select>
                </div>
            </div>
            <div class="filter-section row">
                <div class="col-sm-12">
                    <button (click)="getOverallTasks()" class="btn apply-filter-btn" type="button"> Apply Filters </button>
                    <button (click)="exportTasks()" class="btn apply-filter-btn" type="button"> Export Tasks </button>
                </div>
            </div>
        </div>
    </form>
    <div class="data-table">
        <app-tree-table [spinnerVisible]="spinnerVisible" [spinnerText]="spinnerText" [settings]="settings" [data]="overallTasksData" [currentPage]="currentPage" [pageCount]="pageCount" (pageChangeEvent)="changePage($event)" (pageSizeChangeEvent)="changePageSize($event)" (filterChangeEvent)="filterOrSortChange($event)"></app-tree-table>
    </div>
</div>
