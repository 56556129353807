import { Directive, ElementRef, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BUILD_NUMBER } from '../config';
import { VersionService } from '../services/version.service';

@Directive({
  selector: '[appRouteChange]'
})
export class RouteChangeDirective implements OnInit {

 

  backendUiVersion:string ='';
  backendUiBuildNumber:number;
  buildNumber: string = BUILD_NUMBER;
  currentTimestamp:number = Date.now();
  mismatchedBuildBumber:Boolean=false;

  private versionService = inject(VersionService);
  
  constructor(private el: ElementRef, private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Perform actions on route change
        if(event.urlAfterRedirects!='/login'){

          this.getSystemApiVersion();
        }
      }
    });
  }

 

  getSystemApiVersion(){
    this.versionService.getApiVersion(this.currentTimestamp).subscribe(res=>{
      // let res = {"uiVersion":"2.0.1","uiBuildNumber":1305,"apiVersion":"2.1.0","apiBuildNumber":1330};
      
      this.backendUiVersion = res['uiVersion'];
      this.backendUiBuildNumber = res['uiBuildNumber'];
      console.log('route_change',res['uiBuildNumber'],parseInt(this.buildNumber));
      // if(res['uiBuildNumber']> parseInt(this.buildNumber)){
      if(res['uiBuildNumber']> parseInt(this.buildNumber)){
        this.mismatchedBuildBumber = true;
        let data ={
          data:res,
          isMismatched:this.mismatchedBuildBumber
        }
        this.versionService.sendVersionDetails(data);
      }
    },err=>{
      console.log(err);
      this.versionService.sendVersionDetails(err);
    });
  }

 
}