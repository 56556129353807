import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TasksRoutingModule } from './tasks-routing.module';
import { ProjectSystemTaskSparePartsComponent } from './project-system-task/project-system-task-spare-parts/project-system-task-spare-parts.component';
import { ProjectSystemTaskComponent } from './project-system-task/project-system-task.component';
import { ProjectSystemTaskToolsComponent } from './project-system-task/components/project-system-task-tools/project-system-task-tools.component';
import { ProjectSystemTaskToolsUrlComponent } from './project-system-task/components/project-system-task-tools-url/project-system-task-tools-url.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxSelectModule } from 'ngx-select-ex';
import { LaddaModule } from 'angular2-ladda';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProjectSystemTaskSparepartsUrlComponent } from './components/project-system-task-spareparts-url/project-system-task-spareparts-url.component';

import { ProjectSystemTaskAddComponent } from './components/project-system-task-add/project-system-task-add.component';
import { ProjectSystemTaskEditComponent } from './components/project-system-task-edit/project-system-task-edit.component';
import { ProjectSystemTaskIntervalUrlComponent } from './components/project-system-task-interval-url/project-system-task-interval-url.component';
import { ProjectSystemTaskIntervalComponent } from './components/project-system-task-interval/project-system-task-interval.component';
import { TaskOperatingMaterialUrlComponent } from './project-system-task/components/task-operating-material-url/task-operating-material-url.component';
import { TaskOperatingMaterialComponent } from './project-system-task/components/task-operating-material/task-operating-material.component';
import { SharedModule } from '../../shared/shared.module';
import {TreeTableModule} from "../../shared/components/tree-table/tree-table.module";
import { ActualQuantityComponent } from './project-system-task/project-system-task-spare-parts/components/actual-quantity/actual-quantity.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { CustomStatusComponent } from './project-system-task/components/custom-status/custom-status.component';


@NgModule({
  declarations: [
    ProjectSystemTaskSparePartsComponent,ProjectSystemTaskComponent,
    ProjectSystemTaskToolsComponent,ProjectSystemTaskToolsUrlComponent,
    ProjectSystemTaskSparepartsUrlComponent,
    ProjectSystemTaskAddComponent,
    ProjectSystemTaskEditComponent,
    ProjectSystemTaskIntervalUrlComponent,
    ProjectSystemTaskIntervalComponent,
    TaskOperatingMaterialUrlComponent,
    TaskOperatingMaterialComponent,
    ActualQuantityComponent,
    CustomStatusComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        TasksRoutingModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        FormsModule, NgxSelectModule,
        LaddaModule,
        ModalModule.forRoot(),
        TreeTableModule,
        MatProgressSpinnerModule
    ],
  bootstrap:[ProjectSystemTaskToolsComponent,ProjectSystemTaskToolsUrlComponent,ProjectSystemTaskSparepartsUrlComponent,
    ProjectSystemTaskAddComponent,ProjectSystemTaskEditComponent,
    ProjectSystemTaskSparepartsUrlComponent,
    ProjectSystemTaskSparePartsComponent,
    ProjectSystemTaskIntervalComponent,ProjectSystemTaskIntervalUrlComponent,
    TaskOperatingMaterialUrlComponent,TaskOperatingMaterialComponent
  ]
})

export class TasksModule { }
