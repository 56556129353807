import { UntypedFormGroup, UntypedFormControl, AbstractControl } from '@angular/forms';


/*
* form validate touch method
*/
export function validateForm(formGroup: UntypedFormGroup){
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof UntypedFormControl) {
            control.markAsDirty({ onlySelf: true });
        } else if (control instanceof UntypedFormGroup) {
            this.validateAllFormFields(control);
        }
    });
};

 let activationStatuses:any[]  = [
  {
    id:0,
    code : 'I',
    value:'I - to delete in ST4',
  },
  {
    id:1,
    code : 'A',
    value:'A - Active',
  },
  {
    id:3,
    code : 'AI',
    value:'AI - auto generated initially inactive',
  },
  {
    id:4,
    code : 'AD',
    value:'AD - successfully deleted in ST4',
  }
];

export function getTaskActivatedList(){
  return activationStatuses;
}

export function getStatusIdfromCode(code){
  let status = activationStatuses.find(x=>x.code == code);
  return status?status.id:0;
}


export function getProjectSystemActivatedList(){
  return [
    {
      id:0,
      code : 'A',
      value:'Active',
    },
    {
      id:1,
      code : 'I',
      value:'to delete in ST4',
    }
  ];
}

export function countString(str) {
  let count = 0;
  let letter ='.';
  // looping through the items
  for (let i = 0; i < str.length; i++) {

      // check if the character is at that position
      if (str.charAt(i) == letter) {
          count += 1;
      }
  }
  console.log('dd',count);
  return count;
}

export function currencyList(){
  return [


    {
        "symbol": "CHF",
        "name_plural": "Swiss francs",
        "code": "CHF",
        "symbol_native": "CHF",
        "decimal_digits": 2,
        "name": "Swiss Franc",
        "rounding": 0
    },


    {
        "symbol": "€",
        "name_plural": "euros",
        "code": "EUR",
        "symbol_native": "€",
        "decimal_digits": 2,
        "name": "Euro",
        "rounding": 0
    },

    {
        "symbol": "£",
        "name_plural": "British pounds",
        "code": "GBP",
        "symbol_native": "£",
        "decimal_digits": 2,
        "name": "British Pound",
        "rounding": 0
    },
    {
        "symbol": "$",
        "name_plural": "US dollars",
        "code": "USD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "US Dollar",
        "rounding": 0
    },

]
}

/**
 * Task Nature
 */
export function taskNature(){

    let array = [
        {
            id:'PM',
            name:'Preventive'
        },
        {
            id:'CM',
            name:'Corrective'
        },
        {
            id:'PP',
            name:'Pre- / Postprocessing'
        }

    ];
    return array;
}

/**
 * Task PP Types
 */
export function taskPPTypes(){

    let array = [
        {
            value:'PRE',
            text:'Pre Processing'
        },
        {
            value:'POST',
            text:'Post Processing'
        }
    ];
    return array;
}

/**
 * Task Is Non recurring values
 */
export function taskIsNonRecurring(){
  return [
    {
      id:0,
      name:'Nicht relevant',
    },
    {
      id:1,
      name:'einmalig',
    },
    {
      id:2,
      name:'periodisch',
    }
  ];
}

export function projectStatus(){
  return [
    {
      id:0,
      text:'Active'
    },
    {
      id:1,
      text:'Inactive'
    }
  ];
}

/**
 * Tasak maintainance level data
 */
export function taskMaintainanceLevel(){

  return [
    {
      id: 1,
      name: 'On-train'
    },
    {
      id: 2,
      name: 'Off-train'
    },
    {
      id: 3,
      name: 'Done Externally'
    }
  ]
}

export function taskInfluence(){
  return [
    {
      id: 'S',
      name: 'S'
    },
    {
      id: 'R',
      name: 'R'
    },
    {
      id: 'C',
      name: 'C'
    }
  ]
}

export function taskIntervalUnits(){

  return [
    {
    id: 'days',
    name: 'days'
    },
    {
      id: 'km',
      name: 'km'
    },
    {
      id: 'h',
      name: 'h'
    },
    {
      id: 'cycl',
      name: 'cycl'
    }
];
}


export function cleanseSpaces(str){
  return str.replace(/\s\s+/g, ' ').trim();
}
