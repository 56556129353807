import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class StandardSystemsService {

    constructor(private http: HttpClient)
    {

    }

    getAllSystemNumberandDesignation()
    {
        return this.http.get(environment.apiUrl + "/systems/getAllSystemNumberandDesignation");
    }


    getAllStandardSystems(apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/systems/" + apiType, data, {params: params});
    }

    getLowSystems(apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/systems/level/category/low/" + apiType, data, {params: params});
    }

    /**
     * Create new System Record
     * @param data
     */
    createSystems(data)
    {
        return this.http.post(environment.apiUrl + "/systems/", data);
    }

    /**
     * Create new System Record
     * @param data
     */
    updateSystem(data, id)
    {
        return this.http.put(environment.apiUrl + "/systems/" + id, data);
    }

    getSystemById(id)
    {
        return this.http.get(environment.apiUrl + "/systems/" + id);
    }

    getSBSCodes()
    {
        return this.http.get(environment.apiUrl + "/systems/sbs-codes/");
    }

    getSBSCCodesAccordingToLanguage()
    {
        return this.http.get(environment.apiUrl + "/systems/sbs-codes-according-to-language/");
    }

    getLevelSystemNumbers(level)
    {
        return this.http.get(environment.apiUrl + "/systems/level/" + level + "/")
    }

    /**
     * Update RAM Engineer Based on Parameters
     * @param data
     */
    updateRamEngineer(data)
    {
        return this.http.put(environment.apiUrl + "/systems/ramengineers", data);
    }

    
}
