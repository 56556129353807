<div class="modal-header">
  <h5 class="modal-title pull-left">Version mismatch detected</h5>

</div>
<div class="modal-body">
  <p style="font-size: smaller;">
    There is a new version available. <br /><br />
    Your current Version : {{param['uiVersion']}} Build {{param['uiBuildNumber']}} <br />
    <span style="font-weight: bold;">New Version : {{param['uiVersion']}} Build {{param['backendUiBuildNumber']}}</span>
    <br /><br />
    <small>
      Please clear cache and refresh the page by Pressing CTRL + SHIFT + R  to obtain the new version.<br/>
      If the message persists, Please refer the &quot;User Guide&quot; for instructions <br/>on &quot;How to clear cache and refresh&quot;
  </small>
  </p>

</div>