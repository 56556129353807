<div class="modal-header">
  <h5 class="modal-title pull-left">Add Operating Profiles</h5>
  <div>
    <ngx-select style="padding-left: 25px; width: 200px; display: inline-block; float: right !important;" optionValueField="id" optionTextField="str" [items]="languages" [(ngModel)]="selectedLanguage" (select)="languageChanged()"></ngx-select>
  </div>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="addOperatingProfileForm" appPreventDoubleSubmit class="pr-4 pl-4">
  <div class="modal-body">
    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-5 col-form-label col-form-label-sm">Project Number</label>
      <div class="col-sm-7">
        <input type="text" [readOnly]="true" class="form-control  form-control-sm" formControlName="projectNumber" id="inputEmail3" appInfoPromptBox [page]="'projectOperatingProfile'" [component]="'add'" [field]="'projectNumber'"/>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputEmail3" class="col-sm-5 col-form-label col-form-label-sm">System</label>
      <div class="col-sm-7">
        <ngx-select optionValueField="projectSystemId" (select)="validateProfileUnits()" formControlName="projectSystemId" [items]="projectSystemArray" optionTextField="text" [placeholder]="'System Code'" appMandatoryField appInfoPromptBox [page]="'projectOperatingProfile'" [component]="'add'" [field]="'projectSystemId'"></ngx-select>
        <div *ngIf="addOperatingProfileForm.controls['projectSystemId'].invalid && (addOperatingProfileForm.controls['projectSystemId'].dirty || addOperatingProfileForm.controls['projectSystemId'].touched)" class="ng-validator">
          <div *ngIf="addOperatingProfileForm.controls['projectSystemId'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputEmail3" class="col-sm-5 col-form-label col-form-label-sm">Profile Unit</label>
      <div class="col-sm-7">
        <ngx-select (select)="getProfileUnitById()" optionValueField="profileUnitId" [items]="taskUnitList" formControlName="profileUnitId" optionTextField="{{selectedLanguage==0?'profileUnitDesignationEn':'profileUnitDesignationDe'}}" [placeholder]="'Profile Units'" appMandatoryField appInfoPromptBox [page]="'projectOperatingProfile'" [component]="'add'" [field]="'profileUnitId'"></ngx-select>
        <div *ngIf="addOperatingProfileForm.controls['profileUnitId'].invalid && (addOperatingProfileForm.controls['profileUnitId'].dirty || addOperatingProfileForm.controls['profileUnitId'].touched)" class="ng-validator">
          <div *ngIf="addOperatingProfileForm.controls['profileUnitId'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="inputEmail3" class="col-sm-5 col-form-label col-form-label-sm">Unit Addition</label>
      <div class="col-sm-7">
        <input type="text" autocomplete="off" class="form-control  form-control-sm" formControlName="unitAddition" id="inputEmail3" placeholder="Unit Addition" appMandatoryField appInfoPromptBox [page]="'projectOperatingProfile'" [component]="'add'" [field]="'unitAddition'">
        <div *ngIf="addOperatingProfileForm.controls['unitAddition'].invalid && (addOperatingProfileForm.controls['unitAddition'].dirty || addOperatingProfileForm.controls['unitAddition'].touched)" class="ng-validator">
          <div *ngIf="addOperatingProfileForm.controls['unitAddition'].errors.required">
            This field is required.
          </div>
          <div *ngIf="addOperatingProfileForm.controls['unitAddition'].errors.maxlength">
            Should be Less Than 100 Characters.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputEmail3" class="col-sm-5 col-form-label col-form-label-sm">Value</label>
      <div class="col-sm-7">
        <input type="text" autocomplete="off" class="form-control  form-control-sm" formControlName="value" [appThousandSeparator]="addOperatingProfileForm.controls['value'].value" id="inputEmail3" placeholder="Value" [appDecimal]="2" minMax [min]="minValue" [max]="maxValue" appMandatoryField appInfoPromptBox [page]="'projectOperatingProfile'" [component]="'add'" [field]="'value'">
        <div *ngIf="addOperatingProfileForm.controls['value'].invalid && (addOperatingProfileForm.controls['value'].dirty || addOperatingProfileForm.controls['value'].touched)" class="ng-validator">
          <div *ngIf="addOperatingProfileForm.controls['value'].errors.minMax">
            Minimum value is {{this.minValue}}, Max Value is {{this.maxValue}}
          </div>
          <div *ngIf="addOperatingProfileForm.controls['value'].errors.required">
            This field is required.
          </div>
          <div *ngIf="addOperatingProfileForm.controls['value'].value != '' && addOperatingProfileForm.controls['value'].errors.valueZero">
            Should be Greater Than 0
          </div>
          <div *ngIf="addOperatingProfileForm.controls['value'].errors.maxlength">
            Should be Less Than 100 Characters.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <div *ngIf="!profileUnitValid" class="ng-validator">
        <p>This record already exist. Please add different Unit Addition</p>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <app-action-comment [btnLoading]="btnLoading" [okBtnName]="'Save'" [closeBtnName]="'Close'" [saveDisable]="addOperatingProfileForm.invalid" [mandatoryDescriptionVisible]="true" [commentVisible]="projectFreezeFunction == 'activated'" (okClick)="addOperatingProfiles($event)" (cancelClick)="bsModalRef.hide()"></app-action-comment>
  </div>
</form>
