import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {OverallSystemsComponent} from "./overall-systems/overall-systems.component";


const routes: Routes = [
  { path: 'overall-systems', component: OverallSystemsComponent, data: { animation: 'tasks' }},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OverallSystemsRoutingModule { }
