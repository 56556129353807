<div class="modal-header">
  <h5 class="modal-title pull-left">Add Suppliers</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="addSupplierForm" (ngSubmit)="createSupplier()" class="pr-4 pl-4">
  <div class="modal-body">
    <div class="form-group row">
      <label class="col-sm-5 col-form-label col-form-label-sm">Supplier Name</label>
      <div class="col-sm-7">
        <input type="text" formControlName="firstName" class="form-control form-control-sm" placeholder="Supplier Name" appInfoPromptBox [page]="'masterSuppliers'" [component]="'add'" [field]="'firstName'">
        <div *ngIf="addSupplierForm.controls['firstName'].invalid && (addSupplierForm.controls['firstName'].dirty || addSupplierForm.controls['firstName'].touched)" class="ng-validator">
          <div *ngIf="addSupplierForm.controls['firstName'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label col-form-label-sm">Infor Supplier Id</label>
      <div class="col-sm-7">
        <input type="text" formControlName="erpId" class="form-control form-control-sm" placeholder="Infor Supplier Id" appInfoPromptBox [page]="'masterSuppliers'" [component]="'add'" [field]="'erpId'">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button appButtonLoader [isLoading]="btnLoading" appPreventDoubleSubmit (debounceClick)="createSupplier()" [debounceTime]="500" [disabled]="addSupplierForm.invalid" type="submit" [ladda]="btnLoading" class="btn btn-primary">Save</button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()" >Close</button>
  </div>
</form>

