import { Directive, ElementRef, Renderer2, Input } from '@angular/core';

@Directive({
  selector: '[appButtonLoader]'
})
export class ButtonLoaderDirective {

  public btnName: String = '';
  public _isLoading: Boolean = false;
  public _replaceCaption: Boolean = false;
  public _isDisabled: Boolean = false;

  private originalCaption: String = "";

  @Input() set isLoading(value: Boolean) {
    this._isLoading = value;
    this.updateButtonState();
  }

  @Input() set isDisabled(value: Boolean) {
    this._isDisabled = value;
    this.updateButtonState();
  }

  @Input() set loaderBtnName(value: String) {
    this.btnName = value;
    this.updateButtonState();
  }

  @Input() set replaceCaption(value: Boolean) {
    this._replaceCaption = value;
    this.updateButtonState();
  }



  get isLoading(): Boolean {
    return this._isLoading;
  }

  get isDisabled(): Boolean {
    return this._isDisabled;
  }

  get loaderBtnName(): String {
    return this.btnName;
  }

  get replaceCaption(): Boolean {
    return this._replaceCaption;
  }

  constructor(private element: ElementRef, private renderer: Renderer2) {


  }


  // @Input() set appButtonLoader(isLoading: boolean) {

  private updateButtonState() {
    console.log('updateButtonState', this._isLoading, this._isDisabled, this.btnName, this._replaceCaption);
    this.renderer.setAttribute(this.element.nativeElement, 'disabled', 'true');
    this.renderer.removeClass(this.element.nativeElement, 'btn-loading');
    if (this.btnName != null && this.btnName != '') {
      this.originalCaption = this.btnName;
    } else {
      this.originalCaption = this.element.nativeElement.innerHTML;
    }

    if (this._isLoading) {
      this.renderer.addClass(this.element.nativeElement, 'btn-loading');
      if (this._replaceCaption) {
        this.element.nativeElement.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';
      } {
        this.element.nativeElement.innerHTML = this.originalCaption + ' ' + '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';
      }
    } else {
      if(!this._isDisabled){
        this.renderer.removeAttribute(this.element.nativeElement, 'disabled');
      }
      
      this.renderer.removeClass(this.element.nativeElement, 'btn-loading');

      if (this._replaceCaption) {
        this.element.nativeElement.innerHTML = this.originalCaption;
      }else{
        this.element.nativeElement.innerHTML = this.element.nativeElement.innerHTML.replaceAll('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>', '').trim();
      }
      

    }
  }



}
