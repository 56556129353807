import { Component, OnInit } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {OverallSystemsAndTasksService} from "../../../services/overall-systems-and-tasks.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-add-edit-tag',
  templateUrl: './add-edit-tag.component.html',
  styleUrls: ['./add-edit-tag.component.scss']
})
export class AddEditTagComponent implements OnInit {

  public action:string = null; //Add or Edit
  public tagId:number = -1;
  public tagText:string = "";
  public tagType:string = "";
  public initialTagText = "";

  btnLoading:Boolean = false;

  constructor(public modalRef:BsModalRef,
              public overallSystemsAndTasksService:OverallSystemsAndTasksService,
              public notification: ToastrService) { }

  ngOnInit(): void {
    this.initialTagText = this.tagText;
  }

  public okClicked(event){
    this.btnLoading = true;
    if(!this.tagText || this.tagText == '' || !this.action) return;

    let tag = {
      tagId:this.tagId,
      tagText:this.tagText,
      tagType:this.tagType,
    }
    if(this.action == "Add"){
      this.overallSystemsAndTasksService.addTag(tag).subscribe((res:any[])=>{
        this.btnLoading = false;
        this.notification.success('Tag has been added','success');
        this.modalRef.hide();
      },(error => {
        this.btnLoading = false;
        if(error.status == 409) this.notification.error('Tag name already exists. Cannot be added','Error');
        else this.notification.error('Tag has been not added','Error');
      }));
    }
    else if(this.action == "Edit"){
      this.overallSystemsAndTasksService.updateTag(this.tagId, tag).subscribe((res:any[])=>{
        this.btnLoading = false;
        this.notification.success('Tag has been edited','success');
        this.modalRef.hide();
      },(error => {
        this.btnLoading = false;
        if(error.status == 409) this.notification.error('Tag name already exists. Cannot be added','Error');
        else this.notification.error('Tag has been not edited','Error');
      }));
    }
    else{
      this.btnLoading = false;
      this.modalRef.hide();
    }
  }

}
