<div class="container-fluid">

  <div class="row">
    <div class="pageTitle col-sm-6">
      <span class="breadcrumbText" (click)="breadcrumbService.gotoPage('Projects')">Projects</span>&nbsp;
      <span class="pageSubTitle"><fa-icon [icon]="faAngleRight"></fa-icon></span>&nbsp;
      <span class="breadcrumbText" (click)="breadcrumbService.gotoPage('Systems')">Systems</span>&nbsp;
      <span class="pageSubTitle"><fa-icon [icon]="faAngleRight"></fa-icon></span>&nbsp;
      <span>Parts</span>&nbsp;
      <button (click)="exportProjectSystemParts()" id="btnExportProjectSystemParts" type="button" class="btn btn-primary btn-sm" appInfoPromptBox [page]="'projectSystemParts'" [component]="'grid'" [field]="'exportProjectSystemPartsButton'"><fa-icon [icon]="faFileExport"></fa-icon> &nbsp;&nbsp;Export Project System Parts</button>
    </div>
    <div class=" pageTitle col-sm-6 text-right">
      <button type="button" (click)="breadcrumbService.goBack()"  class="btn btn-primary btn-sm"><fa-icon [icon]="faBackward"></fa-icon> &nbsp;&nbsp;Back</button>
    </div>
  </div>

  <div *ngIf="infoStatus" class="row">
    <div class="" style="padding-left: 15px; padding-right: 15px; ">
      <table class="table table-bordered table-striped">
        <tr>
          <th style="padding-top: 3px !important;vertical-align: middle;padding-bottom: 3px !important;"> Project </th>
          <td style="padding-top: 3px !important;vertical-align: middle;padding-bottom: 3px !important;"> {{projectNumber}} {{projectDesignation}} </td>
        </tr>
        
        <tr>
          <th style="padding-top: 3px !important;vertical-align: middle;padding-bottom: 3px !important;"> System </th>
          <td style="padding-top: 3px !important;vertical-align: middle;padding-bottom: 3px !important;"> {{systemCode}} {{systemName}} </td>
        </tr>
      </table>
    </div>
  </div>

  <div class=" data-table">
    <app-tree-table [spinnerVisible]="spinnerInfo?.spinnerVisible" [spinnerText]="spinnerInfo?.spinnerText" [settings]="settings" [data]="tableData"></app-tree-table>
  </div>

</div> <!-- wrapper -->
