import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProjectSystemTaskSpareService {

    constructor(private http: HttpClient) { }

    /**
     * Add Project system task spare parts
     * @param data
     */
    addSystemTaskSpareParts(data, projectNumber, systemNumber, taskNumber)
    {
        return this.http.post(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/tasks/" + taskNumber + "/parts/", data);
    }

    /**
     * Get All Project System task spare data
     * @param projectNumber
     * @param taskNumber
     * @param systemNumber
     */
    getSystemTaskSpareParts(projectNumber, systemNumber, taskNumber)
    {
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/tasks/" + taskNumber + "/parts/");
    }

    /**
     * Update ProjectSystem Task Parts
     * @param data
     * @param projectNumber
     * @param taskNumber
     * @param systemNumber
     */
    editSystemTaskSpareParts(data, projectNumber, systemNumber, taskNumber)
    {
        return this.http.put(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/tasks/" + taskNumber + "/parts/", data);
    }

    /**
     * Delete task spare part
     * @param projectNumber
     * @param projectSystemId
     * @param taskId
     * @param SpateId
     */
    deleteSystemTaskSpareParts(data, projectNumber, systemNumber, taskNumber)
    {
        return this.http.delete(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/tasks/" + taskNumber + "/parts/", data);
    }

    manageSystemTaskSpareParts(reason, data, projectNumber, systemNumber, taskNumber)
    {
        const httpOptions = {
            params: {
                comment: reason['comment'],
                justificationCategory: reason['justificationCategory'] != null ? reason['justificationCategory'] : -1
            }
        };
        return this.http.post(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/tasks/" + taskNumber + "/parts/manage", data, httpOptions);
    }
}
