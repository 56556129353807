<div class="modal-header">
  <h5 class="modal-title pull-left">Image View</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>



  <div style="height:500px;overflow-y: scroll;" class="modal-body text-center">
    <img class="img" [src]="image"/>

    </div>




  <div class="modal-footer">

    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()" >Close</button>
  </div>

