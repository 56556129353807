import { KeyValue } from '@angular/common';
import {Component, OnInit, EventEmitter, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { StandardSystemsService } from 'src/app/services/standard-systems.service';
import {LanguageServiceService} from "../../../../../services/language-service.service";
import {LanguageSupportComponent} from "../../../../../shared/components/language-support/language-support-component.service";
import {TreeTableComponent} from "../../../../../shared/components/tree-table/pages/tree-table/tree-table.component";

@Component({
  selector: 'app-add-project-systems',
  templateUrl: './add-project-systems.component.html',
  styleUrls: ['./add-project-systems.component.scss']
})
export class AddProjectSystemsComponent extends LanguageSupportComponent implements OnInit {

  param:any;
  event: EventEmitter<any> = new EventEmitter();
  selectedProjectSystem:any=[];
  standardSystemData:any=[];

  filterData = [];
  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 15;
  sortColumn:string = "";
  sortOrder:string = "";
  projectFreezeFunction = "";
  btnLoading:Boolean=false;

  @ViewChild(TreeTableComponent) tree:TreeTableComponent;

  settings = {
    type:'table',
    id:'systemNumber',
    columnManagerEnable:true,
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    filterOptionFunction:(params, filterData)=>{return this.standardSystemsService.getLowSystems("distinct-values", filterData, params)},
    columns:[
      {
        title:'ID',
        attribute:'systemNumber',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:70,
      },
      {
        title:'Code',
        attribute:'systemCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:100
      },
      {
        title:'Designation',
        attribute: this.selectedLanguage == 0 ? 'systemDesignationEn' : 'systemDesignationDe',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:250,
      },
      {
        title:'RAM Engineer',
        attribute:'ramEngineersStr',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:100,
      },
      {
        type:'checkbox',
        actionFunction: (event, row)=>{this.onselectItem(event, row)},
        width:100,
      }
    ]
  };

  constructor(public bsModalRef:BsModalRef,
              private standardSystemsService: StandardSystemsService,
              public languageService: LanguageServiceService,
              public modalService: BsModalService) {
    super(languageService, modalService);
  }

  ngOnInit(): void {
    this.getStandardSystemList();
    super.saveInitialValues(JSON.stringify(this.selectedProjectSystem));
  }

  languageChanged(){
    super.languageChanged(JSON.stringify(this.selectedProjectSystem));
  }

  /**
   * Get Standard System List
   */
  getStandardSystemList() {
    let params = {};
    if (this.currentPage) params['page'] = this.currentPage - 1;
    if (this.pageSize) params['pageSize'] = this.pageSize;
    if (this.sortColumn) params['column'] = this.sortColumn;
    if (this.sortOrder) params['sortOrder'] = this.sortOrder;
    this.standardSystemsService.getLowSystems('filtered-data', this.filterData, params).subscribe(res => {
      if (res && res['page']) {
        this.pageCount = res['page']['totalPages'];
        let result = res['page']['content'];
        if (result[0].systemNumber == 2) {
          console.log("Has AA00 system");
          result.splice(0, 1); //Remove the Element with systemNumber 2
        }
        this.standardSystemData = result;
      }
      if (res && res['tablePropertyMap']) {
        let columns = this.settings.columns;
        let needFillColumnAndTable = false;
        for(let column of columns){
          if(column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
        }
        if(!needFillColumnAndTable) return;

        let tablePropertyMap: Map<string, {}> = res['tablePropertyMap'];
        for (let i = 0; i < columns.length; i++) {
          let data = tablePropertyMap[columns[i].attribute];
          if (!data) continue;
          columns[i].filter['column'] = data['column'];
          columns[i].filter['table'] = data['table'];
        }
      }
      this.settings['isNative'] = false;
    }, (error => {
      console.log(error);
    }));
  }

  onselectItem(event, row){
    if (event.target.checked ) {
      row.selected = true;
      this.selectedProjectSystem.push(row);
    }else{
      let unselectedObject = row;
      let unselectedProjectSystemIndex = this.selectedProjectSystem.findIndex(x=> x.systemNumber== unselectedObject.systemNumber);
      this.selectedProjectSystem.splice(unselectedProjectSystemIndex, 1);
    }
    this.selectedProjectSystem.sort();
  }

  filterOrSortChange(event){
    if(!event){
      this.currentPage = 1;
      this.standardSystemData = [];
      return;
    }
    if(!event['sortChanged']) this.currentPage = 1;
    this.sortColumn = event['sortColumn'];
    this.sortOrder = event['sortOrder'];
    this.filterData = event['filterData'];

    this.getStandardSystemList();
  }

  changePage(currentPage){
    this.currentPage = currentPage;
    this.getStandardSystemList();
  }

  changePageSize(pageSize){
    this.pageSize = pageSize;
    this.getStandardSystemList();
  }

  /**
   * Added the Standard system to Project system list
   */
  addToList(event) {
    this.btnLoading = true;
    let result = {
      res:event,
      projectSystems: this.selectedProjectSystem
    }
    this.triggerEvent(result);
    this.btnLoading = false;
    this.bsModalRef.hide();
  }

  triggerEvent(item: any) {
    this.event.emit({ data: item , res:200 });
  }

  callAfterLanguageChanged() {
    this.settings.columns[2].attribute = this.selectedLanguage == 0 ? 'systemDesignationEn' : 'systemDesignationDe';
    this.settings.columns[2].filter['column'] = null;
    this.settings.columns[2].filter['options'] = [];

    this.getStandardSystemList();
  }
}
