import {Component, OnInit, ViewChild} from '@angular/core';
import { faPlus, faSync, faSearch,faEdit,faBackward } from '@fortawesome/free-solid-svg-icons';
import { ProjectService } from 'src/app/services/project.service';
import {ActivatedRoute, Router} from '@angular/router';
import { SystemUrlComponent } from '../components/system-url/system-url.component';
import { RouterService } from 'src/app/services/router.service';
import { ProjectToolsUrlComponent } from '../project-tools-url/project-tools-url.component';
import { ProjectExportUrlComponent } from '../components/project-export-url/project-export-url.component';
import { OperatingProfilesUrlComponent } from '../components/operating-profiles-url/operating-profiles-url.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ProjecteditComponent} from '../projectedit/projectedit.component';
import {ProjectsaddComponent} from '../projectsadd/projectsadd.component';
import {ProjectLogUrlComponent} from '../components/project-log-url/project-log-url.component';
import {BreadcrumbServiceService} from '../../../services/breadcrumb-service.service';
import {LanguageServiceService} from "../../../services/language-service.service";
import { ProjectCopyUrlComponent } from '../components/project-copy-url/project-copy-url.component';
import {ToastrService} from "ngx-toastr";
import {TreeTableComponent} from "../../../shared/components/tree-table/pages/tree-table/tree-table.component";


@Component({
  selector: 'app-projectslist',
  templateUrl: './projectslist.component.html',
  styleUrls: ['./projectslist.component.scss']
})
export class ProjectslistComponent implements OnInit {

  faPlus = faPlus;
  faSync = faSync;
  faEdit = faEdit;
  faBackward = faBackward;
  faSearch = faSearch;
  laddaLoading = false;
  loading = true;
  tableData : any;
  showReset = false;
  searchValue = "";

  filterData = [];
  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";

  propertyMapLoaded = false;
  @ViewChild(TreeTableComponent) treeComponent: TreeTableComponent;

  settings = {
    type:'table',
    id:'projectNumber',
    columnManagerEnable:true,
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    freezeColumns:{
      enabled:true,
      left:2,
      right:7
    },
    enableZoom:true,
    filterOptionFunction:(params, filterData)=>{return this.projectService.getProjectList("distinct-values", filterData, params)},
    columns:[
      {
        title:'★',
        attribute:'isFavourite',
        type:'checkbox',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        actionFunction: (event, row)=>{this.toggleFavourite(event, row)},
        checkIsCheckedFunction: (row)=>{
          return row.isFavourite == "favourite";
        },
        width:70,
      },
      {
        title:'Number',
        attribute:'projectNumber',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:100,
      },
      {
        title:'Designation',
        attribute:'projectDesignation',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:170
      },
      {
        title:'Power Unit',
        attribute:'powerUnit',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150,
      },
      {
        title:'No Of Cars',
        attribute:'carsCount',
        type:'text',
        format:"'###",
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:100,
      },
      {
        title:'Fleet Size',
        attribute:'vehicleQuantity',
        type:'text',
        format:"'###",
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:150,
      },
      {
        renderComponent: ProjectCopyUrlComponent,
        type:'custom',
        width:30
      },
      {
        renderComponent: OperatingProfilesUrlComponent,
        type:'custom',
        width:30
      },
      {
        renderComponent: ProjectToolsUrlComponent,
        type:'custom',
        width:30
      },
      {
        renderComponent: SystemUrlComponent,
        type:'custom',
        width:30
      },
      {
        renderComponent: ProjectExportUrlComponent,
        type:'custom',
        width:30
      },
      {
        renderComponent: ProjectLogUrlComponent,
        type:'custom',
        width:30
      },
      {
        renderComponent:'<a class="btn btn-info btn-sm text-light btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/edit.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.editProject(row)},
        width:50
      }
    ]
  };

  constructor(
      private projectService : ProjectService,
      private router :Router,
      private routerService : RouterService,
      private modalService: BsModalService,
      private activatedRoute : ActivatedRoute,
      public breadcrumbService: BreadcrumbServiceService,
      private languageService:LanguageServiceService,
      private notification: ToastrService,
  ) {

    activatedRoute.queryParams.subscribe(params => {
      if(params['page']) this.currentPage = +params['page'];
      if(params['column']) this.sortColumn = params['column'];
      if(params['sortOrder']) this.sortOrder = params['sortOrder'];
    });

    this.refreshTableFirstTimeWithFavouriteFilters();
  }

  ngOnInit(): void {
    if(this.breadcrumbService.isStackEmpty()){
      this.breadcrumbService.pushPage(0, "Projects", '/projects');
    }
    this.setFiltersAfterPropertyMapLoaded();
  }

  setFiltersAfterPropertyMapLoaded(){
    if(this.propertyMapLoaded) {
      // this.setFavouriteProjectsFilter();
      return;
    }
    setTimeout(()=>{
      this.setFiltersAfterPropertyMapLoaded();
    },0)
  }

  setFavouriteProjectsFilter(){
    if(!this.treeComponent || !this.treeComponent.treeTable) return;

    let treeTable = this.treeComponent.treeTable;

    treeTable.settings.isNative = false;
    treeTable.filter = {
      enabled:true,
      isNumericField:false,
    };
    treeTable.filterBySearchString('ram_projects', 'is_favourite', "favourite", 'isFavourite');
  }

  //First Time Loading - FavouriteFiltered
  refreshTableFirstTimeWithFavouriteFilters()
  {
    this.loading = true;
    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;
    this.filterData = [{"column":"is_favourite","operation":"IN","values":["favourite"]}];
    this.projectService.getProjectList("filtered-data", this.filterData, params).subscribe(res=>{
      if(res && res['page']){
        this.pageCount = res['page']['totalPages'];
        let result = res['page']['content'];

        Object.entries(result).forEach(([key, value]) => {
          value['searchText'] = value['projectNumber'] + " - " + value['projectDesignation'];
        });
        this.loading = false;
        this.tableData = result;
      }
      if(res && res['tablePropertyMap']){
        let columns = this.settings.columns;
        let needFillColumnAndTable = false;
        for(let column of columns){
          if(column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
        }
        if(!needFillColumnAndTable) return;

        let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];
        for(let i=0; i<columns.length; i++){
          let data = tablePropertyMap[columns[i].attribute];
          if(!data) continue;
          columns[i].filter['column'] = res['isNative'] ? data['table'] + "." + data['column'] : data['column'];
          columns[i].filter['table'] = data['table'];
        }
      }
      this.propertyMapLoaded = true;
      this.settings['isNative'] = false;
    }, (error => {
      console.log(error);
      this.propertyMapLoaded = true;
    }));
  }

  /**
   * Load all project list
   */
  refreshTable()
  {
    this.loading = true;
    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;
    this.projectService.getProjectList("filtered-data", this.filterData, params).subscribe(res=>{
      if(res && res['page']){
        this.pageCount = res['page']['totalPages'];
        let result = res['page']['content'];

        Object.entries(result).forEach(([key, value]) => {
          value['searchText'] = value['projectNumber'] + " - " + value['projectDesignation'];
        });
        this.loading = false;
        this.tableData = result;
      }
      if(res && res['tablePropertyMap']){
        let columns = this.settings.columns;
        let needFillColumnAndTable = false;
        for(let column of columns){
          if(column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
        }
        if(!needFillColumnAndTable) return;

        let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];
        for(let i=0; i<columns.length; i++){
          let data = tablePropertyMap[columns[i].attribute];
          if(!data) continue;
          columns[i].filter['column'] = res['isNative'] ? data['table'] + "." + data['column'] : data['column'];
          columns[i].filter['table'] = data['table'];
        }
      }
      this.propertyMapLoaded = true;
      this.settings['isNative'] = false;
    }, (error => {
      console.log(error);
      this.propertyMapLoaded = true;
    }));
  }

  toggleFavourite(event, project){
    if(!event || !event.target) return;

    let checked = event.target.checked;
    if(checked){
      this.projectService.addToFavouriteProjects(project).subscribe((res)=>{
        this.notification.success('Project has been Added to Favourite List', 'Success');
      }, (error)=>{
        this.notification.error('Project not Added to Favourite List', 'Error');
      });
    }
    else{
      this.projectService.removeFromFavouriteProjects(project).subscribe((res)=>{
        this.notification.success('Project has been Removed from Favourite List', 'Success');
      }, (error)=>{
        this.notification.error('Project not Removed from Favourite List', 'Error');
      });
    }
  }

  filterOrSortChange(event){
    if(!event){
      this.currentPage = 1;
      this.tableData = [];
      return;
    }
    if(!event['sortChanged']) this.currentPage = 1;
    this.sortColumn = event['sortColumn'];
    this.sortOrder = event['sortOrder'];
    this.filterData = event['filterData'];

    this.refreshTable();
  }

  editProject(rowData){
    let lan  = {
      id: rowData['workLangId'],
      str: rowData['workLangId'] == 1 ? "German" : "English"
    }
    this.languageService.setWorkingLanguage(lan);

    const initialState = {
      param : rowData
    };
    let modalRef = this.modalService.show(ProjecteditComponent, {class: 'modal-lg',initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res =>{
        this.refreshTable();
    });
  }

  addProject(){
    let modalRef = this.modalService.show(ProjectsaddComponent, {class: 'modal-lg',keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res =>{
      this.refreshTable();
    });
  }

  changePage(currentPage){
    this.currentPage = currentPage;
    // this.breadcrumbService.replacePage("Projects", 'projects?page='+ this.currentPage +'&pageSize=' + this.pageSize);
    this.refreshTable();
  }

  changePageSize(pageSize){
    this.pageSize = pageSize;
    this.refreshTable();
  }
}
