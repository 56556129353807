<div class="modal-header">
    <h5 class="modal-title pull-left">{{title}}</h5>
    <button (click)="bsModalRef.hide()" aria-label="Close" class="close pull-right" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<form (ngSubmit)="submitForm()" [formGroup]="freezeFunctionCommentsForm" class="pr-4 pl-4">
    <div class="modal-body">
        <div class="data-table" *ngIf="formValuesArray.length>0">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th scope="col">Attribute</th>
                    <th scope="col">Old Value</th>
                    <th scope="col">New Value</th>
                    <th scope="col">Comment</th>
                    <th scope="col">Justification Category</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let formValue of formValuesArray; let i=index">
                    <td>{{formValue['text']}}</td>
                    <td>{{formValue['oldValue']}}</td>
                    <td>{{formValue['newValue']}}</td>
                    <td style="min-width: 225px">
                        <input class="form-control" [formControl]="getCommentsFormControl(i)" placeholder="Reason" type="text">
                        <div *ngIf="getCommentsFormControl(i).invalid && (getCommentsFormControl(i).dirty || getCommentsFormControl(i).touched)" class="ng-validator">
                            <div *ngIf="getCommentsFormControl(i).errors.required">
                                This field is required.
                            </div>
                            <div *ngIf="getCommentsFormControl(i).errors.minlength">
                                minimum quantity characters = 10
                            </div>
                        </div>
                    </td>
                    <td style="min-width: 225px">
                        <ngx-select [items]="justificationCategoryData" [formControl]="getJustificationCategoryControl(i)" optionTextField="categoryText" optionValueField="id" placeholder="Select"></ngx-select>
                        <div *ngIf="getJustificationCategoryControl(i).invalid && (getJustificationCategoryControl(i).dirty || getJustificationCategoryControl(i).touched)" class="ng-validator">
                            <div *ngIf="getJustificationCategoryControl(i).errors.required">
                                This field is required.
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="empty-table-msg" *ngIf="formValuesArray.length==0">
            No Changes found.
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="formValuesArray.length>0" [disabled]="freezeFunctionCommentsForm.invalid" class="btn btn-primary btn-sm" type="submit">Submit</button>
        <button (click)="closeForm()" class="btn btn-warning btn-sm" type="button">Close</button>
    </div>
</form>
