import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { taskIntervalUnits } from 'src/app/shared/helper';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { TaskIntervalService } from 'src/app/services/task-interval.service';
import { ToastrService } from 'ngx-toastr';
import { faPlus,faEdit} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-project-system-task-interval',
  templateUrl: './project-system-task-interval.component.html',
  styleUrls: ['./project-system-task-interval.component.scss']
})
export class ProjectSystemTaskIntervalComponent implements OnInit {

  faEdit = faEdit;
  taskIntervalUnits:any=[];
  taskIntervalForm:any;
  taskIntervalData:any=[];
  taskDbId:Number;
  btnValue:String="Add";
  laddLoading:Boolean=false;
  param:any;
  constructor(
      public bsModalRef: BsModalRef,
      private taskIntervalService: TaskIntervalService,
      private notificationService: ToastrService

  ) { }

  ngOnInit(): void {
    this.taskDbId = this.param.data.taskDbId;
    this.taskIntervalUnits = taskIntervalUnits();
    this.taskIntervalForm = new UntypedFormGroup({
      projectNumber: new UntypedFormControl(this.param.data.projectNumber),
      taskName: new UntypedFormControl(this.param.data.taskName),
      intervalUnit: new UntypedFormControl(),
      intervalId: new UntypedFormControl(),
      taskDbId: new UntypedFormControl(this.param.data.taskDbId),
      intervalValue: new UntypedFormControl(),
      action: new UntypedFormControl('ADD')
    });

    this.getTaskIntervalDataByTaskId(this.taskDbId);
  }

  /**
   * Submit Task interval form
   */
  submitTaskInterval(){
    this.laddLoading=true;
    let submitData = this.taskIntervalForm.value;

    if(submitData['action']=='ADD'){
      // create Task Interval
      let formData = {
        taskDbId: submitData['taskDbId'],
        intervalUnit: submitData['intervalUnit'],
        intervalValue: submitData['intervalValue'],
      };

      this.taskIntervalService.createTaskIntervaldata(formData).subscribe(res=>{
        if(res){
          this.laddLoading = false;
          this.notificationService.success('Task Interval has been added', 'Success');


          this.laddLoading = false;
          this.getTaskIntervalDataByTaskId(this.taskDbId);
        }else{
          this.notificationService.error('Task interval has not been added', 'Error');
          this.laddLoading = false;
          this.getTaskIntervalDataByTaskId(this.taskDbId);
        }

      });

    }else if(submitData['action']=='EDIT'){
      // update task Interval
      let submitData = this.taskIntervalForm.value;
      let intervalId = submitData['intervalId'];
      let formData = {
        taskDbId: submitData['taskDbId'],
        intervalUnit: submitData['intervalUnit'],
        intervalValue: parseInt(submitData['intervalValue']),
      };

      this.taskIntervalService.updateTaskIntervalData(formData,intervalId).subscribe(res=>{
        if(res){
          this.laddLoading = false;
          this.notificationService.success('Task Interval has been updated', 'Success');


          this.laddLoading = false;
          this.getTaskIntervalDataByTaskId(this.taskDbId);
        }else{
          this.notificationService.error('Task interval has not been updated', 'Error');
          this.laddLoading = false;
          this.getTaskIntervalDataByTaskId(this.taskDbId);
        }
      });
    }
  }

  /**
   * Get task intervals by taskdbID
   * @param taskDbId
   */
  getTaskIntervalDataByTaskId(taskDbId){
    this.taskIntervalService.getTaskIntervalByTaskDbId(taskDbId).subscribe(res=>{
      this.taskIntervalData = res;
    });
  }

  editTaskInterval(key){
    this.btnValue="Edit";
    let selectedRow = this.taskIntervalData[key];
    this.taskIntervalForm.patchValue({
      intervalUnit: selectedRow['intervalUnit'],
      intervalValue: selectedRow['intervalValue'],
      intervalId: selectedRow['intervalId'],
      action: 'EDIT',
    })
  }

}
