import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TaskSourceStandardService {

    constructor(private http: HttpClient) { }

    /**
     * Get All Task Source Standard
     */
    getAllTaskSourceStandard()
    {
        return this.http.get(environment.apiUrl + "/taskstandard/");
    }
}
