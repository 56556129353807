import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import {BreadcrumbServiceService} from '../../../../services/breadcrumb-service.service';
import {LanguageServiceService} from "../../../../services/language-service.service";

@Component({
  selector: 'app-operating-profiles-url',
  templateUrl: './operating-profiles-url.component.html',
  styleUrls: ['./operating-profiles-url.component.scss']
})
export class OperatingProfilesUrlComponent implements OnInit {

  @Input() rowData:any;
  projectNumber:any;
  constructor(private breadcrumbService: BreadcrumbServiceService,
              private languageService: LanguageServiceService) { }

  ngOnInit(): void {

  }

  /**
   * Redirect to Operating Profiles
   */
  redirectToOperatingProfiles(){
    let lan  = {
      id: this.rowData['workLangId'],
      str: this.rowData['workLangId'] == 1 ? "German" : "English"
    }
    this.languageService.setWorkingLanguage(lan);
    this.projectNumber = this.rowData.projectNumber;
    this.breadcrumbService.navigate(1, "Operating Profiles", '/operating-profiles/'+this.projectNumber);
  }

}
