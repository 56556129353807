<div class="container-fluid">

  <div class="row">
    <div class="pageTitle col-sm-3">
      Systems <button routerLink="/systems/add" id="btnAddProject" type="button" class="btn btn-primary btn-sm"><fa-icon [icon]="faPlus"></fa-icon> &nbsp;&nbsp;Add</button>
      &nbsp;&nbsp;<button type="button" class="btn btn-primary btn-sm" (click)="showLogs()" appInfoPromptBox [page]="'standardSystems'" [component]="'grid'" [field]="'logsButton'"><fa-icon [icon]="faFile"></fa-icon>&nbsp;&nbsp;Logs</button>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button *ngIf="ramEngineerUpdateAuthorised" type="button" class="btn btn-primary btn-sm" (click)="showRamEngineerPopup()" appInfoPromptBox [page]="'standardSystems'" [component]="'grid'" [field]="'editRamEngineersButton'"><fa-icon [icon]="faFile"></fa-icon>&nbsp;&nbsp;Update RAM Engineers</button>
    </div>
  </div>

  <div class="data-table">
    <app-tree-table  [spinnerVisible]="loading" spinnerText="Loading Standard Systems..." [settings]="settings" [data]="tableData" [currentPage]="currentPage" [pageCount]="pageCount" (pageChangeEvent)="changePage($event)" (pageSizeChangeEvent)="changePageSize($event)"  (filterChangeEvent)="filterOrSortChange($event)"></app-tree-table>
  </div>



</div> <!-- wrapper -->
