<div class="modal-header">
  <h5 (click)="logValues()" class="modal-title pull-left">Edit Task</h5>
  <div>
    <ngx-select style="padding-left: 25px; width: 200px; display: inline-block; float: right !important;" optionValueField="id" optionTextField="str" [items]="languages" [(ngModel)]="selectedLanguage" (select)="languageChanged()"></ngx-select>
  </div>
  <div class="pull-right" style="margin-top: 5px; margin-left : 15px;">
    <span *ngIf="isAutoGenerated"> [ Auto Generated Task ]</span>
  </div>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form class="pr-4 pl-4" [formGroup]="projectSystemTaskEditForm" #form>
  <div class="modal-body scroll">
    &nbsp;
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Status</label>
      <div class="col-sm-7">
        <ngx-select [disabled]="isSystemActive!=0" optionValueField="id" optionTextField="value" [items]="isTaskActivatedList" formControlName="isTaskActivated" (select)="taskActiveStateChanged($event)" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'isTaskActivated'"></ngx-select>
        <div *ngIf="(taskStatusChangedFromActivetoInactive && isThisTaskRelatedtoOtherTasks)" class="alert-warning task-alerts" style="white-space: normal;">
          There are related tasks. These links will be removed when this task is marked as 'to delete in ST4' and saved.
          <div *ngIf="relatedAsCMTasks.length > 0" class="row">
            <div class="col-sm-2">As a Related CM Task in : </div>
            <div class="col-sm-10" style="display: flex;flex-wrap: wrap;">
              <span *ngFor="let relatedAsCMTask of relatedAsCMTasks; let i = index" >
                {{relatedAsCMTask.taskId}}{{(relatedAsCMTask.length-1 == i) ? '' : ','}} 
              </span>
            </div>
          </div>
          <div *ngIf="relatedAsPPTasks.length > 0"  class="row">
            <div class="col-sm-2">As a PP Task in : </div>
            <div class="col-sm-10" style="display: flex;flex-wrap: wrap;">
              <span *ngFor="let relatedAsPPTask of relatedAsPPTasks; let i = index" >
                {{relatedAsPPTask.taskId}}{{(relatedAsPPTasks.length-1 == i) ? '' : ','}} 
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputEmail3" class="col-sm-4 col-form-label col-form-label-sm text-right">Project Number</label>
      <div class="col-sm-7">
        <input type="text" class="form-control project-number" formControlName="projectNumber" id="inputEmail3" placeholder="Project Number" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'projectNumber'">
      </div>
    </div>
    <div class="form-group row">
      <label for="inputProjectSystem" class="col-sm-4 col-form-label col-form-label-sm text-right">Project System</label>
      <div class="col-sm-7">
        <input type="text" class="form-control project-system-number" formControlName="projectSystem" placeholder="System Number" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'projectSystem'">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Manual ID</label>
      <div class="col-sm-7">
        <input type="text" [value]="taskManualId" class="form-control task-manual-id" formControlName="taskId" placeholder="" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'taskId'">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Element</label>
      <div class="col-sm-7">
        <ngx-select (select)="taskElementChange($event)" formControlName="taskElement" optionValueField="id" optionTextField="name" [items]="taskElementData" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'taskElement'"></ngx-select>
        <div *ngIf="projectSystemTaskEditForm.controls['taskElement'].invalid && (projectSystemTaskEditForm.controls['taskElement'].dirty || projectSystemTaskEditForm.controls['taskElement'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['taskElement'].errors?.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Individual Task Element</label>
      <div class="col-sm-7">
        <input [required]="projectSystemTaskEditForm.controls['taskElement'].value=='individual'" type="text" class="form-control" (blur)="checkTaskElementAndActivity()" formControlName="individualTaskElement" placeholder="" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'individualTaskElement'">
        <div *ngIf="projectSystemTaskEditForm.controls['individualTaskElement'].invalid && (projectSystemTaskEditForm.controls['individualTaskElement'].dirty || projectSystemTaskEditForm.controls['individualTaskElement'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['individualTaskElement'].errors?.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Nature</label>
      <div class="col-sm-7">
        <ngx-select (select)="getTaskTypesByNature($event)" formControlName="taskNature" placeholder="Select Task Nature" optionValueField="id" optionTextField="name" [items]="taskNatureData" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'taskNature'"></ngx-select>
        <div *ngIf="projectSystemTaskEditForm.controls['taskNature'].invalid && (projectSystemTaskEditForm.controls['taskNature'].dirty || projectSystemTaskEditForm.controls['taskNature'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['taskNature'].errors?.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Is Non Recurring</label>
      <div class="col-sm-7">
        <ngx-select placeholder="Select" (select)="calculateTaskStage(); checkTaskElementAndActivity()" optionValueField="id" formControlName="isNonRecurring" optionTextField="name" [items]="taskIsNonRecurringData" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'isNonRecurring'"></ngx-select>
        <div *ngIf="projectSystemTaskEditForm.controls['isNonRecurring'].invalid && (projectSystemTaskEditForm.controls['isNonRecurring'].dirty || projectSystemTaskEditForm.controls['isNonRecurring'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['isNonRecurring'].errors?.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Type</label>
      <div class="col-sm-7">
        <ngx-select [disabled]="isAutoGenerated" placeholder="Select task type" formControlName="taskTypeId" (select)="changeTaskType($event, false)" [items]="taskTypeData" optionValueField="taskTypeId" optionTextField="typeDescriptionDe" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'taskTypeId'"></ngx-select>
        <div *ngIf="projectSystemTaskEditForm.controls['taskTypeId'].invalid && (projectSystemTaskEditForm.controls['taskTypeId'].dirty || projectSystemTaskEditForm.controls['taskTypeId'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['taskTypeId'].errors?.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm text-right">Task Name</label>
      <div class="col-sm-7">
        <input type="text" class="form-control" (blur)="checkTaskElementAndActivity()" formControlName="taskName" placeholder="Task Name" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'taskName'">
        <div *ngIf="projectSystemTaskEditForm.controls['taskName'].invalid && (projectSystemTaskEditForm.controls['taskName'].dirty || projectSystemTaskEditForm.controls['taskName'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['taskName'].errors?.required">
            This field is required.
          </div>
          <div *ngIf="projectSystemTaskEditForm.controls['taskName'].errors?.maxlength">
            Should be Less Than 500 Characters.
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Suffix</label>
      <div class="col-sm-7">
        <ngx-select  optionValueField="taskSuffixId" (select)="checkTaskElementAndActivity()" formControlName="taskSuffixId" optionTextField="designationDe" placeholder="Select Task Suffix" [items]="taskSuffixData" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'taskSuffixId'"></ngx-select>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-4 text-right col-form-label col-form-label-sm">Interval A</label>
      <div class="col-sm-2">
        <input type = "text" (keyup)="calculateTaskStage()" class="form-control" formControlName="intervalvalue1" [appThousandSeparator]="projectSystemTaskEditForm.controls['intervalvalue1'].value" placeholder="value" [appDecimal]="0" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'intervalvalue1'">
        <div *ngIf="projectSystemTaskEditForm.controls['intervalvalue1'].invalid && (projectSystemTaskEditForm.controls['intervalvalue1'].dirty || projectSystemTaskEditForm.controls['intervalvalue1'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['intervalvalue1'].errors?.required">
            This field is required.
          </div>
          <div *ngIf="projectSystemTaskEditForm.controls['intervalvalue1'].value != '' && projectSystemTaskEditForm.controls['intervalvalue1'].errors?.valueZero">
            Should be Greater Than 0
          </div>
          <div *ngIf="projectSystemTaskEditForm.controls['intervalvalue1'].errors?.max">
            Should be Less Than 99999999
          </div>
          <div *ngIf="projectSystemTaskEditForm.controls['intervalvalue1'].errors?.min">
            Should be Greater Than 0
          </div>
        </div>
      </div>
      <div class="col-sm-5">
        <ngx-select [allowClear]="true" (select)="calculateTaskStage()" (remove)="calculateTaskStage()" formControlName="intervalunit1" placeholder="Select Interval unit" [items]="finalIntervalData" optionValueField="id" optionTextField="taskUnit" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'intervalunit1'"></ngx-select>
        <div *ngIf="invalidIntervals" class="ng-validator">
          Interval units cannot be duplicate
        </div>
        <div *ngIf="intervalMismatchA" class="ng-validator">
          Mileage unit - mileage interval unit missmatch
        </div>
        <div *ngIf="projectSystemTaskEditForm.controls['intervalunit1'].invalid && (projectSystemTaskEditForm.controls['intervalunit1'].dirty || projectSystemTaskEditForm.controls['intervalunit1'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['intervalunit1'].errors?.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-4 text-right col-form-label col-form-label-sm">Interval B</label>
      <div class="col-sm-2">
        <input type = "text" (keyup)="calculateTaskStage()" class="form-control" formControlName="intervalvalue2" [appThousandSeparator]="projectSystemTaskEditForm.controls['intervalvalue2'].value" placeholder="value" [appDecimal]="0" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'intervalvalue2'">
        <div *ngIf="projectSystemTaskEditForm.controls['intervalvalue2'].invalid && (projectSystemTaskEditForm.controls['intervalvalue2'].dirty || projectSystemTaskEditForm.controls['intervalvalue2'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['intervalvalue2'].errors?.required">
            This field is required.
          </div>
          <div *ngIf="projectSystemTaskEditForm.controls['intervalvalue2'].value != '' && projectSystemTaskEditForm.controls['intervalvalue2'].errors?.valueZero">
            Should be Greater Than 0
          </div>
          <div *ngIf="projectSystemTaskEditForm.controls['intervalvalue2'].errors?.max">
            Should be Less Than 99999999
          </div>
          <div *ngIf="projectSystemTaskEditForm.controls['intervalvalue2'].errors?.min">
            Should be Greater Than 0
          </div>
        </div>
      </div>
      <div class="col-sm-5">
        <ngx-select [allowClear]="true" (select)="calculateTaskStage()" (remove)="calculateTaskStage()" formControlName="intervalunit2" placeholder="Select Interval unit" [items]="finalIntervalData" optionValueField="id" optionTextField="taskUnit" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'intervalunit2'"></ngx-select>
        <div *ngIf="invalidIntervals" class="ng-validator">
          Interval units cannot be duplicate
        </div>
        <div *ngIf="intervalMismatchB" class="ng-validator">
          Mileage unit - mileage interval unit missmatch
        </div>
        <div *ngIf="projectSystemTaskEditForm.controls['intervalunit2'].invalid && (projectSystemTaskEditForm.controls['intervalunit2'].dirty || projectSystemTaskEditForm.controls['intervalunit2'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['intervalunit2'].errors?.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Stages</label>
      <div class="col-sm-7">
        <ngx-select placeholder="Select Task Stage" formControlName="taskStageId" optionValueField="taskStageId" optionTextField="designation" [items]="taskStagesData" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'taskStageId'"></ngx-select>
        <div *ngIf="showTaskStageWarning" class="alert-warning task-alerts" style="white-space: normal;">
          consider that mileage is not set
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Pre- / Postprocessing</label>
      <div class="col-sm-7">
        <ngx-select #ppTasksDropdown formControlName="ppTasksOfNonPpTask" optionValueField="taskDbId"  optionTextField="taskText" [items]="prePostProcessingTaskData" (select)="addToPPTasks()" (remove)="addToPPTasks()" (keyup)="onKeyUpPPTasks($event)" (paste)="ppTaskOnPaste($event)" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'ppTasksOfNonPpTask'"></ngx-select>
      </div>
    </div>
    <div class="ppTasks row" *ngIf="selectedPPTasks?.length > 0">
      <div class="col-sm-10 table-div">
        <table class="table">
          <thead>
          <tr>
            <th>#</th>
            <th>Task Manual ID</th>
            <th>Type</th>
            <th>Task Name</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let task of selectedPPTasks as array; let i = index" (click)="currentSelectedPPTask=i" [ngClass]="{'row-selected':currentSelectedPPTask == i}">
            <td><b>{{i+1}}</b></td>
            <td>{{task['taskId']}}</td>
            <td>{{task['ppType']}}</td>
            <td>{{task['taskName']}}</td>
            <td><button [disabled]="!isTaskActivated" type="button" class="close-btn" (click)="removeFromPPTasks(task)"><i class="fa fa-times"></i></button></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="col-sm-2 d-flex align-items-center" *ngIf="selectedPPTasks.length > 1">
        <div class="ppTask-buttons d-flex flex-column ">
          <button [disabled]="!isTaskActivated || currentSelectedPPTask<=0 || selectedPreTaskCount == currentSelectedPPTask" type="button" class="icon-btn" (click)="movePPTaskToUp()"><i class="fa fa-chevron-circle-up"></i></button>
          <button [disabled]="!isTaskActivated || currentSelectedPPTask<0 || currentSelectedPPTask == selectedPPTasks.length-1 || selectedPreTaskCount-1 == currentSelectedPPTask" type="button" class="icon-btn" (click)="movePPTaskToDown()"><i class="fa fa-chevron-circle-down"></i></button>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">PP Type</label>
      <div class="col-sm-7">
        <ngx-select placeholder="Select PP Type" [required]="projectSystemTaskEditForm.controls['taskNature'].value == 'PP'" (select)="changePpType()" formControlName="ppType" [items]="ppTypeData" optionValueField="value" optionTextField="text" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'ppType'"></ngx-select>
        <div *ngIf="projectSystemTaskEditForm.controls['ppType'].invalid && (projectSystemTaskEditForm.controls['ppType'].dirty || projectSystemTaskEditForm.controls['ppType'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['ppType'].errors?.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Post Processing task reverse order from referred pre processing task</label>
      <div class="col-sm-7">
        <ngx-select placeholder="Select Pre Processing Task" formControlName="ppTaskOfPpTask" [items]="preProcessingTaskData" optionValueField="taskDbId" optionTextField="taskId" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'ppTaskOfPpTask'"></ngx-select>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Maintenance Level</label>
      <div class="col-sm-7">
        <ngx-select (select)="changeMaintainanceLevel($event, false)"  placeholder="Select Maintainance Level" formControlName="maintenanceLevel" optionValueField="id" optionTextField="name" [items]="taskMaintainanceLevelData" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'maintenanceLevel'"></ngx-select>
        <div *ngIf="showMaintenanceLevelWarning" class="alert-warning task-alerts" style="white-space: normal;">
          consider that existing articles in task parts, task operating materials, task tools and infrastructure will be cleared
        </div>
        <div *ngIf="projectSystemTaskEditForm.controls['maintenanceLevel'].invalid && (projectSystemTaskEditForm.controls['maintenanceLevel'].dirty || projectSystemTaskEditForm.controls['maintenanceLevel'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['maintenanceLevel'].errors?.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Maintenance Location</label>
      <div class="col-sm-7">
        <ngx-select  (select)="changeMaintainanceLocation($event, false)" (remove)="changeMaintainanceLocation($event, false)" [items]="maintenanceLocationData | taskStageFilter:onTrainSelected" autoClearSearch = "true" [multiple]="true" placeholder="Select Location" formControlName="tskMaintenanceLocation" optionValueField="mountingLocationId" optionTextField="designationDe" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'tskMaintenanceLocation'"></ngx-select>
        <div *ngIf="projectSystemTaskEditForm.controls['tskMaintenanceLocation'].invalid && (projectSystemTaskEditForm.controls['tskMaintenanceLocation'].dirty || projectSystemTaskEditForm.controls['tskMaintenanceLocation'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['tskMaintenanceLocation'].errors?.required">
            This field is required.
          </div>
        </div>
        <div *ngIf="invalidMaintenanceLocation" class="ng-validator">
          cannot be selected, because maintenance level is on-train.
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Execution time </label>
      <div class="col-sm-5">
        <button [disabled]="!isTaskActivated" style="float:left" type="button" class="btn btn-auxiliary" (click)="addNewExecutionTimeField()">+</button>

        <div *ngIf="validExecutionTime==false" class="ng-validator" style="float: left; margin-left: 10px; margin-top : 5px;">
            Invalid Execution Time found.
        </div>
      </div>
    </div>

    <div formArrayName="executionTime">
      <div class="form-group row" *ngFor="let field of executionTimeField.controls; let i = index">
        <label class="col-sm-4 col-form-label col-form-label-sm text-right">Worker {{i+1}} [min]</label>
        <div class="col-sm-5">
        <input type = "text" class="form-control" [appDecimal]="1" minMax [min]="0.1" (keyup)="updateExecutionTime(i)" [formControlName]="i" placeholder="Worker {{i+1}} Execution time">
        <ng-container *ngIf="executionTimeError[i]==2">
          <div class="ng-validator">
            This field is required. <span *ngIf="i>0">You have to remove the empty rows (if any).</span>
          </div>
        </ng-container>
        <ng-container *ngIf="executionTimeError[i]==1">
          <div class="ng-validator">
            This should be greater than or equals to 0.1
          </div>
        </ng-container>
        <ng-container *ngIf="executionTimeError[i]==3">
          <div class="ng-validator">
            This should be Less than the first worker execution time
          </div>
        </ng-container>
        <!-- <ng-container *ngIf="(projectSystemTaskEditForm.controls['executionTime'][i].value != '' && projectSystemTaskEditForm.controls['executionTime'][i].value < 1)">
          <div class="ng-validator">
            Should be Greater Than or Equal 1
          </div>
        </ng-container> -->
      </div>
       <div class="col-sm-1">
        <button [disabled]="!isTaskActivated" *ngIf="i" type="button" class="btn btn-auxiliary" (click)="removeExecutionTime(i)">-</button>
       </div>
       
        
      </div>
    </div>
    
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Waiting Time [min]</label>
      <div class="col-sm-7">
        <input type = "text" class="form-control" formControlName="waitingTime" [appThousandSeparator]="projectSystemTaskEditForm.controls['waitingTime'].value" placeholder="Waiting Time" [appDecimal]="1" minMax [min]="1" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'waitingTime'">
        <div *ngIf="projectSystemTaskEditForm.controls['waitingTime'].invalid && (projectSystemTaskEditForm.controls['waitingTime'].dirty || projectSystemTaskEditForm.controls['waitingTime'].touched)" class="ng-validator">
         
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Execution time source</label>
      <div class="col-sm-5">
        <input type = "text" class="form-control" formControlName="executionTimeSource" placeholder="Execution time source" [disabled]="isExecutionTimeEmpty" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'executionTimeSource'"  appMandatoryField [conditional]="true" >
        
        <div *ngIf="projectSystemTaskEditForm.controls['executionTimeSource'].invalid && (projectSystemTaskEditForm.controls['executionTimeSource'].dirty || projectSystemTaskEditForm.controls['executionTimeSource'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['executionTimeSource'].errors?.required">
            This field is required.
          </div>

          <ng-container *ngIf="isExecutionTimeEmpty && isExecutionTimeSourceValid">
            <div class="ng-validator">
              No need to put a value here
            </div>
          </ng-container>

        </div>

      </div>
      <div class="col-sm-2 form-switch">
        <div class="form-check" style="padding-top: 0.3rem">
          <input class="form-check-input" type="checkbox" [value]="isExecutionTimeSourceValid?1:0" [disabled]="isExecutionTimeEmpty" [checked]="isExecutionTimeSourceValid" (click)="validExecutionTimeClicked($event)" id="validExecutionTime" >
          <label class="form-check-label" for="validExecutionTime">
            Valid
          </label>
        </div>
      </div>

    </div>

    <!-- Hidden from display as per the task S05:BUFFER 07: in task add / edit -->
    <div style="display: none;" class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Quantity Workers</label>
      <div class="col-sm-7">
        <input type = "text" readonly class="form-control" formControlName="quantityWorkers" [appThousandSeparator]="projectSystemTaskEditForm.controls['quantityWorkers'].value" placeholder="Quantity Workers" [appDecimal]="1" minMax [min]="1" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'quantityWorkers'">
        <div *ngIf="projectSystemTaskEditForm.controls['quantityWorkers'].invalid && (projectSystemTaskEditForm.controls['quantityWorkers'].dirty || projectSystemTaskEditForm.controls['quantityWorkers'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['quantityWorkers'].value != '' && projectSystemTaskEditForm.controls['quantityWorkers'].errors?.minMax">
            Should be Greater Than or Equal 1
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Description Of External Service</label>
      <div class="col-sm-7">
        <input type = "text" class="form-control" formControlName="externalServiceDescription" placeholder="Description" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'externalServiceDescription'">
        <div *ngIf="projectSystemTaskEditForm.controls['externalServiceDescription'].invalid && (projectSystemTaskEditForm.controls['externalServiceDescription'].dirty || projectSystemTaskEditForm.controls['externalServiceDescription'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['externalServiceDescription'].errors?.maxlength">
            Maximum Length is 255
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 text-right col-form-label col-form-label-sm">External Service Cost</label>
      <div class="col-sm-2">
        <input [required]="projectSystemTaskEditForm.controls['externalServiceCurrency'].value!=null && projectSystemTaskEditForm.controls['externalServiceCurrency'].value!=''" type = "text" [appThousandSeparator]="projectSystemTaskEditForm.controls['externalServiceCost'].value" class="form-control" formControlName="externalServiceCost" placeholder="Cost" [appDecimal]="2" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'externalServiceCost'">
        <div *ngIf="projectSystemTaskEditForm.controls['externalServiceCost'].errors?.required" class="ng-validator">
          This field is required.
        </div>
        <div *ngIf="projectSystemTaskEditForm.controls['externalServiceCost'].invalid && (projectSystemTaskEditForm.controls['externalServiceCost'].dirty || projectSystemTaskEditForm.controls['externalServiceCost'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['externalServiceCost'].value != '' && projectSystemTaskEditForm.controls['externalServiceCost'].errors?.valueZero">
            Should be Greater Than 0
          </div>
          <div *ngIf="projectSystemTaskEditForm.controls['externalServiceCost'].errors?.max">
            Should be Less Than 999999.99
          </div>
        </div>
      </div>
      <div class="col-sm-5">
        <ngx-select [required]="projectSystemTaskEditForm.controls['externalServiceCost'].value > 0 && !projectSystemTaskEditForm.controls['externalServiceCurrency'].value" [allowClear]="true" formControlName="externalServiceCurrency" placeholder="Select Currency" [items]="currencyList" optionValueField="code" optionTextField="code" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'externalServiceCurrency'"></ngx-select>
        <div *ngIf="projectSystemTaskEditForm.controls['externalServiceCurrency'].errors?.required" class="ng-validator">
          This field is required.
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Employee Qualification</label>
      <div class="col-sm-7">
        <ngx-select placeholder="Select Emp Qualification" formControlName="employeeQualificationId" [items]="qualificationData"  optionValueField="employeeQualificationId" optionTextField="designationDe" class="mandatory-field" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'employeeQualificationId'"></ngx-select><span *ngIf="projectSystemTaskEditForm.controls['employeeQualificationId'].enabled" class="mandatory-asterisk">*</span>
        <div *ngIf="projectSystemTaskEditForm.controls['employeeQualificationId'].invalid && (projectSystemTaskEditForm.controls['employeeQualificationId'].dirty || projectSystemTaskEditForm.controls['employeeQualificationId'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['employeeQualificationId'].errors?.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Specific Employee Qualification</label>
      <div class="col-sm-7">
        <input type="text" formControlName="specificEmployeeQualification" class="form-control input-box mandatory-field" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'specificEmployeeQualification'"><span *ngIf="projectSystemTaskEditForm.controls['specificEmployeeQualification'].enabled" class="mandatory-asterisk">*</span>
        <div *ngIf="projectSystemTaskEditForm.controls['specificEmployeeQualification'].invalid && (projectSystemTaskEditForm.controls['specificEmployeeQualification'].dirty || projectSystemTaskEditForm.controls['specificEmployeeQualification'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['specificEmployeeQualification'].errors?.maxlength">
            Maximum Length is 255
          </div>
          <div *ngIf="projectSystemTaskEditForm.controls['specificEmployeeQualification'].errors?.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Employee Type</label>
      <div class="col-sm-7">
        <ngx-select placeholder="Select Employee Type" formControlName="employeeTypeId" optionValueField="employeeTypeId" optionTextField="designationDe" [items]="employeeTypeData" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'employeeTypeId'"></ngx-select>
        <div *ngIf="projectSystemTaskEditForm.controls['employeeTypeId'].invalid && (projectSystemTaskEditForm.controls['employeeTypeId'].dirty || projectSystemTaskEditForm.controls['employeeTypeId'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['employeeTypeId'].errors?.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Infrastructure</label>
      <div class="col-sm-7">
        <ngx-select [items]="infrastructureData" autoClearSearch = "true" [multiple]="true" formControlName="taskInfrastructure" placeholder="Select Infrastructure" optionValueField="infrastructureId" optionTextField="designationDe" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'taskInfrastructure'"></ngx-select>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Quantity</label>
      <div class="col-sm-2">
        <input type="text" class="form-control" (blur)="changeQuantities()" formControlName="taskQuantityPerVehicle" [appThousandSeparator]="projectSystemTaskEditForm.controls['taskQuantityPerVehicle'].value" placeholder="" [appDecimal]="0" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'taskQuantityPerVehicle'">
        <div *ngIf="projectSystemTaskEditForm.controls['taskQuantityPerVehicle'].invalid && (projectSystemTaskEditForm.controls['taskQuantityPerVehicle'].dirty || projectSystemTaskEditForm.controls['taskQuantityPerVehicle'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['taskQuantityPerVehicle'].errors?.required">
            This field is required.
          </div>
          <div *ngIf="projectSystemTaskEditForm.controls['taskQuantityPerVehicle'].value != '' && projectSystemTaskEditForm.controls['taskQuantityPerVehicle'].errors?.valueZero">
            Should be Greater Than 0
          </div>
          <div *ngIf="projectSystemTaskEditForm.controls['taskQuantityPerVehicle'].errors?.max">
            Should be Less Than or Equal 2147483647
          </div>
        </div>
      </div>
      <div class="col-sm-1 p-0 d-flex align-items-center">
        <label class="col-form-label col-form-label-sm text-right">Per Vehicle</label>
      </div>
      <div class="col-sm-2">
        <input type="text" class="form-control" (blur)="changeQuantities()" formControlName="taskQuantityPerFleet" [appThousandSeparator]="projectSystemTaskEditForm.controls['taskQuantityPerFleet'].value" placeholder="" [appDecimal]="0" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'taskQuantityPerFleet'">
        <div *ngIf="projectSystemTaskEditForm.controls['taskQuantityPerFleet'].invalid && (projectSystemTaskEditForm.controls['taskQuantityPerFleet'].dirty || projectSystemTaskEditForm.controls['taskQuantityPerFleet'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['taskQuantityPerFleet'].errors?.required">
            This field is required.
          </div>
          <div *ngIf="projectSystemTaskEditForm.controls['taskQuantityPerFleet'].value != '' && projectSystemTaskEditForm.controls['taskQuantityPerFleet'].errors?.valueZero">
            Should be Greater Than 0
          </div>
          <div *ngIf="projectSystemTaskEditForm.controls['taskQuantityPerFleet'].errors?.max">
            Should be Less Than or Equal 2147483647
          </div>
        </div>
      </div>
      <div class="col-sm-1 p-0 d-flex align-items-center">
        <label class="col-form-label col-form-label-sm text-right">Per Fleet</label>
      </div>
      <div class="col-sm-1 d-flex align-items-center">
        <input class="form-check-input" type="checkbox" (change)="changeQuantities()" formControlName="isPerFleetQuantity">
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Source</label>
      <div class="col-sm-7">
        <ngx-select formControlName="taskSourceId" (select)="changeTaskSource($event)"  placeholder="Select Task Source" optionValueField="taskSourceId" optionTextField="designationDe" [items]="taskSourceData" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'taskSourceId'"></ngx-select>
        <div *ngIf="projectSystemTaskEditForm.controls['taskSourceId'].invalid && (projectSystemTaskEditForm.controls['taskSourceId'].dirty || projectSystemTaskEditForm.controls['taskSourceId'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['taskSourceId'].errors?.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Source Standard</label>
      <div class="col-sm-7" *ngIf="projectSystemTaskEditForm.controls['taskSourceId'].value == 4">
        <ngx-select [items]="taskStandardData" optionTextField="standardCode" optionValueField="taskSourceStandardsId" formControlName="taskSourceStandardId"></ngx-select>
      </div>
      <div class="col-sm-7" *ngIf="projectSystemTaskEditForm.controls['taskSourceId'].value != 4">
        <input type="text" formControlName="taskSourceStandardId" class="form-control">
        <div *ngIf="projectSystemTaskEditForm.controls['taskSourceStandardId'].invalid && (projectSystemTaskEditForm.controls['taskSourceStandardId'].dirty || projectSystemTaskEditForm.controls['taskSourceStandardId'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['taskSourceStandardId'].errors?.max">
            Should be Less Than or Equal 2147483647
          </div>
        </div>
      </div>
    </div> -->



    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right" >Task Source Standard</label>
      <div class="col-sm-7"  >
        <input type = "text" class="form-control" formControlName="taskSourceStandard" placeholder="Task Source Standard" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'taskSourceStandard'">
        <div *ngIf="projectSystemTaskEditForm.controls['taskSourceStandard'].invalid && (projectSystemTaskEditForm.controls['taskSourceStandard'].dirty || projectSystemTaskEditForm.controls['taskSourceStandard'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['taskSourceStandard'].errors?.maxlength">
            Maximum Length is 255
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Specific Task Reason</label>
      <div class="col-sm-7">
        <input type="text" formControlName="specificTaskReason" class="form-control" placeholder="Reason" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'specificTaskReason'">
        <div *ngIf="projectSystemTaskEditForm.controls['specificTaskReason'].invalid && (projectSystemTaskEditForm.controls['specificTaskReason'].dirty || projectSystemTaskEditForm.controls['specificTaskReason'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['specificTaskReason'].errors?.maxlength">
            Maximum Length is 255
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Influence</label>
      <div class="col-sm-7">
        <ngx-select [items]="taskInfluenceData" optionValueField="id" optionTextField="name" formControlName="taskInfluenceId" appMandatoryField appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'taskInfluenceId'"></ngx-select>
        <div *ngIf="projectSystemTaskEditForm.controls['taskInfluenceId'].invalid && (projectSystemTaskEditForm.controls['taskInfluenceId'].dirty || projectSystemTaskEditForm.controls['taskInfluenceId'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['taskInfluenceId'].errors?.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">FMECA ID</label>
      <div class="col-sm-7">
        <input type="text" formControlName="fmecaId" class="form-control" placeholder="FMECA ID" appInfoPromptBox [page]="'projectSystemTask'" [component]="'add'" [field]="'fmecaId'">
        <div *ngIf="projectSystemTaskEditForm.controls['fmecaId'].invalid && (projectSystemTaskEditForm.controls['fmecaId'].dirty || projectSystemTaskEditForm.controls['fmecaId'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['fmecaId'].errors?.maxlength">
            Quantity of characters exceeds 1000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">SRAC ID</label>
      <div class="col-sm-7">
        <input type="text" formControlName="taskSracId" class="form-control" placeholder="SRAC ID"  appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'taskSracId'">
        <div *ngIf="projectSystemTaskEditForm.controls['taskSracId'].invalid && (projectSystemTaskEditForm.controls['taskSracId'].dirty || projectSystemTaskEditForm.controls['taskSracId'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['taskSracId'].errors?.maxlength">
            Maximum Length is 1000
          </div>
        </div>
      </div>
    </div>


    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Target Manual</label>
      <div class="col-sm-7">
        <ngx-select formControlName="targetManualId"  placeholder="Select Target manual" optionValueField="targetManualId" optionTextField="designationDe" [items]="targetManualData" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'targetManualId'"></ngx-select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Tag</label>
      <div class="col-sm-7">
        <ngx-select [items]="taskTagList" [multiple]="true" formControlName="taskTags" optionValueField="tagId" optionTextField="tagText" [placeholder]="'Task Tags'" [autoClearSearch]="true" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'taskTags'"></ngx-select>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Reference Documents</label>
      <div class="col-sm-7">
        <textarea formControlName="supplierDocReference" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'supplierDocReference'"></textarea>
        <div *ngIf="projectSystemTaskEditForm.controls['supplierDocReference'].invalid && (projectSystemTaskEditForm.controls['supplierDocReference'].dirty || projectSystemTaskEditForm.controls['supplierDocReference'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['supplierDocReference'].errors?.maxlength">
            Maximum length is 1000
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Difference To Supplier Doc</label>
      <div class="col-sm-7">
        <textarea formControlName="differenceToSupplierDoc" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'differenceToSupplierDoc'"></textarea>
        <div *ngIf="projectSystemTaskEditForm.controls['differenceToSupplierDoc'].invalid && (projectSystemTaskEditForm.controls['differenceToSupplierDoc'].dirty || projectSystemTaskEditForm.controls['differenceToSupplierDoc'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['differenceToSupplierDoc'].errors?.maxlength">
            Maximum length is 1000
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Task Description</label>
      <div class="col-sm-7">
        <textarea formControlName="taskDescription" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'taskDescription'"></textarea>
        <div *ngIf="projectSystemTaskEditForm.controls['taskDescription'].invalid && (projectSystemTaskEditForm.controls['taskDescription'].dirty || projectSystemTaskEditForm.controls['taskDescription'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['taskDescription'].errors?.maxlength">
            Maximum length is 10'000
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Comments Doc Department</label>
      <div class="col-sm-7">
        <textarea formControlName="commentsDocDepartment" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'commentsDocDepartment'"></textarea>
        <div *ngIf="projectSystemTaskEditForm.controls['commentsDocDepartment'].invalid && (projectSystemTaskEditForm.controls['commentsDocDepartment'].dirty || projectSystemTaskEditForm.controls['commentsDocDepartment'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['commentsDocDepartment'].errors?.maxlength">
            Maximum length is 10'000
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Comments RAM Engineer</label>
      <div class="col-sm-7">
        <textarea formControlName="commentsRamEngineer" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'commentsRamEngineer'"></textarea>
        <div *ngIf="projectSystemTaskEditForm.controls['commentsRamEngineer'].invalid && (projectSystemTaskEditForm.controls['commentsRamEngineer'].dirty || projectSystemTaskEditForm.controls['commentsRamEngineer'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['commentsRamEngineer'].errors?.maxlength">
            Maximum length is 10'000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Buffer Field 1</label>
      <div class="col-sm-7">
        <textarea formControlName="bufferField01" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'bufferField01'"></textarea>
        <div *ngIf="projectSystemTaskEditForm.controls['bufferField01'].invalid && (projectSystemTaskEditForm.controls['bufferField01'].dirty || projectSystemTaskEditForm.controls['bufferField01'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['bufferField01'].errors?.maxlength">
            Maximum Length is 1000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Buffer Field 2</label>
      <div class="col-sm-7">
        <textarea formControlName="bufferField02" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'bufferField02'"></textarea>
        <div *ngIf="projectSystemTaskEditForm.controls['bufferField02'].invalid && (projectSystemTaskEditForm.controls['bufferField02'].dirty || projectSystemTaskEditForm.controls['bufferField02'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['bufferField02'].errors?.maxlength">
            Maximum Length is 1000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Buffer Field 3</label>
      <div class="col-sm-7">
        <textarea formControlName="bufferField03" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'bufferField03'"></textarea>
        <div *ngIf="projectSystemTaskEditForm.controls['bufferField03'].invalid && (projectSystemTaskEditForm.controls['bufferField03'].dirty || projectSystemTaskEditForm.controls['bufferField03'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['bufferField03'].errors?.maxlength">
            Maximum Length is 1000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Buffer Field 4</label>
      <div class="col-sm-7">
        <textarea formControlName="bufferField04" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'bufferField04'"></textarea>
        <div *ngIf="projectSystemTaskEditForm.controls['bufferField04'].invalid && (projectSystemTaskEditForm.controls['bufferField04'].dirty || projectSystemTaskEditForm.controls['bufferField04'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['bufferField04'].errors?.maxlength">
            Maximum Length is 1000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Buffer Field 5</label>
      <div class="col-sm-7">
        <textarea formControlName="bufferField05" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'bufferField05'"></textarea>
        <div *ngIf="projectSystemTaskEditForm.controls['bufferField05'].invalid && (projectSystemTaskEditForm.controls['bufferField05'].dirty || projectSystemTaskEditForm.controls['bufferField05'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['bufferField05'].errors?.maxlength">
            Maximum Length is 1000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Buffer Field 6</label>
      <div class="col-sm-7">
        <textarea formControlName="bufferField06" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'bufferField06'"></textarea>
        <div *ngIf="projectSystemTaskEditForm.controls['bufferField06'].invalid && (projectSystemTaskEditForm.controls['bufferField06'].dirty || projectSystemTaskEditForm.controls['bufferField06'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['bufferField06'].errors?.maxlength">
            Maximum Length is 1000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Buffer Field 7</label>
      <div class="col-sm-7">
        <textarea formControlName="bufferField07" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'bufferField07'"></textarea>
        <div *ngIf="projectSystemTaskEditForm.controls['bufferField07'].invalid && (projectSystemTaskEditForm.controls['bufferField07'].dirty || projectSystemTaskEditForm.controls['bufferField07'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['bufferField07'].errors?.maxlength">
            Maximum Length is 1000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Buffer Field 8</label>
      <div class="col-sm-7">
        <textarea formControlName="bufferField08" class="form-control" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'bufferField08'"></textarea>
        <div *ngIf="projectSystemTaskEditForm.controls['bufferField08'].invalid && (projectSystemTaskEditForm.controls['bufferField08'].dirty || projectSystemTaskEditForm.controls['bufferField08'].touched)" class="ng-validator">
          <div *ngIf="projectSystemTaskEditForm.controls['bufferField08'].errors?.maxlength">
            Maximum Length is 1000
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-4 col-form-label col-form-label-sm text-right">Related CM Task</label>
      <div class="col-sm-7">
        <ngx-select #cmTasksDropdown formControlName="relatedCmTask" autoClearSearch = "true" (keyup)="onKeyUpCMTasks($event)" (paste)="cmTaskOnPaste($event)" [multiple]="true" placeholder="Related CM Task" optionValueField="taskDbId" optionTextField="taskText" [items]="cmTaskData" appInfoPromptBox [page]="'projectSystemTask'" [component]="'edit'" [field]="'relatedCmTask'"></ngx-select>
      </div>
    </div>
    
  </div>
  <div *ngIf="showTaskActivityWarning" class="alert-warning task-alerts footer-alert" style="white-space: normal;">
    Attention FUNK-MSN + AOR >140 signs
  </div>
  <div class="modal-footer">
    <app-action-comment [btnLoading]="btnLoading" [okBtnName]="'Save'" [closeBtnName]="'Close'" [noChangesFound]="(initialValues == currentFormValues()) && (initialSelectedPPTasks == getSelectedPPTasksStr()) && taskStatusChanged == false" 
    [saveDisable]="taskRelatedListsLoading || taskStageLoading || projectSystemTaskEditForm.invalid == true || invalidIntervals || showTaskActivityWarning || !validExecutionTime || invalidMaintenanceLocation || prePostProcessingTaskDataLoading || cmTaskDataLoading" [mandatoryDescriptionVisible]="true" [commentVisible]="false" (okClick)="updateProjectSystemTask()" (cancelClick)="bsModalRef.hide()"></app-action-comment>
  </div>

  <div *ngIf="(taskRelatedListsLoading || taskStageLoading)" class="spinner-div d-flex justify-content-center align-items-center">
    <mat-progress-spinner [color]="'primary'" [mode]="'indeterminate'" class="spinner"></mat-progress-spinner>
  </div>
</form>


