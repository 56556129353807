import {Component, EventEmitter, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {ToggleSwitchComponent} from "../toggle-switch/toggle-switch.component";

@Component({
  selector: 'app-parts-import-data-source-prompt-box',
  templateUrl: './parts-import-data-source-prompt-box.component.html',
  styleUrls: ['./parts-import-data-source-prompt-box.component.scss']
})
export class PartsImportDataSourcePromptBoxComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();
  optionData: any[];
  modalRef: BsModalRef;

  data:any[] = [];

  finalDesignation_DEEnable = false;
  finalDesignation_ENEnable = false;
  finalSupplierArticleIDEnable = false;
  finalSupplierIDEnable = false;
  finalSystemCodeEnable = false;
  finalSystemNumberEnable = false;
  finalWeightEnable = false;

  settingsDesignation_DE = {
    type:'table',
    columns:[
      {
        title:'Designation DE',
        attribute:'masterDesignation_DE',
        type:'text',
        width:250,
      },
      {
        title:'Stadler Article ID',
        attribute:'stadler_article_ID',
        type:'text',
        width:250
      },
      {
        title:'Supplier Article ID',
        attribute:'masterSupplierArticleID',
        type:'text',
        width:250,
      },
      {
        title:'DB - Designation DE',
        attribute:'masterDesignation_DE',
        type:'text',
        width:250,
      },
      {
        title:'Import - Designation DE',
        attribute:'designation_DE',
        type:'text',
        width:250,
      },
      {
        renderComponent : ToggleSwitchComponent,
        type:'custom',
        onComponentInitFunction : (instance) => {
          instance.toggleEvent.subscribe((value)=>{
            instance.rowData['finalDesignation_DE'] = value == "db_value" ? instance.rowData['masterDesignation_DE'] : instance.rowData['designation_DE'];
          });
        },
        width:150
      }
    ]
  };

  settingsDesignation_EN = {
    type:'table',
    columns:[
      {
        title:'Designation DE',
        attribute:'masterDesignation_DE',
        type:'text',
        width:250,
      },
      {
        title:'Stadler Article ID',
        attribute:'stadler_article_ID',
        type:'text',
        width:250
      },
      {
        title:'Supplier Article ID',
        attribute:'masterSupplierArticleID',
        type:'text',
        width:250,
      },
      {
        title:'DB - Designation EN',
        attribute:'masterDesignation_EN',
        type:'text',
        width:250,
      },
      {
        title:'Import - Designation EN',
        attribute:'designation_EN',
        type:'text',
        width:250,
      },
      {
        renderComponent : ToggleSwitchComponent,
        type:'custom',
        onComponentInitFunction : (instance) => {
          instance.toggleEvent.subscribe((value)=>{
            instance.rowData['finalDesignation_EN'] = value == "db_value" ? instance.rowData['masterDesignation_EN'] : instance.rowData['designation_EN'];
          });
        },
        width:150
      }
    ]
  };

  settingsSupplierArticleID = {
    type:'table',
    columns:[
      {
        title:'Designation DE',
        attribute:'masterDesignation_DE',
        type:'text',
        width:250,
      },
      {
        title:'Stadler Article ID',
        attribute:'stadler_article_ID',
        type:'text',
        width:250
      },
      {
        title:'Supplier Article ID',
        attribute:'masterSupplierArticleID',
        type:'text',
        width:250,
      },
      {
        title:'DB - Supplier Article ID',
        attribute:'masterSupplierArticleID',
        type:'text',
        width:250,
      },
      {
        title:'Import - Supplier Article ID',
        attribute:'supplier_article_ID',
        type:'text',
        width:250,
      },
      {
        renderComponent : ToggleSwitchComponent,
        type:'custom',
        onComponentInitFunction : (instance) => {
          instance.toggleEvent.subscribe((value)=>{
            instance.rowData['finalSupplierArticleID'] = value == "db_value" ? instance.rowData['masterSupplierArticleID'] : instance.rowData['supplier_article_ID'];
          });
        },
        width:150
      }
    ]
  };

  settingsSupplierID = {
    type:'table',
    columns:[
      {
        title:'Designation DE',
        attribute:'masterDesignation_DE',
        type:'text',
        width:250,
      },
      {
        title:'Stadler Article ID',
        attribute:'stadler_article_ID',
        type:'text',
        width:250
      },
      {
        title:'Supplier Article ID',
        attribute:'masterSupplierArticleID',
        type:'text',
        width:250,
      },
      {
        title:'DB - Supplier ID',
        attribute:'masterSupplierID',
        type:'text',
        width:250,
      },
      {
        title:'Import - Supplier ID',
        attribute:'supplier_ID',
        type:'text',
        width:250,
      },
      {
        renderComponent : ToggleSwitchComponent,
        type:'custom',
        onComponentInitFunction : (instance) => {
          instance.toggleEvent.subscribe((value)=>{
            instance.rowData['finalSupplierID'] = value == "db_value" ? instance.rowData['masterSupplierID'] : instance.rowData['supplier_ID'];
          });
        },
        width:150
      }
    ]
  };

  settingsSystemCode = {
    type:'table',
    columns:[
      {
        title:'Designation DE',
        attribute:'masterDesignation_DE',
        type:'text',
        width:250,
      },
      {
        title:'Stadler Article ID',
        attribute:'stadler_article_ID',
        type:'text',
        width:250
      },
      {
        title:'Supplier Article ID',
        attribute:'masterSupplierArticleID',
        type:'text',
        width:250,
      },
      {
        title:'DB - System Code',
        attribute:'masterSystemCode',
        type:'text',
        width:250,
      },
      {
        title:'Import - System Code',
        attribute:'systemCode',
        type:'text',
        width:250,
      },
      {
        renderComponent : ToggleSwitchComponent,
        type:'custom',
        onComponentInitFunction : (instance) => {
          instance.toggleEvent.subscribe((value)=>{
            instance.rowData['finalSystemCode'] = value == "db_value" ? instance.rowData['masterSystemCode'] : instance.rowData['systemCode'];
            instance.rowData['finalSystemNumber'] = value == "db_value" ? instance.rowData['masterSystemNumber'] : instance.rowData['systemNumber'];
          });
        },
        width:150
      }
    ]
  };

  settingsWeight = {
    type:'table',
    columns:[
      {
        title:'Designation DE',
        attribute:'masterDesignation_DE',
        type:'text',
        width:250,
      },
      {
        title:'Stadler Article ID',
        attribute:'stadler_article_ID',
        type:'text',
        width:250
      },
      {
        title:'Supplier Article ID',
        attribute:'masterSupplierArticleID',
        type:'text',
        width:250,
      },
      {
        title:'DB - Weight',
        attribute:'masterWeight',
        type:'text',
        width:250,
      },
      {
        title:'Import - Weight',
        attribute:'weight',
        type:'text',
        width:250,
      },
      {
        renderComponent : ToggleSwitchComponent,
        type:'custom',
        onComponentInitFunction : (instance) => {
          instance.toggleEvent.subscribe((value)=>{
            instance.rowData['finalWeight'] = value == "db_value" ? instance.rowData['masterWeight'] : instance.rowData['weight'];
          });
        },
        width:150
      }
    ]
  };

  dataDesignation_DE:any[] = [];
  dataDesignation_EN:any[] = [];
  dataSupplierArticleID:any[] = [];
  dataSupplierID:any[] = [];
  dataSystemCode:any[] = [];
  dataSystemNumber:any[] = [];
  dataWeight:any[] = [];

  constructor(modalRef: BsModalRef) {
    this.modalRef = modalRef;
  }

  ngOnInit(): void {
    this.optionData = [{'id':'imported_data', 'text':'From Imported Data'}, {'id':'master_data', 'text':'From Master Data'}];

    for(let obj of this.data)
    {
      if(obj && obj['stadlerArticleIdExists']){
        let masterDesignation_DE = obj['masterDesignation_DE']; let designation_DE = obj['designation_DE'];
        masterDesignation_DE = masterDesignation_DE?masterDesignation_DE.replace('null',''):'';
        designation_DE = designation_DE?designation_DE.replace('null',''):'';

        let masterDesignation_EN = obj['masterDesignation_EN']; let designation_EN = obj['designation_EN'];
        masterDesignation_EN = masterDesignation_EN?masterDesignation_EN.replace('null',''):'';
        designation_EN = designation_EN?designation_EN.replace('null',''):'';

        let masterSupplierArticleID = obj['masterSupplierArticleID']; let supplier_article_ID = obj['supplier_article_ID'];
        masterSupplierArticleID = masterSupplierArticleID?masterSupplierArticleID.replace('null',''):'';
        supplier_article_ID = supplier_article_ID?supplier_article_ID.replace('null',''):'';

        let masterSupplierID = obj['masterSupplierID']; let supplier_ID = obj['supplier_ID'];
        masterSupplierID = masterSupplierID?(""+masterSupplierID).replace('null',''):'';
        supplier_ID = supplier_ID?(""+supplier_ID).replace('null',''):'';

        let masterSystemCode = obj['masterSystemCode']; let systemCode = obj['systemCode'];
        masterSystemCode = masterSystemCode?masterSystemCode.replace('null',''):'';
        systemCode = systemCode?systemCode.replace('null',''):'';

        let masterSystemNumber = obj['masterSystemNumber']; let systemNumber = obj['systemNumber'];
        masterSystemNumber = masterSystemNumber?(""+masterSystemNumber).replace('null',''):'';
        systemNumber = systemNumber?(""+systemNumber).replace('null',''):'';

        let masterWeight = obj['masterWeight']; let weight = obj['weight'];
        masterWeight = masterWeight?(""+masterWeight).replace('null',''):'';
        weight = weight?(""+weight).replace('null',''):'';

        if(masterDesignation_DE != designation_DE && masterDesignation_DE != '' && masterDesignation_DE != null && designation_DE != '' && designation_DE != null) this.dataDesignation_DE.push(obj);
        if(masterDesignation_EN != designation_EN  && masterDesignation_EN != '' && masterDesignation_EN != null && designation_EN != '' && designation_EN != null)  this.dataDesignation_EN.push(obj);
        if(masterSupplierArticleID != supplier_article_ID  && masterSupplierArticleID != '' && masterSupplierArticleID != null && supplier_article_ID != '' && supplier_article_ID != null)  this.dataSupplierArticleID.push(obj);
        if(masterSupplierID != supplier_ID  && masterSupplierID != '' && masterSupplierID != null && supplier_ID != '' && supplier_ID != null)  this.dataSupplierID.push(obj);
        if(masterSystemCode != systemCode  && masterSystemCode != '' && masterSystemCode != null && systemCode != '' && systemCode != null)  this.dataSystemCode.push(obj);
        if(masterSystemNumber != systemNumber  && masterSystemNumber != '' && masterSystemNumber != null && systemNumber != '' && systemNumber != null)  this.dataSystemNumber.push(obj);
        if(masterWeight != weight  && masterWeight != '' && masterWeight != null && weight != '' && weight != null)  this.dataWeight.push(obj);
        
      }
    }
  }

  public okClicked() {
    this.event.emit({result: this.data, action: 'ok'});
    this.modalRef.hide();
  }

  public cancelClicked() {
    this.event.emit({action: 'cancel'});
    this.modalRef.hide();
  }

}
