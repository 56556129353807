
<div class="modal-header">
    <h5 class="modal-title pull-left">Select Source</h5>
    <button  type="button" class="close pull-right" aria-label="Close" (click)="cancelClicked()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-group row" *ngIf="finalDesignation_DEEnable">
        <div class="table-div">
            <h4 class="table-title">Designation DE</h4>
            <app-tree-table [settings]="settingsDesignation_DE" [data]="dataDesignation_DE"></app-tree-table>
        </div>
    </div>
    <div class="form-group row" *ngIf="finalDesignation_ENEnable">
        <div class="table-div">
            <h4 class="table-title">Designation EN</h4>
            <app-tree-table [settings]="settingsDesignation_EN" [data]="dataDesignation_EN"></app-tree-table>
        </div>
    </div>
    <div class="form-group row" *ngIf="finalSupplierArticleIDEnable">
        <div class="table-div">
            <h4 class="table-title">Supplier Article ID</h4>
            <app-tree-table [settings]="settingsSupplierArticleID" [data]="dataSupplierArticleID"></app-tree-table>
        </div>
    </div>
    <div class="form-group row" *ngIf="finalSupplierIDEnable">
        <div class="table-div">
            <h4 class="table-title">Supplier ID</h4>
            <app-tree-table [settings]="settingsSupplierID" [data]="dataSupplierID"></app-tree-table>
        </div>
    </div>
    <div class="form-group row" *ngIf="finalSystemCodeEnable">
        <div class="table-div">
            <h4 class="table-title">System Code</h4>
            <app-tree-table [settings]="settingsSystemCode" [data]="dataSystemCode"></app-tree-table>
        </div>
    </div>
    <div class="form-group row" *ngIf="finalWeightEnable">
        <div class="table-div">
            <h4 class="table-title">Weight</h4>
            <app-tree-table [settings]="settingsWeight" [data]="dataWeight"></app-tree-table>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="okClicked()">Save</button>
    <button type="button" class="btn btn-warning btn-sm" (click)="cancelClicked()">Cancel</button>
</div>

