<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title pull-left" style="font-weight: bold">Update RAM Engineer &nbsp;</h5>
        <button (click)="bsModalRef.hide()" aria-label="Close" class="close " type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">



        <div class="form">
            <form class="pr-4 pl-4" [formGroup]="ramEngineerUpdateForm" #form>

                <div class="row">

                    <div class="form-group col-md-3 right">
                        Update Options
                    </div>
                    <div class="form-group col-md-8">
                        <ngx-select formControlName="lstOptions" appMandatoryField [items]="updateOptions"
                            [multiple]="false" [autoClearSearch]="true" [placeholder]="'Update Options'"
                            optionTextField="optionText" optionValueField="optionId" (select)="onOptionSelect()"
                            [(ngModel)]="selectedOption" appInfoPromptBox [page]="'systems'"
                            [component]="'ramengineerchange'" [field]="'updateOptions'"></ngx-select>
                    </div>

                </div>

                <div class="row">

                    <div class="form-group col-md-3 right">
                        Project(s)
                    </div>
                    <div class="form-group col-md-8">
                        <ngx-select formControlName="updatedProjects" appMandatoryField [conditional]="true"
                            [items]="projects" [multiple]="true" [autoClearSearch]="true" [placeholder]="'Select Project(s)'"
                            optionTextField="projectDesignation" optionValueField="projectNumber"
                            (select)="onProjectSelect()"
                            (remove)="onProjectSelect()" 
                             [(ngModel)]="selectedProjects" appInfoPromptBox
                            [page]="'systems'" [component]="'ramengineerchange'" [field]="'Projects'"></ngx-select>
                    </div>

                </div>

                <div class="row">
                    <div class="form-group col-md-3 right">
                        System(s)
                    </div>
                    <div class="form-group col-md-8">
                        <ngx-select formControlName="updatedSystems" appMandatoryField [items]="standardSystems"
                            [multiple]="true" [autoClearSearch]="true" [placeholder]="'Select System(s)'"
                            optionTextField="systemDesignation" optionValueField="systemNumber"
                            (select)="onSystemsSelect()" [(ngModel)]="selectedSystems" appInfoPromptBox
                            (remove)="onSystemsSelect()" 
                            [page]="'systems'" [component]="'ramengineerchange'" [field]="'Systems'"></ngx-select>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-3 right">
                        RAM Engineer(s)
                    </div>
                    <div class="form-group col-md-8">

                        <ngx-select formControlName="updatedRAMEngineers" appMandatoryField [items]="ramEngineers"
                            [multiple]="true" [autoClearSearch]="true" [placeholder]="'Select RAM Engineer(s)'"
                            optionTextField="stakeholderName" optionValueField="stakeholderId"
                            (select)="onRAMEngineerSelect()" [(ngModel)]="selectedRamEngineers" appInfoPromptBox
                            (remove)="onRAMEngineerSelect()" 
                            [page]="'systems'" [component]="'ramengineerchange'" [field]="'RAMEngineers'"></ngx-select>
                    </div>
                </div>

                <!-- <div class="row">
                    <div class="form-group col-md-3">
                        &nbsp;
                    </div>
                    <div class="form-group col-md-4">
                        <button [disabled]="notSatisfied" class="btn btn-primary" type="button" (click)="doUpdate()"><i
                                class="fa fa-database"></i> Update Now!</button>
                    </div>
                </div> -->
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <!-- <button class="btn btn-primary" (click)="bsModalRef.hide()" 
            type="button">Close</button> -->
        <app-action-comment [okBtnName]="'Save'" [closeBtnName]="'Close'" [btnLoading]="laddLoading" [saveDisable]="notSatisfied" 
            [mandatoryDescriptionVisible]="true" [commentVisible]="true" 
            [commentRequired]="true" 
            [justificationCategoryVisible]="true" [justificationCategoryRequired]="true" 
            (okClick)="doUpdate($event)" 
            (cancelClick)="bsModalRef.hide()">
        </app-action-comment>
    </div>

    <div *ngIf="!(projectsLoaded && systemsLoaded)"
        class="spinner-div d-flex justify-content-center align-items-center">
        <mat-progress-spinner [color]="'primary'" [mode]="'indeterminate'" class="spinner"></mat-progress-spinner>
    </div>

</div>