import { Component, OnInit, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProjectSystemTaskToolsComponent } from '../project-system-task-tools/project-system-task-tools.component';

@Component({
  selector: 'app-project-system-task-tools-url',
  templateUrl: './project-system-task-tools-url.component.html',
  styleUrls: ['./project-system-task-tools-url.component.scss']
})
export class ProjectSystemTaskToolsUrlComponent implements OnInit {

  @Input()
  rowData:any;
  isTaskActivated:boolean;

  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
    // this.isTaskActivated = this.rowData['isTaskActivated'] == 1;
    this.isTaskActivated = this.rowData['isTaskActivated'] == 'A' ? true : false;
  }

  /**
   * Load Project system task tools modal loading
   */
  loadProjectSystemTaskToolsModal(){
    const initialState = {
      param : {
        data:this.rowData
      }
    };
    this.modalService.show(ProjectSystemTaskToolsComponent, {class:'modal-xl',initialState,keyboard:false,ignoreBackdropClick: true});
  }

}
