import {Component, Input, OnInit} from '@angular/core';
import {CopyModalComponent} from '../../project-systems/components/copy-modal/copy-modal.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ProjectLogComponent} from '../../project-log/project-log.component';
import {LanguageServiceService} from "../../../../services/language-service.service";

@Component({
  selector: 'app-project-log-url',
  templateUrl: './project-log-url.component.html',
  styleUrls: ['./project-log-url.component.scss']
})
export class ProjectLogUrlComponent implements OnInit {

  @Input()
  rowData: any;

  modalRef:any;
  public projectNumber: number;

  constructor(private modalService:BsModalService,
              private languageService: LanguageServiceService) { }

  ngOnInit(): void {
    this.projectNumber = this.rowData['projectNumber'];
  }

  loadLogModel(){
    let lan  = {
      id: this.rowData['workLangId'],
      str: this.rowData['workLangId'] == 1 ? "German" : "English"
    }
    this.languageService.setWorkingLanguage(lan);

    const initialState = {
      param : this.rowData
    };
    this.modalRef = this.modalService.show(ProjectLogComponent, {class:'modal-xl',initialState,keyboard:false,ignoreBackdropClick: true});
  }
}
