import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TaskOperatingMaterialComponent } from '../task-operating-material/task-operating-material.component';

@Component({
  selector: 'app-task-operating-material-url',
  templateUrl: './task-operating-material-url.component.html',
  styleUrls: ['./task-operating-material-url.component.scss']
})
export class TaskOperatingMaterialUrlComponent implements OnInit {

  rowData:any;
  isTaskActivated:boolean;
  constructor(
      private modalService: BsModalService

  ) { }

  ngOnInit(): void {
    
    this.isTaskActivated = this.rowData['isTaskActivated'] == 'A' ? true : false;
  }

  /**
   * Load Operating material modal
   */
  loadOperatingMaterialModal(){
    const initialState = {
      param : this.rowData
    };
    this.modalService.show(TaskOperatingMaterialComponent, {class:'modal-xl',initialState,keyboard:false,ignoreBackdropClick: true});
  }

}
