import { NgModule } from '@angular/core';
import { OverallTasksRoutingModule } from './overall-tasks-routing.module';
import {NgxPaginationModule} from "ngx-pagination";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxSelectModule} from "ngx-select-ex";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../../shared/shared.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {LaddaModule} from "angular2-ladda";
import {ModalModule} from "ngx-bootstrap/modal";
import {TreeTableModule} from "../../../shared/components/tree-table/tree-table.module";
import {OverallTasksComponent} from "./overall-tasks/overall-tasks.component";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@NgModule({
  declarations: [OverallTasksComponent],
    imports: [
        OverallTasksRoutingModule,
        NgxPaginationModule,
        FormsModule,
        NgxSelectModule,
        CommonModule,
        SharedModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        LaddaModule,
        ModalModule,
        TreeTableModule,
        MatProgressSpinnerModule
    ],
})
export class OverallTasksModule { }
