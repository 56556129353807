import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {AppComponent} from "../app.component";

@Injectable({
    providedIn: 'root'
})
export class AuthServiceService {

    appComponent: AppComponent;

    constructor(
        private router: Router,
        private http: HttpClient,
    )
    { }

    /**
     * Authenticate User Using Credentials
     * @param data
     */
    authenticateUser(data)
    {
        localStorage.removeItem('user');
        let formData = new FormData();
        formData.append('email', data['username']);
        formData.append('password', data['password']);

        return this.http.post(environment.apiUrl + "/users/verify", formData);
    }

    /**
     * Reset User password
     * @param data
     */
    resetPassword(data)
    {
        return this.http.post(environment.apiUrl + "/users/resetpassword", data);
    }

    /**
     * Check User Is Logged
     */
    isLogged()
    {
        if (localStorage.getItem('user'))
        {
            return true;
        }
        else
        {
            return false;
        }
    }


    /**
     * Get login user data
     * @param userId
     */
    getLoginUserData(userId = null)
    {
        if (!userId)
        {
            let loginUser = JSON.parse(localStorage.getItem('user'));
            userId = loginUser['userId'];
        }
        return this.http.get(environment.apiUrl + "/users/" + userId + "");
    }


    /**
     * User Logout
     */
    logout()
    {
        localStorage.removeItem('user');
    }

    getUserToken()
    {
        let user = JSON.parse(localStorage.getItem('user'));
        return user.authToken;
    }

    setUser(user: any)
    {
        localStorage.setItem('user', JSON.stringify(user));
        this.appComponent.updateUser();
    }

    /**
     * Check whether user has permission to access the page
     */
    hasPermission(permission:string)
    {
        return this.http.get(environment.apiUrl + "/users/haspermission/" + permission);
    }
}
