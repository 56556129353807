<div class="modal-header">
  <h5 class="modal-title pull-left">Edit Suppliers</h5>
  <div>
    <ngx-select style="padding-left: 25px; width: 200px; display: inline-block; float: right !important;" optionValueField="id" optionTextField="str" [items]="languages" [(ngModel)]="selectedLanguage" (select)="languageChanged()"></ngx-select>
  </div>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="editSupplierForm" (ngSubmit)="editSupplier()"  class="pr-4 pl-4">
  <div class="modal-body">
    <div class="form-group row">
      <label class="col-sm-5 col-form-label col-form-label-sm">Supplier Name</label>
      <div class="col-sm-7">
        <input type="text" formControlName="firstName" class="form-control form-control-sm" placeholder="Supplier Name" appInfoPromptBox [page]="'masterSuppliers'" [component]="'edit'" [field]="'firstName'">
        <div *ngIf="editSupplierForm.controls['firstName'].invalid && (editSupplierForm.controls['firstName'].dirty || editSupplierForm.controls['firstName'].touched)" class="ng-validator">
          <div *ngIf="editSupplierForm.controls['firstName'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label col-form-label-sm">Infor Supplier Id</label>
      <div class="col-sm-7">
        <input type="text" formControlName="erpId" class="form-control form-control-sm" placeholder="Infor Supplier Id" appInfoPromptBox [page]="'masterSuppliers'" [component]="'edit'" [field]="'erpId'">
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex align-items-baseline ">
    <div class="alert-warning no-changes-found-warning" *ngIf="initialValues == currentFormValues()">
      <span>No changes found...</span>
    </div>
    <button appButtonLoader [isLoading]="btnLoading" type="submit" [disabled]="editSupplierForm.invalid == true || initialValues == currentFormValues()" [ladda]="btnLoading" class="btn btn-primary">Save</button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()" >Close</button>
  </div>
</form>
