

<div class="modal-header">
    <h5 class="modal-title pull-left">Standard Systems
    </h5>
    <div>
        <ngx-select style="padding-left: 25px; width: 200px; float: right !important;" optionValueField="id" optionTextField="str" [items]="languages" [(ngModel)]="selectedLanguage" (select)="languageChanged()"></ngx-select>
    </div>
    <button type="button" class="close " aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="data-table">
        <app-tree-table [settings]="settings" [data]="standardSystemData" [currentPage]="currentPage" [pageCount]="pageCount" (pageChangeEvent)="changePage($event)" (pageSizeChangeEvent)="changePageSize($event);" (filterChangeEvent)="filterOrSortChange($event)"></app-tree-table>
    </div>
</div>
<div class="modal-footer">
    <app-action-comment [btnLoading]="btnLoading" [okBtnName]="'Add'" [closeBtnName]="'Close'" [saveDisable]="selectedProjectSystem.length == 0" [commentVisible]="projectFreezeFunction == 'activated'" (okClick)="addToList($event)" (cancelClick)="bsModalRef.hide()"></app-action-comment>
</div>

