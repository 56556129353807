<div class="tree-node d-flex justify-content-start" [ngClass]="settings.rowClassFunction ? settings.rowClassFunction(rowData) : ''" [ngStyle]="{'padding-left.px': (!columnType || columnType == 'left') ? 20 * depth : 0, 'background-color': (settings.id && rowData[settings.id] == activeMouseOverRowId && parentId == activeMouseOverRowParentId) ? '#cad5e0' : rowIndex%2 ? '#dfeef9' : '#eff6fb'}">
    <div *ngIf="(!columnType || columnType == 'left') && settings.type == 'table'" class="decorator-r"></div>
    <div *ngIf="(!columnType || columnType == 'left') && settings.type == 'tree'" class="decorator-r d-flex align-items-center">
        <button *ngIf="!expanded" [disabled]="!hasChild" class="decorator" (click)="treeNodeExpanded()"><fa-icon *ngIf="hasChild" [icon]="faPlus"></fa-icon></button>
        <button *ngIf="expanded" [disabled]="!hasChild" class="decorator" (click)="treeNodeCollapsed()"><fa-icon *ngIf="hasChild" [icon]="faMinus"></fa-icon></button>
    </div>

    <div *ngFor="let column of columns; let i = index" (mouseover)="emitMouseOver(rowData[settings.id], parentId)">
        <div *ngIf="!column['isHidden']" [ngStyle]="{'width.px': i==0 && (!columnType || columnType == 'left')  ? ((column['width'] ? column['width']  : columnWidth) - 20 * depth) : ((column['width'] ? column['width']  : columnWidth))}">
            <div [ngClass]="column['class']" class="tree-cell" [ngStyle]="{'background-color': (settings.id && rowData[settings.id] == activeMouseOverRowId && parentId == activeMouseOverRowParentId) ? '#cad5e0' : rowIndex%2 ? '#dfeef9' : '#eff6fb'}" >
                <app-tree-cell [tree]="tree" [loading]="settings['loading']" [type]="column['type']" [content]="rowData[column['attribute']]" [column]="column" [rowData]="rowData"></app-tree-cell>
            </div>
        </div>
    </div>
</div>

<div *ngIf="expanded" class="node-children">
    <div *ngFor="let object of children; let index=index">
        <app-tree-node *ngIf="!object['filtered']" [tree]="tree" [settings]="settings" [rowIndex]="rowIndex+index+1" [depth]="depth+1" [activeMouseOverRowId]="activeMouseOverRowId" [activeMouseOverRowParentId]="activeMouseOverRowParentId" [columns]="columns" [columnType]="columnType" [rowData]="object" [hasChild]="object['hasChild']" [columnWidth]="columnWidth" (toggleEvent)="emitToggleEvent($event)" (mouseOverRowChangeEvent)="emitMouseOver($event['activeMouseOverRowId'], $event['activeMouseOverRowParentId'])"></app-tree-node>
    </div>
</div>
