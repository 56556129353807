import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProjectSystemTaskToolsService {

    constructor(private http: HttpClient)
    {
    }


    getProjectSystemTaskTools(projectNumber, systemNumber, taskNumber)
    {
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/tasks/" + taskNumber + "/tools/");
    }

    /**
     * Get Project system task tool
     * @param projectNumber
     * @param systemNumber
     * @param taskNumber
     * @param toolNumber
     */
    getProjectToolByToolNumber(projectNumber, systemNumber, taskNumber, toolNumber)
    {

    }

    addProjectSystemTaskTools(reason, projectNumber, systemNumber, taskNumber, data)
    {
        const httpOptions = {
            params: {
                comment: reason['comment'],
                justificationCategory: reason['justificationCategory'] != null ? reason['justificationCategory'] : -1
            }
        };
        return this.http.post(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/tasks/" + taskNumber + "/tools/", data, httpOptions);
    }

    updateProjectSystemTaskTools(reason, projectNumber, systemNumber, taskNumber, toolNumber, data)
    {
        const httpOptions = {
            params: {
                comment: reason['comment'],
                justificationCategory: reason['justificationCategory'] != null ? reason['justificationCategory'] : -1
            }
        };
        return this.http.put(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/tasks/" + taskNumber + "/tools/" + toolNumber + "/", data, httpOptions);
    }

    delete(res, projectNumber, systemNumber, taskDbId, toolNumber)
    {
        const httpOptions = {
            params: {
                comment: res['comment'],
                justificationCategory: res['justificationCategory'] ? res['justificationCategory'] : -1
            }
        };
        return this.http.delete(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/tasks/" + taskDbId + "/tools/" + toolNumber, httpOptions);
    }
}

