import {AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";

@Directive({
  selector: '[appInfoPromptBox]'
})
export class InfoPromptBoxDirective implements OnInit, AfterViewInit {


  constructor(private renderer: Renderer2,
              private el: ElementRef,
              private http: HttpClient,
              private notification: ToastrService) {
  }

  @Input()
  public page:string = "";

  @Input()
  public component:string = "";

  @Input()
  public field:string = "";

  private infoButton:any = null;
  private promptBox:any = null;
  private spanDiv:any = null;
  private buttonDiv:any = null;
  private closeButton:any = null;
  private inputBox:any = null;
  private showMoreButton:any = null;
  private saveButton:any = null;
  private editButton:any = null;

  private smallPromptHeight = 150;
  private smallPromptWidth = 300;
  private largePromptHeight = 300;
  private largePromptWidth = 500;

  private defaultInfoText = "";
  private fullInfoText = "";

  ngOnInit() {
    if(!this.el || !this.el.nativeElement) return;

    this.createElements();
    this.addInfoButton(this.el.nativeElement);
  }

  ngAfterViewInit() {
    setTimeout(()=>{
      if(this.component != "grid" && this.el.nativeElement.parentElement){
        this.el.nativeElement.parentElement.style.position = 'relative';
        this.infoButton.style.right = "-10px";
        this.el.nativeElement.parentElement.addEventListener('mouseover',()=>{
          this.infoButton.style.visibility = "visible";
        });
        this.el.nativeElement.parentElement.addEventListener('mouseout',()=>{
          this.infoButton.style.visibility = "hidden";
        });
      }
    })
  }

  private createElements(){
    this.createInfoButton();
    this.createPromptBox();
    this.createSpanDiv();
    this.createButtonDiv();
    this.createCloseButton();
    this.createInputBox();
    this.createShowMoreButton();
    this.createEditButton();
    this.createSaveButton();
  }

  private createInfoButton(){
    this.infoButton = document.createElement("BUTTON");
    this.infoButton.className = "info-button";
    this.infoButton.innerHTML = "<i class=\"fa fa-info\"></i>"
    this.infoButton.addEventListener("click", (event)=>{
      this.showSmallPromptBox(event);
      event.preventDefault();
    });
  }

  private createPromptBox(){
    this.promptBox = document.createElement("DIV");
    this.promptBox.className = "prompt-box d-flex flex-column";
    this.promptBox.name = "promptBox"

    document.addEventListener('click', (e) =>{
      let isClickInsideElement = this.promptBox.contains(e.target) || this.infoButton?.contains(e.target) || this.editButton?.contains(e.target) || this.saveButton?.contains(e.target) || this.showMoreButton?.contains(e.target) ;
      if (!isClickInsideElement) {
        if(this.inputBox) {
          this.inputBox.readOnly = true;
          this.inputBox.className = "read-only-text textarea";
          this.inputBox.value = "";
        }
        if(this.editButton) this.saveButton.insertAdjacentElement('afterend', this.editButton);
        this.saveButton.remove();
        this.promptBox.remove();
        if(this.component != "grid") this.infoButton.style.visibility = "hidden";
      }
    });
  }

  private createSpanDiv(){
    this.spanDiv = document.createElement("DIV");
    this.spanDiv.className = "span-div";
  }

  private createButtonDiv(){
    this.buttonDiv = document.createElement("DIV");
    this.buttonDiv.className = "button-div d-flex justify-content-end";
  }

  private createCloseButton(){
    this.closeButton = document.createElement("BUTTON");
    this.closeButton.className = "close-button";
    this.closeButton.innerHTML = "<i class=\"fa fa-close\"></i>"
    this.closeButton.addEventListener("click", (e)=>{
      if(this.promptBox) {
        if(this.inputBox) {
          this.inputBox.readOnly = true;
          this.inputBox.className = "read-only-text textarea";
          this.inputBox.value = "";
        }
        if(this.editButton) this.saveButton.insertAdjacentElement('afterend', this.editButton);
        this.saveButton.remove();
        this.promptBox.remove();
        if(this.component != "grid") this.infoButton.style.visibility = "hidden";
        e.preventDefault();
      }
      e.preventDefault();
    });
  }

  private createInputBox(){
    this.inputBox = document.createElement("TEXTAREA");
    this.inputBox.readOnly = true;
    this.inputBox.className = "read-only-text textarea";
  }

  private createShowMoreButton(){
    this.showMoreButton = document.createElement("BUTTON");
    this.showMoreButton.className = "show-more-button";
    this.showMoreButton.innerText = "Show More";
    this.showMoreButton.addEventListener("click", (e)=>{
      this.showLargePromptBox();
      e.preventDefault();
    });
  }

  private createEditButton(){
    this.editButton = document.createElement("BUTTON");
    this.editButton.className = "edit-button";
    this.editButton.innerText = "Edit";
    this.editButton.addEventListener("click", (e)=>{
      if(this.saveButton) this.editButton.insertAdjacentElement('afterend', this.saveButton);
      if(this.inputBox) {
        this.inputBox.readOnly = false;
        this.inputBox.className = "editable-text textarea";
        this.inputBox.value = this.fullInfoText;
      }
      this.editButton.remove();
      e.preventDefault();
    });
  }

  private createSaveButton(){
    this.saveButton = document.createElement("BUTTON");
    this.saveButton.className = "save-button";
    this.saveButton.innerText = "Save";
    this.saveButton.addEventListener("click", (e)=>{
      let text = this.inputBox && this.inputBox.value && this.inputBox.value != "" ? this.inputBox.value : " ";
      this.updateInfoText(text).subscribe((infoText)=>{
        if(infoText && infoText['text']){
          if(this.inputBox) {
            this.inputBox.readOnly = true;
            this.inputBox.className = "read-only-text textarea";
            this.inputBox.value = infoText['text'];
            this.fullInfoText = infoText['text'];
          }
          if(this.editButton) this.saveButton.insertAdjacentElement('afterend', this.editButton);
          this.saveButton.remove();
          e.preventDefault();

          this.notification.success('info text has been updated','success');
        }
        else{
          this.notification.error('info text has not been updated','Error');
        }
      }, error => {
        this.notification.error('info text has not been updated','Error');
      })
    });
  }

  private addInfoButton(element:Element){
    element.insertAdjacentElement('afterend', this.infoButton);
    if(this.component != "grid") this.infoButton.style.visibility = "hidden";
  }

  private showSmallPromptBox(event){
    if(!this.promptBox) return;

    this.promptBox.remove();

    this.promptBox.style.height = this.smallPromptHeight + 'px';
    this.promptBox.style.width = this.smallPromptWidth + 'px';
    this.promptBox.style.top = event.y + 15 + 'px';
    this.promptBox.style.left = (event.x > this.smallPromptWidth ? event.x - this.smallPromptWidth : event.x) + 'px';

    this.promptBox.appendChild(this.spanDiv);
    this.promptBox.appendChild(this.buttonDiv);

    this.spanDiv.appendChild(this.closeButton);
    this.spanDiv.appendChild(this.inputBox);

    this.setInfoTextAndShowMoreButton();
    this.showEditButton();

    if(document.getElementsByClassName("modal").length > 0){
      document.getElementsByClassName("modal")[0].appendChild(this.promptBox);
    }
    else{
      document.getElementsByClassName("mainContainer")[0].appendChild(this.promptBox);
    }

  }

  private showLargePromptBox(){
    this.promptBox.style.top = (window.innerHeight - this.largePromptHeight) / 2 + 'px';
    this.promptBox.style.left = (window.innerWidth - this.largePromptWidth) / 2 + 'px';
    this.promptBox.style.height = this.largePromptHeight + 'px';
    this.promptBox.style.width = this.largePromptWidth + 'px';

    if(this.inputBox) this.inputBox.value = this.fullInfoText;
    this.showMoreButton.remove();
  }

  private setInfoTextAndShowMoreButton(){
    this.getInfoText().subscribe((infoText)=>{
      this.fullInfoText = infoText && infoText['text'] && infoText['text'] != " " ? infoText['text'] : "";
      if(this.fullInfoText){
        if(this.fullInfoText.length > 0 && this.fullInfoText.length < 75){
          if(this.inputBox) this.inputBox.value = this.fullInfoText;
          if(this.showMoreButton) this.showMoreButton.remove();
        }
        else if (this.fullInfoText.length > 75) {
          if (this.inputBox) this.inputBox.value = this.fullInfoText.substring(0, 75) + "...";
          if (this.showMoreButton) this.buttonDiv.appendChild(this.showMoreButton);
        }
        else {
          if(this.inputBox) this.inputBox.value = this.defaultInfoText;
          if(this.showMoreButton) this.showMoreButton.remove();
        }
      }
      else{
        if(this.inputBox) this.inputBox.value = this.defaultInfoText;
        if(this.showMoreButton) this.showMoreButton.remove();
      }
    },error => {
      if(this.inputBox) this.inputBox.value = this.defaultInfoText;
      if(this.showMoreButton) this.showMoreButton.remove();
    });
  }

  private showEditButton(){
    this.getUser().subscribe((user)=>{
      if(user && user['primaryRoleDesignation'] == 'Admin'){
        if(this.buttonDiv && this.editButton) this.buttonDiv.appendChild(this.editButton);
        if(this.saveButton) this.saveButton.remove();
      }
    });

    this.getUserAuthorizations().subscribe((authRoles:any[])=>{
      for(let role in authRoles){
        if(role && role['actionName'] && role['actionName'] == 'Add/Edit Information Text'){
          console.log(role);
        }
      }
    })
  }

  private getInfoText(){
    return this.http.get(environment.apiUrl+"/info-text/"+this.page+"/"+this.component+"/"+this.field);
  }

  private getUser(){
    let loginUser = JSON.parse(localStorage.getItem('user'));
    let userId = loginUser['userId'];
    return this.http.get(environment.apiUrl+"/users/"+userId);
  }

  private getUserAuthorizations(){
    let loginUser = JSON.parse(localStorage.getItem('user'));
    let userId = loginUser['userId'];
    return this.http.get(environment.apiUrl+"/authorizationroles/user/"+userId);
  }

  private updateInfoText(text){
    return this.http.post(environment.apiUrl+"/info-text/"+this.page+"/"+this.component+"/"+this.field, text);
  }
}
