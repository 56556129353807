import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import { validateForm } from '../../../../../shared/helper';
import { UserRoleService } from "../../../../../services/user-role.service";
import { UserGroupService } from "../../../../../services/user-group.service";
import { AuthServiceService } from "../../../../../services/auth-service.service";

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();
  loadingBtn: Boolean = false;
  loadingBtnPwd: Boolean = false;
  param: any;
  roleData: any[] = [];
  userGroupData: any[] = [];
  ismatch: Boolean = true;
  disableButton: Boolean = true;
  isChecked: Boolean = false;

  userEditForm = new UntypedFormGroup({
    userEmail: new UntypedFormControl(''),
    firstName: new UntypedFormControl(''),
    lastName: new UntypedFormControl(''),
    userId: new UntypedFormControl(''),
    primaryRole: new UntypedFormControl(''),
    userGroupId: new UntypedFormControl(''),
    active: new UntypedFormControl(''),
  });

  userPasswordResetForm = new UntypedFormGroup({
    newPassword: new UntypedFormControl(''),
    newPasswordConfirm: new UntypedFormControl('')
  });

  constructor(
    public bsModalRef: BsModalRef,
    private userService: UserService,
    private notification: ToastrService,
    private userRoleService: UserRoleService,
    private userGroupService: UserGroupService,
    private authService: AuthServiceService,
  ) {

  }

  ngOnInit(): void {

    this.getUserRoleData();
    this.getUserGroupData();

    this.userEditForm.patchValue(this.param);

    if (this.param.status == "") {
      this.userEditForm.patchValue({ active: false });
      this.isChecked = false;
    } else {
      this.userEditForm.patchValue({ active: true });
      this.isChecked = true;
    }


    validateForm(this.userEditForm);
  }

  updateUser() {

    try {
      let formData = this.userEditForm.getRawValue();
      this.loadingBtn = true;
      this.userService.update(formData, this.param.userId).subscribe(res => {
        if (res) {
          this.notification.success('User has been updated', 'success');
        } else {
          this.notification.error('User has not been updated', 'Error');
        }
      }, (error => {
        this.loadingBtn = false;
        this.notification.error('User has not been updated', 'Error');
      }), () => {
        this.bsModalRef.hide();
        this.loadingBtn = false;
        this.event.emit();
      });
    } catch (error) {
      this.loadingBtn = false;
    }


  }

  resetPassword() {
    let resetPasswordSubmitData = this.userPasswordResetForm.value;
    // let resetPasswordSubmitData = {
    //   newPassword : formData.password,
    //   newPasswordConfirm : formData.confirm
    // };

    this.loadingBtnPwd = true;
    this.userService.resetPassword(resetPasswordSubmitData, this.param.userId).subscribe(res => {
      if (res) {
        this.notification.success('User has been updated', 'success');
      } else {
        this.notification.error('User has not been updated', 'Error');
      }
    }, (error => {
      this.notification.error('User has not been updated', 'Error');
    }), () => {
      this.bsModalRef.hide();
      this.loadingBtnPwd = false;
      this.event.emit();
    });
  }

  isValidPassword() {
    let formData = this.userPasswordResetForm.getRawValue();
    if (formData['newPassword'] != formData['newPasswordConfirm']) {
      this.ismatch = false;
      this.disableButton = true;
    } else {
      this.ismatch = true;
      this.disableButton = false;
    }
  }

  clearPassword() {
    this.userPasswordResetForm.patchValue({
      newPassword: null,
      newPasswordConfirm: null
    });
  }

  getUserRoleData() {
    this.userRoleService.getAll().subscribe((res: any[]) => {
      if (res) this.roleData = res;
    }, (error => {
      console.log(error);
    }))
  }

  getUserGroupData() {
    this.userGroupService.getAll().subscribe((res: any[]) => {
      if (res) this.userGroupData = res;
    }, (error => {
      console.log(error);
    }))
  }

}
