<div class="container-fluid">

    <div class="row">
      <div class="pageTitle col-sm-3">
          <span>Projects</span>&nbsp;
          <button (click)="addProject()" id="btnAddProject" type="button" class="btn btn-primary btn-sm" appInfoPromptBox [page]="'project'" [component]="'grid'" [field]="'addButton'"><fa-icon [icon]="faPlus"></fa-icon> &nbsp;&nbsp;Add</button>
      </div>
    </div>

<!--    <div class="form-group row pr-4 pl-4">-->
<!--        <label class="col-sm-1 col-form-label">Project</label>-->
<!--        <div class="col-sm-5">-->
<!--            <ngx-select [items]="tableData" optionValueField="projectNumber" (select)="search()" [placeholder]="'Select Option'" optionTextField="searchText" [(ngModel)]="searchValue"></ngx-select>-->
<!--        </div>-->
<!--        <div class="col-sm-4">-->
<!--            <button *ngIf="showReset" type="button" (click)="resetSearch()" class="btn btn-primary"><fa-icon [icon]="faSync"></fa-icon>&nbsp;&nbsp;Reset</button>-->
<!--        </div>-->
<!--    </div>-->

    <div class="data-table">
        <app-tree-table  [spinnerVisible]="loading" spinnerText="Loading Projects..." [settings]="settings" [data]="tableData" [currentPage]="currentPage" [pageCount]="pageCount" (pageChangeEvent)="changePage($event)" (pageSizeChangeEvent)="changePageSize($event);" (filterChangeEvent)="filterOrSortChange($event)"></app-tree-table>
    </div>



  </div> <!-- wrapper -->
