import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OperatingProfilesService {

    constructor(private http: HttpClient) { }

    /**
     * Get all operating profiles
     */
    getAllOperatingProfiles(projectNumber, apiType, data, params)
    {
        return this.http.post(environment.apiUrl + "/operatingprofiles/projects/" + projectNumber + "/" + apiType, data, {params: params});
    }

    /**
     * Create new Operating Profiles
     * @param data
     */
    createOperatingProfiles(res, data)
    {
        const httpOptions = {
            params: {
                comment: res['comment'],
                justificationCategory: res['justificationCategory'] ? res['justificationCategory'] : -1
            }
        };
        return this.http.post(environment.apiUrl + "/operatingprofiles/", data, httpOptions);
    }

    /**
     * Get Operating profile by id
     * @param id
     */
    getOperatingProfileByID(id)
    {
        return this.http.get(environment.apiUrl + "/operatingprofiles/" + id + '/');
    }

    deleteOperatingProfile(res, id)
    {
        const httpOptions = {
            params: {
                comment: res['comment'],
                justificationCategory: res['justificationCategory'] ? res['justificationCategory'] : -1
            }
        };
        return this.http.delete(environment.apiUrl + "/operatingprofiles/" + id, httpOptions);
    }

    /**
     * Update Operating Profiles
     * @param data
     * @param id
     */
    updateOperatingProfiles(res, data, id)
    {
        const httpOptions = {
            params: {
                comment: res['comment'],
                justificationCategory: res['justificationCategory'] ? res['justificationCategory'] : -1
            }
        };
        return this.http.put(environment.apiUrl + "/operatingprofiles/" + id + "/", data, httpOptions);
    }
}
