import {AfterViewInit, Component, ComponentFactoryResolver, EventEmitter, Injector, Input, Output, OnInit, ViewChild, ViewContainerRef, ViewRef} from '@angular/core';
import { faFilter} from '@fortawesome/free-solid-svg-icons'
import {TreeComponent} from "../tree/tree.component";

@Component({
  selector: 'app-tree-cell',
  templateUrl: './tree-cell.component.html',
  styleUrls: ['./tree-cell.component.scss']
})
export class TreeCellComponent implements OnInit, AfterViewInit {

  faFilter = faFilter;

  @Input() loading = true;
  @Input() filter;
  @Input() type; // text, custom, custom-text, html, checkbox, radio
  @Input() content;
  @Input() rowData;
  @Input() column;
  @Input() tree:TreeComponent;

  public renderComponent;
  public actionFunction;
  public hideFunction;
  public onComponentInitFunction;
  public checkIsCheckedFunction;
  public valueChangeFunction;
  public toolTip = "";
  public format = "";
  public isDisabledFunction;

  @Output() showFilterEvent = new EventEmitter<any>();

  @ViewChild("vc", {read: ViewContainerRef}) vc: ViewContainerRef;
  @ViewChild('radioButton') radioButton;

  constructor(public injector: Injector,
              public resolver: ComponentFactoryResolver) { }

  ngOnInit(): void {
    if(this.column){
      this.renderComponent = this.column['renderComponent'];
      this.actionFunction = this.column['actionFunction'];
      this.hideFunction = this.column['hideFunction'];
      this.onComponentInitFunction = this.column['onComponentInitFunction'];
      this.checkIsCheckedFunction = this.column['checkIsCheckedFunction'];
      this.valueChangeFunction = this.column['valueChangeFunction'];
      this.isDisabledFunction = this.column['isDisabledFunction'];
      this.format = this.column['format'];
    }
    this.content = this.formatText(this.content);
  }

  ngAfterViewInit() {
    setTimeout(()=>{
      this.addCustomComponents(); // after 1 event loop, calculateColumnWidths calls, this should call after that function, So should call after 2 event loops.
    });
  }

  formatText(value){
    if(this.type != 'text' || this.format == null || this.format == ''|| !value || value == '') return value;

    let finalString = "";
    let valueString: string = value.toString();
    let spacePos = valueString.indexOf(" ");

    let absoluteValue = spacePos>0?valueString.substring(0,spacePos):valueString;
    let unitString = spacePos>0?valueString.substring(spacePos):"";

    let intValueString: string = absoluteValue.split(".")[0];
    let decimalValueString: string = absoluteValue.split(".")[1];
    let format = this.format;
    
    //  [TODO] - Need a proper method to handle the units with seperators. - Handle this with a Space Sign
    

    // let unitmiles = intValueString.indexOf("miles");

    if (intValueString) {
      let count = 1;
      for (let i = intValueString.length - 1; i >= 0; i--) {
        let fm = format.charAt(format.length-1);
        format = format.substr(0, format.length-1);

        if(fm != '#'){
          finalString = fm + finalString;
          format = this.format;
          i++;
        }
        else finalString = intValueString[i] + finalString;

        count++;
      }
    }

    if (decimalValueString) {
      finalString = finalString + "." + decimalValueString;
    }

    return finalString + (unitString?unitString:"");
  }

  // adds custom component to tree cell and calls onComponentInitFunction
  addCustomComponents(){
    setTimeout(()=>{
      if(!this.resolver || !this.vc) return;

      if(this.type == 'custom' || this.type == 'custom-text'){
        let factory = this.resolver.resolveComponentFactory(this.renderComponent);
        if(!factory) return;

        let componentRef = factory.create(this.injector);
        if(!componentRef) return;

        componentRef.instance['rowData'] = this.rowData;
        if(this.onComponentInitFunction) {
          this.onComponentInitFunction(componentRef.instance);
        }

        let view:ViewRef = componentRef.hostView;
        this.vc.insert(view);
      }
    });
  }

  // emits showFilterEvent to notify filter popup event to tree
  popupFilter(event){
    if(!event) return;

    let data = {
      filter:this.filter,
      position:{
        top : event.y,
        left : event.x
      }
    };

    let modalDialog = document.getElementsByClassName('modal-dialog')[0];
    if(modalDialog && modalDialog['offsetTop'] && modalDialog['offsetLeft']){
      data.position.top = data.position.top - (modalDialog['offsetTop'] + 63);
      data.position.left = data.position.left - modalDialog['offsetLeft'];
    }

    this.showFilterEvent.emit(data);
  }

  setToolTipIfTruncated(event){
    if(!event || !event.isTruncated) return;

    setTimeout(()=>{
      this.toolTip= event.toolTip;
    },0);
  }

  deselectOtherRadioButtons(){
    this.tree.deselectAllRadioButtons();
  }

  deselectThisRadio(){
    if (this.type != 'radio' || !this.radioButton || !this.radioButton.nativeElement) return;
    this.radioButton.nativeElement.checked = false;
  }
}
