import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProjectsaddComponent } from './projectsadd/projectsadd.component';
import { ProjectslistComponent } from './projectslist/projectslist.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';
import { NgxSelectModule } from 'ngx-select-ex';
import { ProjecteditComponent } from './projectedit/projectedit.component';
import { ProjectsRoutingModule } from './projects-routing.module';

import { DragToSelectModule } from 'ngx-drag-to-select';
import { ProjectSystemsComponent } from './project-systems/project-systems.component';
import { ProjectPricesComponent } from './project-prices/project-prices.component';
import { SystemUrlComponent } from './components/system-url/system-url.component';
import { ModalModule,BsModalRef} from 'ngx-bootstrap/modal';

import { ProjectSystemSparePartsComponent } from './project-system-spare-parts/project-system-spare-parts.component';
import { AddProjectSystemSparePartsComponent } from './project-system-spare-parts/components/add-project-system-spare-parts/add-project-system-spare-parts.component';
import { EditProjectSystemSparePartsComponent } from './project-system-spare-parts/components/edit-project-system-spare-parts/edit-project-system-spare-parts.component';

import { AddSpecialToolsComponent } from '../special-tools/components/add-special-tools/add-special-tools.component';
import { SpecialToolsComponent } from '../special-tools/special-tools.component';

import { ProjectToolsUrlComponent } from './project-tools-url/project-tools-url.component';
import { ProjectToolsComponent } from './project-tools/project-tools.component';
import { EditSpecialToolsComponent } from '../special-tools/components/edit-special-tools/edit-special-tools.component';

import { ImageViewComponent } from '../special-tools/components/image-view/image-view.component';
import { ProjectExportUrlComponent } from './components/project-export-url/project-export-url.component';
import { ProjectExportModalComponent } from './components/project-export-modal/project-export-modal.component';
import { ProjectSystemSpareUrlComponent } from './components/project-system-spare-url/project-system-spare-url.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { EditProjectSystemsComponent } from './project-systems/components/edit-project-systems/edit-project-systems.component';
import { ProjectSystemEditUrlComponent } from './project-systems/components/project-system-edit-url/project-system-edit-url.component';
import { OperatingProfilesUrlComponent } from './components/operating-profiles-url/operating-profiles-url.component';
import { AddProjectSystemsComponent } from './project-systems/components/add-project-systems/add-project-systems.component';
import { CloneProjectSystemsComponent } from './project-systems/components/clone-project-systems/clone-project-systems.component';
import { SystemsVariationComponent } from './project-systems/components/systems-variation/systems-variation.component';
import { SharedModule } from '../../shared/shared.module';

import { ConfirmationBoxComponent } from "../../shared/components/confirmation-box/confirmation-box.component";
import { CopyButtonUrlComponent } from './project-systems/components/copy-button-url/copy-button-url.component';
import { CopyModalComponent } from './project-systems/components/copy-modal/copy-modal.component';
import { ProjectLogComponent } from './project-log/project-log.component';
import { ProjectLogUrlComponent } from './components/project-log-url/project-log-url.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ViewUrlComponent } from '../../shared/components/common-log/components/view-url/view-url.component';
import { SpecialToolsLogComponent } from '../special-tools/special-tools-log/special-tools-log.component';
import { TreeTableModule } from "../../shared/components/tree-table/tree-table.module";
import { TaskExportLogComponent } from './components/task-export-log/task-export-log.component';
import { ProjectSystemsLogUrlComponent } from './project-systems/components/project-systems-log-url/project-systems-log-url.component';
import { ProjectSystemsLogComponent } from './project-systems/project-systems-log/project-systems-log.component';
import {ProjectSystemsTaskLogUrlComponent} from "../tasks/project-system-task/components/project-systems-task-log-url/project-systems-task-log-url.component";
import {ProjectSystemsTaskLogComponent} from "../tasks/project-system-task/project-systems-task-log/project-systems-task-log.component";
import {PartsImportDataSourcePromptBoxComponent} from "../../shared/components/parts-import-data-source-prompt-box/parts-import-data-source-prompt-box.component";
import { ProjectSystemSparePartsImportUrlComponent } from './project-system-spare-parts/components/project-system-spare-parts-import-url/project-system-spare-parts-import-url.component';
import { ProjectCopyUrlComponent } from './components/project-copy-url/project-copy-url.component';
import { ProjectCopyModalComponent } from './components/project-copy-modal/project-copy-modal.component';
import { DokuVersionComponent } from './components/task-export-log/doku-version/doku-version.component';
import { TaskPartUsagesPartQuantityComponent } from './project-system-spare-parts/components/task-part-usages-part-quantity/task-part-usages-part-quantity.component';
import { ProjectSystemSparePartMoveComponent } from './project-system-spare-parts/components/project-system-spare-part-move/project-system-spare-part-move.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";



@NgModule({
  declarations: [
    ProjectsaddComponent,
    ProjectslistComponent,
    ProjecteditComponent,
    ProjectSystemsComponent,
    ProjectPricesComponent,
    SystemUrlComponent,
    ProjectExportUrlComponent,
    ProjectExportModalComponent,
    ProjectSystemSpareUrlComponent,
    ProjectSystemSparePartsComponent,
    AddProjectSystemSparePartsComponent,
    EditProjectSystemSparePartsComponent,
    SpecialToolsComponent,
    AddSpecialToolsComponent,
    ProjectToolsUrlComponent,
    ProjectToolsComponent,
    EditSpecialToolsComponent,
    ImageViewComponent,
    EditProjectSystemsComponent,
    ProjectSystemEditUrlComponent,
    OperatingProfilesUrlComponent,
    ProjectCopyUrlComponent,
    ProjectCopyModalComponent,
    AddProjectSystemsComponent,
    CloneProjectSystemsComponent,
    SystemsVariationComponent,
    DokuVersionComponent,
    ConfirmationBoxComponent,
    CopyButtonUrlComponent,
    CopyModalComponent,
    ProjectLogComponent,
    ProjectLogUrlComponent,
    ViewUrlComponent,
    SpecialToolsLogComponent,
    TaskExportLogComponent,
    ProjectSystemsLogUrlComponent,
    ProjectSystemsLogComponent,
    ProjectSystemsTaskLogUrlComponent,
    ProjectSystemsTaskLogComponent,
    PartsImportDataSourcePromptBoxComponent,
    ProjectSystemSparePartsImportUrlComponent,
    TaskPartUsagesPartQuantityComponent,
    ProjectSystemSparePartMoveComponent
  ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        ProjectsRoutingModule,
        FormsModule,
        SharedModule,
        NgSelectModule,
        NgxPaginationModule,
        LaddaModule,
        NgxSelectModule,
        DragToSelectModule.forRoot(),
        ModalModule.forRoot(),
        NgbModule,
        TreeTableModule,
        MatProgressSpinnerModule
    ],
  bootstrap: [
    SystemUrlComponent,
    AddProjectSystemSparePartsComponent,
    EditProjectSystemSparePartsComponent,
    AddSpecialToolsComponent,
    ProjectToolsUrlComponent,
    ProjectToolsComponent,
    EditSpecialToolsComponent,
    ProjectSystemSpareUrlComponent,
    ImageViewComponent,
    ProjectExportUrlComponent,
    ProjectExportModalComponent,
    ProjectSystemEditUrlComponent,
    EditProjectSystemsComponent,
    OperatingProfilesUrlComponent,
    ProjectCopyUrlComponent,
    ProjectCopyModalComponent,
    AddProjectSystemsComponent,
    CloneProjectSystemsComponent,
    SystemsVariationComponent,
    DokuVersionComponent,
    ConfirmationBoxComponent,
    ProjectsaddComponent,
    ProjecteditComponent,
    CopyButtonUrlComponent,
    CopyModalComponent,
    ProjectLogUrlComponent,
    ViewUrlComponent,
    ProjectLogComponent,
    SpecialToolsLogComponent,
    ProjectSystemsLogComponent,
    ProjectSystemsTaskLogComponent,
    PartsImportDataSourcePromptBoxComponent
  ],
  providers: [
    BsModalRef
  ]
})
export class ProjectsModule { }
