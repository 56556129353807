import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {Observable} from 'rxjs';
import { faPlus, faMinus} from '@fortawesome/free-solid-svg-icons';
import {TreeCellComponent} from "../tree-cell/tree-cell.component";
import {TreeComponent} from "../tree/tree.component";

@Component({
  selector: 'app-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.scss']
})
export class TreeNodeComponent implements OnInit, AfterViewInit {

  faPlus = faPlus;
  faMinus = faMinus;

  @Input() activeMouseOverRowId = -1;
  @Input() activeMouseOverRowParentId = -1;
  @Input() rowIndex;
  @Input() depth:number = 0;
  @Input() settings;
  @Input() columns;
  @Input() columnType;
  @Input() rowData;
  @Input() hasChild = false;
  @Input() columnWidth:number = 40;
  @Input()  tree:TreeComponent;

  @Output() toggleEvent = new EventEmitter<any>();
  @Output() mouseOverRowChangeEvent = new EventEmitter<any>();

  @ViewChildren(TreeNodeComponent) treeNodes: QueryList<TreeNodeComponent>;
  @ViewChildren(TreeCellComponent) treeCells: QueryList<TreeCellComponent>;

  public parentId:number = -1;
  public children:[];
  public expanded:boolean = false;

  constructor(private el:ElementRef) {
  }

  ngOnInit(): void {
    if(!this.columns) this.columns = this.settings['columns'];
  }

  ngAfterViewInit(): void {
  }

  public treeNodeExpanded(){
    if(!this.settings || !this.settings.childrenFetchFunc) return;

    let fetchChildrenObservable:Observable<any> = this.settings.childrenFetchFunc(this.rowData);
    if(!fetchChildrenObservable) return;

    fetchChildrenObservable.subscribe((data)=>{
      this.children = data;
      this.expanded = true;

      this.toggleEvent.emit({
        parent: this.rowData,
        id : this.rowData[this.settings.id],
        children : this.children,
        expanded : this.expanded,
      });

      setTimeout(()=>{
        for(let childNode of this.treeNodes){
          childNode.parentId =  this.rowData[this.settings.id];
        }
      },0)
    },(error => {
      console.log(error)
    }));
  }

  public treeNodeCollapsed(){
    this.expanded = false;
    this.toggleEvent.emit({
      parent: this.rowData,
      id : this.rowData[this.settings.id],
      children : this.children,
      expanded : this.expanded,
    });
  }

  deselectAllRadioButtons(){
    for(let i=0; i<this.treeCells.length; i++){
      let treeCell:TreeCellComponent = this.treeCells.get(i);
      treeCell.deselectThisRadio();
    }
    for(let j=0; j<this.treeNodes.length; j++){
      let treeNode:TreeNodeComponent = this.treeNodes.get(j);
      treeNode.deselectAllRadioButtons();
    }
  }

  public emitToggleEvent(res){
    this.toggleEvent.emit(res);
  }

  public emitMouseOver(activeMouseOverRowId, activeMouseOverRowParentId){
    this.mouseOverRowChangeEvent.emit({activeMouseOverRowId:activeMouseOverRowId, activeMouseOverRowParentId:activeMouseOverRowParentId});
  }
}
