<div class="container-fluid">

  <div class="row">
    <div class="pageTitle col-sm-6">
      <span>User Authorization </span> &nbsp;
    </div>
    <!-- <div class=" pageTitle col-sm-6 text-right">
      <button type="button" [routerLink]="previousUrl" routerLinkActive="router-link-active"  class="btn btn-primary btn-sm"><fa-icon [icon]="faBackward"></fa-icon> &nbsp;&nbsp;Back</button>
    </div> -->
  </div>


  <div class="row">
    &nbsp;
  </div>





  <div class="row">
   <div class="col-md-12">
     <div class="row justify-content-center">
       <div class="col-md-8">

        <div class="row">
          <div class="col-md-2">
            <label>Select Category</label>
          </div>
          <div class="col-md-4">
            <ngx-select disabled="true" [formControl]="lstSelectedCategory" (select)="onChangeCategory($event)" [items]="roleCategories" optionTextField="actionCategory" [placeholder]="'Select Category'" optionValueField="actionCategory"></ngx-select>
          </div>
        </div>

       </div>
     </div>
   </div>
  </div>
  &nbsp;
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-center">
        <div class="col-md-8">
 
         <div class="row">
           <div class="col-md-2">
             <label>Select Role</label>
           </div>
           <div class="col-md-4">
             <ngx-select id="lstSelectedRole" (select)="onChangeRole($event)" [items]="userRoles" optionTextField="systemRole" [placeholder]="'Select Role'" optionValueField="systemRoleId" appInfoPromptBox [page]="'authorization'" [component]="'addEdit'" [field]="'role'"></ngx-select>
           </div>
         </div>
         
        </div>
      </div>
    </div>
   </div>

  <div class="row mt-4">
    <div class="col-md-12">
      <div class="row justify-content-center">
        <div class="col-md-8">
         <div class="row">
           <div class="col-md-12">

             <table class="table table-bordered table-sm">
               <thead>
                 <tr>
                   <th>Action Name</th>

                   <th class="text-center">Action <input [(ngModel)]="bulkSelected" [disabled]="formInvalid" (change)="selectbulkItems($event)" class="" type="checkbox"/></th>
                 </tr>
               </thead>
               <tbody >
                 <tr *ngFor="let authData of authorization | keyvalue | paginate: { itemsPerPage: 10, currentPage: p }">
                   <td>{{authData.value.actionName}}</td>
                   <!-- <td>  <input value="{{stSystem.key}}" (change)="onselectItem($event)" checked="{{stSystem.value.selected}}" class="" type="checkbox"/></td> -->
                   <td class="text-center">
                     <input style="cursor: pointer;" *ngIf="formInvalid==false" [disabled]="formInvalid" (change)="onSelectItem($event,authData.key)" value="{{authData.key}}" checked="{{authData.value.selected}}" class="" type="checkbox"/>
                     <label style="color:blue" *ngIf="formInvalid && authData.value.selected">Granted</label>
                     <label style="color:gray" *ngIf="formInvalid && authData.value.selected==false">Not Granted</label>
                    </td>
                 </tr>
               </tbody>
             </table>

           </div>

         </div>
         <div class="row">
          <div class="col-md-10">
            <label style="color:red" *ngIf="formInvalid">Please select the Category as 'ALL' and the respective user role to be able to save the authorizations</label>
          </div>
           <div class="col-md-2 text-right">
             <button [disabled]="loadingBtn || formInvalid" (click)="updateAuthorization()" class="btn btn-primary" type="button">
              Save &nbsp;
              <span *ngIf="loadingBtn" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            </button> &nbsp;
             <button type="button" (click)="resetAuth()" class="btn btn-warning">Reset</button>
           </div>
         </div>
        </div>
      </div>
    </div>
   </div>

   <div class="row mt-4 mb-4">
    <div class="col-md-12">
      <div class="row justify-content-center">
        <div class="col-md-4">
         <div class="row">
           <div class="col-md-12">
        
            <pagination-controls (pageChange)="p = $event"></pagination-controls>

           </div>

         </div>
        </div>
      </div>
    </div>
   </div>





</div> <!-- wrapper -->
