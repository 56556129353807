import {Component, EventEmitter, OnInit} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import {UserRoleService} from "../../../../../services/user-role.service";
import {UserGroupService} from "../../../../../services/user-group.service";

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  roleData:any[] = [];
  userGroupData:any[] = [];

  event: EventEmitter<any> = new EventEmitter();
  ismatch:Boolean = true;
  loadingBtn : Boolean = false;
  disableButton:Boolean = false;
  userAddFrom = new UntypedFormGroup({
    userEmail: new UntypedFormControl(''),
    firstName: new UntypedFormControl(''),
    lastName: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
    confirm: new UntypedFormControl(''),
    primaryRole: new UntypedFormControl(''),
    userGroupId: new UntypedFormControl(''),
    active: new UntypedFormControl(''),
  });
  constructor(
      public bsModalRef : BsModalRef,
      private userService : UserService,
      private notification : ToastrService,
      private userRoleService: UserRoleService,
      private userGroupService: UserGroupService,
  ) { 
    this.getUserRoleData();
    this.getUserGroupData();

  }

  ngOnInit(): void {
    this.userAddFrom.patchValue({
      userEmail : null,
      firstName : null,
      lastName : null,
      password : null,
      confirm : null,
      active : 0
    });
  }

  getUserRoleData(){
    this.userRoleService.getAll().subscribe((res:any[])=>{
      if(res) this.roleData = res;
    }, (error => {
      console.log(error);
    }))
  }

  getUserGroupData(){
    this.userGroupService.getAll().subscribe((res:any[])=>{
      if(res) this.userGroupData = res;
    }, (error => {
      console.log(error);
    }))
  }

  createNewUser(){
    let formData = this.userAddFrom.getRawValue();
    this.loadingBtn = true;
    this.userService.create(formData).subscribe(res=>{
      if(res){
        this.notification.success('User has been Created','success');
        this.bsModalRef.hide();
        this.event.emit()
      }else{
        this.notification.error('User has not been created','Error');
      }
      this.loadingBtn = false;
    },error=>{
      if(error.status==412){
        this.notification.error('This user already exist');
      }
      else {
        this.notification.error('User has not been created','Error');
      }
      this.loadingBtn = false;
    });
  }

  isValidPassword(){
    let formData = this.userAddFrom.getRawValue();
    if(formData['password'] != formData['confirm']){
      this.ismatch = false;
      this.disableButton = true;
    }else{
      this.ismatch = true;
      this.disableButton = false;
    }
  }

}
