import {Component, EventEmitter, OnInit} from '@angular/core';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { PowerunitService } from 'src/app/services/powerunit.service';
import { ElectrificationSystemService } from 'src/app/services/electrification-system.service';
import { ProjectService } from 'src/app/services/project.service';
import { ToastrService } from 'ngx-toastr';
import { projectStatus, validateForm } from 'src/app/shared/helper';
import {LanguageSupportComponent} from '../../../shared/components/language-support/language-support-component.service';
import { LanguageServiceService } from '../../../services/language-service.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { UserRoleService } from 'src/app/services/user-role.service';
import {UserGroupService} from "src/app/services/user-group.service";

@Component({
  selector: 'app-projectedit',
  templateUrl: './projectedit.component.html',
  styleUrls: ['./projectedit.component.scss']
})
export class ProjecteditComponent extends LanguageSupportComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();
  updatedProject:any;
  param:any;
  submitLoading = false;
  faAngleRight = faAngleRight;
  faAngleDoubleRight = faAngleDoubleRight;
  faAngleLeft = faAngleLeft;
  hidesystemArea:Boolean=false;
  faAngleDoubleLeft = faAngleDoubleLeft;
  routerState:any;
  powerUnit:any = [];
  
  electrification:any = [];
  electrificationData:any = [];
  
  projectId:any;
  projectStatusData:any = projectStatus();
  projectSystemData: any = [];
  specificEmployeeQualificationData:any = [];
  initialSpecificEmployeeQualification:any = -1;
  showSpecificEmployeeQualificationWarning = false;
  toolCategoriesData = [];
  operatingMaterialsData = [];
  partsData = [];
  initialFreezeValue = "inactivated";
  roleData:any [] = [];
  userGroupData:any[] = [];
  freezeFunctionData = [
    {
      id:'activated',
      str:'Activated',
    },
    {
      id:'inactivated',
      str:'Inactivated',
    }
  ];

  

  userGroupDataLoading:boolean = true;
  projectElectrificationDataLoading:boolean = true;
  powerUnitDataLoading:boolean = true;
  electrificationDataLoading:boolean = true;
  // userRoleDataLoading:boolean = true;

  projectEditForm = new UntypedFormGroup({
    projectNumber : new UntypedFormControl(''),
    projectDesignation : new UntypedFormControl('',[Validators.required, Validators.maxLength(100)]),
    designation : new UntypedFormControl(''),
    parentProject : new UntypedFormControl(''),
    powerUnit : new UntypedFormControl(''),
    electrification : new UntypedFormControl(''),
    projectElectrification : new UntypedFormControl(0),
    carsCount : new UntypedFormControl('',[Validators.required, Validators.max(999)]),
    vehicleQuantity : new UntypedFormControl('',[Validators.required, Validators.max(999)]),
  
    projectStatus : new UntypedFormControl(0),
    specificEmployeeQualification: new UntypedFormControl(0),
    toolCategories: new UntypedFormControl(''),
    operatingMaterials: new UntypedFormControl(''),
    parts: new UntypedFormControl(''),
    freezeFunction: new UntypedFormControl('inactivated'),
    workingLanguage: new UntypedFormControl(),
    exportLanguage: new UntypedFormControl(),
    projectAdmins: new UntypedFormControl(),

  });
  
  

  constructor(
      private router: Router,
      private powerUnitService: PowerunitService,
      private electrificationService: ElectrificationSystemService,
      private projectService: ProjectService,
      private activatedroute:ActivatedRoute,
      private notification: ToastrService,
      public languageService: LanguageServiceService,
      public bsModalRef: BsModalRef,
      public modalService: BsModalService,
      private userRoleService: UserRoleService,
      private userGroupService: UserGroupService,
  ) {
    super(languageService, modalService);
  }

  ngOnInit(): void {
    // this.getUserRoleData();
    this.getUserGroupData();
    this.initFormValues();

  }

  public currentFormValues()
  {
    return JSON.stringify(this.projectEditForm.getRawValue());
  }

  async initFormValues(){
    this.projectId = this.param.projectNumber;
    this.languages = this.languageService.allLanguages();
    this.specificEmployeeQualificationData = [{id:0, text:"Inactivated"}, {id:1, text:"Activated"}];

    await this.loadDataAync();

    validateForm(this.projectEditForm);
  }

  loadDataAync(){
    this.loadPowerUnit();
    this.loadElectrification();
    this.loadProjectData(this.projectId);
    this.getToolCategoriesData();
    this.getOperatingMaterialsData();
    this.getPartsData();
  }

  /**
   * Load Project Electrification
   */
  getProjectElectrification(){
    this.electrificationData = [];
    this.electrificationService.getElectrificationByProjects(this.projectId).subscribe(res=>{
      Object.entries(res).forEach(([key, value]) => {
        this.electrificationData.push(value['electrificationSystemId']);
      });
      this.projectEditForm.patchValue({
        electrification: this.electrificationData
      });
      super.saveInitialValues(JSON.stringify(this.projectEditForm.getRawValue()));
      this.projectElectrificationDataLoading = false; 
    });
  }

  languageChanged(){
    super.languageChanged(JSON.stringify(this.projectEditForm.getRawValue()));
  }

  /**
   * Submit Project Edit Form
   */

  onSubmit(){
    if(this.projectEditForm.valid){
      this.submitLoading = true;
      let electrifiactionArray = this.projectEditForm.value.electrification;
      let newElectrification = [];
      electrifiactionArray.forEach((value,index)=>{
        newElectrification.push({
          electrificationSystemId:value
        });
      });

      this.projectEditForm.patchValue({projectElectrification : newElectrification});
      delete this.projectEditForm.value.electrification;
      this.projectService.updateProject(this.projectEditForm.getRawValue(),this.projectId).subscribe(res=>{
        if(res['projectNumber']){
          this.notification.success('Project has been Updated', 'Success');
          this.updatedProject = res;
          this.submitLoading=false;
        }
        else{
          this.notification.error('Project has not been Updated', 'Error');
          this.updatedProject = null;
        }
      }, (error => {
        console.log(error);
        if(error?.error?.details){
          this.notification.error(error?.error?.details, 'Error');
        }else{
          this.notification.error("Project has not been Updated", 'Error');
        }

        this.updatedProject = null;
      }), ()=>{
        this.bsModalRef.hide();
        this.event.emit();
      });
    }
    else{
      validateForm(this.projectEditForm);
    }
  }

  /**
   * Load Power Unit Data
   */
  loadPowerUnit(){
    this.powerUnitService.getPowerUnit().subscribe(res=>{
      this.powerUnit = res;
      
      this.powerUnitDataLoading = false; 
      
    });
  }

  /**
   * Load Electrification Systems
   */
  loadElectrification(){
    this.electrificationService.getElectrificationSystems().subscribe(res=>{
      this.electrification = res;
      this.electrificationDataLoading = false;
    });
  }

  /**
   * Get Project Data by Project number and Load data to Form
   * @param projectNumber
   */
  loadProjectData(projectNumber){
    this.projectService.getProjectById(projectNumber).subscribe(res=>{

      if(!res) return;

      this.initialFreezeValue = res['freezeFunction'] ? res['freezeFunction'] : 'inactivated';

      this.projectEditForm = new UntypedFormGroup({
        projectNumber : new UntypedFormControl(projectNumber),
        projectDesignation : new UntypedFormControl(res['projectDesignation'],[Validators.required, Validators.maxLength(100)]),
        parentProject : new UntypedFormControl(0),
        powerUnit : new UntypedFormControl(res['powerUnit']),
        electrification : new UntypedFormControl(res['electrification']),
        projectElectrification : new UntypedFormControl([]),
        carsCount : new UntypedFormControl(res['carsCount'] != null ? res['carsCount'].toString() : null,[Validators.required,Validators.pattern("^[0-9]*$")]),
        vehicleQuantity : new UntypedFormControl(res['vehicleQuantity'] != null ? res['vehicleQuantity'].toString() : null,[Validators.required,Validators.pattern("^[0-9]*$")]),
        projectStatus : new UntypedFormControl(0),
        specificEmployeeQualification : new UntypedFormControl(res['specificEmployeeQualification'] != null ? res['specificEmployeeQualification'] : 0),
        toolCategories: new UntypedFormControl(res['toolCategories']),
        operatingMaterials: new UntypedFormControl(res['operatingMaterials']),
        parts: new UntypedFormControl(res['parts']),
        freezeFunction: new UntypedFormControl(res['freezeFunction'] ? res['freezeFunction'] : 'inactivated'),
        workingLanguage: new UntypedFormControl(res['workLangId']),
        exportLanguage: new UntypedFormControl(res['exportLangId']),
        projectAdmins : new UntypedFormControl([]),
      });

      this.initialSpecificEmployeeQualification = res['specificEmployeeQualification'] != null ? res['specificEmployeeQualification'] : 0 ;
      
      this.getProjectElectrification();
      this.getProjectAdmins();
    });
  }



  specificEmployeeQualificationChanged(){
    let prevValue = this.initialSpecificEmployeeQualification;
    let currentValue = this.projectEditForm.controls['specificEmployeeQualification'].value;

    this.showSpecificEmployeeQualificationWarning = prevValue != currentValue;
  }

  getToolCategoriesData(){
    this.toolCategoriesData = [{id:'none', str:'None'}, {id:'special', str:'Special'}, {id:'standard', str:'Standard'}, {id:'all', str:'All'}];
  }

  getOperatingMaterialsData(){
    this.operatingMaterialsData = [{id:'none', str:'None'},  {id:'all', str:'All'}];
  }

  getPartsData(){
    this.partsData = [{id:'none', str:'None'},  {id:'all', str:'All'}];
  }

  callAfterLanguageChanged() {
    this.initFormValues();
  }

  getUserRoleData(){
    this.userRoleService.getAll().subscribe((res:any[])=>{
      if(res) this.roleData = res;
      // this.userRoleDataLoading = false; 
    }, (error => {
      console.log(error);
      // this.userRoleDataLoading = false;
    }))
  }

  getUserGroupData(){
    this.userGroupService.getAll().subscribe((res:any[])=>{
      if(res) this.userGroupData = res;
      this.userGroupDataLoading = false; 
    }, (error => {
      console.log(error);
      this.userGroupDataLoading = false; 
    }))
  }

  getProjectAdmins(){
    let tempProjectAdmins = [];
    this.projectService.getProjectAdmins(this.projectId).subscribe(res=>{
      Object.entries(res).forEach(([key, value]) => {
        tempProjectAdmins.push(value['userGroupId']);
      });
      this.projectEditForm.patchValue({
        projectAdmins: tempProjectAdmins
      });
      super.saveInitialValues(JSON.stringify(this.projectEditForm.getRawValue()));
    });
  }


}
