import { HttpClient } from '@angular/common/http';
import {  Directive, 
          EventEmitter, 
          HostListener, 
          Input, 
          OnDestroy, 
          OnInit, 
          Output } from '@angular/core';
import { debounceTime } from 'rxjs/operators';

import { Subject, Subscription } from 'rxjs';

@Directive({
  selector: '[appPreventDoubleSubmit]'
})
export class PreventDoubleSubmitDirective implements OnInit, OnDestroy { 

  // appPreventDoubleSubmit (debounceClick)="log()" [debounceTime]="700"
  @Input() 
  debounceTime = 500;

  @Output() 
  debounceClick = new EventEmitter();
  
  private clicks = new Subject();
  private subscription: Subscription;

  constructor() { }

  ngOnInit() {
    this.subscription = this.clicks.pipe(
      debounceTime(this.debounceTime)
    ).subscribe(e => this.debounceClick.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
   
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

}
