import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faPlus, faSync, faSearch, faEdit, faAngleRight, faBackward, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { ProjectSystemSparePartsService } from 'src/app/services/project-system-spare-parts.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddProjectSystemSparePartsComponent } from './components/add-project-system-spare-parts/add-project-system-spare-parts.component';
import { EditProjectSystemSparePartsComponent } from './components/edit-project-system-spare-parts/edit-project-system-spare-parts.component';
import { StandardSystemsService } from 'src/app/services/standard-systems.service';
import { RouterService } from 'src/app/services/router.service';
import { ProjectService } from 'src/app/services/project.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationBoxComponent } from '../../../shared/components/confirmation-box/confirmation-box.component';
import { ProjectSystemsService } from '../../../services/project-systems.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BreadcrumbServiceService } from '../../../services/breadcrumb-service.service';
import { consoleTestResultHandler } from "tslint/lib/test";
import { CopyButtonUrlComponent } from "../project-systems/components/copy-button-url/copy-button-url.component";
import { LanguageServiceService } from "../../../services/language-service.service";
import * as FileSaver from 'file-saver';
import { ProjectSystemSparePartsImportUrlComponent } from "./components/project-system-spare-parts-import-url/project-system-spare-parts-import-url.component";
import { TreeTableComponent } from "../../../shared/components/tree-table/pages/tree-table/tree-table.component";
import { SystemsVariationComponent } from "../project-systems/components/systems-variation/systems-variation.component";
import { TaskPartUsagesPartQuantityComponent } from "./components/task-part-usages-part-quantity/task-part-usages-part-quantity.component";
import { ProjectSystemSparePartMoveComponent } from "./components/project-system-spare-part-move/project-system-spare-part-move.component";
import { CommonErrorModalComponent } from 'src/app/shared/components/common-error-modal/common-error-modal.component';

@Component({
  selector: 'app-project-system-spare-parts',
  templateUrl: './project-system-spare-parts.component.html',
  styleUrls: ['./project-system-spare-parts.component.scss']
})
export class ProjectSystemSparePartsComponent implements OnInit {

  faAngleRight = faAngleRight;
  projectNumber: any;
  systemNumber: any;
  projectSystem: any = [];
  projectDesignation: any = "";
  systemName: string = "";
  systemCode: string = "";
  infoStatus = false;
  loading = false;
  laddaLoading = false;
  faPlus = faPlus;
  faFileExport = faFileExport;
  faBackward = faBackward;
  previousUrl: any;
  tableData: any;
  projectFreezeFunction = "";
  currentPath = "";
  childPartsUtilizationList: any = [];

  @ViewChild(TreeTableComponent) treeTable: TreeTableComponent;

  fetchChildren = (row) => {
    let parentId = row['spareNumber'];
    return this.projectSystemSparePartService.getProjectSystemSparePartsByParent(this.projectNumber, this.systemNumber, parentId);
  };

  addAction = (row) => {
    this.loadAddModal(row);
  };

  moveParts = (row) => {
    this.loadMoveModal(row);
  }

  importAction = (row) => {
  };

  editAction = (row) => {
    this.loadUpdateModal(row);
  };

  deleteAction = (row) => {
    this.deleteSystemSpareParts(row);
  };

  hideDelete = (row) => {
    return row['parentId'] == -1;
  };

  spinnerInfo = { spinnerVisible: false, spinnerText: "" };

  settings = {
    type: 'tree',
    id: 'spareNumber',
    childrenFetchFunc: this.fetchChildren,
    tableClass: '',
    tableHeaderClass: '',
    tableRowClass: '',
    columnManagerEnable: true,
    freezeColumns: {
      enabled: true,
      left: 3,
      right: 5
    },
    enableZoom: true,
    columns: [
      {
        title: 'Stadler Article ID',
        attribute: 'spareStadlerArticleId',
        type: 'text',
        width: 250,
      },
      {
        title: 'Supplier Article ID',
        attribute: 'spareSupplierArticleId',
        type: 'text',
        width: 150,
      },
      {
        title: 'Part Designation',
        attribute: 'spareDesignation',
        type: 'text',
        width: 200
      },
      {
        title: 'Quantity',
        attribute: 'quantity',
        type: 'text',
        format: "'###",
        width: 40,
      },
      {
        title: 'Quantity on System Level',
        attribute: 'quantityOnSystemLevel',
        type: 'text',
        format: "'###",
        width: 80,
      },
      {
        title: 'Quantity on Vehicle Level',
        attribute: 'quantityOnVehicleLevel',
        type: 'text',
        format: "'###",
        width: 80
      },
      {
        title: 'Unit',
        attribute: 'spareUnitDesignation',
        type: 'text',
        width: 70,
      },
      {
        title: 'Additional Designation',
        attribute: 'additionalDesignation',
        type: 'text',
        width: 250
      },
      {
        renderComponent: '<a class="btn btn-info btn-sm  btn-url"><img title="Move" class="edit-icon" src="/assets/icon/moveitem.png"/></a>',
        type: 'html',
        actionFunction: this.moveParts,
        width: 30
      },
      {
        renderComponent: '<a class="btn btn-info btn-sm text-light btn-url"><img title="Add" class="edit-icon" src="/assets/icon/addchildren.png"/></a>',
        type: 'html',
        actionFunction: this.addAction,
        width: 30
      },
      {
        renderComponent: ProjectSystemSparePartsImportUrlComponent,
        hideFunction: (row) => { return row.hasChild },
        onComponentInitFunction: (instance) => {
          instance.spinnerInfo = this.spinnerInfo;
          instance.event.subscribe((res) => {
            if (res == 'refresh') this.getProjectSystemSpareParts();
          })
        },
        type: 'custom',
        width: 30
      },
      {
        renderComponent: '<a class="btn btn-info btn-sm text-light btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/edit.png"/></a>',
        type: 'html',
        actionFunction: this.editAction,
        width: 30
      },
      {
        renderComponent: '<a class="btn btn-danger btn-sm text-light btn-url"><img title="Delete" class="delete-icon" src="/assets/icon/delete.png"/></a>',
        type: 'html',
        actionFunction: this.deleteAction,
        width: 50,
        hideFunction: this.hideDelete
      },
    ]
  };

  constructor(
    private activatedroute: ActivatedRoute,
    private projectSystemSparePartService: ProjectSystemSparePartsService,
    private modalService: BsModalService,
    private projectSystemsService: ProjectSystemsService,
    private routerService: RouterService,
    private projectService: ProjectService,
    private notificationService: ToastrService,
    public breadcrumbService: BreadcrumbServiceService,
    private languageService: LanguageServiceService,
    private projectSystemSparePartsService: ProjectSystemSparePartsService
  ) {
    this.previousUrl = this.routerService.getPreviousUrl();
  }

  ngOnInit(): void {
    this.projectNumber = this.activatedroute.snapshot.paramMap.get("projectNumber");
    this.systemNumber = this.activatedroute.snapshot.paramMap.get("systemNumber");

    this.getProjectDataByID(this.projectNumber);

    if (this.breadcrumbService.isStackEmpty()) {
      this.breadcrumbService.pushPage(2, "Parts", "/projects/" + this.projectNumber + "/systems/" + this.systemNumber + "/spare-parts");
    }
  }

  /**
   * Get ProjectSystem by Project number and system number
   * @param projectNumber
   * @param systemNumber
   */
  getProjectSystemByID(projectNumber, systemNumber) {
    this.projectSystemsService.getProjectSystem(projectNumber, systemNumber).subscribe(res => {
      this.projectSystem = res;
      if (res) {
        let sysVariation = res['systemVariation'];
        let sysDesignation = this.languageService.getCurrentLanguage().id == 0 ? res['systemDesignationEn'] : res['systemDesignationDe'];
        this.systemName = (sysVariation != null && sysVariation != sysDesignation) ? sysDesignation + " - " + sysVariation : sysDesignation;
        this.systemCode = "" + res['systemCode'] + '-' + res['systemVariationNumber'];
      }
    });
  }

  /**
   * Delete system spare parts
   * @param event
   */
  deleteSystemSpareParts(row) {
    let path = row.path;
    const initialState = {
      title: 'Are you sure?',
      text: 'Consider that all children of this article will be removed from system parts list',
      okBtnName: 'Yes',
      closeBtnName: 'No',
      isCommentVisible: false
    };
    let modalRef = this.modalService.show(ConfirmationBoxComponent, { class: 'modal-md', initialState, ignoreBackdropClick: true });
    modalRef.content.event.subscribe(res => {
      if (res?.result?.action == 'ok') {

        this.childPartsUtilizationList = [];
        this.projectSystemSparePartService.getChildPartsUtilization(row.spareNumber).subscribe((res) => {
          if (res) {
            console.log(res);
            this.childPartsUtilizationList = res;
          }
        }, (error => { }), () => {

          if (this.childPartsUtilizationList.length > 0) {
            // Can NOT be deleted as there are utilization available
            const initialState = {
              title: 'Error occurred',
              text: '<p>This part or a child part(s) is being utilised in tasks, hence cannot be deleted. <br/><br/>Please remove the utilisation first.</p>',
              buttonText: 'Cancel',
              tableContent: this.childPartsUtilizationList
            };
            let modalRef = this.modalService.show(CommonErrorModalComponent, { class: 'modal-lg', initialState, ignoreBackdropClick: true });
            modalRef.content.event.subscribe(res => {
              modalRef.hide();
            });
          } else {
            // Can be deleted as no utilization is there
            const initialState = {
              title: 'Are you really sure??',
              text: 'Also the children that are used in a task will be removed',
              okBtnName: 'Yes',
              closeBtnName: 'No',
              isCommentVisible: this.projectFreezeFunction == 'activated'
            };
            let modalRef = this.modalService.show(ConfirmationBoxComponent, { class: 'modal-md', initialState, ignoreBackdropClick: true });
            modalRef.content.event.subscribe(res => {
              if (res?.result?.action == 'ok') {
                let projectNumber = row.projectNumber;
                let projectSystemId = row.projectSystemId;
                let spareNumber = row.spareNumber;
                this.projectSystemSparePartService.delete(res?.result, projectNumber, projectSystemId, spareNumber).subscribe((res) => {
                  this.notificationService.success('The Spare Part has been deleted', 'success');
                }, (error) => {
                  if (error.status == 412) {
                    this.notificationService.error('The Spare Part you tried to delete is in use, hence cannot be deleted', 'Error');
                  } else if (error.status == 403) {
                    this.notificationService.error('You need to be in the Project Admins Group to perform the current Action', 'Error');
                  }
                  else {
                    this.notificationService.error('Error while deleting the Spare Part', 'Error');
                  }
                }, () => {
                  this.getProjectSystemSpareParts(-1, path);
                });
              }
              else; // do nothing
            });
          }
        });

      }
      else; // do nothing
    });
  }

  loadMoveModal(row) {
    let path = row.path;
    const initialState = {
      param: {
        projectNumber: this.projectNumber,
        systemNumber: this.systemNumber,
        rowData: row
      },
      projectFreezeFunction: this.projectFreezeFunction
    };
    let modalRef = this.modalService.show(ProjectSystemSparePartMoveComponent, { class: 'modal-lg', initialState, keyboard: false, ignoreBackdropClick: true });
    modalRef.content.event.subscribe(res => {
      this.getProjectSystemSpareParts(-1, path);
    });
  }

  loadUpdateModal(row) {
    let path = row.path;
    const initialState = {
      param: {
        projectNumber: this.projectNumber,
        systemNumber: this.systemNumber,
        rowData: row
      },
      projectFreezeFunction: this.projectFreezeFunction
    };
    let modalRef = this.modalService.show(EditProjectSystemSparePartsComponent, { class: 'modal-xl', initialState, keyboard: false, ignoreBackdropClick: true });
    modalRef.content.event.subscribe(res => {
      this.getProjectSystemSpareParts(-1, path);
    });
  }

  loadAddModal(row) {
    let path = row.path;
    const initialState = {
      param: {
        projectNumber: this.projectNumber,
        systemNumber: this.systemNumber,
        rowData: row
      },
      projectFreezeFunction: this.projectFreezeFunction
    };
    let modalRef = this.modalService.show(AddProjectSystemSparePartsComponent, { class: 'modal-xl', initialState, keyboard: false, ignoreBackdropClick: true });
    modalRef.content.event.subscribe(res => {
      this.getProjectSystemSpareParts(-1, path);
    });
  }

  /**
   * Get All project system spare parts
   */
  getProjectSystemSpareParts(parentId = -1, path = null) {
    this.loading = true;
    this.projectSystemSparePartService.getProjectSystemSparePartsByParent(this.projectNumber, this.systemNumber, parentId).subscribe(res => {
      this.tableData = res;
      this.loading = false;
      if (path) {
        setTimeout(() => {
          this.treeTable.expandPath(path);
        }, 0)
      }
    });
  }
  /**
   *
   * @param projectNumber
   */
  getProjectDataByID(projectNumber) {
    this.projectService.getProjectById(projectNumber).subscribe(res => {
      this.projectFreezeFunction = res['freezeFunction'];
      this.infoStatus = true;
      let lan = {
        id: res['workLangId'],
        str: res['workLangId'] == 1 ? "German" : "English"
      }
      this.languageService.setWorkingLanguage(lan);

      this.getProjectSystemSpareParts();
      this.getProjectSystemByID(this.projectNumber, this.systemNumber);
      this.getProjectDataByProjectID(this.projectNumber);
    });
  }

  getProjectDataByProjectID(projectNumber) {
    this.projectService.getProjectById(projectNumber).subscribe(res => {
      if (res) this.projectDesignation = res['projectDesignation'];
    });
  }

  public exportProjectSystemParts() {

    let data = Object();

    this.projectSystemSparePartsService.exportParts(data, this.projectNumber, this.systemNumber).subscribe(res => {
      const blob = new Blob([res], { type: 'application/octet-stream' });
      const file = new File([blob], 'ProjectParts.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(file);
    }, (error => {
      if (error.status == 403) {
        this.notificationService.error('You need to be in the Project Admins Group to perform the current Action', 'Error');
      } else {
        this.notificationService.error('Project System Parts has not been exported', 'Error');
      }
    }));
  }
}
