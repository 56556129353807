import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';
import { NgxSelectModule } from 'ngx-select-ex';
import { ModalModule,BsModalRef} from 'ngx-bootstrap/modal';
import { OperatingMaterialsRoutingModule } from './operating-materials-routing.module';
import { OperatingMaterialsComponent } from './operating-materials/operating-materials.component';
import { AddOperatingMaterialsComponent } from './components/add-operating-materials/add-operating-materials.component';
import { EditOperatingMaterialsComponent } from './components/edit-operating-materials/edit-operating-materials.component';
import { SharedModule } from '../../shared/shared.module';
import { OperatingMaterialsLogComponent } from './operating-materials-log/operating-materials-log.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {TreeTableModule} from "../../shared/components/tree-table/tree-table.module";


@NgModule({
  declarations: [OperatingMaterialsComponent, AddOperatingMaterialsComponent, EditOperatingMaterialsComponent, OperatingMaterialsLogComponent],
    imports: [
        CommonModule,
        OperatingMaterialsRoutingModule,
        NgSelectModule, FontAwesomeModule,
        ReactiveFormsModule,
        FormsModule,
        LaddaModule,
        NgxSelectModule,
        SharedModule,
        NgbModule,
        ModalModule.forRoot(),
        TreeTableModule
    ],
  bootstrap:[
    AddOperatingMaterialsComponent,
    EditOperatingMaterialsComponent,
    OperatingMaterialsLogComponent
  ]
})
export class OperatingMaterialsModule { }
