import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProjectExportLogService } from 'src/app/services/projectExportLog.service';
import { cleanseSpaces } from 'src/app/shared/helper';

@Component({
  selector: 'app-doku-version',
  templateUrl: './doku-version.component.html',
  styleUrls: ['./doku-version.component.scss']
})
export class DokuVersionComponent implements OnInit {

  rowData:any;
  dokuVersion:any;
  dokuVersionDisabled:Boolean = true;
  logId:any;
  invalidDokuVersion:Boolean=false;
  projectNumber:any;


  constructor(
      private exportLogService: ProjectExportLogService,
      private notification: ToastrService,
  ) { }

  ngOnInit(): void {
    this.dokuVersion = this.rowData.dokuVersion;
    this.projectNumber = this.rowData.projectNumber;
    this.logId = this.rowData.id;
  }

  /**
   * Set variation as readble
   */
  setDokuVersionReadble(){
    this.dokuVersionDisabled=false;
  }

  updateVariation(){

    this.dokuVersion = cleanseSpaces(this.dokuVersion);
    // console.log("Cleansed System Variation : " + this.systemVariation);

    let data ={
      logId: this.logId,
      dokuVersion: this.dokuVersion
    };

    if(this.dokuVersion==''){
      this.invalidDokuVersion=true;
    }else{
      this.dokuVersionDisabled=true;
      this.invalidDokuVersion=false;

      this.exportLogService.updateDokuVersion(this.projectNumber, this.logId, this.dokuVersion).subscribe(res=>{
        if(res){
          this.notification.success('Doku Version has been updated','success');
        }else{
          this.notification.error('Doku Version has not been updated','Error');
        }
      });
    }

  }

}
