

<div class="modal-header">
  <h5 class="modal-title pull-left">Task Interval</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

  <div class="modal-body">

    <!-- <div class="form-group row pr-4 pl-4">
      <label for="inputPassword3" class="col-sm-3 col-form-label col-form-label-sm">Select System Parts</label>
      <div class="col-sm-5">
        <ngx-select></ngx-select>

      </div>
    </div> -->
   <form [formGroup]="taskIntervalForm" (ngSubmit)="submitTaskInterval()"  class="pr-4 pl-4">
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">ProjectNumber</label>
      <div class="col-sm-6">

        <input type="text" class="form-control" formControlName="projectNumber" readonly placeholder="">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Task Name</label>
      <div class="col-sm-6">

        <input type="text" formControlName="taskName" class="form-control" readonly placeholder="">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Interval Unit</label>
      <div class="col-sm-6">

        <ngx-select [items]="taskIntervalUnits" formControlName="intervalUnit" optionValueText="name" optionValueId="id"></ngx-select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label text-right col-form-label-sm">Amount</label>
      <div class="col-sm-6">

        <input type="text" formControlName="intervalValue" class="form-control" placeholder="">
      </div>
    </div>
    <div class="form-group row">

      <div class="col-sm-10 text-right">

        <button type="submit" [ladda]="laddLoading" class="btn btn-primary">{{btnValue}}</button>
      </div>
    </div>
   </form>
    <form class="pr-4 pl-4">
      <div class="row">
        <div class="col-md-12">
         <table class="table table-sm">
           <thead class="thead-blue">
             <tr>
                <th>Project Number</th>
                <th>Task Name</th>
                <th>Interval Unit</th>
                <th>Amount</th>
                <th>Action</th>



             </tr>
           </thead>
           <tbody>

             <tr *ngFor="let intervalItem of taskIntervalData |keyvalue">
               <td><input type="text" value="{{param.data.projectNumber}}" readonly class="form-control" placeholder=""/></td>
               <td><input type="text" value="{{param.data.taskName}}" readonly class="form-control" placeholder=""/></td>
               <td><input type="text" value="{{intervalItem.value.intervalUnit}}" readonly class="form-control" placeholder=""/></td>
               <td><input type="text" value="{{intervalItem.value.intervalValue}}" readonly class="form-control" placeholder=""/></td>
               <td><button (click)="editTaskInterval(intervalItem.key)" class="btn btn-success"><fa-icon [icon]="faEdit"></fa-icon></button></td>
               </tr>
           </tbody>
         </table>
        </div>
       </div>

    </form>


  </div>
  <div class="modal-footer">

  </div>

