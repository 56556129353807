import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { faPlus, faSync, faSearch,faEdit,faBackward } from '@fortawesome/free-solid-svg-icons';
import {CommonLogComponent} from '../../../../shared/components/common-log/common-log.component';
import {AuthServiceService} from '../../../../services/auth-service.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {LogServiceService} from '../../../../services/log-service.service';
import {ActivatedRoute} from "@angular/router";
@Component({
  selector: 'app-system-logs',
  templateUrl: './system-logs.component.html',
  styleUrls: ['./system-logs.component.scss']
})
export class SystemLogsComponent extends CommonLogComponent implements OnInit {

  constructor(
    public authService: AuthServiceService,
    public bsModalRef: BsModalRef,
    public activatedRoute : ActivatedRoute,
    public logService: LogServiceService,
    public cdr: ChangeDetectorRef
  ) {
    super(authService, bsModalRef, activatedRoute, logService, cdr);
  }

  ngOnInit(): void {
    
    super.setLogType("common");
    super.ngOnInit();
  }

}
