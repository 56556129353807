<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title pull-left" style="font-weight: bold">Logs : &nbsp;</h5>
    <h5 style="" class="modal-title pull-left">&nbsp; {{logType}} {{logId}}</h5>
    <span class="export-btn-label" *ngIf="disableExportButton">Export button will be active only when at least one filter is selected. &nbsp;&nbsp;</span>
    <button class="btn btn-primary export-btn" (click)="exportData()" appButtonLoader [isLoading]="loadingExportBtn" [disabled]="disableButton || disableExportButton" type="button">Export</button>
    <button (click)="bsModalRef.hide()" aria-label="Close" class="close " type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div id="data-table" class="data-table">
      <div [ngClass]="{'main-table-small': showResultTable, 'main-table-large': !showResultTable}">
        <app-tree-table [settings]="mainTableSettings" [data]="tableData" [currentPage]="currentPage" [pageCount]="pageCount" (pageChangeEvent)="changePage($event)" (pageSizeChangeEvent)="changePageSize($event);"></app-tree-table>
      </div>
      <div *ngIf="showResultTable" class="result-table">
        <div class="result-action">
          <h6>Action: &nbsp; {{currentAction}}</h6>
        </div>
        <div class="card-body" [ngStyle]="{'height': dataTableHeight + 'px'}">
          <app-tree-table [settings]="currentAction == 'Edit' ? editResultTableSettings : addDeleteResultTableSettings" [data]="moreDetails"></app-tree-table>
        </div>
        <div class="close-result">
          <button class="btn btn-outline-primary btn-sm btn-block" (click)="showResultTable = false" type="button" > Close </button>
        </div>
      </div>
    </div>

    <div class="filters">
      <h4>Filter By</h4>
      <div class="row">
        <div class="form-group col-md-4">
          <div class="input-group" appInfoPromptBox [page]="'common'" [component]="'logs'" [field]="'searchString'">
            <input class="form-control" type="text" placeholder="Search String" [(ngModel)]="searchString">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" type="button"><i class="fa fa-search" (click)="onClickSearch()"></i></button>
            </div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="input-group" appInfoPromptBox [page]="'common'" [component]="'logs'" [field]="'fromDate'">
            <input #fromDate="ngbDatepicker" class="form-control" ngbDatepicker placeholder="From Date" [(ngModel)]="selectedFromDate" (dateSelect)="onSelectFromDate()">
            <div class="input-group-append">
              <button (click)="fromDate.toggle()" class="btn btn-outline-secondary" type="button"><i class="fa fa-calendar"></i></button>
            </div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="input-group" appInfoPromptBox [page]="'common'" [component]="'logs'" [field]="'toDate'">
            <input #toDate="ngbDatepicker" class="form-control" ngbDatepicker placeholder="To Date" [(ngModel)]="selectedToDate" (dateSelect)="onSelectToDate()">
            <div class="input-group-append">
              <button (click)="toDate.toggle()" class="btn btn-outline-secondary" type="button"><i class="fa fa-calendar"></i></button>
            </div>
          </div>
        </div>
      </div>

      <!-- Not yet fully implemented -->
      <!-- <div class="row">
        <div class="form-group col-md-4">
          <ngx-select [items]="projects" [multiple]="true" [autoClearSearch]="true" [placeholder]="'Project Number'" optionTextField="projectTitle" optionValueField="projectNumber" (select)="onSelectProject()" [(ngModel)]="selectedProjects" appInfoPromptBox [page]="'common'" [component]="'logs'" [field]="'projectNumber'"></ngx-select>
        </div>
        <div class="form-group col-md-4">
          <ngx-select [items]="tasks" [multiple]="true" [autoClearSearch]="true" [placeholder]="'Task ID'" optionTextField="taskActivity" optionValueField="taskDbID" (select)="onSelectTask()" [(ngModel)]="selectedTasks" appInfoPromptBox [page]="'common'" [component]="'logs'" [field]="'taskId'"></ngx-select>
        </div>
        <div class="form-group col-md-4">
          <ngx-select [items]="stadlerArticles" [multiple]="true" [autoClearSearch]="true" [placeholder]="'Stadler Article ID'" optionTextField="stadlerArticleTitle" optionValueField="projectNumber" (select)="onSelectStadlerArticle()" [(ngModel)]="selectedArticles" appInfoPromptBox [page]="'common'" [component]="'logs'" [field]="'stadlerArticleId'"></ngx-select>
        </div>
      </div> -->


      <div class="row">
        <div class="form-group col-md-4">
          <ngx-select [items]="users" [multiple]="true" [autoClearSearch]="true" [placeholder]="'User'" optionTextField="userEmail" optionValueField="userId" (select)="onSelectUser()" [(ngModel)]="selectedUsers" appInfoPromptBox [page]="'common'" [component]="'logs'" [field]="'userId'"></ngx-select>
        </div>
        <div class="form-group col-md-4">
          <ngx-select [items]="entities" [multiple]="true" [autoClearSearch]="true" [placeholder]="'Entity'" optionTextField="entityName" optionValueField="entityName" (select)="onSelectEntity()" [(ngModel)]="selectedEntities" appInfoPromptBox [page]="'common'" [component]="'logs'" [field]="'entityName'"></ngx-select>
          <div *ngIf="displayEntityMessage">Other Entities can not be selected along with 'All Project Related Data only' OR 'All Master Data Only' entities</div>
          <div *ngIf="displayEntityMessage">Please remove them to be able to select other entities.</div>
        </div>
        <div class="form-group col-md-4">
          <ngx-select [items]="actions" [multiple]="true" [autoClearSearch]="true" [placeholder]="'Action'" optionTextField="actionName" optionValueField="actionName" (select)="onSelectAction()" [(ngModel)]="selectedActions" appInfoPromptBox [page]="'common'" [component]="'logs'" [field]="'actionName'"></ngx-select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-2">
          <button class="btn btn-primary" appButtonLoader [isLoading]="loadingSearchBtn" [disabled]="disableButton" type="button" (click)="doSearch()"><i class="fa fa-search"></i> Search </button>
        </div>
        
        
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="bsModalRef.hide()" type="button">Close</button>
  </div>
</div>

