

<div class="modal-header">
  <h5 class="modal-title pull-left">
    Copy Project system
  </h5>
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="copyForm">
  <div class="modal-body">
    <div class="form-group row pr-4 pl-4">
      <label for="inputPassword3" class="col-sm-3 col-form-label col-form-label-sm">Projects</label>
      <div class="col-sm-9">
        <ngx-select formControlName="projectNumber" (select)="selectProject($event)" [items]="projectsData" optionTextField="projectDispStr" optionValueField="projectNumber" appInfoPromptBox [page]="'projectSystem'" [component]="'copy'" [field]="'projectNumber'"></ngx-select>
      </div>
    </div>
    <div class="form-group row pr-4 pl-4">
      <label for="inputPassword3" class="col-sm-3 col-form-label col-form-label-sm">Standard Systems</label>
      <div class="col-sm-9">
        <ngx-select formControlName="newSystemNumber" [items]="standardSystems" optionTextField="systemDesignation" optionValueField="systemNumber" appInfoPromptBox [page]="'projectSystem'" [component]="'copy'" [field]="'systemNumber'"></ngx-select>
        <div *ngIf="copyForm.controls['newSystemNumber'].value != defaultSystem" class="alert alert-warning">
          Please make sure, that this system code change makes sense. Consider that the field entries of System Variation Name, Task Element and Task Name is not changed by copying
        </div>
      </div>
    </div>
  </div>
  <div class="alert-warning">
    * Consider that system operating profile is also copied if it exists and task stages are recalculated after copying. <br/>
    ** If you copy the system to another project, cm related tasks that are not of this system will not be copied.
  </div>
  <div class="modal-footer">
    <app-action-comment [btnLoading]="btnLoading" [okBtnName]="'Copy'" [closeBtnName]="'Close'" [saveDisable]="copyForm.invalid" [mandatoryDescriptionVisible]="true" [commentVisible]="projectFreezeFunction == 'activated'" [justificationCategoryVisible]="projectFreezeFunction == 'activated'" (okClick)="onSubmitForm($event)" (cancelClick)="bsModalRef.hide()"></app-action-comment>
  </div>
</form>

