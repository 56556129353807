import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AuthServiceService} from "../../../../services/auth-service.service";
import {BsModalRef} from "ngx-bootstrap/modal";
import {ActivatedRoute} from "@angular/router";
import {LogServiceService} from "../../../../services/log-service.service";
import {CommonLogComponent} from "../../../../shared/components/common-log/common-log.component";

@Component({
  selector: 'app-project-systems-log',
  templateUrl: '../../../../shared/components/common-log/common-log.component.html',
  styleUrls: ['../../../../shared/components/common-log/common-log.component.scss']
})
export class ProjectSystemsLogComponent extends CommonLogComponent implements OnInit {

  constructor(
      public authService: AuthServiceService,
      public bsModalRef: BsModalRef,
      public activatedRoute : ActivatedRoute,
      public logService: LogServiceService,
      public cdr: ChangeDetectorRef
  ) {
    super(authService, bsModalRef, activatedRoute, logService, cdr);
  }

  @Input()
  param:any;

  public projectNumber:any;
  public systemVariationNumberAndCode: any;

  ngOnInit(): void {
    this.projectNumber = this.param['projectNumber'];
    this.systemVariationNumberAndCode = this.param['systemVariationNumberAndCode'];

    super.setProjectNumber(this.projectNumber);
    super.setSystemVariationNumberAndCode(this.systemVariationNumberAndCode);

    super.setLogId(this.systemVariationNumberAndCode);
    super.setLogType("Project System");

    super.ngOnInit();
  }

}
