import {Component, Input, OnInit} from '@angular/core';
import {BsModalService} from "ngx-bootstrap/modal";
import {LanguageServiceService} from "../../../../../services/language-service.service";
import {ProjectSystemsLogComponent} from "../../project-systems-log/project-systems-log.component";

@Component({
  selector: 'app-project-systems-log-url',
  templateUrl: './project-systems-log-url.component.html',
  styleUrls: ['./project-systems-log-url.component.scss']
})
export class ProjectSystemsLogUrlComponent implements OnInit {

  @Input()
  rowData: any;

  modalRef:any;

  constructor(private modalService:BsModalService,
              private languageService: LanguageServiceService) { }

  ngOnInit(): void {
  }

  loadLogModel(){
    let lan  = {
      id: this.rowData['workLangId'],
      str: this.rowData['workLangId'] == 1 ? "German" : "English"
    }
    this.languageService.setWorkingLanguage(lan);

    const initialState = {
      param : this.rowData
    };
    this.modalRef = this.modalService.show(ProjectSystemsLogComponent, {class:'modal-xl',initialState,keyboard:false,ignoreBackdropClick: true});
  }

}
