<div class="modal-header">
  <h5 class="modal-title pull-left">Edit Users</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>



  <div class="modal-body">

    <div class="form-group row">
    <div class="col-sm-6 p-5">
      <form [formGroup]="userEditForm" (ngSubmit)="updateUser()">
      <div class="form-group row">
        <label class="col-sm-4 col-form-label col-form-label-sm">First Name</label>
        <div class="col-sm-8">
          <input type="text" class="form-control " formControlName="firstName" placeholder="First Name" appInfoPromptBox [page]="'users'" [component]="'edit'" [field]="'firstName'"/>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label col-form-label-sm">Last Name</label>
        <div class="col-sm-8">
          <input type="text" class="form-control " formControlName="lastName" placeholder="Last Name" appInfoPromptBox [page]="'users'" [component]="'edit'" [field]="'lastName'"/>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label col-form-label-sm">Email</label>
        <div class="col-sm-8">
          <input type="text" class="form-control " formControlName="userEmail" placeholder="Email" appInfoPromptBox [page]="'users'" [component]="'edit'" [field]="'userEmail'"/>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label col-form-label-sm">Role</label>
        <div class="col-sm-8">
          <ngx-select optionValueField="systemRoleId" placeholder="Role" optionTextField="systemRole" [items]="roleData" formControlName="primaryRole" appInfoPromptBox [page]="'users'" [component]="'edit'" [field]="'primaryRole'"></ngx-select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label col-form-label-sm">User Group</label>
        <div class="col-sm-8">
          <ngx-select optionValueField="userGroupId" placeholder="User Group" optionTextField="userGroupName" [items]="userGroupData" formControlName="userGroupId" appInfoPromptBox [page]="'users'" [component]="'add'" [field]="'userGroupId'"></ngx-select>
        </div>
      </div>
      <div class="form-group row">
        <label for="chkActive" class="col-sm-4 col-form-label col-form-label-sm">Active</label>
        <div class="col-sm-8">
          <input style="margin-left : 0px;" type="checkbox" class="form-check-input" [checked]="isChecked"  formControlName="active" appInfoPromptBox [page]="'users'" [component]="'edit'" [field]="'active'"/>
        </div>
      </div>

      <div class="form-group row">
        <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm"></label>
        <div class="col-sm-8">
          <!-- appPreventDoubleSubmit (debounceClick)="updateUser()" [debounceTime]="500" -->
          <button type="submit" appButtonLoader [isLoading]="loadingBtn" (click)="updateUser()"  class="btn btn-primary">Save</button>
          <button type="button" class="btn btn-default" (click)="bsModalRef.hide()" >Close</button>
        </div>
      </div>
      
      </form>
    </div>

    <div class="col-sm-6 p-5">
      <form  [formGroup]="userPasswordResetForm" (ngSubmit)="resetPassword()">
        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-6 col-form-label col-form-label-sm">Password</label>
          <div class="col-sm-6">
            <input type="password" (keyup)="isValidPassword()" class="form-control " formControlName="newPassword" placeholder="New Password" appInfoPromptBox [page]="'users'" [component]="'edit'" [field]="'newPassword'"/>
          </div>
        </div>
    
        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-6 col-form-label col-form-label-sm">Confirm Password</label>
          <div class="col-sm-6">
            <input type="password" (keyup)="isValidPassword()" class="form-control " formControlName="newPasswordConfirm" placeholder="Confirm Password" appInfoPromptBox [page]="'users'" [component]="'edit'" [field]="'newPasswordConfirm'"/>
            <span *ngIf="!ismatch" class="ng-validator">Your password and confirmation password do not match</span>
          </div>
        </div>

        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-6 col-form-label col-form-label-sm"></label>
          <div class="col-sm-8">
            <button type="submit" appButtonLoader [isLoading]="loadingBtnPwd" appPreventDoubleSubmit [disabled]="disableButton" (debounceClick)="resetPassword()" [debounceTime]="500" class="btn btn-primary">Change Password</button>
            <button type="button" class="btn btn-default" (click)="clearPassword()" >Clear</button>
          </div>
        </div>

      </form>
    </div>
  </div>
    
  </div>

  <div class="modal-footer">
    
  </div>

