<div class="modal-header">
    <h5 class="modal-title pull-left">Move Parts</h5>
    <div>
        <ngx-select style="padding-left: 25px; width: 200px; display: inline-block; float: right !important;" optionValueField="id" optionTextField="str" [items]="languages" [(ngModel)]="selectedLanguage" (select)="languageChanged()"></ngx-select>
    </div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class=" data-table">
        <app-tree-table [settings]="settings" [data]="tableData"></app-tree-table>
    </div>
</div>
<div class="modal-footer">
    <div class="alert-warning">The part will be moved as a child of the selected parent, including all the children of the part.</div>
    <app-action-comment [btnLoading]="btnLoading" [okBtnName]="'Move'" [closeBtnName]="'Close'" [saveDisable]="false" [commentVisible]="projectFreezeFunction == 'activated'" (okClick)="moveParts($event)" (cancelClick)="bsModalRef.hide()"></app-action-comment>
</div>
