<div class="tree-table-outer-div">
    <div id="tree-table-view">
        <div id="zoom-area" [ngStyle]="{'transform': 'scale(' + zoomRatio + ')'}">
            <div #nonFreezeTreeRow class="scroll-div" *ngIf="!settings.freezeColumns?.enabled">
                <div [ngClass]="settings['tableHeaderClass']" class="tree-header tree-header-non-freeze d-flex justify-content-start">
                    <div class="d-flex justify-content-start">
                        <div class="decorator-h"></div>
                        <div *ngFor="let column of settings?.columns; let i = index">
                            <div *ngIf="!column['isHidden']" [ngStyle]="{'min-width.px': column['width'] ? column['width'] : columnWidth}">
                                <div [ngClass]="column['class']" class="tree-header-cell">
                                    <app-tree-cell [loading]="settings['loading']" [type]="'text'" [content]="column['title']" [filter]="column['filter']" (showFilterEvent)="showFilters($event)"></app-tree-cell>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dummy-tree-header"></div>
                <div *ngFor="let object of data; let index = index" [ngClass]="settings['tableClass']" class="tree-body">
                    <div id="tree-row" *ngIf="!object.filtered" [ngClass]="settings['tableRowClass']">
                        <app-tree-node *ngIf="showTreeNode" [tree]="tree" [settings]="settings" [rowData]="object" [rowIndex]="index" [activeMouseOverRowId]="activeMouseOverRowId" [activeMouseOverRowParentId]="activeMouseOverRowParentId" [hasChild]="object['hasChild']" [columnWidth]="columnWidth" [depth]="0" (toggleEvent)="nodeToggled($event)" (mouseOverRowChangeEvent)="activeMouseOverRowId = $event['activeMouseOverRowId']; activeMouseOverRowParentId = $event['activeMouseOverRowParentId']"></app-tree-node>
                    </div>
                </div>
            </div>

            <div #freezeTreeRow *ngIf="settings.freezeColumns?.enabled" class="d-flex justify-content-start" id="freeze-tree-row">
                <div #leftFreezeDiv class="left-freeze-div">
                    <div class="tree-header"></div>
                    <div *ngFor="let object of data; let index = index" [ngClass]="settings['tableClass']" class="tree-body">
                        <div *ngIf="!object.filtered" [ngClass]="settings['tableRowClass']">
                            <app-tree-node [settings]="settings" [tree]="tree" [rowData]="object" [rowIndex]="index" [activeMouseOverRowId]="activeMouseOverRowId" [activeMouseOverRowParentId]="activeMouseOverRowParentId" [columns]="leftColumns" [columnType]="'left'" [hasChild]="object['hasChild']" [columnWidth]="columnWidth" [depth]="0" (toggleEvent)="nodeToggled($event)" (mouseOverRowChangeEvent)="activeMouseOverRowId = $event['activeMouseOverRowId']; activeMouseOverRowParentId = $event['activeMouseOverRowParentId']"></app-tree-node>
                        </div>
                    </div>
                </div>
                <div #middleScrollDiv class="middle-scroll-div" [ngStyle]="{'width.px': scrollDivWidth}">
                    <div class="tree-header"></div>
                    <div *ngFor="let object of data; let index=index" [ngClass]="settings['tableClass']" class="tree-body">
                        <div *ngIf="!object.filtered" [ngClass]="settings['tableRowClass']">
                            <app-tree-node [settings]="settings" [tree]="tree" [rowData]="object" [rowIndex]="index" [activeMouseOverRowId]="activeMouseOverRowId" [activeMouseOverRowParentId]="activeMouseOverRowParentId" [columns]="middleColumns" [columnType]="'middle'" [hasChild]="object['hasChild']" [columnWidth]="columnWidth" [depth]="0" (mouseOverRowChangeEvent)="activeMouseOverRowId = $event['activeMouseOverRowId']; activeMouseOverRowParentId = $event['activeMouseOverRowParentId']"></app-tree-node>
                        </div>
                    </div>
                </div>
                <div class="right-freeze-div">
                    <div class="tree-header"></div>
                    <div *ngFor="let object of data; let index=index" [ngClass]="settings['tableClass']" class="tree-body">
                        <div *ngIf="!object.filtered;" [ngClass]="settings['tableRowClass']">
                            <app-tree-node [settings]="settings" [tree]="tree" [rowData]="object" [rowIndex]="index" [activeMouseOverRowId]="activeMouseOverRowId" [activeMouseOverRowParentId]="activeMouseOverRowParentId" [columns]="rightColumns" [columnType]="'right'" [hasChild]="object['hasChild']" [columnWidth]="columnWidth" [depth]="0" (mouseOverRowChangeEvent)="activeMouseOverRowId = $event['activeMouseOverRowId']; activeMouseOverRowParentId = $event['activeMouseOverRowParentId']"></app-tree-node>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showHorizontalFriezedHeader" class="freeze-tree-header" [ngStyle]="{'top.px': freezeTreeHeaderPosition.top, 'left.px': freezeTreeHeaderPosition.left-1, 'transform': 'scale(' + zoomRatio + ')'}">
        <div *ngIf="settings.freezeColumns?.enabled" class="d-flex justify-content-start">
            <div class="left-freeze-div">
                <div class="tree-header d-flex justify-content-start">
                    <div class="decorator-h" ></div>
                    <div *ngFor="let column of leftColumns; let i = index">
                        <div *ngIf="!column['isHidden']" [ngStyle]="{'min-width.px': column['width'] ? column['width'] : columnWidth}">
                            <div [ngClass]="column['class']" class="tree-header-cell">
                                <app-tree-cell [loading]="settings['loading']" [type]="'text'" [content]="column['title']" [filter]="column['filter']" (showFilterEvent)="showFilters($event)"></app-tree-cell>
                            </div>
                        </div>
                    </div>
                    <div class="scroll-left-btn-div">
                        <button (click)="scrollMiddleSectionLeft()" class="scroll-left-btn-btn" [ngClass]="disableScrollLeftButton ? 'scroll-left-btn-disabled' : ''">
                            <fa-icon [icon]="faLeftArrow" class="scroll-left-btn-icon"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div #middleScrollHeader class="middle-scroll-div" [ngStyle]="{'width.px': scrollDivWidth}">
                <div class="tree-middle-header d-flex justify-content-start">
                    <div *ngFor="let column of middleColumns">
                        <div *ngIf="!column['isHidden']" [ngStyle]="{'min-width.px': column['width'] ? column['width'] : columnWidth}">
                            <div [ngClass]="column['class']" class="tree-middle-header-cell">
                                <app-tree-cell [loading]="settings['loading']" [type]="'text'" [content]="column['title']" [filter]="column['filter']" (showFilterEvent)="showFilters($event)"></app-tree-cell>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-freeze-div">
                <div class="tree-header d-flex justify-content-start">
                    <div class="scroll-right-btn-div">
                        <button (click)="scrollMiddleSectionRight()" class="scroll-right-btn-btn" [ngClass]="disableScrollRightButton ? 'scroll-right-btn-disabled' : ''">
                            <fa-icon [icon]="faRightArrow" class="scroll-right-btn-icon"></fa-icon>
                        </button>
                    </div>
                    <div *ngFor="let column of rightColumns; let i = index">
                        <div *ngIf="!column['isHidden']" [ngStyle]="{'min-width.px': column['width'] ? column['width'] : columnWidth}">
                            <div [ngClass]="column['class']" class="tree-header-cell">
                                <app-tree-cell [loading]="settings['loading']" [type]="'text'" [content]="column['title']" [filter]="column['filter']" (showFilterEvent)="showFilters($event)"></app-tree-cell>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div #filterMenu>
    <div *ngIf="showFilterMenu" class="filter-menu" [ngStyle]="{'top.px': filterMenuPosition.top, 'left.px': filterMenuPosition.left-200}">
        <div class="filter-close-div">
            <button type="button" class="filter-close-btn close pull-right" aria-label="Close" (click)="showFilterMenu = false">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="sortDiv d-flex justify-content-between">
            <div class="sort-asc-btn-div">
                <button type="button" class="sort-asc-btn" (click)="sortChange('ASC')" [ngClass]="{'sort-btn-selected': activeSort == filter['column'] && activeSortOrder == 'ASC'}">
                    Ascending
                </button>
            </div>
            <div class="sort-desc-btn-div">
                <button type="button" class="sort-desc-btn" (click)="sortChange('DESC')" [ngClass]="{'sort-btn-selected': activeSort == filter['column'] && activeSortOrder == 'DESC'}">
                    Descending
                </button>
            </div>
        </div>
        <div class="option-list-outer-div">
            <div class="filter-option-search-input-div">
                <input #filterDrpDownSearchInput class="filter-option-search-input" type="text" placeholder="Search" [(ngModel)]="filterInputValue" (keyup)="filterInputValueChange()"/>
            </div>
            <div *ngIf="!filterOptionLoading && moreThan100RecordsAvailableInFilterOptions" class="more-than-100-records-available-msg-div d-flex align-items-baseline">
                <span>More than 100 records available</span>
            </div>
            <div class="filter-option-list">
                <div *ngIf="!filterOptionLoading">
                    <div class="filter-option-div d-flex justify-content-start align-items-center">
                        <input #filterSelectAll class="option-checkbox" type="checkbox" (change)="filterAllSelectToggle($event.target['checked'])"> &nbsp;
                        <div class="form-check-label option-label">
                            <span>Select All</span>
                        </div>
                    </div>
                    <div *ngFor="let option of filter?.options; let i = index" class="filter-option-div d-flex justify-content-start align-items-center">
                        <input class="option-checkbox" type="checkbox" [checked]="option['selected']" (change)="filterOptionChange(option, $event.target['checked'])"> &nbsp;
                        <div class="form-check-label option-label" appTextTruncate (getTruncatedText)="setToolTipToFilterOptionsIfTruncated($event, option)" [matTooltip]="option['toolTip']">
                            <span>{{option['value']}}</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="filterOptionLoading" class="filter-option-progress-spinner-div d-flex justify-content-center align-items-center">
                    <mat-progress-spinner class="filter-option-progress-spinner" [color]="'accent'" [mode]="'indeterminate'"> </mat-progress-spinner>
                </div>
            </div>
        </div>
    </div>
</div>

