import {Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
    selector: '[appThousandSeparator]',
})
export class ThousandSeparatorDirective implements OnChanges {

    @Input('appThousandSeparator') public input: any;
    public finalValue: any;

    constructor(private element: ElementRef) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.element.nativeElement != document.activeElement) {
            this.element.nativeElement.value = this.getThousandSeparatedString(this.input);
        }
    }

    @HostListener('blur', ['$event'])
    onBlur(event: KeyboardEvent) { // adds separator for view the value
        this.element.nativeElement.value = this.getThousandSeparatedString(this.input);
    }

    @HostListener('focus', ['$event'])
    onFocus(event: KeyboardEvent) { // removes separator for edit the value
        this.element.nativeElement.value = this.input;
    }

    getThousandSeparatedString(value: any): string { // returns string with adding separator for correct positions
        if (!value || value == '') return "";

        let finalString = "";
        let valueString: string = value.toString();
        let intValueString: string = valueString.split(".")[0];
        let decimalValueString: string = valueString.split(".")[1];

        if (intValueString) {
            let count = 1;
            for (let i = intValueString.length - 1; i >= 0; i--) {
                finalString = intValueString[i] + finalString;
                if (count % 3 == 0 && i != 0) finalString = "'" + finalString;

                count++;
            }
        }

        if (decimalValueString) {
            finalString = finalString + "." + decimalValueString;
        }

        return finalString;
    }
}
