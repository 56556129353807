import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CopyModalComponent } from '../copy-modal/copy-modal.component';

@Component({
  selector: 'app-copy-button-url',
  templateUrl: './copy-button-url.component.html',
  styleUrls: ['./copy-button-url.component.scss']
})
export class CopyButtonUrlComponent implements OnInit {

  rowData:any;
  isButtonHidden:Boolean;
  eventEmitter:EventEmitter<string> = new EventEmitter();
  projectFreezeFunction = "";

  constructor(
      private modalService:BsModalService
  ) { }

  ngOnInit(): void {
    this.isButtonHidden = this.rowData.systemCode == "AA00";
  }


  loadCopyModal(){
    const initialState = {
      param : this.rowData,
      projectFreezeFunction: this.projectFreezeFunction
    };
    let modalRef = this.modalService.show(CopyModalComponent, {class:'modal-lg',initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res =>
    {
      this.eventEmitter.emit('refresh');
    });
  }
}
