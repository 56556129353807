
import { Component, OnInit } from '@angular/core';
import { faPlus, faFile, faSearch, faSync } from '@fortawesome/free-solid-svg-icons';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationBoxComponent } from '../../../shared/components/confirmation-box/confirmation-box.component';
import { EditSuppliersComponent } from '../components/edit-suppliers/edit-suppliers.component';
import { AddSuppliersComponent } from '../components/add-suppliers/add-suppliers.component';
import { SuppliersLogComponent } from '../suppliers-log/suppliers-log.component';
import { SuppliersService } from '../../../services/suppliers.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-suppliers-list',
  templateUrl: './suppliers-list.component.html',
  styleUrls: ['./suppliers-list.component.scss']
})
export class SuppliersListComponent implements OnInit {

  suppliersData:any = [];
  showReset:Boolean=false;
  laddaLoading:Boolean=false;
  faSearch = faSearch;
  faSync=faSync;
  faPlus = faPlus;
  faFile = faFile;
  loading:Boolean = true;

  searchForm = new UntypedFormGroup({
    nameUsed : new UntypedFormControl(''),
    erpId : new UntypedFormControl('')
  });

  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";
  filterData = [];

  settings = {
    type:'table',
    id:'stakeholderId',
    columnManagerEnable:true,
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    enableZoom:true,
    filterOptionFunction:(params, filterData)=>{return this.suppliersService.getAllSuppliers("distinct-values", filterData, params)},
    columns:[
      {
        title:'Supplier ID',
        attribute:'stakeholderId',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:100,
      },
      {
        title:'Supplier Name',
        attribute:'firstName',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:350
      },
      {
        title:'Infor Supplier Id',
        attribute:'erpId',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150,
      },
      {
        renderComponent: '<a class="btn btn-info btn-sm text-light btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/edit.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.loadEditModal(row)},
        width:30
      },
      {
        renderComponent:'<a class="btn btn-danger btn-sm text-light btn-url"><img title="Delete" class="delete-icon" src="/assets/icon/delete.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.deleteSupplier(row)},
        width:50
      }
    ]
  };

  constructor(
      private suppliersService: SuppliersService,
      private modalService: BsModalService,
      private activatedRoute : ActivatedRoute,
      private notificationService: ToastrService
  ) {
    activatedRoute.queryParams.subscribe(params => {
      if(params['page']) this.currentPage = +params['page'];
      if(params['column']) this.sortColumn = params['column'];
      if(params['sortOrder']) this.sortOrder = params['sortOrder'];
    });
  }

  ngOnInit(): void {
    this.getAllSuppliers();
  }
  //
  // /**
  //  * Search Suppliers
  //  */
  // search(){
  //   this.showReset = true;
  //   this.loading = true;
  //   this.laddaLoading = true;
  //
  //   this.suppliersService.search(this.searchForm.getRawValue()).subscribe(res=>{
  //     this.laddaLoading = false;
  //     this.loading = false;
  //     this.suppliersData = res;
  //     this.pageCount = 1;
  //     this.currentPage = 1;
  //   });
  // }
  //
  // /**
  //  * Reset the search results
  //  */
  // resetSearch(){
  //   this.showReset=false;
  //   this.getAllSuppliers();
  // }

  /**
   * Get all suppliers
   */
  private getAllSuppliers(){
    this.loading = true;
    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;

    this.suppliersService.getAllSuppliers("filtered-data", this.filterData, params).subscribe(res=>{
      if(res && res['page']){
        this.pageCount = res['page']['totalPages'];
        this.suppliersData = res['page']['content'];
        this.loading = false;
      }
      if(res && res['tablePropertyMap']){
        let columns = this.settings.columns;
        let needFillColumnAndTable = false;
        for(let column of columns){
          if(column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
        }
        if(!needFillColumnAndTable) return;

        let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];
        for(let i=0; i<columns.length; i++){
          let data = tablePropertyMap[columns[i].attribute];
          if(!data) continue;
          columns[i].filter['column'] = res['isNative'] ? data['table'] + "." + data['column'] : data['column'];
          columns[i].filter['table'] = data['table'];
        }
      }
      this.settings['isNative'] = res && res['isNative'];
    });
  }

  filterOrSortChange(event){
    if(!event){
      this.currentPage = 1;
      this.suppliersData = [];
      return;
    }
    if(!event['sortChanged']) this.currentPage = 1;
    this.sortColumn = event['sortColumn'];
    this.sortOrder = event['sortOrder'];
    this.filterData = event['filterData'];
    this.getAllSuppliers();
  }

  /**
   * Delete suppliers
   * @param event
   */
  public deleteSupplier(rowData)
  {
    const initialState = {
      title: 'Are you sure?',
      okBtnName: 'Yes',
      closeBtnName: 'No',
      isCommentVisible:false
    };
    let modalRef = this.modalService.show(ConfirmationBoxComponent, {class:'modal-md', initialState, ignoreBackdropClick:true});
    modalRef.content.event.subscribe(res =>
    {
      if(res?.result?.action == 'ok')
      {
        let supplierId = rowData.stakeholderId;
        this.suppliersService.delete(supplierId).subscribe(res => {}, error =>
        {
          if(error.error.status == 412) {
            this.notificationService.error('The Supplier you tried to delete is in use, hence cannot be deleted','Error');
          }
          else {
            this.notificationService.success('The Supplier has been deleted','success');
          }
          this.getAllSuppliers();
        });
      }
      else ; // do nothing
    });
  }

  /**
   * Load Edit Modal
   * @param rowData
   */
  loadEditModal(rowData){
    var data = rowData;
    const initialState = {
      param : data
    };
    let modalRef = this.modalService.show(EditSuppliersComponent, {class: 'modal-lg',initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe((updatedSupplier:any) => {
      this.getAllSuppliers();
    });
  }

  /**
   * Load suppliers add modal
   */
  loadAddModal(){
    const initialState = {};
    let modalRef = this.modalService.show(AddSuppliersComponent, {class: 'modal-lg',initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe((addedSupplier: {}) => {
      this.getAllSuppliers();
    });
  }

  public showLogs()
  {
    const initialState = {};
    this.modalService.show(SuppliersLogComponent, {class:'modal-xl', initialState,keyboard:false,ignoreBackdropClick: true});
  }


  changePage(currentPage){
    this.currentPage = currentPage;
    this.getAllSuppliers();
  }

  changePageSize(pageSize){
    this.pageSize = pageSize;
    this.getAllSuppliers();
  }
}

