import {Component, EventEmitter, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {SuppliersService} from 'src/app/services/suppliers.service';
import {ToastrService} from 'ngx-toastr';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {validateForm} from 'src/app/shared/helper';
import {LanguageSupportComponent} from "../../../../shared/components/language-support/language-support-component.service";
import {LanguageServiceService} from "../../../../services/language-service.service";

@Component({
  selector: 'app-edit-suppliers',
  templateUrl: './edit-suppliers.component.html',
  styleUrls: ['./edit-suppliers.component.scss']
})
export class EditSuppliersComponent extends LanguageSupportComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();
  param: any;
  editSupplierForm: any;
  btnLoading: Boolean = false;
  supplierId:any;

  constructor(
      public bsModalRef: BsModalRef,
      private supplierService: SuppliersService,
      private notificationService: ToastrService,
      public languageService: LanguageServiceService,
      public modalService: BsModalService
  ) {
    super(languageService, modalService);
  }

  ngOnInit(): void {
    this.editSupplierForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required]),
      erpId: new UntypedFormControl('' ),
    });

    this.supplierId = this.param.stakeholderId;
    this.initFormValues();
  }

  public currentFormValues()
  {
    return JSON.stringify(this.editSupplierForm.getRawValue());
  }

  initFormValues(){
    this.loadformData(this.param);

    validateForm(this.editSupplierForm);
  }
  /**
   * Load Edit form data
   */
  private loadformData(data) {
    this.editSupplierForm.patchValue({
      firstName: data.firstName != null ? data.firstName : '',
      erpId: data.erpId != null ? data.erpId : '',
    });

    super.saveInitialValues(JSON.stringify(this.editSupplierForm.getRawValue()));
  }

  languageChanged(){
    super.languageChanged(JSON.stringify(this.editSupplierForm.getRawValue()));
  }

  /**
   * Submit Edit form
   */
  public editSupplier() {
    if (this.editSupplierForm.valid) {
      this.btnLoading = true;
      this.supplierService.update(this.editSupplierForm.getRawValue(), this.supplierId).subscribe(res => {
        if (res) {
          this.event.emit(res);
          this.notificationService.success('Supplier has been Updated', 'success');
          this.btnLoading = false;
          this.bsModalRef.hide();
        } else {
          this.notificationService.error('Supplier has not been Updated', 'Error');
          this.btnLoading = false;
        }
      });
    } else {
      validateForm(this.editSupplierForm);
    }
  }

  callAfterLanguageChanged() {
    this.initFormValues();
  }
}
