import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {OperatingMaterialService} from 'src/app/services/operating-material.service';
import {TaskOperatingMaterialService} from 'src/app/services/task-operating-material.service';
import {faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import {ToastrService} from 'ngx-toastr';
import {QuantityUnitServiceService} from 'src/app/services/quantity-unit-service.service';
import {ConfirmationBoxComponent} from '../../../../../shared/components/confirmation-box/confirmation-box.component';
import {LanguageSupportComponent} from "../../../../../shared/components/language-support/language-support-component.service";
import {LanguageServiceService} from "../../../../../services/language-service.service";
import {ProjectService} from "../../../../../services/project.service";

@Component({
  selector: 'app-task-operating-material',
  templateUrl: './task-operating-material.component.html',
  styleUrls: ['./task-operating-material.component.scss']
})
export class TaskOperatingMaterialComponent extends LanguageSupportComponent implements OnInit {

  faEdit= faEdit;
  faTrash= faTrash;
  param:any;
  btnValue:String = 'Add';
  taskOperatingMaterialForm:any;
  operatingMaterialsData:any = [];
  filteredOperatingMaterialsData:any = [];
  originalOperatingMaterialsData:any = [];
  taskOperatingMaterials:any = [];
  taskDbId:Number;
  quantityUnitData:any = [];
  btnLoading:Boolean = false;
  materialQuantityDecimalLength = 2;
  disableEditButtons = false;
  projectFreezeFunction = "";

  constructor(
      public bsModalRef: BsModalRef,
      private operatingMaterialService: OperatingMaterialService,
      private taskOperatingMaterialService: TaskOperatingMaterialService,
      private notificationService: ToastrService,
      private projectService: ProjectService,
      private quantityUnitService: QuantityUnitServiceService,
      public languageService: LanguageServiceService,
      public modalService: BsModalService
  ) {
    super(languageService, modalService);
  }

  ngOnInit(): void {
    this.taskDbId = this.param.taskDbId;

    this.taskOperatingMaterialForm = new UntypedFormGroup({
      projectNumber: new UntypedFormControl({value: this.param.projectNumber, disabled:true}),
      projectSystemId: new UntypedFormControl(this.param.projectSystemId),
      taskDbId: new UntypedFormControl(this.param.taskDbId),
      operatingMaterialsId: new UntypedFormControl('', [Validators.required]),
      materialQuantity: new UntypedFormControl('', [Validators.required]),
      quantityAsRequired: new UntypedFormControl(false),
      quantityUnit: new UntypedFormControl('', [Validators.required]),
      materialPrice: new UntypedFormControl(),
      taskName: new UntypedFormControl({value: this.param.taskName, disabled:true}),
      systemName: new UntypedFormControl(this.param.systemDesignation),
      action: new UntypedFormControl('ADD'),
    });

    this.getProjectByProjectNumber();
    super.saveInitialValues("");
  }

  getProjectByProjectNumber() {
    this.projectService.getProjectById(this.param.projectNumber).subscribe(res => {
      this.projectFreezeFunction = res['freezeFunction'];
      let lan  = {
        id: res['workLangId'],
        str: res['workLangId'] == 1 ? "German" : "English"
      }
      this.languageService.setWorkingLanguage(lan);

      this.getTaskOperatingMaterialByTask();
      this.getQuantityUnitData();
    });
  }

  languageChanged(){
    super.languageChanged(""); // does not lose user changes after change language
  }

  /**
   * Submit Task Material Form
   */
  submitTaskMaterialForm(event){
    this.btnLoading = true;
    let submitData = this.taskOperatingMaterialForm.getRawValue();
    if(submitData['action']=='ADD'){
      // Create Action
      this.btnLoading = true;
      let formData = {
        materialPrice: submitData['materialPrice'],
        materialQuantity: submitData['materialQuantity'],
        operatingMaterialsId: submitData['operatingMaterialsId'],
        projectNumber: submitData['projectNumber'],
        quantityUnit: submitData['quantityUnit'],
        quantityAsRequired: submitData['quantityAsRequired'] ? 1 : 0,
        projectSystemId: this.param.projectSystemId,
        taskDbId: submitData['taskDbId']
      };

      this.taskOperatingMaterialService.createTaskOperatingMaterial(event, formData).subscribe(res => {
        this.notificationService.success('Task operating material has been created','success');
        this.getTaskOperatingMaterialByTask();
        this.btnLoading = false;

        this.taskOperatingMaterialForm.patchValue({
          operatingMaterialsId: null,
          materialQuantity: null,
          quantityUnit: null,
          quantityAsRequired:false,
          action: 'ADD'}
        );

      },(error) => {
        if(error.status == 406) {
          this.notificationService.error('Duplicate Task Operating Material','Error');
        }else if(error.status == 403) {
          this.notificationService.error('You need to be in the Project Admins Group to perform the current Action','Error');
        }else {
          this.notificationService.error('Task Operating Material has not been added','Error');
        }
        this.btnLoading = false;
      });
      this.btnLoading = false;
    }
    else if(submitData['action']=='EDIT'){
      // Edit Action
      this.btnLoading = true;
      let formData = {
        materialPrice: submitData['materialPrice'],
        materialQuantity: submitData['materialQuantity'],
        operatingMaterialsId: submitData['operatingMaterialsId'],
        projectNumber: submitData['projectNumber'],
        quantityUnit: submitData['quantityUnit'],
        quantityAsRequired: submitData['quantityAsRequired'] ? 1 : 0,
        projectSystemId: this.param.projectSystemId,
        taskDbId: submitData['taskDbId']
      };
      this.taskOperatingMaterialService.updateTaskOperatingMaterial(event, formData).subscribe(res => {
        this.notificationService.success('Task Operating Material has been updated','success');
        this.getTaskOperatingMaterialByTask();
        this.btnLoading = false;

        this.taskOperatingMaterialForm.patchValue({
          operatingMaterialsId: null,
          materialQuantity: null,
          quantityUnit: null,
          quantityAsRequired:false,
          action: 'EDIT'}
        );

      },(error) => {
        if(error.status == 500) {
          this.notificationService.error('Task Operating Material has not been updated','Error');
        }else if(error.status == 403) {
          this.notificationService.error('You need to be in the Project Admins Group to perform the current Action','Error');
        }else {
          this.notificationService.error('Task Operating Material has not been updated','Error');
        }

        this.btnLoading = false;
      });
      this.btnLoading = false;
    }
  }

  /**
   * Get operating materials
   */
  getOperatingMaterials(){
    this.filteredOperatingMaterialsData = [];
    this.originalOperatingMaterialsData = [];
    this.operatingMaterialsData = [];

    this.operatingMaterialService.getAllOperatingMaterials().subscribe(res=>{
      if(res && res['page']){
        let result = res['page']['content'];
        Object.keys(result).forEach(item => {
          let designation = this.selectedLanguage == 0 ? result[item].designationEn : result[item].designationDe;
          result[item].designation = designation + '-' + result[item].stadlerArticleId;

          let found = false;
          for(let taskOperatingMaterial of this.taskOperatingMaterials){
            if(taskOperatingMaterial.operatingMaterialsId == result[item].operatingMaterialsId) found = true;
          }
          if(!found) this.filteredOperatingMaterialsData.push(result[item])
        });

        this.originalOperatingMaterialsData = result;
        this.operatingMaterialsData = this.filteredOperatingMaterialsData;
        this.disableEditButtons = false;
      }
    });
  }

  selectOperatingMaterial(operatingMaterialsId){
    this.btnValue= 'Add';
    this.taskOperatingMaterialForm.patchValue({
      materialQuantity: null,
      quantityUnit: null,
      action: 'ADD'}
    );
    this.operatingMaterialsData = this.filteredOperatingMaterialsData;
  }

  /**
   * Validate quantity
   */
  validateQuantity(){
    this.materialQuantityDecimalLength = this.taskOperatingMaterialForm.value.quantityUnit == 1 ? 0 : 2;
    if(this.taskOperatingMaterialForm.value.quantityUnit == 1)
    {
      let value = Math.round(this.taskOperatingMaterialForm.controls["materialQuantity"].value);
      let newQuantityValue = value > 0 ? value  : null;
      this.taskOperatingMaterialForm.patchValue({materialQuantity : newQuantityValue});
    }
  }

  deleteMaterial(id)
  {
    const initialState = {
      title: 'Are you sure?',
      okBtnName: 'OK',
      closeBtnName: 'Cancel',
      isCommentVisible: this.projectFreezeFunction == 'activated'
    };
    let modalRef = this.modalService.show(ConfirmationBoxComponent, {class:'modal-md', initialState, ignoreBackdropClick:true});
    modalRef.content.event.subscribe(res =>
    {
      if(res?.result?.action == 'ok')
      {
        let selectedTaskMaterial = this.taskOperatingMaterials[id];
        let taskID = selectedTaskMaterial['taskDbId'];
        let operatingMaterialId = selectedTaskMaterial['operatingMaterialsId'];
        this.taskOperatingMaterialService.removeTaskOperatingMaterials(res?.result, taskID, operatingMaterialId).subscribe(res => {}, error =>
        {
          if(error.status == 500) {
            this.notificationService.error('The Task Operating Material you tried to delete is in use, hence cannot be deleted','Error');
          }else if(error.status == 403) {
            this.notificationService.error('You need to be in the Project Admins Group to perform the current Action','Error');
          }else if(error.status == 200 || error.status == 201 || error.status == 202  ) {
            this.notificationService.success('The Task Operating Material has been deleted','success');
            this.btnValue= 'Add';
            this.taskOperatingMaterialForm.patchValue({
              operatingMaterialsId: '',
              materialQuantity: '',
              quantityUnit: '',
              action: 'ADD'}
            );
            this.taskOperatingMaterialForm.controls['operatingMaterialsId'].enable();
          }else {
            this.notificationService.error('The system has not been deleted','Error');
          }

          
          this.getTaskOperatingMaterialByTask();
        });
      }
      else ; // do nothing
    });
  }

  /**
   * Get Quantity unit data
   */
  getQuantityUnitData(){
    this.quantityUnitService.getAllQuantityUnits().subscribe(res=>{
      this.quantityUnitData = res;
    });
  }

  updateQuantityValidation() {
    if(this.taskOperatingMaterialForm.controls['quantityAsRequired'].value){
      this.taskOperatingMaterialForm.controls['quantityUnit'].clearValidators();
      this.taskOperatingMaterialForm.controls['materialQuantity'].clearValidators();
      this.taskOperatingMaterialForm.controls['quantityUnit'].updateValueAndValidity();
      this.taskOperatingMaterialForm.controls['materialQuantity'].updateValueAndValidity();
    }
    else{
      this.taskOperatingMaterialForm.controls['quantityUnit'].setValidators([Validators.required]);
      this.taskOperatingMaterialForm.controls['materialQuantity'].setValidators([Validators.required]);
      this.taskOperatingMaterialForm.controls['quantityUnit'].updateValueAndValidity();
      this.taskOperatingMaterialForm.controls['materialQuantity'].updateValueAndValidity();
    }
  }

  /**
   * Get task operating materials by task
   */
  getTaskOperatingMaterialByTask(){
    this.disableEditButtons = true;
    this.taskOperatingMaterialService.getTaskOperatingMaterialsByTask(this.taskDbId).subscribe(res => {
      this.taskOperatingMaterials = res;

      if(this.originalOperatingMaterialsData.length > 0){
        let result = this.originalOperatingMaterialsData;

        this.filteredOperatingMaterialsData = [];
        Object.keys(result).forEach(item => {
          let found = false;
          for(let taskOperatingMaterial of this.taskOperatingMaterials){
            if(taskOperatingMaterial.operatingMaterialsId == result[item].operatingMaterialsId) found = true;
          }
          if(!found) this.filteredOperatingMaterialsData.push(result[item])
        });
        this.operatingMaterialsData = this.filteredOperatingMaterialsData;
        this.disableEditButtons = false;
      }
      else {
        this.getOperatingMaterials();
      }
    }, error => {
      this.getOperatingMaterials();
    });
  }

  /**
   * Edit task operating material
   * @param key
   */
  edittaskOperatingMaterial(key){
    this.btnValue= 'Edit';
    let selectedTaskMaterial = this.taskOperatingMaterials[key];
    this.taskOperatingMaterialForm.patchValue({
      projectNumber: this.param.projectNumber,
      systemNumber: this.param.systemNumber,
      taskDbId: this.param.taskDbId,
      operatingMaterialsId: selectedTaskMaterial['operatingMaterialsId'],
      materialQuantity: selectedTaskMaterial['materialQuantity'],
      quantityUnit: selectedTaskMaterial['quantityUnit'],
      quantityAsRequired: selectedTaskMaterial['quantityAsRequired'] == 1,
      taskName: this.param.taskName,
      systemName: this.param.systemDesignation,
      action: 'EDIT'}
    );

    // this.taskOperatingMaterialForm.controls['operatingMaterialsId'].disable();
    this.operatingMaterialsData.push(selectedTaskMaterial);
  }

  callAfterLanguageChanged() {
    this.getOperatingMaterials();
    this.getTaskOperatingMaterialByTask();
    this.getQuantityUnitData();
  }
}
