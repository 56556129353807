import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SparePartsService {

    constructor(private http: HttpClient) { }

    /**
     * Get all spare parts
     */
    getAllSpareParts(apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/parts/" + apiType, data, {params: params});
    }

    /**
     * Create new spareparts
     * @param data
     */
    create(reason, data)
    {
        const httpOptions = {
            params: {
                comment: reason['comment'],
                justificationCategory: reason['justificationCategory'] != null ? reason['justificationCategory'] : -1
            }
        };
        return this.http.post(environment.apiUrl + "/parts/", data, httpOptions);
    }

    /**
     * Delete spare parts
     * @param id
     */
    delete(res, id)
    {
        const httpOptions = {
            params: {
                comment: res['comment'],
                justificationCategory: res['justificationCategory'] ? res['justificationCategory'] : -1
            }
        };
        return this.http.delete(environment.apiUrl + "/parts/" + id, httpOptions);
    }

    /**
     * Update spare part
     * @param data
     * @param id
     */
    update(reason, data, id)
    {
        const httpOptions = {
            params: {
                comment: reason['comment'],
                justificationCategory: reason['justificationCategory'] != null ? reason['justificationCategory'] : -1
            }
        };
        return this.http.put(environment.apiUrl + "/parts/" + id + "/", data, httpOptions);
    }

    search(data)
    {
        return this.http.post(environment.apiUrl + "/parts/search/", data);
    }

    exportSpareParts()
    {
        return this.http.get(environment.apiUrl + "/parts/export/", {responseType: 'blob'});
    }

    uploadSpareParts(data)
    {
        return this.http.post(environment.apiUrl + "/parts/upload", data);
    }

    importSpareParts(data)
    {
        return this.http.post(environment.apiUrl + "/parts/import", data);
    }

    uploadSparePartsArticleChange(data)
    {
        return this.http.post(environment.apiUrl + "/parts/upload-article-change", data);
    }

    importSparePartsArticleChange(data)
    {
        return this.http.post(environment.apiUrl + "/parts/import-article-change", data);
    }
}
