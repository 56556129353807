import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxSelectModule } from 'ngx-select-ex';
import { LaddaModule } from 'angular2-ladda';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UserRoutingModule } from './user-routing.module';
import { UsersComponent } from './users/users.component';
import { AddUserComponent } from './components/add-user/add-user.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from 'src/app/shared/shared.module';
import {TreeTableModule} from "../../../shared/components/tree-table/tree-table.module";


@NgModule({
  declarations: [UsersComponent, AddUserComponent, EditUserComponent],
    imports: [
        CommonModule,
        UserRoutingModule,
        SharedModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        FormsModule,
        NgxSelectModule,
        NgxPaginationModule,
        LaddaModule,
        ModalModule,
        TreeTableModule,

    ],
  bootstrap:[AddUserComponent,EditUserComponent],
})
export class UserModule { }
