import { Component, OnInit } from '@angular/core';
import { faPlus, faSync, faFileExport, faSearch,faEdit,faAngleRight, faPrint, faBackward, faFile } from '@fortawesome/free-solid-svg-icons';
import { OperatingMaterialService } from 'src/app/services/operating-material.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AddOperatingMaterialsComponent } from '../components/add-operating-materials/add-operating-materials.component';
import { EditOperatingMaterialsComponent } from '../components/edit-operating-materials/edit-operating-materials.component';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationBoxComponent } from '../../../shared/components/confirmation-box/confirmation-box.component';
import { OperatingMaterialsLogComponent } from '../operating-materials-log/operating-materials-log.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {MasterListUsageUrlComponent} from "../../../shared/components/master-list-usage/master-list-usage-url/master-list-usage-url.component";
import {ActivatedRoute} from "@angular/router";
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-operating-materials',
  templateUrl: './operating-materials.component.html',
  styleUrls: ['./operating-materials.component.scss']
})
export class OperatingMaterialsComponent implements OnInit {

  faPlus=faPlus;
  faEdit=faEdit;
  faFile = faFile;
  showReset:Boolean=false;
  faSearch = faSearch;
  faFileExport = faFileExport;
  faSync=faSync;
  laddaLoading:Boolean=false;
  loading:Boolean=true;
  tableData:any=[];

  searchForm = new UntypedFormGroup({
    designationDe : new UntypedFormControl(''),
    stadlerArticleId : new UntypedFormControl('')
});

  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";
  filterData = [];

  settings = {
    type:'table',
    id:'operatingMaterialsId',
    columnManagerEnable:true,
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    enableZoom:true,
    filterOptionFunction:(params, filterData)=>{return this.operatingMaterialService.getAllOperatingMaterials("distinct-values", filterData, params)},
    columns:[
      {
        title:'ID',
        attribute:'operatingMaterialsId',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:50,
      },
      {
        title:'Category',
        attribute:'materialCategory',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150,
      },
      {
        title:'Designation DE',
        attribute:'designationDe',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:250
      },
      {
        title:'Designation EN',
        attribute:'designationEn',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:250,
      },
      {
        title:'Stadler Article ID',
        attribute:'stadlerArticleId',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Dummy',
        attribute:'dummy',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:100,
      },
      {
        title:'Comment',
        attribute:'comment',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150,
      },
      {
        renderComponent : MasterListUsageUrlComponent,
        type:'custom',
        onComponentInitFunction : (instance) => {
          instance.itemType = "operatingmaterials";
        },
        width:30
      },
      {
        renderComponent:'<a class="btn btn-info btn-sm text-light btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/edit.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.loadEditModal(row)},
        width:30
      },
      {
        renderComponent:'<a class="btn btn-danger btn-sm text-light btn-url"><img title="Delete" class="delete-icon" src="/assets/icon/delete.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.deleteMaterial(row)},
        width:50
      }
    ]
  };

  constructor(
      private operatingMaterialService: OperatingMaterialService,
      private modalService: BsModalService,
      private activatedRoute : ActivatedRoute,
      private notificationService: ToastrService

  ) {
    activatedRoute.queryParams.subscribe(params => {
      if(params['page']) this.currentPage = +params['page'];
      if(params['column']) this.sortColumn = params['column'];
      if(params['sortOrder']) this.sortOrder = params['sortOrder'];
    });

  }

  ngOnInit(): void {
    this.getAllOpearatingMaterials();
  }

  /**
   * Get all operating materials
   */
  getAllOpearatingMaterials(){
    this.loading = true;
    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;
    this.operatingMaterialService.getAllOperatingMaterials('filtered-data', this.filterData, params).subscribe(res=>{
      if(res && res['page']){
        this.pageCount = res['page']['totalPages'];
        this.tableData = res['page']['content'];
        this.loading = false;
      }
      if(res && res['tablePropertyMap']){
        let columns = this.settings.columns;
        let needFillColumnAndTable = false;
        for(let column of columns){
          if(column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
        }
        if(!needFillColumnAndTable) return;

        let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];
        for(let i=0; i<columns.length; i++){
          let data = tablePropertyMap[columns[i].attribute];
          if(!data) continue;
          columns[i].filter['column'] = res['isNative'] ? data['table'] + "." + data['column'] : data['column'];
          columns[i].filter['table'] = data['table'];
        }
      }
      this.settings['isNative'] = res && res['isNative'];
    });
  }

  filterOrSortChange(event){
    if(!event){
      this.currentPage = 1;
      this.tableData = [];
      return;
    }
    if(!event['sortChanged']) this.currentPage = 1;
    this.sortColumn = event['sortColumn'];
    this.sortOrder = event['sortOrder'];
    this.filterData = event['filterData'];

    this.getAllOpearatingMaterials();
  }

  /**
   * Delete operating material
   * @param event
   */
  deleteMaterial(rowData)
  {
    const initialState = {
      title: 'Are you sure?',
      okBtnName: 'Yes',
      closeBtnName: 'No'
    };
    let modalRef = this.modalService.show(ConfirmationBoxComponent, {class:'modal-md', initialState, ignoreBackdropClick:true});
    modalRef.content.event.subscribe(res =>
    {
      if(res?.result?.action == 'ok')
      {
        let operatingMaterialId = rowData.operatingMaterialsId;
        this.operatingMaterialService.deleteOperatingMaterial(res?.result, operatingMaterialId).subscribe(res => {}, error =>
        {
          if(error.status == 401 || error.status == 500) {
            this.notificationService.error('The Operating Material you tried to delete cannot be deleted!','Error');
          }else if(error.status == 412){
            this.notificationService.error('The Part you tried to delete is in use, hence cannot be deleted','Error');
          }
          else {
            this.notificationService.success('The Operating Material has been deleted','success');
          }
          this.getAllOpearatingMaterials();
        });
      }
      else ; // do nothing
    });
  }

  /**
   * Load Operating material Edit Modal
   * @param data
   */
  loadEditModal(rowData){

    const initialState = {
      param : rowData
    };
    let modalRef = this.modalService.show(EditOperatingMaterialsComponent, {class:'modal-lg',initialState,keyboard:false,ignoreBackdropClick: true});

    this.laddaLoading = false;
    modalRef.content.event.subscribe(res =>
    {
      this.getAllOpearatingMaterials();
    });
  }

  loadAddModal(){
    this.laddaLoading=true;
    const initialState = {
    };
    let modalRef = this.modalService.show(AddOperatingMaterialsComponent, {class:'modal-lg',initialState,keyboard:false,ignoreBackdropClick: true});

    this.laddaLoading = false;
    modalRef.content.event.subscribe(res =>
    {
      this.getAllOpearatingMaterials();
    });
  }

  public showLogs()
  {
    const initialState = {};
    this.modalService.show(OperatingMaterialsLogComponent, {class:'modal-xl', initialState,keyboard:false,ignoreBackdropClick: true});
  }

  changePage(currentPage){
    this.currentPage = currentPage;
    this.getAllOpearatingMaterials();
  }

  changePageSize(pageSize){
    this.pageSize = pageSize;
    this.getAllOpearatingMaterials();
  }

  public exportOperatingMaterials() {
    this.operatingMaterialService.exportOperatingMaterials().subscribe((res:any)=>{
      const blob = new Blob([res], { type: 'application/octet-stream' });
      const file = new File([blob], 'operating-materials.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      FileSaver.saveAs(file);
    });
  }
}
