import { Component } from '@angular/core';

@Component({
  selector: 'app-version-modal',
  templateUrl: './version-modal.component.html',
  styleUrls: ['./version-modal.component.scss']
})
export class VersionModalComponent {

  param:any;

  ngOnInit(): void {

    // apiBuildNumber:this.apiBuildNumber,
    // apiVersion:this.apiVersion,
    // uiVersion:this.uiVersion,
    // uiBuildNumber:this.buildNumber,
    // mismatchedBuild:this.mismatchedBuildBumber
  }

}
