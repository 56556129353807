import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OperatingProfileRoutingModule } from './operating-profile-routing.module';
import { OperatingProfileListComponent } from './operating-profile-list/operating-profile-list.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';
import { NgxSelectModule } from 'ngx-select-ex';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AddOperatingProfilesComponent } from './components/add-operating-profiles/add-operating-profiles.component';
import { EditOperatingProfilesComponent } from './components/edit-operating-profiles/edit-operating-profiles.component';
import { SharedModule } from '../../shared/shared.module';
import {TreeTableModule} from "../../shared/components/tree-table/tree-table.module";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@NgModule({
  declarations: [OperatingProfileListComponent, AddOperatingProfilesComponent, EditOperatingProfilesComponent],
    imports: [
        CommonModule,
        NgSelectModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        FormsModule,
        LaddaModule,
        SharedModule,
        NgxSelectModule,
        ModalModule.forRoot(),
        OperatingProfileRoutingModule,
        TreeTableModule,
        MatProgressSpinnerModule
    ],
  bootstrap:[AddOperatingProfilesComponent,EditOperatingProfilesComponent]
})
export class OperatingProfileModule { }
