import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { validateForm } from '../../shared/helper';
import { AuthServiceService } from '../../services/auth-service.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  disableSubmit:Boolean=false;
  btnLadda:Boolean=false;
  loginUser:any=[];
  passwordResetForm = new UntypedFormGroup(
      {
        newPassword : new UntypedFormControl('',[Validators.required]),
        newPasswordConfirm : new UntypedFormControl('',[Validators.required]),
      }
  );
  constructor(
      private notification: ToastrService,
      private authService : AuthServiceService,
      private userService : UserService

  ) { }


  ngOnInit(): void {
    this.getLoginUserData();
  }

  /**
   * Get login user data
   */
  getLoginUserData(){
    let loginUser = JSON.parse(localStorage.getItem('user'));

    let userId = loginUser['userId'];
    this.authService.getLoginUserData(userId).subscribe(res=>{
      this.loginUser = res;
      console.log('usr',this.loginUser);
    });


  }


  /**
   * Change Password
   */
  changePassword(){

    if(this.passwordResetForm.valid){

      let loginUser = JSON.parse(localStorage.getItem('user'));
      console.log(loginUser);
      this.btnLadda=true;
      let data = this.passwordResetForm.getRawValue();
      let payload = {
        newPassword: data['newPassword'],
        newPasswordConfirm: data['newPasswordConfirm']
      };

      // let formData = new FormData();
      // formData.append('newPassword',data['newPassword']);
      // formData.append('newPasswordConfirm',data['newPasswordConfirm']);
      // formData.append('userId',loginUser['userId']);

      this.userService.resetOwnPassword(payload, loginUser['userId']).subscribe(res=>{
        if(res){
          this.notification.success('Password reset has been done','success');
          this.btnLadda = false;

        }
      },error=>{
        this.notification.error('Password reset failed','Error');
        this.btnLadda = false;
      });



    }else{

      validateForm(this.passwordResetForm);
    }

  }


  validateConfirmPassword(){
    let formData = this.passwordResetForm.getRawValue();

    if(formData.newPasswordConfirm!=''){
      if(formData.newPassword!=formData.newPasswordConfirm){
        this.disableSubmit = true;
      }else{
        this.disableSubmit = false;
      }
    }
  }

}
