import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faPlus, faSync, faSearch,faEdit, faFileExport, faBackward, faFile } from '@fortawesome/free-solid-svg-icons';
import { AddSpecialToolsComponent } from './components/add-special-tools/add-special-tools.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RouterService } from 'src/app/services/router.service';
import { EditSpecialToolsComponent } from './components/edit-special-tools/edit-special-tools.component';
import { SpecialToolService } from 'src/app/services/special-tool.service';
import { ToastrService } from 'ngx-toastr';
import {ConfirmationBoxComponent} from '../../shared/components/confirmation-box/confirmation-box.component';
import {SpecialToolsLogComponent} from './special-tools-log/special-tools-log.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {MasterListUsageUrlComponent} from "../../shared/components/master-list-usage/master-list-usage-url/master-list-usage-url.component";
import * as FileSaver from 'file-saver';

@Component({
    selector: 'app-project-system-tools',
    templateUrl: './special-tools.component.html',
    styleUrls: ['./special-tools.component.scss']
})
export class SpecialToolsComponent implements OnInit {

    projectNumber:String;
    faSync = faSync;
    faSearch = faSearch;
    faFileExport = faFileExport;
    systemNumber:String;
    searchForm:any;
    showReset:Boolean=false;
    searchLadda:Boolean = false;
    laddaLoading = false;
    faPlus = faPlus;
    faBackward = faBackward;
    faFile = faFile;
    previousUrl:any;
    tableData:any;
    loading=true;
    config = {
        keyboard: true
    };
    toolNumber:any;

    modalRef : BsModalRef;

    currentPage:number = 1;
    pageCount:number = 0;
    pageSize:number = 20;
    sortColumn:string = "";
    sortOrder:string = "";
    filterData = [];

    settings = {
        type:'table',
        id:'toolNumber',
        columnManagerEnable:true,
        pagination:{
            enabled:true,
            pageSize:this.pageSize
        },
        enableZoom:true,
        filterOptionFunction:(params, filterData)=>{return this.specialToolService.getSpecialToolList("distinct-values", filterData, params)},
        columns:[
            {
                title:'Tool Number',
                attribute:'toolNumber',
                type:'text',
                filter: {
                    enabled:true,
                    isNumericField:true,
                },
                width:100,
            },
            {
                title:'Tool Category',
                attribute:'category',
                type:'text',
                filter: {
                    enabled:true,
                    isNumericField:true,
                },
                width:100
            },
            {
                title:'Tool Designation DE',
                attribute:'designationDe',
                type:'text',
                filter: {
                    enabled:true,
                    isNumericField:false,
                },
                width:250,
            },
            {
                title:'Tool Designation EN',
                attribute:'designationEn',
                type:'text',
                filter: {
                    enabled:true,
                    isNumericField:false,
                },
                width:250,
            },
            {
                title:'Stadler Article ID',
                attribute:'toolArticleId',
                type:'text',
                filter: {
                    enabled:true,
                    isNumericField:true,
                },
                width:250,
            },
            {
                title:'Supplier Article ID',
                attribute:'toolSupplierArticleId',
                type:'text',
                filter: {
                    enabled:true,
                    isNumericField:true,
                },
                width:250,
            },
            {
                title:'Supplier',
                attribute:'toolSupplierName',
                type:'text',
                filter: {
                    enabled:true,
                    isNumericField:false,
                },
                width:250,
            },
            {
                title:'Dummy',
                attribute:'dummy',
                type:'text',
                filter: {
                    enabled:true,
                    isNumericField:false,
                },
                width:150,
            },
            // {
            //     title:'Tool Cost',
            //     attribute:'cost',
            //     type:'text',
            //     filter: {
            //         enabled:true,
            //         isNumericField:true,
            //     },
            //     width:100,
            // },
            {
                renderComponent : MasterListUsageUrlComponent,
                type:'custom',
                onComponentInitFunction : (instance) => {
                    instance.itemType = "tools";
                },
                width:30
            },
            {
                renderComponent:'<a class="btn btn-info btn-sm text-light btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/edit.png"/></a>',
                type:'html',
                actionFunction: (row)=>{this.loadEditModal(row)},
                width:30
            },
            {
                renderComponent:'<a class="btn btn-danger btn-sm text-light btn-url"><img title="Delete" class="delete-icon" src="/assets/icon/delete.png"/></a>',
                type:'html',
                actionFunction: (row)=>{this.deleteRecord(row)},
                width:50
            },
        ]
    };

    constructor(
        private activatedRoute: ActivatedRoute,
        private modalService: BsModalService,
        private routerService: RouterService,
        private specialToolService: SpecialToolService,
        private notificationService: ToastrService
    ) {
        activatedRoute.queryParams.subscribe(params => {
            if(params['page']) this.currentPage = +params['page'];
            if(params['column']) this.sortColumn = params['column'];
            if(params['sortOrder']) this.sortOrder = params['sortOrder'];
        });
    }

    ngOnInit(): void {
        this.searchForm = new UntypedFormGroup({
            designation : new UntypedFormControl(null),
            toolArticleId : new UntypedFormControl(null)
        });
        this.projectNumber = this.activatedRoute.snapshot.paramMap.get("projectNumber");
        this.systemNumber = this.activatedRoute.snapshot.paramMap.get("systemNumber");
        this.refreshTable();
    }

    loadAddModal(){
        const initialState = {};
        let modalRef = this.modalService.show(AddSpecialToolsComponent, {class: 'modal-xl',initialState,keyboard:false,ignoreBackdropClick: true});
        modalRef.content.event.subscribe(res => {
            this.refreshTable();
        });
    }

    deleteRecord(rowData)
    {
        const initialState = {
            title: 'Are you sure?',
            okBtnName: 'Yes',
            closeBtnName: 'No'
        };
        this.modalRef = this.modalService.show(ConfirmationBoxComponent, {class:'modal-md', initialState, ignoreBackdropClick:true});
        this.modalRef.content.event.subscribe(res =>
        {
            if(res?.result?.action == 'ok')
            {
                let toolNumber = rowData.toolNumber;
                this.specialToolService.deleteTool(res?.result, toolNumber).subscribe(res => {}, error =>
                {
                    if(error.status == 412 || error.status == 401 || error.statusCode == 500) {
                        this.notificationService.error('The Tool you tried to delete is in use, hence cannot be deleted','Error');
                    }
                    else {
                        this.notificationService.success('The Tool has been deleted','success');
                    }
                    this.refreshTable();
                });
            }
            else ; // do nothing
        });
    }

    refreshTable(){
        this.loading = true;
        let params = {};
        if(this.currentPage) params['page'] = this.currentPage-1;
        if(this.pageSize) params['pageSize'] = this.pageSize;
        if(this.sortColumn) params['column'] = this.sortColumn;
        if(this.sortOrder) params['sortOrder'] = this.sortOrder;
        this.specialToolService.getSpecialToolList("filtered-data", this.filterData, params).subscribe(res=>{
            if(res && res['page']){
                this.pageCount = res['page']['totalPages'];
                this.tableData = res['page']['content'];
                
            }
            if(res && res['tablePropertyMap']){
                let columns = this.settings.columns;
                let needFillColumnAndTable = false;
                for(let column of columns){
                    if(column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
                }
                if(!needFillColumnAndTable) return;

                let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];
                for(let i=0; i<columns.length; i++){
                    let data = tablePropertyMap[columns[i].attribute];
                    if(!data) continue;
                    columns[i].filter['column'] = res['isNative'] ? data['table'] + "." + data['column'] : data['column'];
                    columns[i].filter['table'] = data['table'];
                }
            }
            this.settings['isNative'] = false;
        }, error => {}, () => {
            this.loading = false;
        });
    }

    filterOrSortChange(event){
        if(!event){
            this.currentPage = 1;
            this.tableData = [];
            return;
        }
        if(!event['sortChanged']) this.currentPage = 1;
        this.sortColumn = event['sortColumn'];
        this.sortOrder = event['sortOrder'];
        this.filterData = event['filterData'];

        this.refreshTable();
    }

    loadEditModal(rowData){
        const initialState = {
            param : {
                toolNumber : rowData.toolNumber
            }
        };
        let modalRef = this.modalService.show(EditSpecialToolsComponent, {class: 'modal-xl',initialState,keyboard:false,ignoreBackdropClick: true});
        modalRef.content.event.subscribe(res => {
            this.refreshTable();
        });
    }

    public showLogs()
    {
        const initialState = {};
        this.modalRef = this.modalService.show(SpecialToolsLogComponent, {class:'modal-xl', initialState,keyboard:false,ignoreBackdropClick: true});
    }

    public changePage(currentPage){
        this.currentPage = currentPage;
        this.refreshTable();
    }

    public changePageSize(pageSize){
        this.pageSize = pageSize;
        this.refreshTable();
    }

    public exportTools() {
        this.specialToolService.exportTools().subscribe((res:any)=>{
            const blob = new Blob([res], { type: 'application/octet-stream' });
            const file = new File([blob], 'tools.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            FileSaver.saveAs(file);
        });
    }
}
