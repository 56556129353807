import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TreeComponent} from "../tree/tree.component";

@Component({
  selector: 'app-tree-table',
  templateUrl: './tree-table.component.html',
  styleUrls: ['./tree-table.component.scss']
})
export class TreeTableComponent implements OnInit {

  MAX_PAGINATE_NUMBER = 7;


  @ViewChild(TreeComponent) public treeTable: TreeComponent;
  @ViewChild('outerDiv', { static: false }) outerDivElement: ElementRef;

  @Output() pageChangeEvent = new EventEmitter<any>();
  @Output() pageSizeChangeEvent = new EventEmitter<any>();
  @Output() filterChangeEvent = new EventEmitter<any>();

  @Input() spinnerVisible = false;
  @Input() spinnerText = "";
  @Input() settings;
  @Input() data;
  @Input() pageCount:number = 10;
  @Input() currentPage:number = 1;
  pageSize:number = 20;

  showResetFilters = false;
  zoomRatio:any = 1;
  pageSizeInputValue = this.pageSize;

  spinnerPosition:any = {'top':0, 'left':0};

  constructor() { }

  ngOnInit(): void {
    if(this.settings.pagination && this.settings.pagination.enabled){
      this.pageSize = this.settings.pagination.pageSize
      this.pageSizeInputValue = this.pageSize;
    }
    this.checkForShowResetFilters();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    
      this.treeTable.calculateColumnWidths();
    
  }

  ngAfterViewInit(){
    setTimeout(()=>{
      this.placeSpinnerAfterDataReceived();
    })
  }

  placeSpinnerAfterDataReceived(){
    if(this.data){
      this.placeSpinner();
      return;
    }
    setTimeout(()=>{
      this.placeSpinnerAfterDataReceived();
    })
  }

  placeSpinner(){ // sets spinner location
    if(!this.outerDivElement || !this.outerDivElement.nativeElement || !this.outerDivElement.nativeElement.getBoundingClientRect()) return;

    let divHeight = this.outerDivElement.nativeElement.getBoundingClientRect().height;
    let divWidth = this.outerDivElement.nativeElement.getBoundingClientRect().width;
    let divTop = this.outerDivElement.nativeElement.getBoundingClientRect().top;
    let divLeft = this.outerDivElement.nativeElement.getBoundingClientRect().left;

    this.spinnerPosition.top = divHeight/2 + divTop - 50;
    this.spinnerPosition.left = divWidth/2 + divLeft - 50;
  }

  zoomRatioChange(event){
    if(!event || !event.value) return;

    this.zoomRatio = event.value;
  }

  gotoFirst(){
    this.currentPage = 1;
    this.pageChangeEvent.emit(this.currentPage);
  }

  gotoPrevious(){
    this.currentPage--;
    this.pageChangeEvent.emit(this.currentPage);
  }

  gotoNext(){
    this.currentPage++
    this.pageChangeEvent.emit(this.currentPage);
  }

  gotoLast(){
    this.currentPage = this.pageCount;
    this.pageChangeEvent.emit(this.currentPage);
  }

  gotoPage(page){
    this.currentPage = page;
    this.pageChangeEvent.emit(this.currentPage);
  }

  getPaginationSequence(): Array<number> { // sets pagination's pages according to MAX_PAGINATE_NUMBER
    let array = [];
    let start;
    let end;
    if(this.pageCount > this.MAX_PAGINATE_NUMBER) {
      if(this.currentPage > this.pageCount - (this.MAX_PAGINATE_NUMBER-1)/2) {
        start = this.pageCount - (this.MAX_PAGINATE_NUMBER-1);
      }
      else if(this.currentPage > (this.MAX_PAGINATE_NUMBER-1)/2){
        start = this.currentPage - 3
      }
      else {
        start = 1;
      }
      end = start + this.MAX_PAGINATE_NUMBER-1;
    }
    else{
      start = 1;
      end = this.pageCount;
    }

    for(let i=start; i<end+1; i++){
      array.push(i);
    }

    return array;
  }

  expandAll(){
    for(let i=0; i<this.treeTable.treeNodes.length; i++){
      let node = this.treeTable.treeNodes.get(i);
      if(node && (!node['columnType'] || node['columnType'] == 'left')){
        this.treeTable.expandAllNodes(node);
      }
    }
  }

  expandPath(path){
    this.treeTable.expandAllNodes(null, path);
  }


  columnEditChange(state, index){
    this.settings.columns[index].isHidden = !state;
    this.treeTable.calculateColumnWidths();
  }

  changePageSize(){
    this.pageSize = this.pageSizeInputValue;
    this.pageSizeChangeEvent.emit(this.pageSize);
  }

  changeFilter(event){
    this.filterChangeEvent.emit(event);
  }

  resetFilters(){
    this.treeTable.resetFilters();
  }

  checkForShowResetFilters(){
    for(let column of this.settings.columns){
      if(column && column['filter'] && column['filter']['enabled']) {
        this.showResetFilters = true;
        break;
      }
    }
  }
}
