import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss']
})
export class ConfirmationBoxComponent implements OnInit {

  isCommentVisible:boolean = true;
  isCommentRequired:boolean = this.isCommentVisible;
  event: EventEmitter<any> = new EventEmitter();
  title:string;
  text:string;
  modalRef:BsModalRef;
  titleBackground:string;
  okBtnName:string = "OK";
  closeBtnName:string = "Cancel";

  btnLoading:Boolean = false;

  constructor(modalRef:BsModalRef) {
    this.modalRef = modalRef;
  }

  ngOnInit(): void {
  }

  public okClicked(event){
    this.btnLoading = true;
    this.event.emit({ result: event });
    this.modalRef.hide();
    this.btnLoading = false;
  }

  public cancelClicked(event){
    this.event.emit({ result: event });
    this.modalRef.hide();
  }
}
