import {Component, EventEmitter, OnInit} from '@angular/core';

@Component({
  selector: 'app-actual-quantity',
  templateUrl: './actual-quantity.component.html',
  styleUrls: ['./actual-quantity.component.scss']
})
export class ActualQuantityComponent implements OnInit {

  rowData:any;
  inputValueChange:EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {

  }

  valueChange(){
    this.rowData['invalidActualQuantity'] = this.rowData['actualQuantity'] == null || this.rowData['actualQuantity'] == "" || this.rowData['actualQuantity'] == 0 || this.rowData['actualQuantity'] > this.rowData['quantityOnVehicleLevel'];
    this.inputValueChange.emit(this.rowData);
  }
}
