import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TargetManualService {

    constructor(private http: HttpClient) { }

    /**
     * Get all Target manual data
     */
    getAll()
    {
        return this.http.get(environment.apiUrl + "/targetmanual/");
    }
}
