// version.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  // private apiVersionSubject = new BehaviorSubject<string>(''); // Initialize with an empty string
  // public apiVersion$ = this.apiVersionSubject.asObservable();
  // private apiBaseUrl = environment.apiUrl;
  private versionOutputSource = new Subject<string>();
  versionOutput$ = this.versionOutputSource.asObservable();


  constructor(private http: HttpClient) {
    // Fetch API version every 5 minutes (300000 ms)
    // interval(300000).pipe(
    //   switchMap(() => this.http.get<string>(this.apiBaseUrl + '/version'))
    // ).subscribe((version: string) => {
    //   this.apiVersionSubject.next(version);
    // });
  }

  getApiVersion(timestamp){
    try{
      return this.http.get(environment.apiUrl + "/version/internal");
    }catch(e){
      console.log("Error in fetching version details");
    }
  }

  getApiVersionExternal(timestamp){
    try{
      return this.http.get(environment.apiUrl + "/version");
    }catch(e){
      console.log("Error in fetching version details");
    }
  }

  sendVersionDetails(data){
    this.versionOutputSource.next(data);
  }

 

  
}
